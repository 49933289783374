import React, { Component } from 'react';
import moment from 'moment';
// import { removeHTML } from '../../LumiAPI/newChatAPI.js';

// import Icon from '../../components/Icon';

import { connect } from 'react-redux';
import { openChat, refreshMessages } from '../../actions/chat-actions.js';

import './css/conversationList.css';

class ConversationList extends Component {
    state = {
        selectedChat: this.props.currentChat,
        chats: this.props.chats,
        recentMessages: this.props.recentMessages,
        listOpen: this.props.open ?? false
    };

    openChat = chatId => {
        this.props.onRefreshMessages([]);
        this.props.onOpenChat(chatId);
        this.setState({ listOpen: false });
    };

    getUserInitials = userId => {
        let initials = "",
            users = this.props.chatUsers,
            name = users[userId]?.displayName.split(" ") ?? [];
        name.forEach(n => {
            initials += n[0].toUpperCase();
        });
        return initials;
    };

    getTimestamp = lastSent => {
        let now = moment();
        if (lastSent.date() !== now.date()) {
            return lastSent.format('MMM Mo');
        } else if (now.hour() === lastSent.hour() &&
            (now.minutes() - lastSent.minutes()) < 1
        ) {
            return "Just Now";
        } else {
            return lastSent.format('LT');
        }
    };

    className = () => {
        let className = "";
        className += this.state.listOpen ? "" : " hide";
        className += this.state.selectedChat === "" ? " fullscreen" : "";
        return className.trim();
    };

    componentDidUpdate(prevProps) {
        if (this.props.currentChat !== prevProps.currentChat) {
            this.setState({ selectedChat: this.props.currentChat });
        }

        if (this.props.chats !== prevProps.chats) {
            this.setState({ chats: this.props.chats });
        }

        if ((this.props.recentMessages !== prevProps.recentMessages) ||
            (this.props.deliveredIndex !== prevProps.deliveredIndex)
        ) {
            this.setState({ recentMessages: this.props.recentMessages });
        }

        if (this.props.open !== prevProps.open) {
            this.setState({ listOpen: this.props.open });
        }
    }

    render() {
        let chats = this.state.chats,
            chatIds = Object.keys(chats).sort((a, b) => {
                let recents = this.state.recentMessages,
                    left = recents?.[a]?.created,
                    right = recents?.[b]?.created;
                if (left === right) return 0;
                return left > right ? -1 : 1;
            });
        return (
            <aside className={this.className()}>
                <ul>
                    {chatIds.map(chatId => (
                        <li key={chatId} className={chatId === this.state.selectedChat ? "selected" : chatId === "noChats" ? "noClick" : ""} onClick={() => this.openChat(chatId)}>
                            {chats[chatId]?.userIds?.map((userId, i) => {
                                return i < 2 ? <span>{this.getUserInitials(userId)}</span> : "";
                            })}
                            <div>
                                <b>{chats[chatId].displayName}</b>
                                <span>{chatId !== "noChats"
                                    ? this.state.recentMessages?.[chatId]?.message ?? "Loading..."
                                    : ""
                                }</span>
                            </div>
                            <div className="timestamp">
                                <span></span>
                                {this.getTimestamp(this.state.recentMessages?.[chatId]?.created ?? moment())}
                            </div>
                        </li>
                    ))}
                    {chatIds.length === 0 && this.props.loading && <li className="noClick">
                        <div><span>Loading...</span></div>
                    </li>}
                    {chatIds.length === 0 && !this.props.loading && <li className="noClick">
                        <div><b>No Chats Found</b></div>
                    </li>}
                </ul>
            </aside>
        );
    }
}

const mapStateToProps = state => ({
    chats: state.chats,
    currentChat: state.currentChat,
    recentMessages: state.recentMessages,
    deliveredIndex: state.deliveredIndex
});

const mapActionsToProps = {
    onOpenChat: openChat,
    onRefreshMessages: refreshMessages
};

export default connect(mapStateToProps, mapActionsToProps)(ConversationList);
