import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { Token } from '../LumiAPI/APIs/auth.js';
import {
    confColors as colors,
    confColors_hex as hexColors, defaultConfValues,
    features, featuresList, hostPrivileges, presenterPrivileges, privileges, privilegesList, startMethodOptions, STATE
} from '../LumiAPI/APIs/core/confDefaults';
import ConferenceAPI from '../LumiAPI/APIs/core/conference.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { roles, rolesText } from '../LumiAPI/APIs/internal/account.js';
import { getIntegrations } from '../LumiAPI/APIs/internal/integration.js';
import { ENV } from '../tools/envTools';
import Events from '../tools/Events';
import { checkProtocolSupport } from '../tools/protocol_detection';

import ConferenceTour from '../partials/ConferenceTour';

import { faKey, faLink } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/Button';
import Dialog, { Container } from '../components/Dialog';
import Icon from '../components/Icon';
import SelectBox from '../components/SelectBox';
import ShareDialog from '../components/ShareDialog';
import TextField from '../components/TextField';
import Toggle from '../components/Toggle';
import blackboardLogo from '../images/integrations/blackboardLogo.png';
import canvasLogo from '../images/integrations/canvasLogo.png';
import moodleLogo from '../images/integrations/moodleLogo.png';
import List from '../partials/List';
import ContentWithToolbar from '../templates/ContentWithToolbar';

import Modal from '../tools/Modal';
import Notification from '../tools/Notification';

import { countryCodeIndex, countryCodes } from './json/countryCodes.js';

import { connect } from 'react-redux';

import './css/conferences.css';

const STATE_LIST = { ALL: 1, WAITING: 2, START: 3, END: 4 };
const STATE_DESC = { 1: "All", 2: "Waiting to Start", 3: "Live", 4: "Ended" };
const INT_ICONS = { 1: canvasLogo, 2: moodleLogo, 3: blackboardLogo };

const apiDomain = ENV.getApiDomain();

class Conferences extends Component {
    confTour = React.createRef();
    shareRef = ShareDialog.getRef();

    state = {
        loaded: false,
        state: STATE_LIST.ALL,
        conferences: {},
        selected: [],
        integrations: [],
        features: [...featuresList],
		privileges: [...privilegesList],
        selectedPrivilegeRole: "privileges",
        connectSubdomain: `connect${apiDomain}`,
        rootDomain: "cordoniq.com",
        new: {}
    };

    switchList = state => {
        this.setState({ state });
        this.goTo(`/Portal/Conferences/${state}`);
    };

    clearConferences = () => this.setState({ loaded: false, conferences: {} });

    getConferences = (state, active, intId) => {
        this.clearConferences();

        let filters = {};

        if (state) {
            this.switchList(state);
        } else {
            state = this.state.state;
        }

        filters.state = state - 1 !== 0 ? state - 1 : undefined;

        if (active !== undefined && active !== null) {
            this.setState({ active });
        } else {
            active = this.state.active;
        }

        filters.active = active;

        if (intId !== undefined && intId !== null) {
            this.setState({ intId });
        } else {
            intId = this.state.intId;
        }

        filters.meta_data3 = intId !== undefined && intId !== null && intId !== "" ? intId : undefined;

        ConferenceAPI.conferences.get(filters).then(conferences => {
            let conferencesObj = {};
            conferences.forEach(conference => {
                conference.id = conference.confId;
                conference.title = conference.confId;
                conference.subtitle = conference.displayName;
                conference.action = {
                    label: parseInt(this.state.state) === STATE_LIST.END ? "Restart" : "Join Now",
                    props: {
                        onClick: e => {
                            e.stopPropagation();
                            parseInt(this.state.state) === STATE_LIST.END
                                ? this.changeState({ newState: STATE.START, confId: conference.confId })
                                : this.joinNow(conference.confId);
                        }
                    }
                };
                conference.buttons = [
                    { icon: "share", props: {
                        mode: "light",
                        onClick: e => {
                            e.stopPropagation();
                            this.shareRef.current.open("attendee", conference);
                        }
                    }},
                    { icon: "moreVert", props: {
                        mode: "light",
                        onClick: e => {
                            e.stopPropagation();
                            this.goTo(`/Portal/Conference/${conference.confId.toLowerCase()}`);
                        }
                    }}
                ];
                conferencesObj[conference.confId] = conference;
            });
            this.setState({ loaded: true, conferences: conferencesObj });
        }).catch(handleErrors);
    };

    openNewDialog = () => {
		let defaults = { ...defaultConfValues };
		defaults.features = { ...features };
		defaults.privileges = { ...privileges };
		defaults.presenterPrivileges = { ...presenterPrivileges };
		defaults.hostPrivileges = { ...hostPrivileges };
		defaults.attendMethods = { password: true, url: true };
		this.setState({ new: defaults, selectedPrivilegeRole: "privileges", showNewDialog: true });
	};

    addConference = () => {
        ConferenceAPI.conference.new(this.state.new).then(conference => {
            this.getConferences(STATE_LIST.WAITING);
            this.setState({ showNewDialog: false });
            if (!ENV.isDev()) {
                new Notification({
    				id: "newConfAdded",
    				message: "Your conference was added successfully!",
    				type: "success"
    			});
            }
        }).catch(handleErrors);
    };

    changeState = ({ newState, confId }) => {
        if (confId === undefined) {
            this.state.selected.forEach(confId => this.changeState({ newState, confId }));
        } else {
            if (newState === STATE.DELETE) {
                ConferenceAPI.conference.delete(confId).then(result => {
                    if (confId !== undefined) {
                        this.getConferences();
                    }
                }).catch(err => {
                    let code = err?.response?.data?.error_code ?? null;
                    let message = err?.response?.data?.error_description ?? null;
                    if (code === 205) {
                        Modal(<>
							<h1>Are You Sure?</h1>
							<p>The conference has not been ended. Do you want to delete it anyway?</p>
						</>, [
							{ name: "No", props: { outline: true } },
							{ name: "Yes", props: { onClick: () => {
								ConferenceAPI.conference.update(confId, { state: STATE.END })
									.then(conference => this.changeState({ newState: STATE.DELETE, confId }))
									.catch(handleErrors);
							}}}
						], { alert: true });
                    } else {
                        new Notification({ id: "cannotDelete", message: message, type: "error" });
						handleErrors(err);
                    }
                });
            } else {
                ConferenceAPI.conference.update(confId, { state: newState })
                    .then(conference => this.getConferences(newState + 1))
                    .catch(handleErrors);
            }
        }
    };

    getConferenceAction = confId => {
        let button = { label: "Start", props: {} };

        let startButton = disabled => {
            button.label = "Start";
            button.props.onClick = () => this.changeState({ newState: STATE.START, confId });
            button.props.disabled = disabled;
            return button;
        };

        let endButton = disabled => {
            button.label = "End";
            button.props.onClick = () => this.changeState({ newState: STATE.END, confId });
            button.props.disabled = disabled;
            return button;
        };

        let restartButton = disabled => {
            button.label = "Restart";
            button.props.onClick = () => this.changeState({ newState: STATE.START, confId });
            button.props.disabled = disabled;
            return button;
        };

		let lookupButton = (state, disabled) => {
			switch (state) {
				case 1:
					return startButton(disabled);
				case 2:
					return endButton(disabled);
				case 3:
					return restartButton(disabled);
                default:
                    return undefined;
			}
		};

        switch(parseInt(this.state.state)) {
			case STATE_LIST.ALL:
				let selectedStates = [],
					conferences = this.state.conferences;

				this.state.selected.forEach(confId => {
                    if (conferences[confId] === undefined) return;
					let confState = conferences[confId].state;
					if (!selectedStates.includes(confState)) {
						selectedStates.push(confState);
					} else {
						let index = selectedStates.indexOf(confState);
						selectedStates.splice(selectedStates.length, 0, selectedStates.splice(index, 1)[0]);
					}
				});

				return selectedStates.length > 0
					? lookupButton(selectedStates[selectedStates.length - 1], selectedStates.length > 1)
					: startButton(this.state.selected.length === 0);
			case STATE_LIST.WAITING:
				return startButton(this.state.selected.length === 0);
			case STATE_LIST.START:
				return endButton(this.state.selected.length === 0);
			case STATE_LIST.END:
				return confId === undefined ? null : restartButton(this.state.selected.length === 0);
			default:
				return null;
		}
    };

    openDeleteDialog = () => {
        Modal(<>
            <h1>Delete?</h1>
            <p>Are you sure you want to delete the selected conference/s?</p>
        </>, [
            { name: "No", props: { outline: true } },
            { name: "Yes", props: { onClick: () => this.changeState({ newState: STATE.DELETE }) } }
        ], { alert: true });
    };

    joinString = params => {
        let search = `?id=${params.id}`;
        search += params.password ? `&password=${params.password}` : "";
        search += params.token ? `&access_token=${params.token}` : "";
        return search;
    };

    protocolUrl = params => {
        let urlProtocolHandler = this.props.app?.urlProtocolHandler ?? "cordoniq";
        return `${urlProtocolHandler}://${this.state.connectSubdomain}.${this.state.rootDomain}/join${this.joinString(params)}`;
    };

    connectUrl = params => (`https://${this.state.connectSubdomain}.${this.state.rootDomain}/join${this.joinString(params)}`);

    joinNow = confId => {
        let user = this.props.curRole === roles.OWNER ? roles.HOST : this.props.curRole;
        let password = this.state.conferences[confId][rolesText[user].toLowerCase()+"Password"];
        let params = {
            id: confId,
            password: password === "" ? undefined : password,
            token: Token.get()
        };

        let protocolUrl = this.protocolUrl(params);
        let connectPage = this.connectUrl(params);

        checkProtocolSupport(protocolUrl,
            () => { window.open(connectPage, '_blank'); }, // Failed
            () => { return protocolUrl; }, // Successful
            () => { window.open(connectPage, '_blank'); } // Unsupported
        );
    }

    getIntegrations = app => {
        let clientId = app?.clientId ?? undefined;
        if (clientId !== undefined) {
            getIntegrations(clientId).then(integrations => {
                this.setState({ integrations: integrations.filter(integration => integration.state !== 3)});
            }).catch(handleErrors);
        }
    };

    onEdit = change => {
        let newConf = this.state.new;
        newConf[change.name] = change.value;
        this.setState({ new: newConf });
    };

    handleEvents = events => {
        let e = events;
        new Notification({ id: e.confId+e.eventKind, message: e.eventKind, type: "success" });
        this.getConferences();
    };

    componentDidMount() {
        document.title = "Conferences | Cordoniq";

        let state = this.props.match.params.state;
        this.setState({ state });

        this.getConferences(state);
        this.getIntegrations(this.props.app);

        let rootDomain = this.props.app?.rootDomain ?? "cordoniq.com";
		let connectSubdomain = this.props.app?.connectSubdomain ?? "connect";
		this.setState({ rootDomain: rootDomain, connectSubdomain: connectSubdomain + apiDomain });

        if (ENV.isDev()) {
            Events.addListener("conferences", this.handleEvents);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.getConferences();
            this.getIntegrations(this.props.app);
            let rootDomain = this.props.app?.rootDomain ?? "cordoniq.com";
    		let connectSubdomain = this.props.app?.connectSubdomain ?? "connect";
    		this.setState({ rootDomain: rootDomain, connectSubdomain: connectSubdomain + apiDomain });
        }

        if (this.props.match.params.state !== prevProps.match.params.state) {
            let state = this.props.match.params.state;
            this.getConferences(state);
            this.setState({ state });
        }
    }

    componentWillUnmount() {
        if (ENV.isDev()) {
            Events.removeListener("conferences", this.handleEvents);
        }
    }

    render() {
        let tools = [];
        if (this.props.curRole === roles.OWNER) tools.push(<button id="confTour" onClick={() => this.confTour.current.show()}>Demo</button>);
        tools.push(<button onClick={this.openNewDialog}>Create New</button>);

        let actions = [];
        let confAction = this.getConferenceAction();
        if (confAction !== null) actions.push(confAction);
        if (this.props.curRole >= roles.HOST) actions.push({
            label: "Delete",
            props: { delete: true, onClick: () => this.openDeleteDialog(), disabled: this.state.selected.length === 0 }
        });

        return (
            <>
            <ConferenceTour ref={this.confTour} openNew={this.openNewDialog} dialogControl={this.state.dialogControl ?? 0} />
            <ContentWithToolbar id="conferences" path={`/Portal/Conferences/${this.state.state}`} tools={tools}>
                <ShareDialog ref={this.shareRef} app={this.props.app} />
                <Dialog className="newConference" show={this.state.showNewDialog} title="Create New" footer={[
                    <Button outline onClick={() => {
                        this.setState({ showNewDialog: false, dialogControl: this.state.dialogControl + 1 ?? 1 });
                    }}>Cancel</Button>,
                    <Button onClick={() => {
                        this.addConference();
                        this.setState({ dialogControl: this.state.dialogControl + 1 ?? 1 });
                    }} disabled={!this.state.new?.displayName?.length > 0 ?? true}>Save</Button>
                ]}>
                    <Container label="Conference Details">
                        <TextField tabIndex="1" name="displayName" placeholder="Display Name" label="Name" value={this.state.new?.displayName} onEdit={this.onEdit} />
                        <SelectBox tabIndex="2" name="startMethod" label="Start Method" selected={this.state.new?.startMethod} options={startMethodOptions} onChange={value => {
                            let newConf = this.state.new;
                            newConf.startMethod = value;
                            this.setState({ new: newConf });
                        }} />
                        <div className="tag">
                            <label>Select a color label</label>
                            {colors.map((color, i) => (
                                <React.Fragment key={"color_"+i}>
                                <input type="radio" id={color} name="backgroundColor" value={i} checked={this.state.new?.backgroundColor === i ?? i === 0} onClick={e => {
                                    let newConf = this.state.new;
                                    newConf.backgroundColor = parseInt(e.target.value);
                                    this.setState({ new: newConf });
                                }} />
                                <label htmlFor={color} className={color}></label>
                                </React.Fragment>
                            ))}
                        </div>
                    </Container>
                    <Container label="Attendance Methods">
                        <div className="features">
                            <div>
                                <Icon icon={faKey} />
                                <label>By Password</label>
                                <Toggle name="password" enabled={this.state.new?.attendMethods?.password} click={value => {
                                    let newConf = this.state.new;
                                    newConf.attendMethods.password = value;
                                    this.setState({ new: newConf });
                                }} />
                            </div>
                            <div>
                                <Icon icon={faLink} />
                                <label>By URL</label>
                                <Toggle name="url" enabled={this.state.new?.attendMethods?.url} click={value => {
                                    var newConf = this.state.new;
                                    newConf.attendMethods.url = value;
                                    this.setState({ new: newConf });
                                }} />
                            </div>
                        </div>
                    </Container>
                    <Container label="Conference Location">
                        <SelectBox tabIndex="3" name="location" label="Determine Location" selected={this.state.new?.location} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Automatically", value: 1 },
                            { name: "By Country", value: 2 }
                        ]} onChange={value => {
                            let newConf = this.state.new;
                            newConf.location = value;
                            newConf.countryCode = value === 1 ? "" : newConf.countryCode;
                            this.setState({ new: newConf });
                        }} />
                        <SelectBox tabIndex="4" name="countryCode" label="Country" options={countryCodes}
                            selected={countryCodeIndex(this.state.new?.countryCode)}
                            onChange={value => {
                                let newConf = this.state.new;
                                newConf.countryCode = value;
                                this.setState({ new: newConf });
                            }}
                            disabled={this.state.new?.location === 1} />
                    </Container>
                    <Container label="Features">
                        <div className="features">
                            {this.state.features.map((feature, i) => (
                                <div key={feature.key}>
                                    <Icon icon={feature.icon} />
                                    <label>{feature.name}</label>
                                    <Toggle name={feature.key} enabled={this.state.new?.features?.[feature.key]} click={value => {
                                        let newConf = this.state.new;
                                        newConf.features[feature.key] = value;
                                        this.setState({ new: newConf });
                                    }} />
                                </div>
                            ))}
                        </div>
                    </Container>
                    <Container label="Privileges" tabs={[
                        <button className={this.state.selectedPrivilegeRole === "privileges" && "selected"}
                            onClick={() => this.setState({ selectedPrivilegeRole: "privileges" })}>Attendee</button>,
                        <button className={this.state.selectedPrivilegeRole === "presenterPrivileges" && "selected"}
                            onClick={() => this.setState({ selectedPrivilegeRole: "presenterPrivileges" })}>Presenter</button>,
                        <button className={this.state.selectedPrivilegeRole === "hostPrivileges" && "selected"}
                            onClick={() => this.setState({ selectedPrivilegeRole: "hostPrivileges" })}>Host</button>
                    ]}>
                        <div className="features">
                            {this.state.privileges.map((privilege, i) =>(
                                <div key={privilege.key}>
                                    <Icon icon={privilege.icon} />
                                    <label>{privilege.name}</label>
                                    <Toggle name={privilege.key} enabled={this.state.new?.[this.state.selectedPrivilegeRole]?.[privilege.key] ?? false}
                                        click={value => {
                                            let newConf = this.state.new;
                                            newConf[this.state.selectedPrivilegeRole][privilege.key] = value;
                                            this.setState({ new: newConf });
                                        }} />
                                </div>
                            ))}
                        </div>
                    </Container>
                </Dialog>
                <h1>Conferences</h1>
                <List id="conferences" loaded={this.state.loaded} items={this.state.conferences} actions={actions}
                    tabs={[
                    { label: STATE_DESC[STATE_LIST.ALL], props: {
                        selected: parseInt(this.state.state) === STATE_LIST.ALL,
                        onClick: () => this.getConferences(STATE_LIST.ALL)
                    }},
                    { label: STATE_DESC[STATE_LIST.WAITING], props: {
                        selected: parseInt(this.state.state) === STATE_LIST.WAITING,
                        onClick: () => this.getConferences(STATE_LIST.WAITING)
                    }},
                    { label: STATE_DESC[STATE_LIST.START], props: {
                        selected: parseInt(this.state.state) === STATE_LIST.START,
                        onClick: () => this.getConferences(STATE_LIST.START)
                    }},
                    { label: STATE_DESC[STATE_LIST.END], props: {
                        selected: parseInt(this.state.state) === STATE_LIST.END,
                        onClick: () => this.getConferences(STATE_LIST.END)
                    }}
                ]} filters={[
                    { name: "Search", value: "search", match: "displayName", placeholder: "Search Conferences..." },
                    { name: "Color", value: "backgroundColor", match: "backgroundColor", options: [
                        { name: "Select color...", dontSelect: true, color: "transparent" },
                        { name: "Candlelight", value: 0, color: hexColors[0] },
                        { name: "Bubble", value: 1, color: hexColors[1] },
                        { name: "Sky", value: 2, color: hexColors[2] },
                        { name: "Lime", value: 3, color: hexColors[3] }
                    ] },
                    { name: "IntegrationId", value: "intId", options: [
                        { name: "Select Integration...", action: () => this.getConferences(null, null, ""),
                          dontSelect: true, color: "transparent" },
                        ...this.state.integrations.map(integration => {
                            return {
                                name: integration.description,
                                action: () => this.getConferences(null, null, integration.integrationId),
                                icon: INT_ICONS[integration.platform]
                            }
                        })
                    ] },
                    { name: "Active", value: "active", default: 1, selected: this.state.active ? 2 : 1, options: [
                        { name: "Pick One...", dontSelect: true },
                        { name: "All Conferences", action: () => this.getConferences(null, false) },
                        { name: "With Online Users", action: () => this.getConferences(null, true) }
                    ] }
                ]} sort={[
                    { options: [
                        { name: "Name", value: "displayName" },
                        { name: "Conference ID", value: "confId" },
                        { name: this.state.state <= STATE_LIST.WAITING ? "Created" : "Started",
                          value: this.state.state <= STATE_LIST.WAITING ? "created" : "started" },
                        { name: "Active", value: "active" },
                        { name: "Color", value: "backgroundColor" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
            </>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    app: state.apps[state.currentApp]
});

export default connect(mapStateToProps)(withNav(Conferences));
