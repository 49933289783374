import React, { Component } from 'react';
import { signin } from '../LumiAPI/APIs/auth.js';
import { withNav } from '../hooks/useNav';

import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import Password from '../components/Password';
import TextField from '../components/TextField';
import CordoniqContentWithToolbar from '../templates/CordoniqContentWithToolbar';
import Notification from '../tools/Notification';

import './css/login.css';


class Login extends Component {

    state = {
        username: "", 
        password: "",
        rememberMe: false,
        processing: false
    };
    
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }
    
    handleEdit = change => this.setState({ [change.name]: change.value, invalid: false });

    login = e => {
        e.preventDefault();
        this.setState({ processing: true });
        let { username, password } = this.state;
        if (username.trim() === "" || password.trim() === "") {
            let message = "Please fill in all fields";
            new Notification({ id: "signinMessage", message, type: "warning" });
            this.setState({ processing: false });
            return;
        }

        signin({ username, password }).then(tokenInfo => {
			if (tokenInfo.grant_type === 'expired') {
				// Not sure what to do here at the moment for TV users...			
			} else {
                this.goTo("/JoinConference");
			}
		})
		.catch(() => {
			// We could display a more useful error to the user
			// at the risk of exposing information (eg: account id doesn't exist)
			// which can be used to phish. 
			let message = "Login failed - Please check your credentials and try again.";
            new Notification({ id: "signinMessage", message, type: "error" });
		});
        
        this.setState({ processing: false });
    };

    disabled = () => {
        return (this.state.username.trim() === "" || this.state.password.trim() === "") || this.state.processing;
    }

    getHelp = () => {
        window.location.assign("https://www.cordoniq.com/contact/");
    }

    render() {
        let footerButtons = [
            {
                name: "Login",
                path: "/TV/login"
            },
            {
                name: "Sign up",
                path: "/SignUp"
            }
        ].map((button) => {
            return <Button key={button.name} onClick={() => this.goTo(button.path)}>{button.name}</Button> 
        });

        let headerButtons = [
            {
                name: "Enter a room",
                path: "/TV/join"
            }
        ].map((button) => {
            return <Button key={button.name} onClick={() => this.goTo(button.path)}>{button.name}</Button> 
        });


        return (
            // <CordoniqContentWithToolbar buttons={buttons} headerextra={<Button>What's up</Button>}>
            <CordoniqContentWithToolbar footerButtons={footerButtons} headerButtons={headerButtons}>
                <section>
                    <div className="login content">
                        <h1>Welcome back</h1>
                        {/* marginLeft: '20px', marginRight: '20px', paddingBottom: '10vh' */}
                        <form>
                            <TextField id="inputUsername" name="username" placeholder="Username" value={this.state.username} onEdit={this.handleEdit} />
                            <Password name="password" placeholder="Password" value={this.state.password} onEdit={this.handleEdit} />
                            <Button id="login" mode="light" type="submit" onClick={this.login} disabled={this.disabled()}>Next</Button>
                        </form>
                        <div className='loginOptions'>                           
                            <div>
                                <Checkbox id="rememberMe" mode="dark" defaultChecked={false} checked={this.state.rememberMe} onClick={() => {
                                    let rememberMe = this.state.rememberMe;
                                    this.setState({ rememberMe: !rememberMe, saving: false });
                                }} />
                                <span>Remember me</span>
                            </div>
                            <div>
                                <Button link="true" onClick={this.getHelp}>Need help?</Button>
                            </div>
                        </div>                        
                        <div className='loginAlt'>
                            <span>Don't have an account?</span>
                            <Button link="true" onClick={() => this.goTo("/SignUp")}>Sign up now</Button>
                            {/* <span className='button link' onClick={() => this.goTo("/SignUp")}>Sign up now</span> */}
                        </div>
                    </div>
                </section>
            </CordoniqContentWithToolbar>
        );
    }
}

export default withNav(Login);
