import closeIcon from '../../images/close_dark.svg';
// import closeIconBlack from '../images/close_light.svg';
//
// import alertWhite from '../images/alert_dark.svg';
// import alertBlack from '../images/alert_light.svg';

import './css/toast.css';

class Toast {
    toast = null;

    constructor(data) {
        this.toast = document.createElement("div");
        this.toast.id = data.id;
        this.toast.classList.add("toast");
        // let icon = document.createElement("img");
        // icon.src = data.type === "error" ? alertWhite : alertBlack;
        let message = document.createElement("p");
        message.innerHTML = data.message;
        let close = document.createElement("img");
        close.id = "close";
        close.src = closeIcon;
        close.onclick = () => this.close();
        // if (data.type !== "success") this.toast.appendChild(icon);
        this.toast.appendChild(message);
        this.toast.appendChild(close);
        let toastContainer = document.querySelector("#root #toastContainer");
        if (toastContainer === null) {
            toastContainer = document.createElement("div");
            toastContainer.id = "toastContainer";
            document.querySelector("#root").appendChild(toastContainer);
        }
        toastContainer.appendChild(this.toast);
        if (data.duration !== "infinite") {
            setTimeout(this.close, (data?.duration * 1000 ?? 3000));
        }
    }

    close = () => {
        this.toast.remove();
    };
}

export default Toast;
