import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';

import Tools from '../partials/Tools';

import './css/contentWithToolbar.css';

class ContentNoToolbar extends Component {
    render() {
        return (
            <div id="newportal" className="noToolbar">
                <main>
                    <div id="content" className={this.props.id ?? ""}>
                        <Tools tools={this.props.tools} />
                        {this.props.children}
                    </div>
                </main>
            </div>
        );
    }
}

export default withNav(ContentNoToolbar);
