import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB8tTBB-Nl9bG_mmQMJ96AfWN_7nsCdhac",
  authDomain: "logging-c5698.firebaseapp.com",
  projectId: "logging-c5698",
  storageBucket: "logging-c5698.appspot.com",
  messagingSenderId: "475983176765",
  appId: "1:475983176765:web:edafddfd9d007550c53654"
};

let fb = firebase;
fb.initializeApp(firebaseConfig);

export const firebaseSignIn = async token => {
    try {
        let user = await fb.auth().signInWithCustomToken(token);
        return user;
    } catch (e) {
        throw e;
    }
};

let firestore = fb.firestore();
firestore.settings({
    timestampsInSnapshots: true
});

export default firestore;
