import io from 'socket.io-client';
import { ENV } from '../../../tools/envTools.js';
import { EVENT_KINDS } from '../../eventsAPI';
import { papi } from '../config.js';

let socket = { on: (...args) => console.log("Need to initialize socket connection to listen to events...") };

if (!ENV.isProd()) {
    socket = io(`https://portal-api${ENV.getApiDomain()}.cordoniq.com`, {
        transports: [ "websocket" ]
    });

    socket.on("connect", () => console.log("Socket is connected"));
    socket.on("connect_error", err => console.log(err));
}

export const getConferenceInfo = async (intId, confId) => {
    try {
        let response = await papi.get(`/api/lumiapp/${intId}/conference/${confId}/info`);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const getConferenceFeatures = async (intId, confId) => {
    try {
        let response = await papi.get(`/api/lumiapp/${intId}/conference/${confId}/features`);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const getUserPrivileges = async (intId, confId, password) => {
    try {
        let response = await papi.get(`/api/lumiapp/${intId}/conference/${confId}/privileges`, {
            params: { password }
        });
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const updateUser = async (intId, confId, userId, changes) => {

};

export const getUsers = async (intId, confId) => {
    try {
        let options = `?active=true&video_state=true&audio_state=true`;
        let response = await papi.get(`/api/lumiapp/${intId}/conference/${confId}/users${options}`);
        return response.data;
    } catch (e) {
        throw e;
    }
};

let listeners = {
    users: [],
    conferences: [],
    shares: []
};

socket.on("events_received", events => {
    let kinds = Object.keys(EVENT_KINDS);
    events.forEach(event => {
        let kind = event.eventKind;

        if (kind > 0 && kind <= 10) { // All User Events
            listeners.users.forEach(listener => listener(event));
        } else if (kind >= 100 && kind <= 104) { // All Conference Events
            listeners.conferences.forEach(listener => listener(event));
        } else if (kind >= 200 && kind <= 202) { // All Share Events
            listeners.shares.forEach(listener => listener(event));
        }

        kinds.filter(key => {
            return EVENT_KINDS[key] === kind;
        }).forEach(label => {
            if (listeners[label.toLowerCase()] === undefined) listeners[label.toLowerCase()] = [];
            listeners[label.toLowerCase()].forEach(listener => listener(event));
        });
    });
});

const WebApp = {
    conference: {
        getInfo: getConferenceInfo,
        features: getConferenceFeatures
    },
    user: {
        privileges: getUserPrivileges,
        update: updateUser
    },
    users: {
        get: getUsers
    },
    subscriber: {
        start: async intId => {
            try {
                let response = await papi.post(`/api/lumiapp/${intId}/subscribe`);
                return response.data;
            } catch (e) {
                throw e;
            }
        }
    },
    listeners: {
        add: (kind, listener) => {
            kind = kind.toLowerCase();
            let index = listeners?.[kind]?.indexOf(listener) ?? -1;
            if (index !== -1) return;
            if (listeners[kind] === undefined) listeners[kind] = [];
            listeners[kind].push(listener);
        },
        remove: (kind, listener) => {
            kind = kind.toLowerCase();
            let index = listeners?.[kind]?.indexOf(listener) ?? -1;
            if (index === -1) return;
            if (listeners[kind] === undefined) listeners[kind] = [];
            listeners[kind].splice(index, 1);
        }
    }
};

export default WebApp;
