import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { addCertificate, removeCertificate, updateDomains } from '../LumiAPI/APIs/internal/app.js';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';
import CopyField from '../components/CopyField';
import File from '../components/File';
import TextField from '../components/TextField';
import Section from '../partials/Section';

import { connect } from 'react-redux';
import { updateApp } from '../actions/apps-actions.js';

import './css/privateCloud.css';

class PrivateCloud extends Component {
    state = {
        app: this.props.app ?? undefined,
        certificates: {
            certificate: {
                label: "Certificate",
                content: undefined
            },
            privateKey: {
                label: "Private Key",
                content: undefined
            }
        },
        domainButton: undefined
    };

    handleChange = (field, value) => {
        var app = this.state.app;
        app[field] = value;
        var changes = { app: app };
        if (field === "rootDomain" || field === "connectSubdomain") {
            changes.domainButton = undefined;
        }
        this.setState(changes);
    };

    addCertificate = e => {
        e.preventDefault();

        var certificates = this.state.certificates;
        const certificate = new Blob([certificates.certificate.content], { type: 'application/octet-stream' });
        const privateKey = new Blob([certificates.privateKey.content], { type: 'application/octet-stream' });

        var data = new FormData();
        data.append('certificate', certificate);
        data.append('privateKey', privateKey);

        addCertificate(this.state.app.clientId, data)
            .then(result => { return null })
            .catch(handleErrors);
    };

    removeCertificate = () => removeCertificate(this.state.app.clientId)
        .then(result => { return null })
        .catch(handleErrors);

    updateDomains = e => {
        e.preventDefault();

        var app = this.state.app;
        let domains = { rootDomain: app.rootDomain };
        if (app.connectSubdomain) {
            domains.connectSubdomain = app.connectSubdomain;
        }
        updateDomains(app.clientId, domains).then(() => {
            this.props.onUpdateApp(app);
            this.setState({ domainButton: "Domains Updated" });
        }).catch(handleErrors);
    };

    checkBackend = backend => {
        if (!backend) {
            this.goTo("/Portal/Settings");
        }
    }

    componentDidMount() {
        document.title = "Private Cloud | Cordoniq";
        this.checkPermissions();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.setState({ app: this.props.app });
            this.checkBackend(this.props.app.backend);
        }
    }

    render() {
        const hasDomain = this.props.app?.rootDomain ? true : false;
        const hasCertificate = this.state.app?.backendCertificate ? true : false;
        let domain = this.state.app?.rootDomain ?? "";
        let subdomain = this.state.app?.connectSubdomain ?? "";

        return (
            <ContentWithToolbar id="privateCloud">
                <h1>Private Cloud Settings</h1>
                <Section title="Custom Domain Setup">
                    <h2>Certificate</h2>
                    {hasCertificate
                        ? <React.Fragment>
                            <p>A certificate has already been added: {this.state.app.backendCertificate}</p>
                            <Button fill onClick={this.removeCertificate}>Remove Certificate</Button>
                        </React.Fragment>
                        : <React.Fragment>
                            <p>Upload your certificate and private key in PEM format.</p>
                            <form onSubmit={this.addCertificate}>
                                <div className="container">
                                    {Object.keys(this.state.certificates).map(cert => {
                                        var props = this.state.certificates[cert];
                                        return (
                                            <File name={cert} label={props.label} onChange={file => {
                                                var certificates = this.state.certificates;
                                                certificates[cert].content = file;
                                                this.setState({ certificates: certificates });
                                            }} />
                                        );
                                    })}
                                </div>
                                <Button fill type="submit">Add Certificate</Button>
                            </form>
                        </React.Fragment>
                    }
                    {hasDomain && <React.Fragment>
                        <h2>Custom Domains</h2>
                        <p>Please enter the root domain of your app as well as the subdomain for the connect page. The connect page is used to launch the Cordoniq app from your browser.</p>
                        <p>By default, Cordoniq will use "connect" as the subdomain. If you would like to use another subdomain, please update both fields.</p>
                        <form onSubmit={this.updateDomains}>
                            <div className="container">
                                <TextField name="rootDomain" label="Root Domain" value={domain} placeholder="Enter domain..." onChange={value => this.handleChange("rootDomain", value)} />
                                <TextField name="connectSubdomain" label="Connect Subdomain" value={subdomain} placeholder="connect" onChange={value => this.handleChange("connectSubdomain", value)} />
                                <div>
                                    <h2>Preview</h2>
                                    <p>Main Domain: https://{domain === "" ? "[YOUR_DOMAIN]" : domain}/</p>
                                    <p>Connect Domain: https://{subdomain === "" ? "[connect]" : subdomain}.{domain === "" ? "[YOUR_DOMAIN]" : domain}/</p>
                                </div>
                                <Button fill type="submit">{this.state.domainButton ?? "Update Domains"}</Button>
                            </div>
                        </form>
                    </React.Fragment>}
                </Section>
                <Section title="Server Setup">
                    <p>In order to set up Cordoniq on your servers, you'll need to download the Cordoniq Server Setup app using the link below.</p>
                    <Button fill onClick={() => {
                        window.open(this.state.app.serverSetupUrl, "_blank");
                    }}>Cordoniq Server Setup</Button>
                    <p>After downloading the setup, you'll need the Client ID and API Key below to complete the setup.</p>
                    <CopyField id="clientId" label="Client ID" placeholder="Client ID" value={this.state.app?.clientId ?? ""} />
                    <CopyField id="apiKey" label="API Key" placeholder="API Key" value={this.state.app?.apiKey ?? ""} />
                    <p>Once you have installed your servers, go to <Button link onClick={e => {
                        e.preventDefault();
                        this.goTo("/Portal/Servers");
                    }}>My Servers</Button> to view and manage a list of all servers.</p>
                </Section>
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    app: state.apps[state.currentApp]
});

const mapActionsToProps = {
    onUpdateApp: updateApp
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(PrivateCloud));
