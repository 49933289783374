import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';

import Button from '../../../components/Button';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs';

import canvas1 from '../../../images/integrations/canvas/canvas-1.png';
import canvas2 from '../../../images/integrations/canvas/canvas-2.png';
import canvas3 from '../../../images/integrations/canvas/canvas-3.png';
import canvas4 from '../../../images/integrations/canvas/canvas-4.png';
import canvasLogo from '../../../images/integrations/canvasLogo.png';

import '../css/canvasLanding.css';

class Canvas extends Component {
    openLTI = () => {
        if (ENV.isProd()) {
            ReactGA.event({
                category: 'App Installation',
                action: 'Canvas'
            });
        }
        window.open(`https://developers${ENV.getApiDomain()}.cordoniq.com/Portal/Integrations/LTI`, "_self");
    };

    openCategory = e => {
        let category = e.target.innerHTML.toLowerCase();
        this.goTo(`/Integrations?category=${category}`);
    };

    componentDidMount() {
        document.title = "Canvas Integration | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <LandingPageWithBreadCrumbs id="canvasLanding" breadcrumbs={[
                { title: "App Integrations", path: "/Integrations" },
                { title: "Canvas" }
            ]} logo={canvasLogo} title="Canvas Integration" subtitle="Engage with your students through secure, HD video in Canvas">
                <div id="content">
                    <Button onClick={this.openLTI}>Add to Canvas</Button>
                    <p>The Cordoniq platform is designed for your online classroom. Simply add Cordoniq to your Canvas account and engage your classroom with high quality video meetings and a full suite of collaboration tools to help you be more efficient and productive.</p>
                    <h2>How to Add a Conference to an Assignment</h2>
                    <img src={canvas1} alt="Setting up an External Tool in Canvas" />
                    <p className="caption">Add a conference to your assignment with the External Tool Submission Type</p>
                    <p>It's easy to add a conference to your assignments. Once you've set up Cordoniq in Canvas, choose External Tool as your Submission Type in your assignment settings. Then find the option "Select a Conference".</p>
                    <img src={canvas2} alt="Select or add a new conference to your assignment" />
                    <p className="caption">Select or add a new conference to your assignment</p>
                    <p>Select a conference you have already created, or create a new one for your assignment, then click "Submit". Then name and save your assignment.</p>
                    <h2>How to Join a Conference in Canvas</h2>
                    <img src={canvas3} alt="Assignment with a conference" />
                    <p className="caption">Assignment with a conference added</p>
                    <p>Once you save your assignment, you'll see a button to open the conference. Teachers will have two buttons, one to open the conference as a teacher/presenter and one to open the conference as a student/attendee.</p>
                    <img src={canvas4} alt="Cordoniq join page" />
                    <p className="caption">Example: Cordoniq join page</p>
                    <p>Clicking either button in Canvas will open the join page. Now just click to open Cordoniq and your classroom is ready.</p>
                    <h2>Integration Features</h2>
                    <h3>Real-time Document and Screen Sharing</h3>
                    <p>Seamless integration for sharing over 70 document format types - no more scrambling to change files for your team. Share them immediately.</p>
                    <h3>Document Annotation</h3>
                    <p>Need to markup and change documents in real time? Cordoniq provides easy to use annotation tools that get the documents you need changed while the whole team participates.</p>
                    <h3>Whiteboarding</h3>
                    <p>Need to draw, design, or create great business or learning ideas? Utilize the Cordoniq whiteboarding features with easy-to-use tools, for quick and productive teamwork.</p>
                    <h3>Recording</h3>
                    <p>Record meetings for later reference. View your collaboration information whenever you need it, with recorded video meetings.</p>
                    <h2>Privacy & Security</h2>
                    <p>Cordoniq enhances your classroom with secure, end-to-end encryption utilizing industry standard protocols. API driven, efficient and easy-to-use, Cordoniq is the platform that supports business, education and training teams with the latest in video meeting collaboration.</p>
                </div>
                <aside>
                    <Button onClick={this.openLTI}>Add to Canvas</Button>
                    <h2>Categories</h2>
                    <div id="categories">
                        <span onClick={this.openCategory}>Education</span>
                    </div>
                    <h2>Support</h2>
                    <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                    <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                </aside>
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(Canvas);
