import ConferenceAPI from './core/conference.js';
import UserAPI from './core/user.js';
import RecordingAPI from './core/recording.js';
import ToastAPI from './core/toast.js';

const Core = {
    ...ConferenceAPI,
    ...UserAPI,
    ...RecordingAPI,
    ...ToastAPI
};

export default Core;
