import AddCircleDark from './add_circle_dark.svg';
import AddCircleLight from './add_circle_light.svg';
import AddDark from './add_dark.svg';
import AddLight from './add_light.svg';
import AlertDark from './alert_dark.svg';
import AlertLight from './alert_light.svg';
import AnnotateDark from './annotate_pencil_dark.svg';
import AnnotateLight from './annotate_pencil_light.svg';
import WhiteboardDark from './annotate_whiteboard_dark.svg';
import WhiteboardLight from './annotate_whiteboard_light.svg';
import AppLogoMini from './app-logo-mini.svg';
import AppLogo from './app-logo.svg';
import ArrowDark from './arrow-down-dark.svg';
import ArrowLight from './arrow-down.svg';
import ArrowLeftLight from './arrow-left.svg';
import ArrowLeftDark from './arrow-left_dark.svg';
import ArrowRightLight from './arrow-right.svg';
import ArrowRightDark from './arrow-right_dark.svg';
import AudioDark from './audio_dark.svg';
import AudioLight from './audio_light.svg';
import BellDark from './bell_dark.svg';
import BellLight from './bell_light.svg';
import BreakoutDark from './breakout_dark.svg';
import BreakoutLight from './breakout_light.svg';
import CameraOffFilledLight from './cameraOff_filled_light.svg';
import CameraDark from './camera_dark.svg';
import CameraFilledLight from './camera_filled_light.svg';
import CameraLight from './camera_light.svg';
import CameraOffDark from './camera_off_dark.svg';
import CameraOffLight from './camera_off_light.svg';
import ChatDark from './chat_message_dark.svg';
import ChatLight from './chat_message_light.svg';
import CheckBoxGrey from './check_box.svg';
import CheckBoxGreen from './check_box_green.svg';
import CheckBoxLightGrey from './check_light_grey.svg';
import CheckDark from './check_dark.svg';
import CheckLight from './check_light.svg';
import CloseDark from './close_dark.svg';
import CloseLight from './close_light.svg';
import ComposeDark from './compose_dark.svg';
import ComposeLight from './compose_light.svg';
import ConferenceDark from './conference_dark.png';
import ConferenceLight from './conference_light.png';
import DashboardDark from './dashboard_dark.png';
import DashboardLight from './dashboard_light.png';
import DesktopDark from './desktop_dark.svg';
import DesktopLight from './desktop_light.svg';
import DownloadDark from './download_dark.png';
import DownloadLight from './download_light.png';
import EyeCloseDark from './eye-close_dark.svg';
import EyeCloseLight from './eye-close_light.svg';
import EyeDark from './eye_dark.svg';
import EyeLight from './eye_light.svg';
import FileDark from './file_dark.svg';
import FileLight from './file_light.svg';
import FilePDFDark from './file_pdf_dark.svg';
import FilePDFLight from './file_pdf_light.svg';
import FullscreenDark from './fullscreen_dark.svg';
import FullscreenLight from './fullscreen_light.svg';
import ImageDark from './image_dark.svg';
import ImageLight from './image_light.svg';
import IntegrationsDark from './integrations_dark.png';
import IntegrationsLight from './integrations_light.png';
import InvalidDark from './invalid_dark.svg';
import InvalidLight from './invalid_light.svg';
import LeaveDark from './leave_dark.svg';
import LeaveLight from './leave_light.svg';
import LibraryDark from './library_dark.png';
import LibraryLight from './library_light.png';
import LoadingDark from './loading-dark.svg';
import LoadingLight from './loading-light.svg';
import LocationDark from './location_dark.svg';
import LogoDark from './logo-dark.svg';
import LogoFullDark from './logo-full_dark.svg';
import LogoFullLight from './logo-full_light.svg';
import LogoLight from './logo-light.svg';
import MenuDark from './menu_dark.svg';
import MenuLight from './menu_light.svg';
import MicDisabledFilledLight from './micDisabled_filled_light.svg';
import MicDark from './mic_dark.svg';
import MicDisabledDark from './mic_disabled_dark.svg';
import MicDisabledLight from './mic_disabled_light.svg';
import MicFilledLight from './mic_filled_light.svg';
import MicLight from './mic_light.svg';
import MinusCircleDark from './min_circle_dark.svg';
import MinusCircleLight from './min_circle_light.svg';
import MinusDark from './min_dark.svg';
import MinusLight from './min_light.svg';
import MoreHorDark from './more_horizontal_dark.svg';
import MoreHorLight from './more_horizontal_light.svg';
import MoreVertDark from './more_vertical_dark.svg';
import MoreVertLight from './more_vertical_light.svg';
import MoreHorFilledLight from './morHor_filled_light.svg';
import PartSelectDark from './partial-select_dark.svg';
import PartSelectLight from './partial-select_light.svg';
import PeopleDark from './people_dark.png';
import PeopleLight from './people_light.png';
import PersonDark from './person1_dark.svg';
import PersonLight from './person1_light.svg';
import PhoneDark from './phone_dark.svg';
import PhoneLight from './phone_light.svg';
import PrintDark from './print_dark.svg';
import PrintLight from './print_light.svg';
import RecordDark from './record_dark.png';
import RecordLight from './record_light.png';
import RefreshDark from './refresh_dark.svg';
import RefreshLight from './refresh_light.svg';
import SearchDark from './search_dark.svg';
import SearchLight from './search_light.svg';
import SelectArrowDark from './selectDown_dark.svg';
import SelectArrowLight from './selectDown_light.svg';
import SelectDark from './select_dark.svg';
import SelectLight from './select_light.svg';
import SettingsDark from './settings_dark.png';
import SettingsLight from './settings_light.png';
import ShareDesktopDark from './share-desktop_dark.svg';
import ShareDesktopDisabledDark from './share-desktop_disabled_dark.svg';
import ShareDesktopDisabledLight from './share-desktop_disabled_light.svg';
import ShareDesktopLight from './share-desktop_light.svg';
import ShareDark from './share_dark.svg';
import ShareLight from './share_light.svg';
import EmailDark from './social/email_dark.svg';
import EmailLight from './social/email_light.svg';
import FacebookDark from './social/facebook_dark.svg';
import FacebookLight from './social/facebook_light.svg';
import LinkedinDark from './social/linkedin_dark.svg';
import LinkedinLight from './social/linkedin_light.svg';
import TwitterDark from './social/twitter_dark.svg';
import TwitterLight from './social/twitter_light.svg';
import SupportDark from './support_dark.svg';
import SupportLight from './support_light.svg';
import TrashDark from './trash_dark.svg';
import TrashLight from './trash_light.svg';
import UnselectDark from './unselect_dark.svg';
import UnselectLight from './unselect_light.svg';
import UserAccountDark from './user-account_dark.png';
import UserAccountLight from './user-account_light.png';
import UserDark from './user-dark.svg';
import UserLight from './user-light.svg';
import Valid from './valid.svg';
import VideoDark from './video-call_dark.svg';
import VideoDisabledDark from './video-call_disabled_dark.svg';
import VideoDisabledLight from './video-call_disabled_light.svg';
import VideoLight from './video-call_light.svg';

import DelphiDark from './partners/dark_delphi.png';
import MathnasiumDark from './partners/dark_mathnasium.png';
import PECDark from './partners/dark_pec.png';
import DelphiLight from './partners/light_delphi.png';
import MathnasiumLight from './partners/light_mathnasium.png';
import PECLight from './partners/light_pec.png';

import HubSpotLight from './integrations/hubspot/hubspotlogo-web-black.svg';
import HubSpotColor from './integrations/hubspot/hubspotlogo-web-color.svg';
import HubSpotDark from './integrations/hubspot/hubspotlogo-web-white.svg';
import HubSprocketLight from './integrations/hubspot/sprocket-web-black.svg';
import HubSprocketColor from './integrations/hubspot/sprocket-web-color.svg';
import HubSprocketDark from './integrations/hubspot/sprocket-web-white.svg';

import TvSettingsDark from './tv_settings_dark.png';
import TvSettingsLight from './tv_settings_dark.png'; // !!!

const icons = {
	addCircle: {
		light: AddCircleLight,
		dark: AddCircleDark
	},
	add: {
		light: AddLight,
		dark: AddDark
	},
	alert: {
		light: AlertLight,
		dark: AlertDark
	},
	annotate: {
		light: AnnotateLight,
		dark: AnnotateDark
	},
	whiteboard: {
		light: WhiteboardLight,
		dark: WhiteboardDark
	},
	appLogo: {
		light: AppLogo,
		dark: AppLogo
	},
	appLogoMini: {
		light: AppLogoMini,
		dark: AppLogoMini
	},
	arrow: {
		light: ArrowLight,
		dark: ArrowDark
	},
	arrowLeft: {
		light: ArrowLeftLight,
		dark: ArrowLeftDark
	},
	arrowRight: {
		light: ArrowRightLight,
		dark: ArrowRightDark
	},
	audio: {
		light: AudioLight,
		dark: AudioDark
	},
	bell: {
		light: BellLight,
		dark: BellDark
	},
	breakout: {
		light: BreakoutLight,
		dark: BreakoutDark
	},
	camera: {
		light: CameraLight,
		dark: CameraDark,
		fill: CameraFilledLight
	},
	cameraOff: {
		light: CameraOffLight,
		dark: CameraOffDark,
		fill: CameraOffFilledLight
	},
	chat: {
		light: ChatLight,
		dark: ChatDark
	},
	check: {
		light: CheckLight,
		dark: CheckDark
	},
	checkBoxGrey: {
		light: CheckBoxGrey,
		dark: CheckBoxGrey
	},
	checkBoxGreen: {
		light: CheckBoxGreen,
		dark: CheckBoxGreen
	},
	checkBoxLightGrey: {
		light: CheckBoxLightGrey,
		dark: CheckBoxLightGrey
	},
	compose: {
		light: ComposeLight,
		dark: ComposeDark
	},
	select: {
		light: SelectLight,
		dark: SelectDark
	},
	unselect: {
		light: UnselectLight,
		dark: UnselectDark
	},
	partSelect: {
		light: PartSelectLight,
		dark: PartSelectDark
	},
	close: {
		light: CloseLight,
		dark: CloseDark
	},
	conference: {
		light: ConferenceLight,
		dark: ConferenceDark
	},
	dashboard: {
		light: DashboardLight,
		dark: DashboardDark
	},
	desktop: {
		light: DesktopLight,
		dark: DesktopDark
	},
	download: {
		light: DownloadLight,
		dark: DownloadDark
	},
	eye: {
		light: EyeLight,
		dark: EyeDark
	},
	eyeClose: {
		light: EyeCloseLight,
		dark: EyeCloseDark
	},
	file: {
		light: FileLight,
		dark: FileDark
	},
	filePDF: {
		light: FilePDFLight,
		dark: FilePDFDark
	},
	fullscreen: {
		light: FullscreenLight,
		dark: FullscreenDark
	},
	image: {
		light: ImageLight,
		dark: ImageDark
	},
	integrations: {
		light: IntegrationsLight,
		dark: IntegrationsDark
	},
	invalid: {
		light: InvalidLight,
		dark: InvalidDark
	},
	leave: {
		light: LeaveLight,
		dark: LeaveDark
	},
	library: {
		light: LibraryLight,
		dark: LibraryDark
	},
	loading: {
		light: LoadingLight,
		dark: LoadingDark
	},
	location: {
		light: LocationDark,
		dark: LocationDark
	},
	logo: {
		light: LogoLight,
		dark: LogoDark
	},
	logoFull: {
		light: LogoFullLight,
		dark: LogoFullDark
	},
	menu: {
		light: MenuLight,
		dark: MenuDark
	},
	mic: {
		light: MicLight,
		dark: MicDark,
		fill: MicFilledLight
	},
	micDisabled: {
		light: MicDisabledLight,
		dark: MicDisabledDark,
		fill: MicDisabledFilledLight
	},
	minusCircle: {
		light: MinusCircleLight,
		dark: MinusCircleDark
	},
	minus: {
		light: MinusLight,
		dark: MinusDark
	},
	moreHor: {
		light: MoreHorLight,
		dark: MoreHorDark,
		fill: MoreHorFilledLight
	},
	moreVert: {
		light: MoreVertLight,
		dark: MoreVertDark
	},
	people: {
		light: PeopleLight,
		dark: PeopleDark
	},
	person: {
		light: PersonLight,
		dark: PersonDark
	},
	phone: {
		light: PhoneLight,
		dark: PhoneDark
	},
	print: {
		light: PrintLight,
		dark: PrintDark
	},
	record: {
		light: RecordLight,
		dark: RecordDark
	},
	refresh: {
		light: RefreshLight,
		dark: RefreshDark
	},
	search: {
		light: SearchLight,
		dark: SearchDark
	},
	selectArrow: {
		light: SelectArrowLight,
		dark: SelectArrowDark
	},
	settings: {
		light: SettingsLight,
		dark: SettingsDark
	},
	share: {
		light: ShareLight,
		dark: ShareDark
	},
	shareDesktop: {
		light: ShareDesktopLight,
		dark: ShareDesktopDark
	},
	shareDesktopDisabled: {
		light: ShareDesktopDisabledLight,
		dark: ShareDesktopDisabledDark
	},
	support: {
		light: SupportLight,
		dark: SupportDark
	},
	trash: {
		light: TrashLight,
		dark: TrashDark
	},
	userAccount: {
		light: UserAccountLight,
		dark: UserAccountDark
	},
	user: {
		light: UserLight,
		dark: UserDark
	},
	valid: {
		light: Valid,
		dark: Valid
	},
	video: {
		light: VideoLight,
		dark: VideoDark
	},
	videoDisabled: {
		light: VideoDisabledLight,
		dark: VideoDisabledDark
	},
	email: {
		light: EmailLight,
		dark: EmailDark
	},
	facebook: {
		light: FacebookLight,
		dark: FacebookDark
	},
	linkedin: {
		light: LinkedinLight,
		dark: LinkedinDark
	},
	twitter: {
		light: TwitterLight,
		dark: TwitterDark
	},
	pec: {
		light: PECLight,
		dark: PECDark
	},
	mathnasium: {
		light: MathnasiumLight,
		dark: MathnasiumDark
	},
	delphi: {
		light: DelphiLight,
		dark: DelphiDark
	},
	hubspot: {
		light: HubSpotLight,
		dark: HubSpotDark,
		color: HubSpotColor
	},
	hubsprocket: {
		light: HubSprocketLight,
		dark: HubSprocketDark,
		color: HubSprocketColor
	},
	tvSettings: {
		light: TvSettingsLight,
		dark: TvSettingsDark
	},
};

export default icons;
