import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui';

import '../../node_modules/swagger-ui/dist/swagger-ui.css';
import './css/swagger.css';

class Swagger extends Component {
    state = {
        definitionLink: this.props.url
    }

    loadDoc = url => {
        SwaggerUI({
            domNode: document.getElementById("swaggerContainer"),
            url: url
        });
    }

    componentDidMount() {
        this.loadDoc(this.state.definitionLink);
    }

    componentDidUpdate(prevProps) {
        if (this.props.url !== prevProps.url) {
            this.loadDoc(this.props.url);
        }
    }

    render() {
        return (
            <div id="swaggerContainer">
            </div>
        );
    }
}

export default Swagger;
