import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { createApp, setAppLogo, switchApp, getApps } from '../LumiAPI/APIs/internal/app.js';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Dialog, { Container } from '../components/Dialog';
import ImageSelector from '../components/ImageSelector';
import Toggle from '../components/Toggle';
import TextField from '../components/TextField';
import Button from '../components/Button';

import Notification from '../tools/Notification';

import { connect } from 'react-redux';
import { changeApp } from '../actions/currentApp-actions';
import { refreshApps } from '../actions/apps-actions.js';

import './css/newApp.css';

const newAppDialogRef = React.createRef();

class NewApp extends Component {
	nameRef = React.createRef();
	descriptionRef = React.createRef();

	state = {
		inProgress: false,
		progress: 0,
		icon: undefined,
		title: "",
		description: "",
		default: false,
		privacyPolicyUrl: "",
		termsOfServiceUrl: ""
	};

	updateField = change => this.setState({ [change.name]: change.value });

	open = () => this.setState({ show: true });

	close = () => {
		this.setState({
			inProgress: false,
			progress: 0,
			icon: undefined,
			title: "",
			description: "",
			default: false,
			privacyPolicyUrl: "",
			termsOfServiceUrl: ""
		});
		newAppDialogRef.current.hide();
	};

	updateIcon = newIcon => this.setState({ icon: newIcon });

	addApp = e => {
		var appTitle = this.nameRef.current;
		var appDesc = this.descriptionRef.current;

		if (this.state.title === "") {
			new Notification({ name: "noTitle", message: "Please enter a title for your app", type: "warning" });
			appTitle.focus();
		} else if (this.state.description === "") {
			new Notification({ name: "noDescription", message: "Please enter a description for your app", type: "warning" });
			appDesc.focus();
		} else {
			this.setState({ inProgress: true });
			var fields = {
				default: this.state.default,
				title: this.state.title,
				description: this.state.description,
				privacyPolicyUrl: this.state.privacyPolicyUrl,
				termsOfServiceUrl: this.state.termsOfServiceUrl
			};
			createApp(fields).then(app => {
				var icon = this.state.icon?.file ?? undefined;
				if (icon) {
					setAppLogo(app.clientId, icon, progress => {
						var completed = Math.round( (progress.loaded * 100) / progress.total );
						this.setState({ progress: completed });
					}).then(result => {
						this.close();
					}).catch(handleErrors);
				} else {
					this.close();
				}
				getApps().then(apps => {
					let appObj = {};
		            apps.forEach(item => {
		                appObj[item.clientId] = item;
		            });
					this.props.onRefreshApps(appObj);
					switchApp(app.clientId).then(result => {
						this.props.onChangeApp(app.clientId);
					}).catch(handleErrors);
				}).catch(handleErrors);
			}).catch(handleErrors);
		}
	};

	static getRef = () => (newAppDialogRef);

	render() {
		return(
			<Dialog ref={newAppDialogRef} className="newApp" progress={this.state.progress} title="Create New App" footer={[
				<Button outline onClick={this.close}>Cancel</Button>,
				<Button onClick={this.addApp} disabled={this.state.inProgress}>{this.state.inProgress ? "Processing..." : "Create App"}</Button>
			]}>
				<Container label="App Details">
					<div id="details">
						<ImageSelector image={this.state.icon?.image ?? undefined} onChange={this.updateIcon} />
						<div>
							<TextField ref={this.nameRef} name="title" className="grow"
								label="App Name" placeholder="App Name Here"
								value={this.state.title} onEdit={this.updateField} required />
							<div className="static">
								<label>Make Default</label>
								<Toggle name="default" enabled={this.state.default} click={value => this.setState({ default: value })} />
							</div>
							<TextField ref={this.descriptionRef} type="textarea" name="description"
								label="App Description" placeholder="App Description Here"
								value={this.state.description} onEdit={this.updateField} required />
						</div>
					</div>
					<div id="urls">
						<TextField type="url" name="privacyPolicyUrl" className="half"
							label="Privacy Policy URL" placeholder="Enter URL (Optional)"
							value={this.state.privacyPolicyUrl} onEdit={this.updateField} />
						<TextField type="url" name="termsOfServiceUrl" className="half"
							label="Terms of Service URL" placeholder="Enter URL (Optional)"
							value={this.state.termsOfServiceUrl} onEdit={this.updateField} />
					</div>
				</Container>
			</Dialog>
		);
	}
}

const mapStateToProps = state => ({
	curRole: state.curRole
});

const mapActionsToProps = {
	onChangeApp: changeApp,
	onRefreshApps: refreshApps
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(NewApp));
