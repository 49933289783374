import { api, allDetails } from '../config.js';
import { Token } from '../auth.js';
import { defaultConfValues, privileges as attendeePrivileges } from './confDefaults.js';

export const CONF_STATE = {
    1: "Waiting to start",
    2: "Live",
    3: "Ended",
    4: "Deleted"
};

export const START_METHOD = {
    1: "Manually waiting to be started",
    2: "Automatically started when the first user joins"
};

export const RELATE_CONTENT_AS = { DOCSHARE: 1, FILESHARE: 2 };

export const newConference = settings => {
    return new Promise((resolve, reject) => {
        let data = { ...defaultConfValues, ...settings };
        api(Token.get()).post("/conference", data)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getConfInfo = confId => {
    return new Promise((resolve, reject) => {
        let options = { params: { id: confId } };
        api().get("/conference/confinfo", options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getConference = confId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}`, allDetails)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateConference = (confId, settings) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/conference/${confId}`, settings)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const deleteConference = confId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/conference/${confId}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const linkContent = (confId, contentId, relatedAs) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).post(`/conference/${confId}/content/${contentId}`, { relatedAs })
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const unlinkContent = (confId, contentId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/conference/${confId}/content/${contentId}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

// Filter examples: state, active, meta_data1, meta_data2, meta_data3
export const getConferences = filters => {
    let options = { params: { details: "all" } };
    if (filters) {
        Object.keys(filters).forEach(key => {
            options.params[key] = filters[key] ? filters[key] : undefined;
        });
    }
    return new Promise((resolve, reject) => {
        api(Token.get()).get("/conferences", options)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

export const getLinkedContent = confId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}/contents`, allDetails)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

export const getPrivileges = (confId, role) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}`, allDetails).then(response => {
            let lookup = { 1: "privileges", 2: "presenterPrivileges", 3: "hostPrivileges" };
            let rolePrivileges = lookup[role];
            let privileges = response.data[rolePrivileges];
            if (privileges === undefined) {
                privileges = { ...attendeePrivileges };
                switch (role) {
                    case 1:
                        break;
                    case 2:
                    case 3:
                        Object.keys(attendeePrivileges).forEach(privilege => {
                            privileges[privilege] = (privilege === "record" && role === 2) ? false : true;
                        })
                        break;
                    default:
                        break;
                }
            }
            resolve(privileges);
        }).catch(reject);
    });
};

export const newDemo = (displayName, metaData) => {
    return new Promise((resolve, reject) => {
        let data = {
            ...defaultConfValues,
            displayName: displayName,
            metaData1: JSON.stringify(metaData)
        };
        let options = { params: { meetId: "trial" } };
        api().post("/conference", data, options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const newMeeting = (meetId, displayName, metaData) => {
    return new Promise((resolve, reject) => {
        let data = {
            ...defaultConfValues,
            displayName: displayName
        };
        if (metaData) data.metaData1 = JSON.stringify(metaData);
        let options = { params: { meetId } };
        api().post("/conference", data, options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

const ConferenceAPI = {
    conference: {
        new: newConference,
        info: getConfInfo,
        get: getConference,
        update: updateConference,
        delete: deleteConference,
        linkContent,
        unlinkContent
    },
    conferences: {
        get: getConferences,
        getContents: getLinkedContent
    }
};

export default ConferenceAPI;
