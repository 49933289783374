import React, { Component } from 'react';
import { getReleaseNotes } from '../LumiAPI/APIs/internal.js';
import { ENV } from '../tools/envTools';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';

import "./css/downloads.css";

const OS = { WINDOWS: 1, MAC: 2, UNIX: 3, LINUX: 4 };
const OS_NAME = { 1: "Windows", 2: "MacOS", 3: "Unix", 4: "Linux" };

class Downloads extends Component {
	state = {
		OS: undefined,
		version: undefined
	};

	determineOS = () => {
		var OSName = undefined;
		var nav = navigator.appVersion;

		if (nav.indexOf("Win") !== -1) OSName = OS.WINDOWS;
		if (nav.indexOf("Mac") !== -1) OSName = OS.MAC;
		if (nav.indexOf("X11") !== -1) OSName = OS.UNIX;
		if (nav.indexOf("Linux") !== -1) OSName = OS.LINUX;

		this.setState({ OS: OSName });
	};

	downloadLink = alt => {
		var link = `https://setup${ENV.getApiDomain()}.cordoniq.com/cordoniq/cordoniq-setup.`;
		switch (this.state.OS) {
			case OS.WINDOWS:
				link += alt ? "pkg" : "exe";
				break;
			case OS.MAC:
				link += alt ? "exe" : "pkg";
				break;
			default:
				link += "exe";
				break;
		}
		return link;
	};

	altDownloadText = () => {
		var osName = "";
		switch (this.state.OS) {
			case OS.WINDOWS:
				osName = OS_NAME[OS.MAC];
				break;
			case OS.MAC:
				osName = OS_NAME[OS.WINDOWS];
				break;
			default:
				osName = OS_NAME[OS.WINDOWS];
				break;
		}
		return osName;
	};

	getVersion = () => {
		getReleaseNotes().then(notes => this.setState({ version: notes[0].version }));
	};

	componentDidMount() {
		document.title = "Downloads | Cordoniq";
		this.determineOS();
		this.getVersion();

		window.scrollTo(0, 0);
	}

	render () {
		const ALT = true;

		return (
			<ContentWithToolbar id="downloads">
				<div id="lumi">
					<div id="graphic"></div>
					<div id="details">
						<h1>Download</h1>
						<h2>Available for Windows, Mac OS X.</h2>
						<p>By downloading Cordoniq, you accept <a href="https://www.cordoniq.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms 
							Of Use</a> and <a href="https://www.cordoniq.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy & Cookies</a>.</p>
						<Button onClick={() => {
							window.open(this.downloadLink());
						}}>Download Now</Button>
						<div>
							<b>Version {this.state.version !== undefined
								? this.state.version
								: <span className="placeholder"></span>
							}</b>
							<span>|</span>
							<Button link onClick={() => window.open('/ReleaseNotes', '_blank', 'height=940,width=700,top=0,left=0')}>Release Notes</Button>
						</div>
						<p>Not your OS? Download for <a href={this.downloadLink(ALT)}>{this.altDownloadText()}</a></p>
					</div>
				</div>
			</ContentWithToolbar>
		);
	}
}

export default Downloads;
