import React, { Component } from 'react';
import { signin } from '../LumiAPI/APIs/auth.js';
import { ERRORCODE } from '../LumiAPI/APIs/errors.js';
import Dialog, { Container } from '../components/Dialog.js';
import { withNav } from '../hooks/useNav';
import Query from '../tools/Query';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';
import { hasSavedProfiles } from '../tools/profileManager.js';

import SignInSaved from './SignInSaved';

import Button from '../components/Button';
import Password from '../components/Password';
import TextField from '../components/TextField';
import GraphicSidebar from '../partials/GraphicSidebar';

import Token from '../LumiAPI/APIs/auth';
import Lumi from '../LumiAPI/APIs/lumi.js';
import Notification from '../tools/Notification';

import './css/welcomePage.css';

const TERMS = {
    education: {
        room: "classroom",
        attendee: "student",
        attendees: "students",
        presenter: "teacher"
    },
    corporate: {
        room: "meeting",
        attendee: "coworker",
        attendees: "coworkers",
        presenter: "presenter"
    },
    general: {
        room: "room",
        attendee: "guest",
        attendees: "guests",
        presenter: "presenter"
    }
};

class SignIn extends Component {
	state = {
		type: "general",
		hasSavedProfiles: false,
		loadSaved: false,
		processing: false,
		username: "",
		password: "",
		showSidebar: true,
		menuOpen: false,
		showForcePasswordChange: false,
		newPassword: "",
		confirmPassword: ""
	};

	updateMessage = (type, code, message) => {
        type = type ?? "error";
        message = message ?? "";
		this.setState({ processing: false });

		switch(code) {
			case ERRORCODE.TOKEN_UNAUTHORIZED:
				message = "Session Timed Out: Please sign in";
				break;
            case ERRORCODE.INVALID_USERNAME:
			case ERRORCODE.USERNAME_NOT_FOUND:
				message = "Username does not exist";
				break;
            case ERRORCODE.INVALID_PASSWORD:
			case ERRORCODE.INCORRECT_PASSWORD:
				message = "Password is incorrect";
                break;
			default:
                if (code !== null) {
                    message = "Please check your credentials";
                }
		}

		new Notification({ id: "signinMessage", message: message, type: type });
	};

	handleChange = field => this.setState({ [field.name]: field.value });

	submit = e => {
		e.preventDefault();
		this.setState({ processing: true });

        let { username, password } = this.state;
        if (username === "" || password === "") {
            let message = this.state.loadSaved
                ? "Please select an account and fill in your password"
                : "Please fill in all fields";
            new Notification({ id: "signinMessage", message, type: "warning" });
            if (!this.state.loadSaved) {
                let selector = username === "" ? "username" : "password";
                document.querySelector(`#welcome .content form input[name=${selector}]`).focus();
            }
            this.setState({ processing: false });
            return;
        }

        signin({ username, password }).then(tokenInfo => {
			if (tokenInfo.grant_type === 'expired') {
				this.setState({showForcePasswordChange: true});				
			} else {
				this.goTo("/Portal");
			}
		})
		.catch(() => {
			// We could display a more useful error to the user
			// at the risk of exposing information (eg: account id doesn't exist)
			// which can be used to phish. 
			this.updateMessage("error");
		});
	};

	openSavedProfiles = () => {
		this.setState({ loadSaved: true });
	};

	checkForSavedProfiles = profiles => {
		let hasProfiles = profiles !== undefined
			? Object.keys(profiles).length > 0
			: hasSavedProfiles();
		this.setState({ hasSavedProfiles: hasProfiles, loadSaved: hasProfiles });
	};

	manualOverride = () => this.setState({ loadSaved: false });

	setCredentials = credentials => this.setState(credentials);

	cancelChangePassword = e => {
		e.preventDefault();
		this.setState({showForcePasswordChange: false});		
		Token.remove();
	}

	submitForcedPasswordChange = e => {
		e.preventDefault();
		this.setState({ processing: true });
		
		let { newPassword, confirmPassword, password, username } = this.state;

        if (newPassword !== confirmPassword) {
            this.setState({ processing: false });
            new Notification({ id: "signinMessage", message: "Passwords do not match. Re-enter and try again", type: "warning" });
            return;
        }
		
		Lumi.internal.forcePasswordChange({username, password, newPassword}).then(res => {
			new Notification({ id: "signinMessage", message: "Please log in with your new password", type: "success" });
			// Clear the password field to avoid confusion
			this.setState({password: ""});			
		}).catch(e => {
			new Notification({ id: "signinMessage", message: "The password change could not be completed. Please contact support@cordoniq.com", type: "warning" });
		});
		this.setState({processing: false, showForcePasswordChange: false});
	}

	disabled = () => {
		return this.state.newPassword === "" ||
               this.state.confirmPassword === "" ||
			   this.state.newPassword !== this.state.confirmPassword ||
			   !Password.valid(this.state.newPassword);
	}

	componentDidMount() {
		document.title = "Sign In | Cordoniq";

		var query = new Query(this.props?.location?.state?.query ?? window.location.search);
		var messageType = query.get("type");
		var message = query.get("message");

		if (message !== undefined) {
			this.updateMessage(messageType, null, message);
		}

        let types = Object.keys(TERMS);
		let type = this.props.match?.params?.type?.toLowerCase() ?? "general";
        if (!types.includes(type)) this.goTo("/SignIn");
        else this.setState({ type: type });

		// this.checkForSavedProfiles();
		// ProfileUpdater.add(this.checkForSavedProfiles);

        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);

		let firstField = document.querySelectorAll("form input")[0];
		firstField.focus();
	}

	componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
		// ProfileUpdater.remove(this.checkForSavedProfiles);
	}

  	render() {
		// let terms = TERMS[this.state.type];
		let loadSavedParagraph;
		if (this.state.loadSaved) {
			loadSavedParagraph = <p>Select your account or enter credentials for another account.</p>;
		} else {
			loadSavedParagraph = 
			<p>
				Enter your credentials below. Don't have an account? 
				Then you may create one {<Button link onClick={() => this.goTo(`/SignUp/`)}>here</Button>}.
			</p>;
		}

	  	return (
		  	<main id="welcome" className="signIn">
		  		<GraphicSidebar show={this.state.showSidebar} processing={this.state.processing} />
		  		<section>
		  			<div className="content">
		  				<h1>Developers' Portal sign in</h1>
		  				{loadSavedParagraph}
						<form onSubmit={this.submit}>
							{this.state.loadSaved
								? <SignInSaved onChange={this.setCredentials} onManualEntry={this.manualOverride} />
								: <>
								<TextField name="username" label="Username" placeholder="Username" value={this.state.username} onEdit={this.handleChange} />
								<Password name="password" label="Password" placeholder="Enter your password" value={this.state.password} onEdit={this.handleChange} />
								</>
							}
							{this.state.hasSavedProfiles && !this.state.loadSaved && <Button outline type="button" onClick={this.openSavedProfiles}>Back</Button>}
							<Button onClick={this.submit} type="submit" disabled={this.state.processing}>{this.state.processing ? "Signing In..." : "Sign In"}</Button>
							<Dialog id="forceChangePassword" title="Password change required" show={this.state.showForcePasswordChange} footer={[
								// <Button outline onClick={this.cancelChangePassword}>Cancel</Button>,
								<Button fill disabled={this.disabled()} onClick={this.submitForcedPasswordChange}>Submit</Button>
							]}>
								<header >
									<p>You must change your password before logging in.</p>
								</header>
								{/* <Container label="Current password">
									<Password required name="curPass" placeholder="Current password" autoComplete="current-password"/>
								</Container> */}
								<Container>
									<Password new required name="newPassword" label="Enter a new password..." placeholder="Enter a new password..." value={this.state.newPassword} onEdit={this.handleChange} />
									<Password required name="confirmPassword" label="Confirm password" placeholder="Please confirm your new password..." showValidation="true" value={this.state.confirmPassword} onEdit={this.handleChange} />
								</Container>
							</Dialog>
						</form>
                        <p style={this.state.loadSaved
                            ? undefined
                            : this.state.hasSavedProfiles
                                ? { textAlign: "right" }
                                : undefined
                        }>
                        <span>Need help signing in? <Button link onClick={() => this.goTo('/SignIn/Help')}>Get Help</Button></span>
                        </p>
		  			</div>
		  		</section>
		  	</main>
		);
	}
}

export default withNav(SignIn);
