import React, { Component } from 'react';
import { roles } from '../LumiAPI/APIs/internal/account.js';
import { ENV } from '../tools/envTools';
import { withNav } from '../hooks/useNav';

import Icon from '../components/Icon';
import SelectBox from '../components/SelectBox';

import { connect } from 'react-redux';
import { viewAs } from '../actions/curRole-actions';

import "./css/tools.css";

class Tools extends Component {
    state = {
        viewAs: { index: 0, option: { value: roles.OWNER } }
    };

    setSelectedRole = () => {
        let { account, curRole } = this.props;
        let viewAs = { index: 0, option: { value: roles.OWNER } };
        if (curRole !== account.role) {
            switch (curRole) {
                case roles.OWNER:
                    viewAs.index = 1;
                    viewAs.option.value = roles.OWNER;
                    break;
                case roles.HOST:
                    viewAs.index = 2;
                    viewAs.option.value = roles.HOST;
                    break;
                case roles.PRESENTER:
                    viewAs.index = 3;
                    viewAs.option.value = roles.PRESENTER;
                    break;
                case roles.ATTENDEE:
                    viewAs.index = 4;
                    viewAs.option.value = roles.ATTENDEE;
                    break;
                default:
                    break;
            }
            this.setState({ viewAs });
        }
    };

    handleSelect = selection => {
        this.setState({ [selection.name]: selection });
        this.props.onViewAs(selection.option.value);
    };

    backButton = () => {
        let currentPage = this.getCurrentPage();
        switch (currentPage) {
            case "Conference":
                return { text: "Conferences", action: () => this.goTo('/Portal/Conferences') };
            case "User Settings":
                return this.props.curRole === roles.OWNER
                    ? { text: "Dashboard", action: () => this.goTo('/Portal') } : undefined;
            case "New":
                return { text: "Dashboard", action: () => this.goTo('/Portal') };
            default:
                return undefined;
        }
    };

    componentDidMount() {
        this.setSelectedRole();
    }

    render () {
        let backButton = this.backButton();
        let showTools = this.props.tools?.length > 0 ?? false;
        let showViewAs = this.props.account.role === roles.OWNER &&
            ENV.isDev() && this.getCurrentPage() !== "Chat";

        if (backButton || showTools || showViewAs) {
            return <nav>
                {backButton && <button className="back" onClick={backButton.action}>
                    <Icon icon="arrow" />
                    <span>{backButton.text}</span>
                </button>}
                {this.props.tools && <>
                    {this.props.tools.map(tool => tool)}
                </>}
                {showViewAs && <SelectBox name="viewAs" selected={this.state.viewAs.index} options={[
                    { name: "View As..." },
                    { name: "Owner", value: roles.OWNER },
                    { name: "Host", value: roles.HOST },
                    { name: "Presenter", value: roles.PRESENTER },
                    { name: "Attendee", value: roles.ATTENDEE }
                ]} onSelect={this.handleSelect} />}
            </nav>;
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    account: state.account,
    curRole: state.curRole
});

const mapActionsToProps = {
    onViewAs: viewAs
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(Tools));
