import { ENV } from '../../tools/envTools.js';
import { auth } from './config.js';

const storageSuffix = ENV.getStorageSuffix();

export const TOKEN_KEY = `access_token${storageSuffix}`;
export const REFRESH_TOKEN = `refresh_token${storageSuffix}`;
export const TOKEN_VALID_KEY = `token_valid${storageSuffix}`;

const VALID = true, INVALID = false;

var TokenTimer = {
    timer: null,
    setup: function(tokenInfo) {
        var expires = tokenInfo.expires_in - 60; // In Seconds
        this.timer = setTimeout(() => {
            console.log("Refreshing Session");
            refreshToken().catch(err => {
                Token.notifyListeners(INVALID);
            });
        }, expires * 1000);
    },
    start: function(tokenInfo) {
        if (this.timer) this.stop();
        if (tokenInfo === undefined) {
            getTokenInfo().then(this.setup).catch(err => console.log(err));
        } else {
            this.setup(tokenInfo);
        }
    },
    stop: function() {
        clearTimeout(this.timer);
    }
};

export var Token = {
    get: () => localStorage.getItem(TOKEN_KEY),
    getRefresh: () => localStorage.getItem(REFRESH_TOKEN),
    isAuthenticated: () => {
        return localStorage.getItem(TOKEN_KEY) !== null ? true : false;
    },
    isValid: () => {
        return (
            (localStorage.getItem(TOKEN_VALID_KEY) === "true") ||
            (localStorage.getItem(TOKEN_VALID_KEY) === true)
        );
    },
    set: function(tokenInfo) {
        if (tokenInfo === undefined) return;
        localStorage.setItem(TOKEN_KEY, tokenInfo.access_token);
        localStorage.setItem(REFRESH_TOKEN, tokenInfo.refresh_token);
        TokenTimer.start(tokenInfo);
        setTimeout(() => this.notifyListeners(VALID), 1);
    },
    remove: () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(TOKEN_VALID_KEY);
        TokenTimer.stop();
    },
    listeners: [],
    addListener: function(listener) {
        this.listeners.push(listener);
    },
    removeListener: function(listener) {
        var index = this.listeners.indexOf(listener);
        if (index !== -1) this.listeners.splice(index, 1);
    },
    notifyListeners: function(isValid) {
        let validState = isValid ?? INVALID;
        localStorage.setItem(TOKEN_VALID_KEY, validState);
        this.listeners.forEach(listener => {
            listener(validState);
        });
    }
};

export const getTokenInfo = token => {
    token = token ?? Token.get();
    // Token from LocalStorage may be null, in which
    // case we should return
    if (!token) { return Promise.reject(); }
    return new Promise((resolve, reject) => {
        let options = { params: { 'access_token': token } };
        auth().get("/auth/oauth2/tokenInfo", options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const refreshToken = refresh_token => {
    refresh_token = refresh_token ?? Token.getRefresh();
    return new Promise((resolve, reject) => {
        let claims = { "grant_type": "refresh_token", refresh_token };
        auth(Token.get()).post("/auth/oauth2/token", claims).then(response => {
            let tokenInfo = response.data;
            Token.set(tokenInfo);
            resolve(tokenInfo);
        }).catch(reject);
    });
};

export const signin = ({ username, password }) => {
    return new Promise((resolve, reject) => {
        auth().post("/auth/login", { username, password }).then(response => {
            let tokenInfo = response.data;
            Token.set(tokenInfo);
            resolve(tokenInfo);
        }).catch(reject);
    });
};

export const signout = message => {
    return new Promise((resolve, reject) => {
        auth(Token.get()).delete("/auth/logout").then(response => {
            Token.remove();
            if (message) {
                window.location.href = `/SignIn?type=${message?.type ?? "error"}&message=${message?.message ?? "Unknown Error Occurred. Please Sign In Again"}`;
            } else {
                resolve("Signed Out!");
            }
        }).catch(reject);
    });
};

export const verifyPassword = credentials => {
    return new Promise((resolve, reject) => {
        auth().post("/auth/login", credentials).then(response => {
            resolve(true);
        }).catch(reject);
    });
};

const Auth = {
    signin: signin,
    signout: signout,
    verifyPassword: verifyPassword,
    token: {
        ...Token,
        getInfo: getTokenInfo,
        refresh: refreshToken
    }
};

export default Auth;
