import React, { Component } from 'react';
import { rolesText, getSubAccounts } from '../LumiAPI/APIs/internal/account.js';
import { Token } from '../LumiAPI/APIs/auth.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { colors, accountIconColor } from '../tools/colorSchemeManager';
import { withNav } from '../hooks/useNav';

import Section from '../partials/Section';
import Placeholder from '../components/Placeholder';
import Icon from '../components/Icon';

import { connect } from 'react-redux';

import './css/flexRows.css';

class NewUsers extends Component {
	state = { ready: false, users: [] };

	refreshUsersList = () => {
		this.setState({ users: [], ready: false });
		if (!Token.isValid()) return;
		getSubAccounts().then(subaccounts => this.setState({ users: subaccounts, ready: true })).catch(handleErrors);
	}

	getName = user => {
		if (user.firstName !== undefined) {
			return user.lastName !== undefined
				? `${user.firstName} ${user.lastName}`
				: `${user.firstName}`;
		}
		return user.username;
	};

	componentDidMount() {
		this.refreshUsersList();
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentApp !== prevProps.currentApp) {
			this.refreshUsersList();
		}
	}

	render() {
		let account = this.props.account;
		return (
			<Section id="newUsers" title="Accounts" buttons={[
				<button onClick={() => this.goTo("/Portal/Roles/1")}>View All</button>
			]}>
				{account !== undefined && <div id="myAccount" className="subaccount" onClick={() => this.goTo('/Portal/UserSettings')}>
					<div className="icon">
						{account.pictureImgUrl
							? <div className="profile" style={{ backgroundImage: `url(${account.pictureImgUrl})`, borderColor: colors[account.color]}}></div>
							: <Icon icon="userAccount" mode={account.color !== undefined ? accountIconColor(account.color) : undefined} bg={colors[account.color]} />
						}
					</div>
					<b>My Account</b>
					<span><Icon icon="settings" />Manage Settings</span>
					<div className="role">{rolesText[account.role]}</div>
				</div>}
				{this.state.users.sort((a, b) => {
					return new Date(b.created) - new Date(a.created);
				}).map((user, i) => (
					<React.Fragment key={user.username}>
					{i < 5 && <div className="subaccount" onClick={() => this.goTo(`/Portal/Roles/${user.role}`)}>
						<div className="icon">
							{user.pictureImgUrl
								? <div className="profile" style={{ backgroundImage: 'url('+user.pictureImgUrl+')', borderColor: colors[user.color]}}></div>
								: <Icon icon="userAccount" mode={user.color !== undefined ? accountIconColor(user.color) : undefined} bg={colors[user.color]} />
							}
						</div>
						<b>{this.getName(user)}</b>
						<span>{user.email}</span>
						<div className="role">{rolesText[user.role]}</div>
					</div>}
					</React.Fragment>
				))}
				{!this.state.ready ? <><Placeholder /><Placeholder /><Placeholder /></> : ""}
			</Section>
		);
	}
}

const mapStateToProps = state => ({
	account: state.account,
	currentApp: state.currentApp
});

export default connect(mapStateToProps)(withNav(NewUsers));
