import React, { Component } from 'react';
import moment from 'moment';
import { CONF_STATE, getConferences } from '../LumiAPI/APIs/core/conference.js';
import { Token } from '../LumiAPI/APIs/auth.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { withNav } from '../hooks/useNav';

import Placeholder from '../components/Placeholder';
import Section from '../partials/Section';

import { connect } from 'react-redux';

import './css/flexRows.css';

const colors = [ "candlelight", "bubble", "sky", "lime" ];

class Upcoming extends Component {
	state = { ready: false, conferences: [] };

	clearList = () => this.setState({ ready: false, conferences: [] });

	refreshList = () => {
		this.setState({ conferences: [], ready: false });
		if (!Token.isValid()) return;
		var upcoming = [];
		getConferences().then(conferences => {
			conferences.forEach(conference => {
				if (conference.state < 3) {
					upcoming.push(conference);
				}
			});
			this.setState({ conferences: upcoming, ready: true });
		}).catch(handleErrors);
	};

	componentDidMount() {
		this.refreshList();
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentApp !== prevProps.currentApp) {
			this.refreshList();
		}
	}

	render() {
		return (
			<Section id="upcoming" title="Conferences" buttons={[
				<button onClick={() => this.goTo("/Portal/Conferences")}>View All</button>
			]}>
				{this.state.conferences.sort((a, b) => {
					let firstDate = new Date(a.created);
					let secondDate = new Date(b.created);
					return firstDate > secondDate ? -1 : 1;
				}).map((conference, i) => (
					<React.Fragment key={conference.confId}>
					{i < 6 && <div className={"conf " + colors[conference.backgroundColor ?? 0]} onClick={() => this.goTo(`/Portal/Conference/${conference.confId}`)}>
						<b>{conference.displayName}</b>
						<span className="date">{moment(conference.created).format('ll')}</span>
						<span className="status"><span className={conference.state === 2 ? "live" : ""}></span>{CONF_STATE[conference.state]}</span>
					</div>}
					</React.Fragment>
				))}
				{this.state.conferences.length === 0 && (this.state.ready
					? <div><b>No Upcoming Conferences</b></div>
					: <><Placeholder /><Placeholder /><Placeholder /></>
				)}
			</Section>
		);
	}
}

const mapStateToProps = state => ({
	currentApp: state.currentApp
});

export default connect(mapStateToProps)(withNav(Upcoming));
