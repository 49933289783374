export const faqs = {
    auth: {
        heading: "Auth APIs",
        items: [
            {
                question: "Where can I find my client_id and client_secret?",
                answer: 'Go to your account settings to find your client_id, client_secret, and all other details relating to your app.',
                quickLink: {
                    text: "Account Settings",
                    url: "/Portal/Settings"
                }
            }
        ]
    },
    core: {
        heading: "Core APIs",
        items: [
            {
                question: "What filters can I use to get my conferences?",
                answer: "When making your request, you can add parameters for active, state, and meta data to customize the list returned from the API. See API reference for more details."
            },
            {
                question: "Why don't the audio and video settings get returned in my get user/s request?",
                answer: "To allow for faster responses, getting a user or users will only send basic details about their privileges. To get all audio or video settings, send a parameter with your request set to true for either all_audio or all_video. You can also specify which settings you would like returned. See API reference for more details."
            }
        ]
    },
    integCanvas: {
        heading: "Integrations: Canvas",
        items: [
            {
                question: "What is an OIDC Authorization Redirect URL?",
                answer: "This is a special url that Cordoniq needs to communicate and authorize access to Canvas. If you are hosting Canvas on your own private servers, you will need to provide this url in order to set up an integration with Cordoniq. Otherwise, Cordoniq will use the default url provided by Canvas.\nIf you are hosting Canvas on your own servers, ask your Canvas Administrator for this url."
            },
            {
                question: "How do I know if my Canvas site is being hosted on my organization's servers or on Canvas?",
                answer: "If the web address you use to access Canvas looks like 'https://canvas.YOUR_DOMAIN.com', your organization is hosting Canvas on their servers. If not, the address will be 'https://canvas.INSTRUCTURE.com', which means Canvas is the host of your LMS."
            },
            {
                question: "Where do I find the public keys for Canvas?",
                answer: "If you are hosting Canvas on your own servers, ask your Canvas Administrator for either the actual JSON Web Keys or the url where the keys are hosted.\nOtherwise, the keys will be one of the default keys provided by Canvas. These can be found in Step 2 of the Canvas Documentation from the link below.",
                quickLink: {
                    text: "Configuring LTI Advantage Tools",
                    url: "https://canvas.instructure.com/doc/api/file.lti_dev_key_config.html"
                }
            },
            {
                question: "How do I set up Cordoniq in Canvas once my integration is ready?",
                answer: "You will need to add a Developer Key in the Canvas admin settings as an LTI Key to use Cordoniq in Canvas. Go to the article below for step by step instructions on how to do this.",
                quickLink: {
                    text: "How do I configure an LTI Key for an account?",
                    url: "https://community.canvaslms.com/t5/Admin-Guide/How-do-I-configure-an-LTI-key-for-an-account/ta-p/140"
                }
            },
            {
                question: "How do I use the LTI key in Canvas to add my integration to a course?",
                answer: "Once your LTI key is set up in Canvas, you can add it to a specific course or your entire organization using the client ID associated with your LTI key. Go to the article below for more details.",
                quickLink: {
                    text: "How do I configure an external app for an account using a client ID?",
                    url: "https://community.canvaslms.com/t5/Admin-Guide/How-do-I-configure-an-external-app-for-an-account-using-a-client/ta-p/202"
                }
            }
        ]
    },
    integMoodle: {
        heading: "Integrations: Moodle",
        items: [
            {
                question: "What is an OIDC Authorization Redirect URL?",
                answer: "This is a special url that Cordoniq needs to communicate and authorize access to Moodle. You will need to provide this url in order to set up an integration with Cordoniq. Ask your Moodle Administrator for this url."
            },
            {
                question: "Where do I find the public keys for Moodle?",
                answer: "Ask your Moodle Administrator for the url where the keys are hosted."
            },
            {
                question: "How do I know if I have Moodle 3.10 or an older version?",
                answer: "Once you've reached the 'Manage Tools' page, check the 'Add Tool' dialog. If there is a green button next to the text field that says 'Add LTI Advantage', then you have 3.10 installed. Otherwise, the version of Moodle is 3.9 or older."
            },
            {
                question: "How do I set up my Cordoniq integration in Moodle 3.10 or newer?",
                answer: "To set up your Cordoniq in Moodle 3.10, go to 'Site administation' > 'Plugins' > 'External tool' > 'Manage tools'.\nNext, in the 'Add tool' dialog box, enter the Tool Url in the field and click 'Add LTI Advantage'.\nAfter you've added the tool, click Activate on the integration added below in the list of external tools to start using your new integration tool.",
                quickLink: {
                    text: "External Tool Settings",
                    url: "https://docs.moodle.org/310/en/External_tool_settings"
                }
            },
            {
                question: "How do I set up my Cordoniq integration in Moodle 3.9 or older?",
                answer: "To set up your Cordoniq in Moodle, go to 'Site administration' > 'Plugins' > 'External tool' > 'Manage tools'.\nNext, in the 'Add tool' dialog box, click the link to 'configure a tool manually'.\nEnter all the fields provided from Cordoniq into the form and click 'Save changes'. Your tool should now appear in the list of tools and is ready to use."
            }
        ]
    }
};
