import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import React, { Component } from 'react';
import { ENV } from '../../tools/envTools.js';

import Button from '../../components/Button';
import Icon from '../../components/Icon';

import "./css/compactFooter.css";

const apiDomain = ENV.getApiDomain();

class CompactFooter extends Component {
    open = (path, domain) => {
        if (path === "") return;
        domain = domain ?? "www";
        let url = `https://${domain}${apiDomain}.cordoniq.com/${path}`;
        window.open(url, "_blank");
    };

    render() {
        return (
            <footer id="mainSiteFooter">
                <span>{new Date().getFullYear()}&copy; Cordoniq</span>
                <div id="socialContainer">
                    <Icon icon={faTwitter} onClick={() => window.open("https://twitter.com/cordoniq", "_blank")} />
                    <Icon icon={faLinkedin} onClick={() => window.open("http://www.linkedin.com/company/cordoniq", "_blank")} />
                    <Icon icon={faFacebook} onClick={() => window.open("https://www.facebook.com/Cordoniq", "_blank")} />
                </div>
                <div id="linksContainer">
                    <Button link onClick={() => this.open("privacy-policy")}>Privacy Policy</Button>
                    <Button link onClick={() => this.open("terms-of-use")}>Terms of Use</Button>
                </div>
            </footer>
        );
    }
}

export default CompactFooter;
