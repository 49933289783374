import React, { Component } from 'react';
import { roles } from '../LumiAPI/APIs/internal/account.js';
import { capitalize } from '../tools/stringTools';

import Dialog from './Dialog';
import CopyField from './CopyField';
import TextField from './TextField';
import Button from './Button';
import SelectBox from './SelectBox';

const shareRef = React.createRef();

const DOMAINS = { "development": "-staging", "staging": "-staging", "root": "" };
const apiDomain = DOMAINS[window.env.ENVIRONMENT] || "";

class ShareDialog extends Component {
    state = {
        show: this.props.show ?? false,
        role: "attendee",
        link: "",
        conference: undefined,
        confId: "",
        password: "",
        user: undefined,
        displayName: undefined,
        buttonLabel: "Share Link",
        connectSubdomain: `connect${apiDomain}`,
    };

    show = () => this.setState({ show: true });
    hide = () => this.setState({ show: false });

    getShareUrl = role => {
        var user = this.state.user || undefined;
        var url = user?.[`attendAs${capitalize(role)}Url`] ?? this.state.conference?.[role+"Url"] ?? "";

        let parts = url.split('.');
        if (parts.length === 2) {
            parts[0] = parts[0].replace("https://", `https://${this.state.connectSubdomain}.`);
        } else {
            if (!parts[0].includes("-staging")) parts[0] += apiDomain;
        }
        url = parts.join('.');
        return url;
    };

    open = (role, conference, user) => {
        if (conference === undefined) conference = this.state.conference;
        var roles = [ "", "attendee", "presenter", "host" ];
        role = roles?.[user?.role] ?? role
        var share = this.state;
        share.role = role;
        share.link = this.getShareUrl(role);
        share.conference = {...conference};
        share.confId = conference.confId;
        share.password = conference[role+"Password"];
        share.user = user ?? undefined;
        share.displayName = user?.displayName;
        share.buttonLabel = navigator.share ? "Share Link" : "Email Link";
        this.setState(share);
        this.show();
    };

    share = () => {
        var link = this.getShareUrl(this.state.role);
        if (navigator.share) {
            navigator.share({
                title: capitalize(this.state.role)+' Link to Conference',
                url: link
            });
        } else {
            let encoded = encodeURIComponent(link);
            window.open('mailto:YOUR_RECIPIENT?subject='+capitalize(this.state.role)+' Link to Conference&body='+encoded);
        }
    };

    static getRef() {
        return shareRef;
    }

    componentDidMount() {
        let connectSubdomain = this.props.app?.connectSubdomain ?? "connect";
        this.setState({ connectSubdomain: connectSubdomain + apiDomain });
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            let connectSubdomain = this.props.app?.connectSubdomain ?? "connect";
    		this.setState({ connectSubdomain: connectSubdomain + apiDomain });
        }
    }

    render() {
        return (
            <Dialog show={this.state.show} title="Invite to Join Conference" footer={[
                <Button outline onClick={() => this.hide()}>Close</Button>,
                <Button onClick={this.share}>{this.state.buttonLabel}</Button>
            ]}>
                {this.state.user === undefined && <>
                    <h2>Share Access As</h2>
                    <SelectBox name="sharedRole" selected={roles[this.state.role.toUpperCase()]}
                        onChange={newRole => this.open(newRole)} options={[
                        { name: "Pick One..." },
                        { name: "Attendee", value: "attendee" },
                        { name: "Presenter", value: "presenter" },
                        { name: "Host", value: "host" }
                    ]} />
                </>}
                <h2>Connect with Link</h2>
                <CopyField id={`JoinLink${this.state.confId}`} name={this.state.role+"Url"} value={this.getShareUrl(this.state.role)} label={capitalize(this.state.role)+" Link"} />
                <h2>Connect with Credentials</h2>
                <TextField name="confId" value={this.state.confId} label="Conference ID" disabled />
                <TextField name="password" value={this.state.password} label="Password" placeholder="No Password Required" disabled />
                {this.state.displayName && <TextField name="displayName" value={this.state.displayName} label="Display Name" disabled />}
            </Dialog>
        );
    }
}

export default ShareDialog;
