import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';
import { ENV } from '../../tools/envTools.js';

import Button from '../../components/Button';
import Section from '../../partials/Section';
import ContentWithToolbar from '../../templates/ContentWithToolbar';

import './css/redirect.css';

class ForDevs extends Component {
    openDevPortal = () => {
        let domain = ENV.getApiDomain();
        let url = `https://developers${domain}.cordoniq.com${window.location.pathname}`;
        window.open(url, "_blank");
    };

	componentDidMount() {
        document.title = "More Features | Cordoniq";
	}

	render() {
		return (
			<ContentWithToolbar id="redirect" noSidebar>
				<Section title="Do More with Cordoniq">
					<p>Sign into the Cordoniq for Developers to access this and many more features!</p>
					<p>Talk to your account administrator if you need access to this feature</p>
					<Button onClick={this.openDevPortal}>Go to Cordoniq for Developers</Button>
                    <Button outline onClick={() => this.goTo("/Portal")}>Back to Dashboard</Button>
				</Section>
			</ContentWithToolbar>
		);
	}
}

export default withNav(ForDevs);
