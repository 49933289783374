import { papi } from '../LumiAPI/APIs/config.js';
import firestore, { firebaseSignIn } from '../tools/firestore';

const configuration = {
    iceServers: [
        {
            urls: [
                'stun:stun1.1.google.com:19302',
                'stun:stun2.1.google.com:19302'
            ]
        }
    ],
    iceCandidatePoolSize: 10
};

let authenticated = false;
let peerConnection = null;
let roomId = null;
let role = null;

export const authenticate = async () => {
    try {
        let response = await papi.get('/api/firestore/login');
        let fbToken = response.data;
        let user = await firebaseSignIn(fbToken);
        authenticated = true;
        return user;
    } catch (e) {
        throw e?.response ?? e.config ?? e;
    }
};

// GETTERS

export const getDevices = async type => {
    try {
        let devices = await navigator.mediaDevices.enumerateDevices();
        return type !== undefined
            ? devices.filter(device => device.kind === type)
            : devices;
    } catch (e) {
        throw e;
    }
};

export const getConnection = () => {
    return peerConnection;
};

// SETTERS

const trackKind = (sender, kind) => {
    return sender?.track?.kind === kind ?? false;
}

export const setLocalStream = stream => {
    let videoTracks = stream.getVideoTracks(),
        audioTracks = stream.getAudioTracks();

    let videoSenders = peerConnection.getSenders().filter(sender => trackKind(sender, "video")),
        audioSenders = peerConnection.getSenders().filter(sender => trackKind(sender, "audio"));

    if (videoTracks.length > 0 && videoSenders.length === 0) {
        videoTracks.forEach(track => peerConnection.addTrack(track, stream));
    } else if (videoTracks.length === 0 && videoSenders.length > 0) {
        videoSenders.forEach(sender => peerConnection.removeTrack(sender));
    } else {
        videoTracks.forEach(track => {
            videoSenders.forEach(sender => sender.replaceTrack(track));
        });
    }

    if (audioTracks.length > 0 && audioSenders.length === 0) {
        audioTracks.forEach(track => peerConnection.addTrack(track, stream));
    } else if (audioTracks.length === 0 && audioSenders.length > 0) {
        audioSenders.forEach(sender => peerConnection.removeTrack(sender));
    } else {
        audioTracks.forEach(track => {
            audioSenders.forEach(sender => sender.replaceTrack(track));
        });
    }

    renegotiate();
};

// export const setVideoTrack = newTrack => {
//     let senders = peerConnection.getSenders().filter(sender => sender.track.kind === "video");
//     senders.length === 0 && newTrack
//         ? peerConnection.addTrack(track, stream)
//         : senders.forEach(sender => {
//             sender.replaceTrack(newTrack);
//
//             // track.applyConstraints({});
//             // track.getConstraints();
//             // track.getSettings(); // deviceId, groupId
//             //     // AUDIO volume, autoGainControl, echoCancellation, latency
//             //     // VIDEO facingMode(user, environment, left, right), frameRate, height, width
//             // track.stop();
//         });
// };

// ROOM MANAGEMENT

const collectIceCandidates = (roomRef, localName, remoteName) => {
    const localCollection = roomRef.collection(localName),
          remoteCollection = roomRef.collection(remoteName);

    peerConnection.addEventListener('icecandidate', e => {
        if (e.candidate) {
            const json = e.candidate.toJSON();
            localCollection.add(json);
        }
    });

    remoteCollection.onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
                const candidate = new RTCIceCandidate(change.doc.data());
                peerConnection.addIceCandidate(candidate);
            }
        })
    });
};

const renegotiate = async () => {
    try {
        if (roomId === null) return;
        const roomRef = firestore.collection('rooms').doc(`${roomId}`);
        const roomSnapshot = await roomRef.get();

        if (role === "offer") {
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            console.log("New offer: ", offer);

            const roomWithOffer = {
                offer: {
                    type: offer.type,
                    sdp: offer.sdp
                },
                negotiationneeded: false,
                negotiationstarted: roomSnapshot.data().answer !== undefined
            };
            await roomRef.update(roomWithOffer);
        } else if (role === "answer") {
            await roomRef.update({
                negotiationneeded: true
            });
        }

    } catch (e) {
        throw e;
    }
};

export const openRoom = async (id, stream) => {
    try {
        if (!authenticated) await authenticate();

        const roomRef = firestore.collection('rooms').doc(`${id}`),
              roomSnapshot = await roomRef.get();

        peerConnection = new RTCPeerConnection(configuration);
        registerPeerConnectionListeners();

        if (roomSnapshot.exists) {
            roomId = id;
            collectIceCandidates(roomRef, "calleeCandidates", "callerCandidates");

            if (stream) {
                stream.getTracks().forEach(track => {
                    console.log("Adding track.....", track);
                    peerConnection.addTrack(track, stream);
                });
            }

            // Code for creating SDP answer below
            const offer = roomSnapshot.data().offer;
            console.log('Got offer:', offer);
            await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await peerConnection.createAnswer();
            console.log('Created answer: ', answer);
            await peerConnection.setLocalDescription(answer);

            const roomWithAnswer = {
                answer: {
                    type: answer.type,
                    sdp: answer.sdp
                }
            };
            await roomRef.update(roomWithAnswer);
            // Code for creating SDP answer above

            // Listening for remote session description below
            roomRef.onSnapshot(async snapshot => {
                const data = snapshot.data();
                if (data && data.negotiationstarted) {
                    console.log('Got new remote description: ', data.offer);
                    const rtcSessionDescription = new RTCSessionDescription(data.offer);
                    await peerConnection.setRemoteDescription(rtcSessionDescription);
                    const answer = await peerConnection.createAnswer();
                    console.log("New answer for negotiation: ", answer);
                    await peerConnection.setLocalDescription(answer);

                    const roomWithAnswer = {
                        answer: {
                            type: answer.type,
                            sdp: answer.sdp
                        },
                        negotiationstarted: false,
                        negotiationcompleted: true
                    };
                    await roomRef.update(roomWithAnswer);
                }
            });
            // Listening for remote session description above

            role = "answer";
        } else {
            collectIceCandidates(roomRef, "callerCandidates", "calleeCandidates");

            if (stream) {
                stream.getTracks().forEach(track => {
                    console.log("Adding track.....", track);
                    peerConnection.addTrack(track, stream);
                });
            }

            // Code for creating a room below
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            console.log(`Created offer: `, offer);

            const roomWithOffer = {
                offer: {
                    type: offer.type,
                    sdp: offer.sdp
                }
            };
            await roomRef.set(roomWithOffer);
            roomId = roomRef.id;
            console.log(`New room created with SDP offer. Room ID: ${roomRef.id}`);
            // Code for creating a room above

            // Listening for remote session description below
            roomRef.onSnapshot(async snapshot => {
                const data = snapshot.data();
                if (!peerConnection.currentRemoteDescription && data && data.answer) {
                    console.log('Got remote description: ', data.answer);
                    const rtcSessionDescription = new RTCSessionDescription(data.answer);
                    await peerConnection.setRemoteDescription(rtcSessionDescription);
                }
                if (data && data.negotiationneeded) {
                    renegotiate();
                }
                if (data && data.negotiationcompleted) {
                    console.log("Got new remote description: ", data.answer);
                    const rtcSessionDescription = new RTCSessionDescription(data.answer);
                    await peerConnection.setRemoteDescription(rtcSessionDescription);
                    await roomRef.update({ negotiationcompleted: false });
                }
            });
            // Listening for remote session description above

            role = "offer";
        }
        return roomId;
    } catch (e) {
        throw e;
    }
};

export const createRoom = async stream => {
    try {
        if (!authenticated) await authenticate();

        const roomRef = await firestore.collection('rooms').doc();
        peerConnection = new RTCPeerConnection(configuration);
        registerPeerConnectionListeners();

        collectIceCandidates(roomRef, "callerCandidates", "calleeCandidates");

        if (stream) {
            stream.getTracks().forEach(track => {
                console.log("Adding track.....", track);
                peerConnection.addTrack(track, stream);
            });
        }

        // Code for creating a room below
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        console.log(`Created offer: `, offer);

        const roomWithOffer = {
            offer: {
                type: offer.type,
                sdp: offer.sdp
            }
        };
        await roomRef.set(roomWithOffer);
        roomId = roomRef.id;
        console.log(`New room created with SDP offer. Room ID: ${roomRef.id}`);
        // Code for creating a room above

        // Listening for remote session description below
        roomRef.onSnapshot(async snapshot => {
            const data = snapshot.data();
            if (!peerConnection.currentRemoteDescription && data && data.answer) {
                console.log('Got remote description: ', data.answer);
                const rtcSessionDescription = new RTCSessionDescription(data.answer);
                await peerConnection.setRemoteDescription(rtcSessionDescription);
            }
            if (data && data.negotiationneeded) {
                renegotiate();
            }
            if (data && data.negotiationcompleted) {
                console.log("Got new remote description: ", data.answer);
                const rtcSessionDescription = new RTCSessionDescription(data.answer);
                await peerConnection.setRemoteDescription(rtcSessionDescription);
                await roomRef.update({ negotiationcompleted: false });
            }
        });
        // Listening for remote session description above

        role = "offer";
        return roomId;
    } catch (e) {
        throw e;
    }
};

export const joinRoom = async (id, stream) => {
    try {
        if (!authenticated) await authenticate();

        const roomRef = firestore.collection('rooms').doc(`${id}`);
        const roomSnapshot = await roomRef.get();
        console.log('Got room:', roomSnapshot.exists);

        if (roomSnapshot.exists) {
            roomId = id;
            peerConnection = new RTCPeerConnection(configuration);
            registerPeerConnectionListeners();

            collectIceCandidates(roomRef, "calleeCandidates", "callerCandidates");

            if (stream) {
                stream.getTracks().forEach(track => {
                    console.log("Adding track.....", track);
                    peerConnection.addTrack(track, stream);
                });
            }

            // Code for creating SDP answer below
            const offer = roomSnapshot.data().offer;
            console.log('Got offer:', offer);
            await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await peerConnection.createAnswer();
            console.log('Created answer: ', answer);
            await peerConnection.setLocalDescription(answer);

            const roomWithAnswer = {
                answer: {
                    type: answer.type,
                    sdp: answer.sdp
                }
            };
            await roomRef.update(roomWithAnswer);
            // Code for creating SDP answer above

            // Listening for remote session description below
            roomRef.onSnapshot(async snapshot => {
                const data = snapshot.data();
                if (data && data.negotiationstarted) {
                    console.log('Got new remote description: ', data.offer);
                    const rtcSessionDescription = new RTCSessionDescription(data.offer);
                    await peerConnection.setRemoteDescription(rtcSessionDescription);
                    const answer = await peerConnection.createAnswer();
                    console.log("New answer for negotiation: ", answer);
                    await peerConnection.setLocalDescription(answer);

                    const roomWithAnswer = {
                        answer: {
                            type: answer.type,
                            sdp: answer.sdp
                        },
                        negotiationstarted: false,
                        negotiationcompleted: true
                    };
                    await roomRef.update(roomWithAnswer);
                }
            });
            // Listening for remote session description above

            role = "answer";
            return roomId;
        }
    } catch (e) {
        throw e;
    }
};

export const leaveRoom = async e => {
    try {
        if (!authenticated) await authenticate();
        
        if (peerConnection) {
            peerConnection.getSenders().forEach(sender => {
                peerConnection.removeTrack(sender);
            });

            await renegotiate();

            unregisterPeerConnectionListeners();

            // peerConnection.close();
        }

        // peerConnection = null;
        role = null;
        roomId = null;

        // if (roomId) {
        //     const roomRef = firestore.collection('rooms').doc(roomId);
        //     const calleeCandidates = await roomRef.collection('calleeCandidates').get();
        //     calleeCandidates.forEach(async candidate => {
        //         await candidate.ref.delete();
        //     });
        //     const callerCandidates = await roomRef.collection('callerCandidates').get();
        //     callerCandidates.forEach(async candidate => {
        //         await candidate.ref.delete();
        //     });
        //     await roomRef.delete();
        //     roomId = null;
        // }

        return "OK";
    } catch (e) {
        throw e;
    }
};

const registerPeerConnectionListeners = () => {
    peerConnection.addEventListener('track', e => {
        console.log("New Remote Tracks Found ", e, e.streams);
        e.streams.forEach(stream => notify('remotestream', stream));
    });

    peerConnection.addEventListener('icegatheringstatechange', () => {
        console.log(`ICE gathering state changed: ${peerConnection.iceGatheringState}`);
    });

    peerConnection.addEventListener('connectionstatechange', () => {
        console.log(`Connection state changed: ${peerConnection.connectionState}`);
    });

    peerConnection.addEventListener('signalingstatechange', () => {
        console.log(`Signaling state change: ${peerConnection.signalingState}`);
    });

    peerConnection.addEventListener('iceconnectionstatechange', () => {
        console.log(`ICE connection state change: ${peerConnection.iceConnectionState}`);
    });
};

const unregisterPeerConnectionListeners = () => {
    peerConnection.ontrack = null;
    peerConnection.onicegatheringstatechange = null;
    peerConnection.onconnectionstatechange = null;
    peerConnection.onsignalingstatechange = null;
    peerConnection.oniceconnectionstatechange = null;
};

// CLIENT LISTENERS

let listeners = {};

const addListener = (key, listener) => {
    if (listeners[key] === undefined) listeners[key] = [];
    if (listeners[key].includes(listener)) return;
    listeners[key].push(listener);
};

const removeListener = (key, listener) => {
    if (listeners[key] === undefined) return;
    let index = listeners[key].indexOf(listener);
    if (index !== -1) listeners[key].splice(index, 1);
};

const notify = (key, value) => {
    if (listeners[key] === undefined) listeners[key] = [];
    listeners[key].forEach(listener => {
        listener(value);
    });
};

// LISTEN FOR NEW DEVICES

if (navigator.mediaDevices) {
    navigator.mediaDevices.addEventListener('devicechange', async e => {
        let devices = await getDevices();
        notify("devices", devices);
    });
}

/*let peer = undefined, connection = undefined, roomId = undefined;
let hostname = window.location.hostname,
    domain = ENV.getApiDomain(),
    host = hostname === "localhost"
        ? 'localhost'
        : `portal-api${domain}.lumicademy.com`,
    port = hostname === "localhost"
        ? 5000
        : 443;

const PEER_SETTINGS = { host, port, path: '/api/peer' };

const createRoom = ({ stream, onGetStreams }) => {
    if (peer === undefined) peer = new Peer(PEER_SETTINGS);

    peer.on('open', id => {
        roomId = id;
        notify('ready');
    });

    peer.on('call', call => {
        connection = call;

        console.log(connection, call);

        connection.answer(stream);

        connection.on('stream', stream => {
            if (onGetStreams) {
                onGetStreams({
                    id: connection.peer,
                    stream,
                    metadata: connection.metadata
                });
            }
        });

        connection.on('error', err => console.log("CONNECTION ERROR", err));
    });

    peer.on('error', err => console.log("PEER ERROR", err));
};

const joinRoom = (id, stream, { onGetStreams, metadata }) => {
    if (peer === undefined) peer = new Peer(PEER_SETTINGS);

    connection = peer.call(id, stream, { metadata });

    roomId = id;
    notify('ready');

    console.log(connection);

    connection.on('stream', stream => {
        console.log("Found Stream", stream);
        if (onGetStreams) {
            onGetStreams({ id: connection.peer, stream });
        }
    });

    connection.on('error', err => console.log("CONNECTION ERROR", err));

    peer.on('error', err => console.log("PEER ERROR", err));
};

const leaveRoom = () => {
    if (connection === undefined) return "Not Connected";
    connection.close();
    return "OK";
};

const listeners = {};

const addListener = (key, listener) => {
    if (listeners[key] === undefined) listeners[key] = [];
    switch (key) {
        case "ready":
            if (listeners.ready.includes(listener)) return;
            listeners.ready.push(listener);
            break;
        default:
    }
};

const removeListener = (key, listener) => {
    if (listeners[key] === undefined) return;
    switch (key) {
        case "ready":
            let index = listeners.ready.indexOf(listener);
            if (index === -1) return;
            listeners.ready.splice(index, 1);
            break;
        default:
    }
};

const notify = key => {
    if (listeners[key] === undefined) listeners[key] = [];
    switch (key) {
        case "ready":
            listeners.ready.forEach(listener => {
                listener(roomId);
            });
        default:
    }
};

const WebRTC = {
    create: createRoom,
    join: joinRoom,
    leave: leaveRoom,
    addListener,
    removeListener
};*/

const WebRTC = {
    get: {
        devices: getDevices,
        connection: getConnection
    },
    set: {
        stream: setLocalStream,
        // videoTrack: setVideoTrack,
        // audioTrack: setAudioTrack
    },
    openRoom,
    createRoom,
    joinRoom,
    leaveRoom,
    addListener,
    removeListener
};

export default WebRTC;
