import Auth from './auth.js';
import Internal from './internal.js';
import Core from './core.js';
import ContentAPI from './content.js';

const Lumi = {
    auth: { ...Auth },
    internal: { ...Internal },
    core: { ...Core },
    ...ContentAPI
};

export default Lumi;
