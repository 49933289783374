import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { withNav } from '../hooks/useNav';
import { signout, verifyPassword } from '../LumiAPI/APIs/auth.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import AccountAPI from '../LumiAPI/APIs/internal/account.js';
import { changeColorScheme, colors, setColorScheme } from '../tools/colorSchemeManager';
import { ENV, PORTAL } from '../tools/envTools';
import { getProfilePreference, setProfilePreference } from '../tools/profileManager';

import Button from '../components/Button';
import Dialog, { Container } from '../components/Dialog';
import ImageSelector from '../components/ImageSelector';
import Password from '../components/Password';
import TextField from '../components/TextField';
import Toggle from '../components/Toggle';
import Section from '../partials/Section';
import ContentWithToolbar from '../templates/ContentWithToolbar';

import Notification from '../tools/Notification';

import { connect } from 'react-redux';
import { updateCredentials } from '../actions/account-actions.js';
import { setUnsavedChanges } from '../actions/unsavedChanges-actions.js';

import './css/accountSettings.css';

const parseBool = string => (string !== false && string !== "false");

const SHOW_TOUR_KEY = `showTour${ENV.getStorageSuffix()}`;
const getSavedTourStatus = username => {
	return !parseBool(localStorage.getItem(SHOW_TOUR_KEY+username));
};

class AccountSettings extends Component {
    newPassRef = React.createRef();

    state = {
        changes: {},
        profilePic: this.props.account.pictureImgUrl ?? undefined,
        firstName: this.props.account.firstName ?? "",
        lastName: this.props.account.lastName ?? "",
        username: this.props.account.username ?? "",
        email: this.props.account.email ?? "",
        color: this.props.account.color ?? undefined,
		curPass: "",
		newPass: "",
		saveProfile: getProfilePreference(this.props.account) ?? false,
		tourHidden: getSavedTourStatus(this.props.account.username ?? ""),
		reviewChanges: []
    };

    setAccountDetails = account => this.setState({
		...account,
		profilePic: account.pictureImgUrl ?? undefined,
		saveProfile: getProfilePreference(account),
		tourHidden: getSavedTourStatus(account.username)
	});

    onEdit = change => {
        let key = change.name,
            value = change.value,
            changes = this.state.changes;

        let original = this.props.account[key];
        if (key === "newPass") {
            if (Password.valid(value)) {
                changes.password = value;
            } else {
                delete changes.password;
            }
        } else if (value !== original) {
            if (value !== "") {
                changes[key] = value;
            } else {
                delete changes[key];
            }
        } else {
            delete changes[key];
        }

        this.props.onSetUnsavedChanges(Object.keys(changes).length > 0);
        this.setState({ [key]: value, changes });
    };

    reviewChanges = e => {
        e && e.preventDefault();

        let changes = this.state.changes;
        let message = ["Please review your changes, verify your current password, then Submit."];
        Object.keys(changes).forEach(key => {
			switch(key) {
				case "firstName":
					message.push(`First Name: ${changes[key]}`);
					break;
				case "lastName":
					message.push(`Last Name: ${changes[key]}`);
					break;
				case "username":
					message.push(`Username: ${changes[key]} (Note: Sign in will be required after saving)`);
					break;
				case "email":
					message.push(`Email: ${changes[key]}`);
					break;
				case "password":
					message.push(`Password: ${changes[key]}`);
					break;
				case "color":
					message.push(<span>Profile Color: <span style={{ backgroundColor: colors[changes[key]]}}></span></span>);
					break;
				default:
			}
		});

		this.setState({ reviewChanges: message, showChangeDialog: true });
    };

    saveChanges = () => {
        let username = this.props.account.username;
		let password = this.state.curPass;
		let credentials = { username: username, password: password };

		verifyPassword(credentials).then(match => {
			this.setState({ showChangeDialog: false });
			AccountAPI.account.update(this.state.changes).then(account => {
				this.props.onSetUnsavedChanges(false);
				if (this.state.changes.username) {
					signout({ type: "success", message: "Successfully Signed Out!"});
				} else {
					AccountAPI.account.get().then(newAccount => this.props.onUpdateCredentials(newAccount));
					this.setState({ newPass: "", changes: {} });
					new Notification({ id: "accountChangesSaved", message: "Changes were saved successfully!", type: "success" });
				}
			}).catch(handleErrors);
		}).catch(err => {
			new Notification({ id: "verPasswordIncorrect", message: "Password did not match records! Please try again", type: "error" });
			handleErrors(err);
		});
    };

    enableTour = () => {
        localStorage.removeItem(SHOW_TOUR_KEY+this.state.username);
		this.setState({ tourHidden: false });
    };

    updateSaveProfilePref = preference => {
		setProfilePreference(preference, this.props.account);
		this.setState({ saveProfile: preference });
	};

    componentDidMount() {
        document.title = "Account Settings | Cordoniq";

        let mode = setColorScheme();
        document.querySelector(`input#${mode}`).checked = true;
        this.setAccountDetails(this.props.account);

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setAccountDetails(this.props.account);
        }
    }

    render() {
        let notOwner = this.props.curRole < AccountAPI.roles.OWNER;

        let uploadInfo = { action: AccountAPI.account.setProfilePic };
        let deleteInfo = { action: AccountAPI.account.removeProfilePic };

        return (
            <>
            <Prompt when={Object.keys(this.state.changes).length > 0} message={() => "You have unsaved changes!"} />
            <ContentWithToolbar id="accountSettings" noSidebar={!notOwner}>
                <Dialog show={this.state.showChangeDialog} title="Review Changes" footer={[
                    <Button outline onClick={() => this.setState({ showChangeDialog: false })}>Close</Button>,
                    <Button onClick={this.saveChanges}>Submit</Button>
                ]}>
                    <Container label="Account Changes">
                        {this.state.reviewChanges.map(message => <p>{message}</p>)}
                    </Container>
                    <Container label="Verify">
                        <Password fill name="curPass" placeholder="Verify Current Password" onChange={value => this.setState({ curPass: value })} autoComplete="current-password" />
                    </Container>
                </Dialog>
                <h1>Account Settings</h1>
                <Section id="appearance" title="Appearance">
                    <h2>Color Scheme</h2>
                    <input id="auto" type="radio" name="scheme" value="auto" />
                    <label htmlFor="auto" onClick={(e) => changeColorScheme(e)}>Auto</label>
                    <input id="light" type="radio" name="scheme" value="light" />
                    <label htmlFor="light" onClick={(e) => changeColorScheme(e)}>Light</label>
                    <input id="dark" type="radio" name="scheme" value="dark" />
                    <label htmlFor="dark" onClick={(e) => changeColorScheme(e)}>Dark</label>
                </Section>
                <Section id="accountDetails" title="Account Details">
					<div id="profile">
	                    <ImageSelector label="Set Profile" image={this.state.profilePic} border={colors[this.state.color]} upload={uploadInfo} delete={deleteInfo} />
	                    <div className="colors">
	                        <label>Profile Color</label>
	                        {colors.map((color, i) => (
	                            <React.Fragment key={color}>
	                            <input type="radio" id={color} name="profileColor" value={i} checked={parseInt(i) === this.state.color}
	                                onClick={e => this.onEdit({ name: "color", value: parseInt(e.target.value) })} />
	                            <label htmlFor={color} style={{ backgroundColor: color }}></label>
	                            </React.Fragment>
	                        ))}
	                    </div>
					</div>
                    <div id="fields">
                        <TextField className="half" name="firstName" placeholder="Jon" label="First Name" value={this.state.firstName} onEdit={this.onEdit} />
                        <TextField className="half" name="lastName" placeholder="Doe" label="Last Name" value={this.state.lastName} onEdit={this.onEdit} />
                        <TextField className="half" name="username" placeholder="jondoe" label="Username" value={this.state.username} onEdit={this.onEdit} />
                        <TextField className="half" type="email" name="email" placeholder="jon@company.com" label="Email" value={this.state.email} onEdit={this.onEdit} />
                        <Password ref={this.newPassRef} name="newPass" placeholder="Create New Password" label="New Password" new={true} onEdit={this.onEdit} autoComplete="new-password" />
                        <Button disabled={Object.keys(this.state.changes).length === 0} onClick={this.reviewChanges}>Save Changes</Button>
                    </div>
                </Section>
				<Section id="moreOptions" title="More Options">
					<h2>Save Sign In Profile</h2>
					<div id="saveProfile">
						<b>Save Sign In Profile</b>
						<Toggle name="saveProfilePref" enabled={this.state.saveProfile} click={checked => this.updateSaveProfilePref(checked)} />
					</div>
					<h2>Dashboard Settings</h2>
					<div id="restoreTour">
						<b>Restore Portal Tour</b>
						<Button outline disabled={!this.state.tourHidden} onClick={this.enableTour}>
							{this.state.tourHidden ? "Restore" : "Tour Enabled"}
						</Button>
					</div>
					{PORTAL.isMy() && <>
						<h2>Are you a developer?</h2>
						<div id="developer">
							<p>Sign into the Developer Portal to access more features.</p>
							<Button outline onClick={() => {
								let domain = ENV.getApiDomain();
								let url = `https://developers${domain}.cordoniq.com/SignIn`;
								window.open(url, '_blank');
							}}>Developer Portal</Button>
						</div>
					</>}
				</Section>
            </ContentWithToolbar>
            </>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    curRole: state.curRole,
    unsavedChanges: state.unsavedChanges
});

const mapActionsToProps = {
    onUpdateCredentials: updateCredentials,
    onSetUnsavedChanges: setUnsavedChanges
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(AccountSettings));
