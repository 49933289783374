import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';
import { signin, Token } from '../../LumiAPI/APIs/auth.js';
import { ERRORCODE } from '../../LumiAPI/APIs/errors.js';
import { getAccount } from '../../LumiAPI/APIs/internal/account.js';

import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import Password from '../../components/Password';
import TextField from '../../components/TextField';

import Notification from '../../tools/Notification';

import { connect } from 'react-redux';
import { updateCredentials } from '../../actions/account-actions';
import { viewAs } from '../../actions/curRole-actions';

import './css/sessionEnded.css';

const SESSION = { NEW: 1, ENDED: 2 };

class SessionEnded extends Component {
    usernameRef = React.createRef();
    passwordRef = React.createRef();

    state = {
        valid: false,
        show: false,
        mode: SESSION.ENDED,
        processing: false,
        username: "",
        password: ""
    };

    handleEdit = change => this.setState({ [change.name]: change.value });

    handleError = code => {
        let type = "error", message = "";
        this.setState({ processing: false });
        switch(code) {
            case ERRORCODE.TOKEN_UNAUTHORIZED:
                message = "Session Timed Out: Please sign in";
                break;
            case ERRORCODE.INVALID_USERNAME:
            case ERRORCODE.USERNAME_NOT_FOUND:
                message = "Username does not exist";
                break;
            case ERRORCODE.INVALID_PASSWORD:
            case ERRORCODE.INCORRECT_PASSWORD:
                message = "Password is incorrect";
                break;
            default:
                if (code !== null) {
                    message = "Please check your credentials";
                }
        }
        new Notification({ id: "signinMessage", message, type });
    };

    submit = () => {
        this.setState({ processing: true });

        let { username, password } = this.state;
        if (username === "" || password === "") {
            new Notification({ id: "missingFields", message: "Please fill in all fields", type: "warning" });
            let selector = username === "" ? "username" : "password";
            document.querySelector(`.sessionEnded #prompt input[name=${selector}]`).focus();
            this.setState({ processing: false });
            return;
        }
        signin(this.state).then(tokenInfo => {
            getAccount().then(account => {
                this.props.onUpdateCredentials(account);
                this.setState({ show: false, processing: false, password: "" });
            }).catch(err => {
                this.setState({ processing: false });
                new Notification({ id: "failedSignIn", message: "An error occurred! Please try again", type: "error" });
            });
        }).catch(err => {
            let code = err?.response?.data?.error_code ?? null;
            this.handleError(code);
        });
    };

    disabled = () => (
        this.state.username === "" ||
        this.state.password === "" ||
        this.state.processing
    );

    checkToken = tokenValid => {
        if (tokenValid === this.state.valid) return;
        this.setState({ valid: (tokenValid ?? false) });
        if (tokenValid) {
            this.setState({ show: false });
            if (Object.keys(this.props.account).length === 0) {
                getAccount().then(account => {
                    this.props.onUpdateCredentials(account);
                    this.props.onViewAs(account.role);
                }).catch(err => {
                    new Notification({ id: "errorOccurred", message: "An error occurred. Please try again", type: "error" });
                });
            }
        } else {
            let mode = Token.isAuthenticated() ? SESSION.ENDED : SESSION.NEW;
            this.setState({ show: true, mode });
        }
    };

    componentDidMount() {
        this.checkToken();
        Token.addListener(this.checkToken);
    }

    componentDidUpdate(prevProps) {
        // We no longer prompt the user to save their profile to LocalStorage
        // Nor do we send an XHR to update their info on HubSpot.
        // Both were previously performed in this method.
        // See previous commit 57720a644a87b72024f93976b3a86ecbd6ca03ca
    }

    componentWillUnmount() {
        Token.removeListener(this.checkToken);
    }

    render () {
        let disabled = this.disabled();
        let buttons = [
            <Button disabled={disabled} onClick={this.submit}>
                {this.state.processing ? "Processing..." : "Sign In"}
            </Button>
        ];
        if (this.state.mode === SESSION.NEW) {
            buttons.unshift(<Button outline onClick={() => this.goTo("/SignUp")}>Sign Up</Button>);
        }

        return (
            <Dialog className="sessionEnded" alert={true} show={this.state.show} footer={buttons} onLoad={() => {
                let ref = this.state.username === "" ? this.usernameRef : this.passwordRef;
                if (ref.current) ref.current.focus();
            }}>
                <h1>{this.state.mode === SESSION.ENDED ? "Session Ended!" : "Please Sign In"}</h1>
                <p>{this.state.mode === SESSION.ENDED
                    ? "Your session has ended. Please sign in to continue."
                    : "Please sign in below to access this page. If you don't have an account, click Sign Up below."
                }</p>
                <TextField ref={this.usernameRef} name="username" label="Username" placeholder="Enter username"
                    value={this.state.username} onEdit={this.handleEdit}
                    submit={!disabled ? this.submit : undefined} />
                <Password ref={this.passwordRef} name="password" label="Password" placeholder="Enter password"
                    value={this.state.password} onEdit={this.handleEdit}
                    submit={!disabled ? this.submit : undefined}
                    autoComplete="current-password" />
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapActionsToProps = {
    onUpdateCredentials: updateCredentials,
    onViewAs: viewAs
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(SessionEnded));
