import React, { Component } from 'react';

import './css/file.css';

class File extends Component {
	constructor(props) {
		super(props);

		this.state = {
			content: this.props.file ?? undefined,
			fileName: this.props.file?.name,
			contentType: this.props.file?.type
		};
	}

	getValue = () => {
		return this.state;
	};

	isValid = () => { return true; };

	getClassName = () => {
		var className = this.props.className || "";
		if (this.props.disabled) className += " disabled";
		if (this.props.wide) className += " wide";
		if (this.props.fill) className += " fill";
		return className;
	};

	handleFiles = e => {
		var files = [...e.target.files];
		var file = files[0];

		if (this.props.onChange) this.props.onChange(file);

		this.setState({
			content: file,
			fileName: file.name,
			contentType: file.type
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value && this.state.value === "") {
			this.setState({ value: this.props.value });
		}
	}

	render() {
		return (
			<div id="field" className={this.getClassName()}>
				{this.props.label && <label>{this.props.label}</label>}
				<input type="file" id={this.props.name || undefined} name={this.props.name || undefined} onChange={this.handleFiles} />
				<div>
					<label htmlFor={this.props.name || undefined}>Choose File</label>
					<span>{this.state.fileName || "No File Selected"}</span>
				</div>
			</div>
		);
	}
}

export default File;
