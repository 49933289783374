import React, { Component } from 'react';
import { newContent, getContents } from '../LumiAPI/APIs/content.js';
import { linkContent, RELATE_CONTENT_AS } from '../LumiAPI/APIs/core/conference.js';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Content from './Content';
import Icon from './Icon';
import ProgressBar from './ProgressBar';
import Button from './Button';

import './css/documentLoader.css';

class DocumentLoader extends Component {
	state = {
		show: false,
		selected: [],
		dragActive: false,
		filesDropped: false,
		files: [],
		loading: {}
	};

	show = () => {
		this.setState({ show: true });
	};

	hide = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ show: false });
	};

	// Update files list with contents from API
	updateFilesList = contents => {
		var otherFilesInProgress = false;
		var files = [];
		var loading = this.state.loading;
		var keys = Object.keys(loading);
		for (var i = 0; i < keys.length; i++) {
			if (loading[keys[i]].progress < 100) otherFilesInProgress = true;
		}

		if (!otherFilesInProgress) {
			const updateList = contents => {
				if (contents.length === undefined) contents = [contents];
				contents.forEach(content => {
					var newContent = new Content();
					newContent.set(content);
					files.push(newContent);

					if (loading[newContent.fileName] !== undefined) {
						delete loading[newContent.fileName];
					}
				});
				this.setState({ files: files, loading: loading });
			};

			if (contents === undefined) {
				getContents().then(this.updateFilesList).catch(handleErrors);
			} else {
				updateList(contents);
			}
		}
	};

	// Upload selected content via POST /content
	uploadContent = content => {
		newContent(content.getFormData(), progress => {
			var completed = Math.round( (progress.loaded * 100) / progress.total );
			var loading = this.state.loading;
			loading[content.fileName].progress = completed;
			this.setState({ loading: loading });
		}).then(() => this.updateFilesList()).catch(handleErrors);
	};

	// Added selected file to array of selected files
	selectFile = (e, i) => {
		e.preventDefault();
		e.stopPropagation();

		var selected = this.state.selected;
		var index = selected.indexOf(i);
		if (index === -1) {
			selected.push(i);
		} else {
			selected.splice(index, 1);
		}
		this.setState({ selected: selected });
	};

	// POSTs each file selected to be linked to the current conference
	chooseFile = e => {
		e.preventDefault();
		e.stopPropagation();

		var selected = this.state.selected;
		var files = this.state.files;
		selected.forEach(i => {
			var file = files[i];
			linkContent(this.props.confId, file.contentId, RELATE_CONTENT_AS.FILESHARE)
				.then(result => this.props.reload())
				.catch(handleErrors);
		});

		this.setState({
			selected: [],
			show: false
		});
	};

	handleFiles = files => {
		files = [...files];

		files.forEach(file => {
			var content = Content.fromFile(file);
			var loading = this.state.loading;
			loading[content.fileName] = {
				icon: content.getIcon(),
				progress: 1
			};
			this.setState({ loading: loading });
			this.uploadContent(content);
		});
	};

	handleDrop = e => {
		e.preventDefault();

		var dt = e.dataTransfer;
		var files = dt.files;

		this.handleFiles(files);
		this.setState({ dragActive: false, filesDropped: true });
		setTimeout(() => {
			this.setState({ filesDropped: false });
		}, 310);
	};

	handleDragEnter = e => {
		e.preventDefault();
		this.setState({ dragActive: true });
	};

	handleDragOver = e => {
		e.preventDefault();
		const bubble = document.querySelector("#dialogContainer #fileSelector #dropArea #bubble");
		bubble.style.top = e.offsetY + "px";
		bubble.style.left = e.offsetX + "px";
	};

	handleDragLeave = e => {
		e.preventDefault();
		this.setState({ dragActive: false });
	};

	componentDidMount() {
		getContents().then(this.updateFilesList).catch(handleErrors);

		const container = document.querySelector("#dialogContainer");
		const dropArea = container.querySelector("#fileSelector #dropArea");
		dropArea.addEventListener('drop', this.handleDrop, false);
		dropArea.addEventListener('dragenter', this.handleDragEnter, false);
		dropArea.addEventListener('dragover', this.handleDragOver, false);
		dropArea.addEventListener('dragleave', this.handleDragLeave, false);
	}

	componentWillUnmount() {
		const container = document.querySelector("#dialogContainer");
		const dropArea = container.querySelector("#fileSelector #dropArea");
		dropArea.removeEventListener('drop', this.handleDrop, false);
		dropArea.removeEventListener('dragenter', this.handleDragEnter, false);
		dropArea.removeEventListener('dragover', this.handleDragOver, false);
		dropArea.removeEventListener('dragleave', this.handleDragLeave, false);
	}

	render() {
		return (
			<div id="dialogContainer" className={this.state.show ? "show" : ""}>
				<div id="fileSelector">
					<h1>Upload / Choose Files</h1>
					<div id="dropArea" className={this.state.dragActive ? "over" : ""}>
						<div>
							<Icon icon={faFileAlt} />
							<p>Drop files here</p>
						</div>
						<span>or</span>
						<input id="fileElem" type="file" multiple onChange={e => this.handleFiles(e.target.files)} />
						<Button onClick={() => document.getElementById("fileElem").click()} disabled={this.state.dragActive}>Select Files</Button>
						<div id="bubble" className={this.state.filesDropped ? "dropped" : ""}></div>
					</div>
					<div id="files">
						{this.state.files.map((file, i) => (
							<div key={i} className={this.state.selected.includes(i) ? "selected" : ""} onClick={e => this.selectFile(e, i)}>
								<Icon icon={file.getIcon()} color={file.getIconColor()}  />
								{file.displayName}
							</div>
						))}
						{Object.keys(this.state.loading).length > 0 && Object.keys(this.state.loading).map(key => (
							<div>
								<Icon icon={this.state.loading[key].icon} />
								{key}<ProgressBar value={this.state.loading[key].progress} />
							</div>
						))}
						{this.state.files.length === 0 && <div className="none">NO FILES TO SELECT</div>}
					</div>
					<div>
						<Button outline onClick={this.hide}>Cancel</Button>
						<Button onClick={this.chooseFile} disabled={this.state.selected.length === 0 ? true : false}>Choose...</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default DocumentLoader;
