import axios from 'axios';
import { ENV } from '../../tools/envTools.js';

const hostname = window.location.hostname;
const domain = ENV.getApiDomain();

const defaultHeaders = {
    'accept': 'application/json',
    'content-type': 'application/json'
};

export const allDetails = {
    params: {
        'details': 'all'
    }
};

export const fileOptions = onUploadProgress => {
    return {
        headers: {},
        onUploadProgress
    };
};

export const imgOptions = onUploadProgress => {
    return {
        headers: {
            "content-Type": "image/*"
        },
        onUploadProgress
    };
};

export const downloadOptions = onDownloadProgress => {
    return {
        responseType: "blob",
        onDownloadProgress
    };
};

// LUMI APIs

export const api = token => {
    let headers = { ...defaultHeaders };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.create({
        baseURL: `https://api${domain}.cordoniq.com`,
        headers
    });
};

export const upload = token => {
    let headers = token
        ? { 'Authorization': `Bearer ${token}` }
        : undefined;
    return axios.create({
        baseURL: `https://api${domain}.cordoniq.com`,
        headers
    });
};

export const auth = token => {
    let headers = { ...defaultHeaders };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.create({
        baseURL: `https://auth${domain}.cordoniq.com`,
        headers
    });
};

// INTEGRATION APIs

export const ltiDomain = hostname === "localhost"
    ? 'http://localhost:4000'
    : `https://lti${domain}.cordoniq.com`;

export const lti = axios.create({
    baseURL: ltiDomain,
    headers: defaultHeaders
});

export const papiDomain = hostname === "localhost"
    ? 'http://localhost:5000'
    : `https://portal-api${domain}.cordoniq.com`;

export const papi = axios.create({
    baseURL: papiDomain,
    headers: defaultHeaders
});

const Definitions = {
    api, upload, auth, lti, papi
};

export default Definitions;
