import React, { Component } from 'react';

import "./css/graphicHeader.css";

class GraphicHeader extends Component {
    getClass = () => {
        let className = `${this.props.className} ` ?? "";
        className += this.props.align ?? "";
        className += this.props.graphic ? " withGraphic" : "";
        return className;
    };

    render() {
        return (
            <header id="graphicHeader" className={this.getClass()}>
                <div id="circle1"></div>
                <div id="circle2"></div>
                <div id="circle3"></div>
                <div id="circle4"></div>
                <div id="circle5"></div>
                <div id="circle6"></div>
                {this.props.graphic && <img id="graphic" src={this.props.graphic} alt="User defined graphic" />}
                <div id="heading">
                    <h1>{this.props.title}</h1>
                    {this.props.subtitle && <h2>{this.props.subtitle}</h2>}
                    {this.props.heading && <p>{this.props.heading}</p>}
                </div>
            </header>
        );
    }
}

export default GraphicHeader;
