import React, { useState, useEffect } from 'react';
import { colors, accountIconColor } from '../../tools/colorSchemeManager';
import useLocalStorage from '../../hooks/useLocalStorage';
// import { useParams } from 'react-router-dom';

import Dialog, { Container } from '../../components/Dialog';
import ImageSelector from '../../components/ImageSelector';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import Icon from '../../components/Icon';

import './css/header.css';

const WEBAPPKEY = "lumiWebAppSavedData";

const Header = props => {
    // const { intId } = useParams();
    const [ storedProfile, setStoredProfile ] = useLocalStorage(WEBAPPKEY);
    const [ profile, setProfile ] = useState({});
    const [ changes, setChanges ] = useState({});
    const [ showProfile, setShowProfile ] = useState(false);

    useEffect(() => {
        setProfile({
            name: storedProfile?.name ?? "",
            color: storedProfile?.color ?? 0,
            image: storedProfile?.image ?? undefined
        });
    }, [storedProfile]);

    const handleEdit = change => setChanges({ ...changes, [change.name]: change.value });

    const openProfile = () => {
        setChanges(profile);
        setShowProfile(true);
    };

    const saveDisabled = () => {
        if (profile.image !== changes.image) return false;
        if (profile.color !== changes.color) return false;
        if (profile.name !== changes.name) return false;
        return true;
    };

    const saveProfile = () => {
        setShowProfile(false);
        setStoredProfile(changes);
    };

    return (
        <header>
            <div id="logo">
                {props.conference
                    ? <img alt="Lumi App Logo" src={props.conference.appLogo} />
                    : <Icon icon="logo" mode="light" />
                }
            </div>
            <div id="tools">
                <h1>{props.conference?.displayName ?? "Loading..."}</h1>
                {props.recording.show && <Icon icon="record" mode="dark" disabled={props.recording.disabled} />}
                <Icon icon="leave" mode="dark" onClick={props.leave} />
                <Icon icon="settings" mode="dark" onClick={props.settings} />
                <div id="profile" onClick={openProfile} style={{ background: colors[profile?.color ?? 0]}}>
                    {profile.image
                        ? <img alt="Profile Pic" src={profile.image} />
                        : <Icon icon="userAccount" mode={accountIconColor(profile?.color ?? 0)} />
                    }
                </div>
            </div>
            {showProfile && <Dialog alert show={showProfile} title="My Profile" footer={[
                <Button outline onClick={() => setShowProfile(false)}>Close</Button>,
                <Button onClick={saveProfile} disabled={saveDisabled()}>Save</Button>
            ]}>
                <Container label="Customize Profile">
                    <ImageSelector label="Choose Picture" image={changes.image} border={colors[changes.color]}
                        remove={() => setChanges({ ...changes, image: undefined })}
                        onChange={change => setChanges({ ...changes, image: change.image })} />
                    <div id="colors">
                        {colors.map((color, i) => (
                            <span className={changes.color === i ? "selected" : ""} style={{ background: color }}
                                onClick={() => setChanges({ ...changes, color: i })}></span>
                        ))}
                    </div>
                    <TextField name="name" placeholder="Your name..." value={changes.name} onEdit={handleEdit} />
                </Container>
            </Dialog>}
        </header>
    );
};

export default Header;
