import UserAgent from './userAgentHandler.js';
let ua = new UserAgent();

export const _registerEvent = (target, eventType, cb) => {
    if (target.addEventListener) {
        target.addEventListener(eventType, cb);
        return {
            remove: function () {
                target.removeEventListener(eventType, cb);
            }
        };
    } else {
        target.attachEvent(eventType, cb);
        return {
            remove: function () {
                target.detachEvent(eventType, cb);
            }
        };
    }
};

export const _createHiddenIframe = (target, uri) => {
    var iframe = document.createElement("iframe");
    iframe.src = uri;
    iframe.id = "hiddenIframe";
    iframe.style.display = "none";
    target.appendChild(iframe);

    return iframe;
};

export const openUriWithHiddenFrame = (uri, failCb, successCb) => {

    var timeout = setTimeout(function () {
        failCb();
        handler.remove();
    }, 1000);

    var iframe = document.querySelector("#hiddenIframe");
    if (!iframe) {
        iframe = _createHiddenIframe(document.body, "about:blank");
    }

    var handler = _registerEvent(window, "blur", onBlur);

    function onBlur() {
        clearTimeout(timeout);
        handler.remove();
        successCb();
    }

    iframe.contentWindow.location.href = uri;
};

export const openUriWithTimeoutHack = (uri, failCb, successCb) => {

    var timeout = setTimeout(function () {
        failCb();
        handler.remove();
    }, 1000);

    //handle page running in an iframe (blur must be registered with top level window)
    var target = window;
    while (target !== target.parent) {
        target = target.parent;
    }

    var handler = _registerEvent(target, "blur", onBlur);

    function onBlur() {
        clearTimeout(timeout);
        handler.remove();
        successCb();
    }

    window.location = uri;
};

export const openUriUsingFirefox = (uri, failCb, successCb) => {
    var iframe = document.querySelector("#hiddenIframe");

    if (!iframe) {
        iframe = _createHiddenIframe(document.body, "about:blank");
    }

    try {
        iframe.contentWindow.location.href = uri;
        successCb();
    } catch (e) {
        if (e.name === "NS_ERROR_UNKNOWN_PROTOCOL") {
            failCb();
        }
    }
};

export const openUriUsingIEInOlderWindows = (uri, failCb, successCb) => {
    let version = ua.version();
    if( version === 11) {
        openUriWithMsLaunchUri(uri, failCb, successCb);
    } else if (version === 10) {
        openUriUsingIE10InWindows7(uri, failCb, successCb);
    } else if (version === 9) {
        openUriWithHiddenFrame(uri, failCb, successCb);
    } else {
        openUriInNewWindowHack(uri, failCb, successCb);
    }
};

export const openUriUsingIE10InWindows7 = (uri, failCb, successCb) => {
    var timeout = setTimeout(failCb, 1000);
    window.addEventListener("blur", function () {
        clearTimeout(timeout);
        successCb();
    });

    var iframe = document.querySelector("#hiddenIframe");
    if (!iframe) {
        iframe = _createHiddenIframe(document.body, "about:blank");
    }
    try {
        iframe.contentWindow.location.href = uri;
    } catch (e) {
        failCb();
        clearTimeout(timeout);
    }
};

export const openUriInNewWindowHack = (uri, failCb, successCb) => {
    var myWindow = window.open('', '', 'width=0,height=0');

    myWindow.document.write("<iframe src='" + uri + "'></iframe>");

    setTimeout(function () {
        try {
            myWindow.location.href = uri;
            myWindow.setTimeout("window.close()", 1000);
            successCb();
        } catch (e) {
            myWindow.close();
            failCb();
        }
    }, 1000);
};

export const openUriWithMsLaunchUri = (uri, failCb, successCb) => navigator.msLaunchUri(uri, successCb, failCb);

export const checkProtocolSupport = (uri, failCb, successCb, unsupportedCb) => {
    function failCallback() {
        failCb && failCb();
    }

    function successCallback() {
        successCb && successCb();
    }

    if (navigator.msLaunchUri) { //for IE and Edge in Win 8 and Win 10
        openUriWithMsLaunchUri(uri, failCb, successCb);
    } else {
        if (ua.isFirefox()) {
            let version = ua.version();
            if (version >= 78) {
                openUriWithHiddenFrame(uri, failCallback, successCallback);
            } else {
                failCallback();
            }
            // openUriUsingFirefox(uri, failCallback, successCallback);
        } else if (ua.isChrome()) {
            openUriWithTimeoutHack(uri, failCallback, successCallback);
        } else if (ua.isIOS()) {
            openUriWithTimeoutHack(uri, failCallback, successCallback);
        } else if (ua.isIE()) {
            openUriUsingIEInOlderWindows(uri, failCallback, successCallback);
        } else if (ua.isSafari()) {
            openUriWithHiddenFrame(uri, failCallback, successCallback);
        } else {
            unsupportedCb();
            //not supported, implement please
        }
    }
};

export default checkProtocolSupport;
