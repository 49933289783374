import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../hooks/useNav';
import { sendSupportForm } from '../../LumiAPI/emailAPI';
import { ENV } from '../../tools/envTools';

import ContentPageWithHeader from '../templates/ContentPageWithHeader';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import SelectBox from '../../components/SelectBox';
import TextField from '../../components/TextField';
import Notification from '../../tools/Notification';

import "./css/contactSupport.css";

const noHeading = window.matchMedia("(max-width: 500px)");

class ContactSupport extends Component {
    nameRef = React.createRef();
    emailRef = React.createRef();
    subjectRef = React.createRef();
    messageRef = React.createRef();

    state = {
        processing: false,
        valid: true,
        showHeading: false,
        first: "",
        email: "",
        topic: { index: 0, option: { value: "" } },
        subject: "",
        message: ""
    };

    edit = change => this.setState({ [change.name]: change.value });
    select = selection => this.setState({ [selection.name]: selection });

    validate = () => {
        let form = this.state, valid = true;
        if (form.first === "") {
            valid = false;
            this.nameRef.current.focus();
            new Notification({ id: "noName", message: "Please enter your name", type: "warning" });
        } else if (form.email === "" || !this.emailRef.current.isValid()) {
            valid = false;
            this.emailRef.current.focus();
            new Notification({ id: "invlalidEmail", message: "Please enter a valid email", type: "warning" });
        } else if (form.topic.index === 0) {
            valid = false;
            new Notification({ id: "noTopic", message: "Please choose a topic for your message", type: "warning" });
        } else if (form.subject === "") {
            valid = false;
            this.subjectRef.current.focus();
            new Notification({ id: "noSubject", message: "Please enter a subject for your message", type: "warning" });
        } else if (form.message === "") {
            valid = false;
            this.messageRef.current.focus();
            new Notification({ id: "noMessage", message: "Please enter more information to your message", type: "warning" });
        }
        this.setState({ valid: valid });
        if (!valid) return;
        this.submit();
    };

    submit = () => {
        let form = this.state;
        let data = {
            first: form.first,
            email: form.email,
            topic: form.topic.index,
            subject: form.subject,
            message: form.message
        };

        this.setState({ processing: true });
        sendSupportForm(data, (response, err) => {
            if (err) {
                this.setState({ processing: false });
                new Notification({ id: "failedEmailSend", message: "Error Occurred! Please try again!", type: "error" });
            } else {
                if (ENV.isProd()) {
                    ReactGA.event({
                        category: 'Form Submission',
                        action: 'Contact Support',
                        label: data.email
                    });
                }
                this.goTo("/Support/Sent");
            }
        });
    };

    adjustLayout = e => this.setState({ showHeading: noHeading.matches });

    componentDidMount() {
        document.title = "Contact Support | Cordoniq";
        window.scrollTo(0, 0);

        this.adjustLayout();
        noHeading.addListener(this.adjustLayout);
    }

    componentWillUnmount() {
        noHeading.removeListener(this.adjustLayout);
    }

    render() {
        return (
            <ContentPageWithHeader id="contactSupport" title="Help and Support" heading={this.state.showHeading ? undefined : "If you have a technical question, would like to request a feature or provide feedback, please say Hello! We're here to listen and have a conversation."}>
                <section>
                    {this.state.showHeading && <p>If you have a technical question, would like to request a feature or provide feedback, please say Hello! We're here to listen and have a conversation.</p>}
                    <div>
                        <TextField ref={this.nameRef} tabIndex="1" name="first" className="half" label="First Name *" placeholder="Your name" onEdit={this.edit} />
                        <TextField ref={this.emailRef} type="email" tabIndex="2" name="email" className="half" label="Work Email *" placeholder="Your email" onEdit={this.edit} />
                        <SelectBox tabIndex="3" name="topic" label="Topic *" selected={this.state.topic.index} options={[
                            { name: "Please select one", dontSelect: true },
                            { name: "Technical issues and bugs" },
                            { name: "Migration support" },
                            { name: "My account" },
                            { name: "Product suggestions" },
                            { name: "Feature requests" },
                            { name: "Other" }
                        ]} onSelect={this.select} />
                        <TextField ref={this.subjectRef} tabIndex="4" name="subject" label="Subject *" placeholder="Message Subject" onEdit={this.edit} />
                        <TextField ref={this.messageRef} type="textarea" tabIndex="5" name="message" label="Message *" placeholder="Please include as much information as possible" onEdit={this.edit} />
                        {!this.state.valid && <p className="invalid">Fields marked with an asterisk (*) are required.</p>}
                        <p>By submitting the form, you agree to the <Button link href="https://www.cordoniq.com/privacy-policy/">Privacy Policy</Button>.</p>
                        <Button onClick={this.validate} disabled={this.state.processing}>{this.state.processing ? "Sending..." : "Submit"}</Button>
                    </div>
                </section>
                <aside>
                    <div>
                        <h1>Our team is here to help.</h1>
                        <p>If you have any questions, please reach out to us. Please provide as much information as possible</p>
                    </div>
                    <div className="links" onClick={() => this.goTo("/Docs")}>
                        <h2>Documentation<Icon icon="arrowRight" /></h2>
                        <p>Find help by visiting the guide to using and implementing our product.</p>
                    </div>
                    <div className="links" onClick={() => this.goTo("/Sales")}>
                        <h2><span>Interested in partnership?</span><Icon icon="arrowRight" /></h2>
                        <p>Connect with us and have a conversation about how we can work together.</p>
                    </div>
                </aside>
            </ContentPageWithHeader>
        );
    }
}

export default withNav(ContactSupport);
