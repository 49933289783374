export const deleteMethod = { NEVER: 1, AUTO: 2 };

export class Content {
    constructor() {
        this.contentId = undefined;
        this.content = "";
        this.contentType = "*/*";
        this.displayName = "";
        this.fileName = "";
        this.created = undefined;
        this.downloading = 0;
        this.deleteMethod = deleteMethod.NEVER;
    }

    set = file => {
        this.contentId = file.contentId;
        this.content = file.content;
        this.contentType = file.contentType;
        this.displayName = file.displayName;
        this.fileName = file.fileName;
        this.created = file.created;
        this.deleteMethod = file.deleteMethod;
    };

    getFormData = () => {
        var request = {
            contentType: this.contentType,
            displayName: this.displayName,
            fileName: this.fileName,
            deleteMethod: this.deleteMethod
        };

        const json = JSON.stringify(request);
        const requestBlob = new Blob([json], { type: "application/json" });

        let data = new FormData();
        data.append('request', requestBlob);
        data.append('content', this.content);
        return data;
    };

    static fromFile = file => {
        var content = new this();
        content.contentType = file.type;
        content.content = file;
        content.displayName = file.name;
        content.fileName = file.name;
        return content;
    };
}

export default Content;
