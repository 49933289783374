import { store } from '../index.js';

export const refreshApps = apps => {
	return {
		type: 'REFRESHAPPS',
		payload: {
			apps: apps
		}
	}
};

export const updateApp = app => {
	let apps = store.getState().apps;
	let clientId = store.getState().currentApp;
	apps[clientId] = app;
	return {
		type: 'UPDATEAPP',
		payload: {
			apps: apps
		}
	}
};
