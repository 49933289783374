import { faKey, faLink } from '@fortawesome/free-solid-svg-icons';

export const STATE = { WAITING: 1, START: 2, END: 3, DELETE: 4 };

export const features = {
    video: true,
    audio: true,
    whiteboard: true,
    desktopShare: true,
    documentShare: true,
    fileShare: true,
    recording: true,
    chat: true
};

export const privileges = {
    annotate: true,
    video: true,
    audio: true,
    share: false,
    viewUsers: true,
    record: false,
    changePages: false,
    changeShares: false,
    chat: true,
    changeUser: false
};

export const presenterPrivileges = {
    annotate: true,
    video: true,
    audio: true,
    share: true,
    viewUsers: true,
    record: false,
    changePages: true,
    changeShares: true,
    chat: true,
    changeUser: true
};

export const hostPrivileges = {
    annotate: true,
    video: true,
    audio: true,
    share: true,
    viewUsers: true,
    record: true,
    changePages: true,
    changeShares: true,
    chat: true,
    changeUser: true
};

export const confColors = [ "candlelight", "bubble", "sky", "lime" ];
export const confColors_hex = [ "#f1a738", "#e0528b", "#56cbf2", "#67d890" ];

export const defaultConfValues = {
    displayName: "",
    startMethod: 2,
    backgroundColor: 0,
    location: 1,
    countryCode: "",
    features: features,
    privileges: privileges,
    presenterPrivileges: presenterPrivileges,
    hostPrivileges: hostPrivileges,
    attendMethods: {
        password: true,
        url: true
    }
};

/* LIST DEFAULTS */

export const startMethodOptions = [
    { name: "", dontSelect: true },
    { name: "Manually waiting to be started", value: 1 },
    { name: "Automatically started when the first user joins", value: 2 }
];

export const attendMethodsList = [
    { key: "password", name: "By Password", icon: faKey },
    { key: "url", name: "By URL", icon: faLink }
];

export const featuresList = [
    { key: "video", name: "Video", checked: true, icon: "video" },
    { key: "audio", name: "Audio", checked: true, icon: "audio" },
    { key: "whiteboard", name: "Whiteboard", checked: true, icon: "whiteboard" },
    { key: "desktopShare", name: "Desktop Share", checked: true, icon: "desktop" },
    { key: "documentShare", name: "Document Share", checked: true, icon: "file" },
    { key: "fileShare", name: "File Share", checked: true, icon: "download" },
    { key: "recording", name: "Recording", checked: true, icon: "record" },
    { key: "chat", name: "Chat", checked: true, icon: "chat" }
];

export const privilegesList = [
    { key: "video", name: "Can Adjust Video?", checked: true, icon: "video" },
    { key: "audio", name: "Can Adjust Audio?", checked: true, icon: "audio" },
    { key: "viewUsers", name: "Can View Users?", checked: true, icon: "people" },
    { key: "changeUser", name: "Can Change Users?", checked: true, icon: "person" },
    { key: "annotate", name: "Can Annotate?", checked: true, icon: "annotate" },
    { key: "share", name: "Can Share Screen/Documents?", checked: true, icon: "desktop" },
    { key: "changeShares", name: "Can Change Shared Content?", checked: false, icon: "desktop" },
    { key: "changePages", name: "Can Change Pages?", checked: false, icon: "file" },
    { key: "record", name: "Can Record?", checked: true, icon: "record" },
    { key: "chat", name: "Can Chat?", checked: true, icon: "chat" }
];
