// import React, { Component } from 'react';

const minSwipeLength = 30;

export function withTouch(WrappedComponent) {
    const WrappedWithTouch = class extends WrappedComponent {
        touchStart = e => {
            e.preventDefault();

            if (e.touches.length === 1) {
                this.setState({
                    fingerCount: e.touches.length,
                    startX: e.touches[0].pageX,
                    startY: e.touches[0].pageY
                });
            } else {
                this.touchCancel();
            }
        };

        touchMove = e => {
            e.preventDefault();

            if (this.state.fingerCount === 1) {
                this.setState({
                    curX: e.touches[0].pageX,
                    curY: e.touches[0].pageY
                });
            } else {
                this.touchCancel();
            }
        };

        touchEnd = e => {
            let targetId = e.target.id || "";
            if (this.state.buttons && this.state.buttons.includes(targetId)) {
                e.target.click();
                this.touchCancel();
                return;
            }
            e.preventDefault();

            if (this.state.fingerCount === 1 && this.state.curX !== 0) {
                let swipeLength = Math.round(Math.sqrt(Math.pow(this.state.curX - this.state.startX, 2) + Math.pow(this.state.curY - this.state.startY, 2)));

                if (swipeLength >= minSwipeLength) {
                    let direction = this.getDirection();
                    this.onSwipe(direction);
                } else {
                    this.touchCancel();
                }
            } else {
                this.touchCancel();
            }
        };

        touchCancel = e => {
            this.setState({
                fingerCount: 0,
                startX: 0,
                startY: 0,
                curX: 0,
                curY: 0
            });
        };

        getAngle = () => {
            var diffX = this.state.startX - this.state.curX;
            var diffY = this.state.curY - this.state.startY;
            var r = Math.atan2(diffY, diffX);
            var angle = Math.round(r * 180 / Math.PI);

            return angle < 0 ? 360 - Math.abs(angle) : angle;
        };

        getDirection = () => {
            var direction, angle = this.getAngle();

            if (angle <= 45 && angle >= 0) {
                direction = 'left';
            } else if (angle <= 360 && angle >= 315) {
                direction = 'left';
            } else if (angle >= 135 && angle <= 225) {
                direction = 'right';
            } else if (angle > 45 && angle < 135) {
                direction = 'down';
            } else {
                direction = 'up';
            }

            return direction;
        };

        addTouchEvents = element => {
            this.touchCancel();
            element.addEventListener("touchstart", this.touchStart);
            element.addEventListener("touchmove", this.touchMove);
            element.addEventListener("touchend", this.touchEnd);
            element.addEventListener("touchcancel", this.touchCancel);
        };

        removeTouchEvents = element => {
            element.removeEventListener("touchstart", this.touchStart);
            element.removeEventListener("touchmove", this.touchMove);
            element.removeEventListener("touchend", this.touchEnd);
            element.removeEventListener("touchcancel", this.touchCancel);
        };

        render() {
            return super.render();
        }
    }

    return WrappedWithTouch;
}
