import React, { Component } from 'react';

import Icon from './Icon';

import './css/slider.css';

var x = 0,
    deltaX = 0,
    posInitial = 4,
    posCurrent = 4;

class Slider extends Component {
    state = {
        button: undefined,
        className: this.props.disabled ? "" : "active"
    };

    dragStart = e => {
        if (this.props.disabled) return;
        e = e ?? window.event;
        e.preventDefault();
        this.setState({ className: "" });

        if (e.type === 'touchstart') {
            x = e.touches[0].pageX;
        } else {
            x = e.pageX;
            document.onmouseup = this.dragEnd;
            document.onmousemove = this.dragAction;
        }
    };

    dragAction = e => {
        e = e ?? window.event;
        let button = this.state.button;
        let container = button.parentElement;
        let min = 4, max = container.clientWidth - button.clientWidth - 4;

        if (e.type === 'touchmove') {
            deltaX = e.touches[0].pageX - x;
            x = e.touches[0].pageX;
        } else {
            deltaX = e.pageX - x;
            x = e.pageX;
        }

        posCurrent += deltaX;
        posCurrent = posCurrent < min ? min : (posCurrent > max ? max : posCurrent);
        button.style.left = posCurrent + "px";
    };

    dragEnd = e => {
        let buttonWidth = this.state.button.clientWidth;
        let containerWidth = this.state.button.parentElement.clientWidth;
        let max = containerWidth - buttonWidth - 4;
        let change = posCurrent - posInitial;
        let thresoldMet = (change / containerWidth) > 0.5 || x >= max;
        this.snapBack();
        if (thresoldMet) {
            this.props.action();
            this.setState({ className: "progress" });
        } else {
            this.setState({ className: "active" });
        }
        document.onmouseup = null;
        document.onmousemove = null;
    };

    snapBack = () => {
        this.setState({ className: "moving" });
        var button = this.state.button;
        button.style.left = "4px";
        x = 0;
        posCurrent = 4;
        deltaX = 0;
        this.setState({ className: "" });
    };

    componentDidMount() {
        var button = document.querySelector("#slider span");
        button.onmousedown = this.dragStart;
        button.addEventListener('touchstart', this.dragStart);
        button.addEventListener('touchend', this.dragEnd);
        button.addEventListener('touchmove', this.dragAction);
        this.setState({ button: button });
    }

    componentDidUpdate(prevProps) {
        if (this.props.disabled !== prevProps.disabled) {
            let newClass = this.props.disabled ? "" : "active";
            this.setState({ className: newClass });
        }
    }

    render() {
        return (
            <div id="slider" className={this.props.disabled ? "disabled" : ""}>
                <span className={this.state.className}>
                    {this.state.className === "progress" ? "Processing..." : this.props.label}
                    <Icon icon="arrow" highlight />
                </span>
            </div>
        );
    }
}

export default Slider;
