import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';
import { ENV } from '../../tools/envTools.js';

import Button from '../../components/Button';
import Icon from '../../components/Icon';

import "./css/navBar.css";

const apiDomain = ENV.getApiDomain();
const compactLayout = window.matchMedia("(max-width: 990px)");

class NavBar extends Component {
    state = {
        compact: compactLayout.matches,
        menuOpen: false
    };

    open = (page, option) => {
        let url = `https://www${apiDomain}.cordoniq.com/${page}/`;
        window.open(url, option ?? "_blank");
    };

    toggleMenu = e => {
        e.preventDefault();
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    closeMenu = e => {
        if (e.target.id === "menu") return;
        this.setState({ menuOpen: false });
    };

    adjustLayout = e => this.setState({ compact: e.matches });

    componentDidMount() {
        compactLayout.addListener(this.adjustLayout);
        window.addEventListener("click", this.closeMenu);
    }

    componentWillUnmount() {
        compactLayout.removeListener(this.adjustLayout);
        window.removeEventListener("click", this.closeMenu);
    }

    render() {
        return (
            <nav id="navBar" className={this.state.compact ? "compact" : ""}>
                <Icon id="logoFull" icon="logoFull" onClick={() => this.open("")} />
                <Icon id="logo" icon="logo" onClick={() => this.open("")} />
                <div id="buttons" className={this.state.menuOpen ? "menuOpen" : ""}>
                    <button onClick={() => this.open("problems-we-solve", "_self")}>Problems we solve</button>
                    <button onClick={() => this.open("solutions", "_self")}>Solutions</button>
                    <button onClick={() => this.open("developer-center", "_self")}>Developer Center</button>
                    <button onClick={() => this.open("about-cordoniq", "_self")}>About Cordoniq</button>
                    <Button outline onClick={() => this.open("/contact")}>Talk to an expert</Button>
                </div>
                <Icon id="menu" icon="menu" onClick={this.toggleMenu} />
            </nav>
        );
    }
}

export default withNav(NavBar);
