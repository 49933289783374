import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const useQuery = () => {
    const location = useLocation();
    const [ searchParams, setSearchParams ] = useState(() => {
        return new URLSearchParams(location.search);
    });

    useEffect(() => {
        const newParams = new URLSearchParams(location.search);
        setSearchParams(newParams);
    }, [location]);

    return searchParams;
};

export default useQuery;
