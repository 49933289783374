import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';

import Icon from '../../components/Icon';

import "./css/landingHeader.css";

class LandingHeader extends Component {
    getClass = () => {
        let className = `${this.props.className} ` ?? "";
        return className;
    }

    render() {
        return (
            <header id="landingHeader" className={this.getClass()}>
                {this.props.breadcrumbs && <ul id="breadcrumbs">
                    {this.props.breadcrumbs.map(breadcrumb => (
                        <li className={breadcrumb.path ? "bold" : ""} onClick={breadcrumb.path ? () => this.goTo(breadcrumb.path) : undefined}>
                            {breadcrumb.title}
                        </li>
                    ))}
                </ul>}
                <div id="heading" className={this.props.id ?? ""}>
                    {this.props.logo && <img id="logo" src={this.props.logo} alt="product logo" />}
                    {this.props.icon && <Icon icon={this.props.icon} mode={this.props.iconMode ?? undefined} />}
                    <div>
                        <h1>{this.props.title}</h1>
                        {this.props.subtitle && <h2>{this.props.subtitle}</h2>}
                    </div>
                </div>
            </header>
        );
    }
}

export default withNav(LandingHeader);
