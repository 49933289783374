import React, { Component } from 'react';
import { overrideColorScheme, removeColorSchemeOverride, setThemeColor } from '../../tools/colorSchemeManager';

import CordoniqFooter from '../partials/CordoniqFooter';
import NavBar from '../partials/NavBar';

import "./css/contentPageNoHeader.css";

class ContentPageNoHeader extends Component {
    componentDidMount() {
        setThemeColor(255, 255, 255);
        overrideColorScheme('light');
    }

    componentWillUnmount() {
        removeColorSchemeOverride();
    }

    render () {
        return (
            <main className="contentPageNoHeader">
                <NavBar linkTag={this.props.id ?? "nav"} />
                <div id={this.props.id}>{this.props.children}</div>
                <CordoniqFooter />
            </main>
        );
    }
}

export default ContentPageNoHeader;
