export const ReactGS = {
    init: function(convId) {
        window.dataLayer = window.dataLayer || [];
        (function (w,d,t,s,f) {
            return w.gtag || (
                w.gtag = function () {
                    window.dataLayer.push(arguments);
                },
                s = d.createElement(t),
                s.async = !0,
                s.src = `https://www.googletagmanager.com/gtag/js?id=${convId}`,
                f = d.getElementsByTagName(t)[0],
                f === undefined
                    ? document.body.appendChild(s)
                    : f.parentNode.insertBefore(s,f)
            )
        })(window,document,'script');
        window.gtag('js', new Date());
        window.gtag('config', convId);
    },
    event: function(event_name, event_params) {
        window.gtag('event', event_name, event_params);
    }
};

export default ReactGS;
