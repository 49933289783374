import { api } from '../config.js';
import { Token } from '../auth.js';

export const ToastIcons = {
    None: 0,
    Success: 1,
    Warning: 2,
    Error: 3,
    Info: 4
};

export const ToastSounds = {
    None: 0,
    Chat: 1,
    Join: 2,
    Leave: 3
};

export const UserGroup = {
    Specified: 1,
    All: 2,
    Attendees: 3,
    Presenters: 4,
    Hosts: 5
};

let defaultToast = {
   messageText: "",
   icon: ToastIcons.None,
   sound: ToastSounds.None,
   durationMs: 1000,
   userGroup: UserGroup.Specified,
   userIds: [],
   flags: 0,
   buttonText: undefined,
   buttonUrl: undefined
};

export const sendToast = (confId, options) => {
    let requestData = { ...defaultToast, ...options };
    return new Promise((resolve, reject) => {
        api(Token.get()).post(`/conference/${confId}/toast`, requestData)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

const ToastAPI = {
    toast: {
        send: sendToast
    }
};

export default ToastAPI;
