import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import CordoniqContentWithToolbar from '../templates/CordoniqContentWithToolbar';

import './css/tvPreJoin.css';


class TvPreJoin extends Component {
    
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }
    
    disabled = () => {
    }

    render() {
        
        return (
            <CordoniqContentWithToolbar footerButtons={[]} >
                <section>
                    <div className="TvPreJoin content">
                        <h1>How would you like to begin?</h1>
                        <div className='two-col-divider'>
                            <div className='col'>
                                <div onClick={() => this.goTo("/TV/start")}>
                                    <div className='imgcont'>
                                        <div className='glyph' style={{ backgroundImage: `url('/tv_dashboard.png')` }}></div>
                                    </div>
                                    <h3>Create a new room</h3>
                                </div>
                                
                            </div>
                            <div className='vl'></div>
                            <div className='col'>
                                <div onClick={() => this.goTo("/TV/join")}>
                                    <div className='imgcont'>
                                        <div className='glyph' style={{backgroundImage: `url('/tv_join.png')` }}></div>
                                    </div>
                                    <h3>Enter an existing room</h3>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </CordoniqContentWithToolbar>
        );
    }
}

export default withNav(TvPreJoin);
