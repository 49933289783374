import React, { Component } from 'react';

import Icon from '../../components/Icon';

import './css/user.css';


let gray = "rgb(171, 169, 171)",
    green = "rgb(25, 182, 100)",
    red = "rgb(236, 16, 36)"

class User extends Component {
    state = {
        videoIcon: "cameraOff",
        videoColor: red,
        audioIcon: "micDisabled",
        audioColor: red,
        menuOpen: false
    };

    getUserInitials = () => {
        let { displayName } = this.state;
        if (displayName === undefined) return "";
        let parts = displayName.split(" "), initials = "";
        for (var i = 0; i < parts.length; i++) {
            if (i < 2) initials += parts[i][0];
        }
        return initials;
    };

    updateSetting = (property, value) => {
        // Change for actually API update with refresh from event
        let changes = { [property]: value };
        if (property === "videoState") {
            changes.videoIcon = value ? "camera" : "cameraOff";
            changes.videoColor = value ? gray : red;
        }
        if (property === "audioState") {
            changes.audioIcon = value ? "mic" : "micDisabled";
            changes.audioColor = value ? gray : red;
        }
        this.setState(changes);
    };

    toggleMenu = e => {
        e.stopPropagation();
        let { menuOpen } = this.state;
        this.setState({ menuOpen: !menuOpen });
    };

    closeMenu = e => {
        this.setState({ menuOpen: false });
    };

    updateUser = user => {
        let videoIcon = user.videoState ? "camera" : "cameraOff",
            videoColor = user.videoState ? gray : red,
            audioIcon = user.audioState ? "mic": "micDisabled",
            audioColor = user.audioState ? gray : red;
        this.setState({ ...user, videoIcon, videoColor, audioIcon, audioColor });
    };

    componentDidMount() {
        this.updateUser(this.props.user);
        window.addEventListener("click", this.closeMenu);
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.updateUser(this.props.user);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.closeMenu);
    }

    render () {
        let user = this.state;
        return (
            <li className="user">
                <span>{this.getUserInitials()}</span>
                <div className="name">{user.displayName}</div>
                <div className="icon" onClick={() => this.updateSetting("videoState", user.videoState ? 0 : 1)}
                    onMouseOver={e => {
                        let videoIcon = user.videoState ? "cameraOff" : "camera",
                            videoColor = user.videoState ? red : green;
                        this.setState({ videoIcon, videoColor });
                    }}
                    onMouseOut={e => {
                        let videoIcon = user.videoState ? "camera" : "cameraOff",
                            videoColor = user.videoState ? gray : red;
                        this.setState({ videoIcon, videoColor });
                    }}
                >
                    <Icon svg icon={user.videoIcon} color={user.videoColor} mode="fill" />
                </div>
                <div className="icon" onClick={() => this.updateSetting("audioState", user.audioState ? 0 : 1)}
                    onMouseOver={e => {
                        let audioIcon = user.audioState ? "micDisabled" : "mic",
                            audioColor = user.audioState ? red : green;
                        this.setState({ audioIcon, audioColor });
                    }}
                    onMouseOut={e => {
                        let audioIcon = user.audioState ? "mic" : "micDisabled",
                            audioColor = user.audioState ? gray : red;
                        this.setState({ audioIcon, audioColor });
                    }}
                >
                    <Icon svg icon={user.audioIcon} color={user.audioColor} mode="fill" />
                </div>
                <div id="menuButton" className="icon" onClick={this.toggleMenu}>
                    <Icon svg icon="moreHor" color={gray} mode="fill" />
                    <ul className={user.menuOpen ? "show" : ""}>
                        {user.role < 3 && <li>Make a host</li>}
                        {user.role < 2 && <li>Make a presenter</li>}
                        <li>Expel user</li>
                    </ul>
                </div>
            </li>
        );
    }
}

export default User;
