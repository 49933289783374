import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Token } from '../LumiAPI/APIs/auth';
import { PORTAL } from '../tools/envTools';

import SessionEnded from '../pages/routing/SessionEnded';

import NewApp from './NewApp';
import Dashboard from './Dashboard';
import Settings from './Settings';
import AccountSettings from './AccountSettings';
import Conferences from './Conferences';
import ConferenceDetails from './ConferenceDetails';
import Users from './Users';
import Roles from './Roles';
import Recordings from './Recordings';
import Library from './Library';
import Integrations from './Integrations';
import Subscribers from './Subscribers';
import Downloads from './Downloads';
import PrivateCloud from './PrivateCloud';
import Servers from './Servers';
import NoPermission from './routing/NoPermission';
import ForDevs from './routing/ForDevs';
import NotFound from './routing/NotFound';

class Portal extends Component {
    render () {
        let signedIn = Token.isAuthenticated();
        return (<>
            <NewApp />
            <SessionEnded />
            <Switch>
                <Route exact path={[ "/Portal/Dashboard", "/Portal" ]}>
                    {signedIn ? <Dashboard /> : <Redirect to="/SignIn" />}
                </Route>
                <Route exact path="/Portal/Docs"><Redirect to="/Docs/Docs/Overview" /></Route>

                <Route exact path="/Portal/Conferences"><Redirect to="/Portal/Conferences/1" /></Route>
                <Route exact path="/Portal/Conferences/:state" component={Conferences} />
                <Route exact path="/Portal/Conference/:id" component={ConferenceDetails} />
                <Route exact path="/Portal/Users"><Users /></Route>

                <Route exact path="/Portal/Roles"><Redirect to="/Portal/Roles/1" /></Route>
                <Route exact path="/Portal/Roles/:role" component={Roles} />

                <Route exact path="/Portal/Recordings"><Recordings /></Route>

                <Route exact path="/Portal/Library"><Library /></Route>

                <Route exact path="/Portal/Integrations"><Redirect to="/Integrations" /></Route>
                <Route exact path="/Portal/Integrations/LTI">
                    {PORTAL.isDev() ? <Integrations /> : <ForDevs />}
                </Route>
                <Route exact path="/Portal/Subscribers">
                    {PORTAL.isDev() ? <Subscribers /> : <ForDevs />}
                </Route>

                <Route exact path="/Portal/Downloads"><Downloads /></Route>

                <Route exact path="/Portal/UserSettings"><AccountSettings /></Route>
                <Route exact path="/Portal/Settings"><Settings /></Route>
                <Route exact path="/Portal/PrivateCloud">
                    {PORTAL.isDev() ? <PrivateCloud /> : <ForDevs />}
                </Route>
                <Route exact path="/Portal/Servers">
                    {PORTAL.isDev() ? <Servers /> : <ForDevs />}
                </Route>

                <Route exact path="/Portal/NoPermission"><NoPermission /></Route>
                <Route path="/Portal/:page" component={NotFound} />
            </Switch>
        </>);
    }
}

export default Portal;
