import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../hooks/useNav';
import { newDemo } from '../LumiAPI/APIs/core/conference.js';
import CordoniqContentWithToolbar from '../templates/CordoniqContentWithToolbar';
import Notification from '../tools/Notification';
import { ENV } from '../tools/envTools';

import './css/startMeeting.css';


class StartMeeting extends Component {
   
    componentDidMount() {
        this.startNewMeeting();
    }

    componentDidUpdate(prevProps) {
    }
    
    startNewMeeting = () => {        
        newDemo("Cordoniq for TV").then(demo => {
            let apiDomain = ENV.getApiDomain();
            let hostUrl = new URL(demo.hostUrl);
            let confInfo = {
                confId: demo.confId,
                password: hostUrl.searchParams.get('password'),
                joinUrl: demo.hostUrl.replace("connect", `connect${apiDomain}`)
            };
            if (ENV.isProd()) {
                ReactGA.event({
                    category: 'Cordoniq for TV Demo Room',
                    action: 'New Meeting Started',
                    label: ""
                });
            }
            window.location.assign(confInfo.joinUrl);
        }).catch(err => {
            new Notification({ id: "unknownError", message: err, type: "error" });
        });
    }

    render() {
        
        return (
            // <CordoniqContentWithToolbar buttons={buttons} headerextra={<Button>What's up</Button>}>
            <CordoniqContentWithToolbar footerButtons={[]} >
                <section>
                    <div className="startMeeting content">                        
                    </div>
                </section>
            </CordoniqContentWithToolbar>
        );
    }
}

export default withNav(StartMeeting);
