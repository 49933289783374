import React, { Component } from 'react';

import './css/toggle.css';

class Toggle extends Component {
	state = {
		enabled: this.props.enabled ?? false,
		disabled: this.props.disabled ?? false
	};

	handleClick = e => {
		if (!this.state.disabled) {
			var checked = e.target.previousSibling.checked;
			if (this.props.click) this.props.click(!checked);
			this.setState({ enabled: !checked });
		}
	};

	componentDidUpdate(prevProps) {
		if(this.props.enabled !== prevProps.enabled) {
			this.setState({
				enabled: this.props.enabled,
				disabled: this.props.disabled
			});
		}
	}

	render() {
		return (
			<div className={"toggleContainer " + this.props.className}>
				{this.props.label && <label>{this.props.label}</label>}
				<input type="checkbox" name={this.props.name} checked={this.state.enabled} onChange={() => { return null; }} />
				<div id={this.props.name} className={this.state.disabled ? "toggle disabled" : "toggle"} role="button" onClick={this.handleClick}>
					<div className="ripple"></div>
					<div className="knob"></div>
					<div className="rail"></div>
				</div>
			</div>
		);
	}
}

export default Toggle;
