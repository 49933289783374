import { store } from '../index.js';

export const setChatOwner = owner => {
    return {
        type: 'SETCHATOWNER',
        payload: {
            owner: owner
        }
    }
};

export const refreshChats = chats => {
    return {
        type: 'REFRESHCHATS',
        payload: {
            chats: chats
        }
    }
};

export const changeChatConf = confId => {
    return {
        type: 'CHANGECHATCONF',
        payload: {
            confId: confId
        }
    }
};

export const openChat = chatId => {
    return {
        type: 'OPENCHAT',
        payload: {
            chatId: chatId
        }
    }
};

export const updateChat = chat => {
    let chats = store.getState().chats;
    chats[chat.chatId] = chat;
    return {
        type: 'UPDATECHAT',
        payload: {
            chats: chats
        }
    }
};

export const refreshChatUsers = users => {
    return {
        type: 'REFRESHCHATUSERS',
        payload: {
            users: users
        }
    }
};

export const refreshMessages = messages => {
    return {
        type: 'REFRESHMESSAGES',
        payload: {
            messages: messages
        }
    }
};

export const updateMessage = message => {
    let messages = store.getState().messages;
    messages.some((item, i) => {
        if (message.messageId === item.messageId) {
            messages[i] = message;
            return true;
        } else {
            return false;
        }
    });
    return {
        type: 'UPDATEMESSAGE',
        payload: {
            messages: messages
        }
    }
};

export const refreshRecents = recents => {
    return {
        type: 'REFRESHRECENTS',
        payload: {
            recents: recents
        }
    }
};

export const setDelivered = index => {
    return {
        type: 'SETDELIVERED',
        payload: {
            index: index
        }
    }
};

export const setRecipients = recipients => {
    return {
        type: 'SETRECIPIENTS',
        payload: {
            recipients: recipients
        }
    }
};
