import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { addSpaces } from '../tools/stringTools';
import ReactGA from 'react-ga';
import ReactGS from '../tools/react-gs.js';
import ReactTwit from '../tools/react-twitter.js';
import { ENV } from '../tools/envTools';

export const pathToTitle = pathname => {
    var path = pathname.split("/");
    var pageTitle = path[2] ?? path[1] ?? "Dashboard";
    pageTitle = pageTitle.split("?")[0];
    return addSpaces(pageTitle);
};

const usePageViews = () => {
    let location = useLocation();
    let isForm = location.pathname.includes("forms");

    useEffect(() => {
        if (ENV.isProd() && !isForm) {
        	// Initialize Google Analytics
        	ReactGA.initialize('UA-159582842-1');
        	// Initialize Google Global Site tag
        	ReactGS.init('AW-404253915');
        	// Set up Twitter
        	ReactTwit.init('o3d6l');
        }
    });

    useEffect(() => {
        if (ENV.isProd() && !isForm) {
            let path = location.pathname;
            ReactGA.pageview(path, undefined, pathToTitle(path));
            ReactTwit.pageView();
        }
    }, [location, isForm]);
};

export const withPageViews = WrappedComponent => props => {
    usePageViews();
    return <WrappedComponent {...props} />;
};

export default usePageViews;
