import React, { Component } from 'react';

import './css/placeholder.css';

class Placeholder extends Component {
    className = () => {
        let className = "placeholder";
        className += this.props.section ? " section" : "";
        className += this.props.circle ? " circle" : "";
        className += this.props.heading ? " heading" : "";
        className += this.props.light ? " light" : "";
        className += this.props.className ? ` ${this.props.className}` : "";
        return className;
    };

    render() {
        return (
            <div {...this.props} className={this.className()}></div>
        );
    }
}

export default Placeholder;
