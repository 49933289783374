import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { getConferenceInfo } from '../LumiAPI/APIs/internal/webApp';
import { setThemeColor } from '../tools/colorSchemeManager';
import Query from '../tools/Query';

import Button from '../components/Button';
import Icon from '../components/Icon';
import Password from '../components/Password';
import TextField from '../components/TextField';

import Notification from '../tools/Notification';
import Interface from './pages/Interface';

import './css/lumiApp.css';

const WEBAPPKEY = "lumiWebAppSavedData";

class LumiApp extends Component {
    state = {
        loading: true,
        changes: {}
    };

    handleEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    joinDisabled = () => {
        let { changes } = this.state;
        if (changes.confId === "") return true;
        if (changes.name === "") return true;
        return false;
    };

    submit = () => {
        let { changes } = this.state;
        let redirect = `/app/${changes.intId}`;
            redirect += `?id=${changes.confId}&name=${encodeURIComponent(changes.name)}`;
            redirect += changes.password !== "" ? `&password=${changes.password}` : "";

        let storedData = localStorage.getItem(WEBAPPKEY);
        storedData = JSON.parse(storedData);

        localStorage.setItem(WEBAPPKEY, JSON.stringify({ ...storedData, ...changes }));
        this.goTo(redirect);
    };

    checkConfId = confId => {
        let intId = this.props.match.params.intId;
        if (confId) {
            getConferenceInfo(intId, confId).then(conference => {
                this.setState({ intId, confId, conference, loading: false });
            }).catch(err => {
                new Notification({ id: "invalidConf", message: "Conference Not Found!", type: "error" });
                this.setState({ loading: false });
            });
        } else {
            let storedData = localStorage.getItem(WEBAPPKEY);
            storedData = JSON.parse(storedData);
            this.setState({ changes: {
                intId,
                confId: storedData?.confId ?? "",
                name: storedData?.name ?? "",
                password: ""
            }, loading: false });
        }
    };

    componentDidMount() {
        setThemeColor(55, 18, 66);
        let query = new Query(window.location.search),
            confId = query.get('id');
        this.checkConfId(confId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.changes.confId !== this.state.changes.confId) {
            this.checkConfId(this.state.changes.confId);
        }
    }

    render() {
        let { changes } = this.state;
        return (<>
            {this.state.confId
                ? <Interface intId={this.state.intId} conference={this.state.conference} />
                : <main id="lumiAppDetails">
                    <section>
                        <div id="logo">
                            <Icon icon="logo" mode="dark" />
                            <h1>Cordoniq</h1>
                        </div>
                        <div id="fields">
                            <TextField name="confId" label="Conference ID" placeholder="Enter Conference ID..." value={changes.confId} onEdit={this.handleEdit} />
                            <Password name="password" label="Conference Password" placeholder="Enter Conference Password (Optional)" value={changes.password} onEdit={this.handleEdit} />
                            <TextField name="name" label="Your Name" placeholder="Enter your name..." value={changes.name} onEdit={this.handleEdit} />
                        </div>
                        <Button onClick={this.submit} disabled={this.joinDisabled()}>Join Conference</Button>
                    </section>
                    {this.state.loading && <div id="loading">
                        <div></div>
                        <b>Loading...</b>
                    </div>}
                </main>
            }
        </>);
    }
}

export default withNav(LumiApp);
