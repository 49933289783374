import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../hooks/useNav';
import { Token } from '../LumiAPI/APIs/auth';
import AccountAPI from '../LumiAPI/APIs/internal/account';


import { getConfInfo } from '../LumiAPI/APIs/core/conference.js';
import Button from '../components/Button';
import Password from '../components/Password';
import TextField from '../components/TextField';
import CordoniqContentWithToolbar from '../templates/CordoniqContentWithToolbar';
import Notification from '../tools/Notification';
import { ENV } from '../tools/envTools';


import './css/joinConference.css';


class JoinConference extends Component {

    state = {
        confId: "", 
        password: "", 
        userCn: ""
    };

    constructor(props) {
        super(props);
        let authenticated = Token.isAuthenticated();
        if(authenticated) {
            AccountAPI.account.get()
            .then((account) => {
                let displayName = account.firstName !== "" && account.lastName !== "" ?
                    `${account.firstName} ${account.lastName}` : account.username
                this.setState({userCn: displayName})
            })
            .catch((err) => {
                // No action to take - let the user fill out 
                // the form and join a meeting.
            });
        }
    }
    
    componentDidMount() {
        setTimeout(() => {
            let input = document.querySelector('#inputConfId');
            if(input) {
                input.focus();
            }
        }, 0.25);
    }

    componentDidUpdate(prevProps) {
    }
    
    handleEdit = change => this.setState({ [change.name]: change.value, invalid: false });

    JoinNow = e => {
        e.preventDefault();

        if (ENV.isProd()) {
            ReactGA.event({
                category: 'JoinRoom',
                action: 'joinNow',
                label: this.state.confId
            });
        }
        getConfInfo(this.state.confId)
            .then(details => {
                let link = `https://connect${ENV.getApiDomain()}.cordoniq.com/join?id=${this.state.confId}&password=${this.state.password}&name=${this.state.userCn}`;
                window.location.assign(link);
            }).catch(err => {
                console.log(`getConfInfo error: ${err}`);
                new Notification({ id: "getConfInfo", message: "The Room ID does not exist or has already ended, please try again ", type: "error" });
            });
    };

    disabled = () => {
        return this.state.confId === "" || this.state.userCn === "";
    }

    render() {
        let footerButtons = [
            {
                name: "Log in",
                path: "/TV/login"
            },
            {
                name: "Sign up",
                path: "/SignUp"
            }
        ].map((button) => {
            return <Button key={button.name} onClick={() => this.goTo(button.path)}>{button.name}</Button> 
        })


        return (
            // <CordoniqContentWithToolbar buttons={buttons} headerextra={<Button>What's up</Button>}>
            <CordoniqContentWithToolbar footerButtons={footerButtons} >
                <section>
                    <div className="joinConference content">
                        <h1>Enter a room</h1>
                        {/* marginLeft: '20px', marginRight: '20px', paddingBottom: '10vh' */}
                        <form>
                            <TextField id="inputConfId" name="confId" placeholder="Room ID" value={this.state.confId} onEdit={this.handleEdit} />
                            <Password name="password" placeholder="Password" value={this.state.password} onEdit={this.handleEdit} />
                            <TextField name="userCn" placeholder="Display name" value={this.state.userCn} onEdit={this.handleEdit} aria-flowto="joinNow" />
                            <Button id="joinNow" onClick={this.JoinNow} disabled={this.disabled()}>Join now</Button>
                        </form>
                        <div className='joinConferenceAlt'>
                            <span>Don't have a room ID?</span>
                            <Button link="true" onClick={() => this.goTo("/TV/start")}>Explore a meeting room now</Button>
                        </div>
                    </div>
                </section>
            </CordoniqContentWithToolbar>
        );
    }
}

export default withNav(JoinConference);
