import { Content as ContentAPI } from '../LumiAPI/APIs/content/Content.js';
import { faFileWord, faFilePowerpoint, faFileExcel, faFilePdf,
		 faFileAudio, faFileImage, faFileVideo, faFileCode, faFileAlt
} from '@fortawesome/free-solid-svg-icons';

export default class Content extends ContentAPI {
	getPreview = () => {
		return this.preview;
	};

	setPreview = image => {
		this.preview = image;
	};

	getIcon = () => {
		var type = this.contentType;
		if (type.includes("officedocument")) {
			if (type.includes("presentation")) {
				return faFilePowerpoint;
			} else if (type.includes("sheet")) {
				return faFileExcel;
			} else if (type.includes("document")) {
				return faFileWord;
			}
		} else if (type.includes("pdf")) {
			return faFilePdf;
			// return "filePDF";
		} else if (type.includes("audio")) {
			return faFileAudio;
			// return "audio";
		} else if (type.includes("video")) {
			return faFileVideo;
			// return "camera";
		} else if (type.includes("image")) {
			return faFileImage;
			// return "image";
		} else if (type.includes("html") ||
			type.includes("javascript") ||
			type.includes("css")
		) {
			return faFileCode;
		} else {
			return faFileAlt;
			// return "file";
		}
	};

	getIconColor = () => {
		var type = this.contentType;
		if (type.includes("officedocument")) {
			if (type.includes("presentation")) {
				return "rgb(170, 58, 51)";
			} else if (type.includes("sheet")) {
				return "rgb(58, 113, 74)";
			} else if (type.includes("document")) {
				return "rgb(52, 87, 149)";
			}
		} else if (type.includes("pdf")) {
			return "rgb(244, 15, 2)";
		} else if (type.includes("audio")) {
			return "rgb(91, 140, 0)"
		} else if (type.includes("video")) {
			return "rgb(154, 61, 165)";
		} else if (type.includes("image")) {
			return "rgb(12, 126, 179)";
		} else if (type.includes("html") ||
			type.includes("javascript") ||
			type.includes("css")
		) {
			return "rgb(20, 168, 169)";
		} else {
			return "rgb(143, 141, 144)";
		}
	};

	getProp = prop => {
		return this?.[prop] ?? null;
	};

	setProp = (prop, value) => {
		this[prop] = value;
	};

	static fromFile = file => {
		var content = new this();
        content.contentType = file.type;
        content.content = file;
        content.displayName = file.name;
        content.fileName = file.name;
        return content;
	};
}
