import React, { Component } from 'react';
// import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { MESSAGE_TYPE, getUrlPreview, removeHTML } from '../../LumiAPI/newChatAPI.js';
import { /*colors, accountIconColor,*/ getColorScheme, ColorUpdater } from '../../tools/colorSchemeManager';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import Icon from '../../components/Icon';

import { connect } from 'react-redux';

import './css/message.css';

const urlExp = /^((http[s]?):\/\/)?([a-zA-Z\d-]*\.)?([a-zA-Z\d-]+)\.([a-zA-Z]{2,5})(:[\d]{1,5})?/g;
const urlRegEx = new RegExp(urlExp);
const tweetExp = /(?:http[s]?:\/\/)?(?:[www.]?twitter.com\/)(?:[a-zA-Z\d/]+)(?:\/status\/)([a-zA-Z\d]+)/;
const tweetRegEx = new RegExp(tweetExp);

class Message extends Component {
    state = {
        ownerId: this.props.chatOwner?.userId,
        message: this.props.messages?.[this.props.index] ?? undefined,
        messages: this.props.messages ?? [],
        preview: undefined,
        showDelivered: (this.props.index === this.props.deliveredIndex) &&
            (this.props.messages[this.props.index].userId === this.props.chatOwner.userId),
        colorScheme: getColorScheme()
    };

    getMessageInitials = () => {
        let name = this.state.message.displayName.split(" ");
        let initials = "";
        name.forEach(n => {
            initials += n[0]?.toUpperCase() ?? "U";
        });
        return initials;
    };

    showTimestamp = index => {
        index = index ?? this.props.index;
        let message = this.state.messages?.[index],
            prevMessage = this.state.messages?.[index - 1];
        return prevMessage?.created.date() !== message?.created.date() ?? false;
    };

    hasUrl = () => {
        let { message } = this.state,
            words = message.messageData.split(" "),
            hasUrl = false;
        words.some(word => {
            if (word.match(urlRegEx)) {
                hasUrl = true;
                return true;
            } else {
                return false;
            }
        });
        return hasUrl;
    };

    getUrl = () => {
        let { message } = this.state,
            words = removeHTML(message.messageData).split(" "),
            url = null;
        words.some(word => {
            if (word.match(urlRegEx)) {
                url = word;
                if (url.indexOf("http") === -1) url = `https://${url}`;
                return true;
            } else {
                return false;
            }
        });
        return url;
    };

    parsePreview = async () => {
        let { message, preview } = this.state,
            words = removeHTML(message.messageData).split(" ");
        for (var i = 0; i < words.length; i++) {
            if (words[i].match(tweetRegEx)) { // Check for tweets first
                let tweetId = words[i].match(tweetExp)[1];
                if (tweetId !== undefined) {
                    let uniqueId = tweetId+uuid().substring(0, 5);
                    preview = {
                        uniqueId: uniqueId,
                        tweetId: tweetId,
                        img: null,
                        video: null
                    };
                    return preview;
                }
            } else if (words[i].match(urlRegEx)) { // Check for other urls
                let link = words[i];
                if ((i === 0 || i === words.length - 1) && words.length !== 1) {
                    message.messageData = message.messageData.replace(link, "").trim();
                }
                try {
                    preview = await getUrlPreview({ uri: link });
                    if (preview?.img ?? false) {
                        let img = document.createElement("img");
                        img.src = preview.img;
                        let aspectRatio = img.naturalWidth / img.naturalHeight;
                        if (0.85 < aspectRatio && aspectRatio < 1.15) {
                            preview.imgType = "icon";
                        }
                    }
                    this.setState({ message: message });
                    return preview;
                } catch (e) {
                    if (link.indexOf("http") === -1) link = `https://${link}`;
                    return {
                        description: link,
                        link: link,
                        img: null,
                        video: null
                    };
                }
            } else if (i === words.length - 1) { // Return unchanged message otherwise
                return {};
            }
        }
    };

    getPreviewClass = () => {
        let className = "message preview",
            { message, preview, ownerId } = this.state,
            { index, deliveredIndex } = this.props,
            prevDelivered = index - 1 === deliveredIndex;
        if (message.userId === ownerId) {
            className += " me";
        }

        let messageClass = this.getClassName();

        if (messageClass.includes("first")) {
            className += " first";
        } else if (messageClass.includes("middle")) {
            className += " middle";
        } else if (messageClass.includes("last")) {
            className += prevDelivered ? " first" : " middle";
        }

        if (preview.img === null &&
            preview.video === null &&
            preview.tweetId === undefined
        ) {
            className += " noImgPreview";
        }

        if (preview.imgType === "icon") {
            className += " hasIcon";
        }

        if (preview.tweetId !== undefined) {
            className += " tweet";
        }

        return className;
    };

    getClassName = () => {
        let className = "message",
            { messages, index, deliveredIndex } = this.props,
            { ownerId, message, preview, showDelivered } = this.state,
            prevMessage = messages?.[index - 1],
            nextMessage = messages?.[index + 1];

        let senderIsOwner = message.userId === ownerId,
            lastSameSender = prevMessage?.userId === message.userId,
            nextSameSender = nextMessage?.userId === message.userId,
            hasTimeStamp = this.showTimestamp(),
            nextHasTimeStamp = this.showTimestamp(index + 1),
            hasPreview = preview !== undefined,
            prevDelivered = index - 1 === deliveredIndex,
            hasDeliveredBadge = showDelivered;

        className += senderIsOwner ? " me" : "";
        className += message.animate ? " sent" : "";
        className += message.failed ? " failed" : "";

        if (nextSameSender && lastSameSender) {
            if (nextHasTimeStamp) {
                className += (hasTimeStamp && hasPreview) || !hasTimeStamp ? " last" : "";
            } else {
                className += hasTimeStamp
                    ? hasPreview ? " middle" : " first"
                    : " middle";
            }
        } else if (!nextSameSender && lastSameSender) {
            className += !hasTimeStamp || hasPreview ? " last" : "";
        } else if (nextSameSender && !lastSameSender) {
            className += !nextHasTimeStamp
                ? hasPreview ? " middle" : " first"
                : "";
        }

        if (hasDeliveredBadge) {
            className = className.replace("first", "last").replace("middle", "last");
        } else if (prevDelivered) {
            className = className.replace("middle", "first");
            if (index === messages.length - 1 && !hasPreview) className = className.replace("last", "");
        }

        return className;
    };

    updateColorScheme = colorScheme => this.setState({ colorScheme: getColorScheme() });

    componentDidMount() {
        ColorUpdater.add(this.updateColorScheme);

        let url = this.getUrl();
        if (this.state.message.messageType === MESSAGE_TYPE.Text && url !== null) {
            this.setState({ preview: { title: "Loading preview...", description: url, img: null, video: null, link: url } });
            this.parsePreview().then(preview => this.setState({ preview }));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.chatOwner !== prevProps.chatOwner) {
            this.setState({ ownerId: this.props.chatOwner.userId });
        }

        if ((this.props.index !== prevProps.index) ||
            (this.props.messages !== prevProps.messages)
        ) {
            this.setState({
                message: this.props.messages?.[this.props.index] ?? undefined,
                messages: this.props.messages
            });
        }

        if (this.props.deliveredIndex !== prevProps.deliveredIndex) {
            this.setState({
                showDelivered: (this.props.index === this.props.deliveredIndex) &&
                    (this.props.messages[this.props.index].userId === this.props.chatOwner.userId)
            });
        }
    }

    componentWillUnmount() {
        ColorUpdater.remove(this.updateColorScheme);
    }

    render() {
        let { message, preview } = this.state,
            initials = this.getMessageInitials();
        if (preview?.tweetId !== undefined ?? false) {
            window.twttr.widgets.createTweet(preview.tweetId,
                document.getElementById(preview.uniqueId),
                { theme: this.state.colorScheme }
            ).then(el => {
                console.log(el);
            }).catch(err => console.log(err));
        }
        return (<>
            {this.state.message && <>
                {this.showTimestamp() && <div className="timestamp">{message.created.format('ll')}</div>}
                {preview !== undefined && <div id={preview?.uniqueId ?? undefined} className={this.getPreviewClass()} onClick={() => window.open(preview.link)}>
                    {preview.video !== null
                        ? <iframe title="Video Message" className="previewVideo" src={preview.video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        : preview?.img !== null
                            ? (preview?.imgType === "icon" ?? false)
                                ? ""
                                : <img src={preview.img} alt="Message with attachment" />
                            : <Icon className="noImg" icon={faLink} />
                    }
                    {preview.tweetId === undefined && <div style={preview?.imgType === "icon" ?? false ? { backgroundImage: `url(${preview.img})` } : undefined }>
                        {preview.title !== null && <b>{preview.title}</b>}
                        {preview.description}
                        {preview.domain && <div><Icon id="linkIcon" icon={faLink} />{preview.domain}</div>}
                    </div>}
                </div>}
                <div className={this.getClassName()}>
                    <span>{initials}</span>
                    {removeHTML(this.state.message.messageData)}
                    {(message?.failed ?? false) && <Icon id="failed" icon="alert" mode="dark" />}
                </div>
                {this.state.showDelivered && <div className="delivered">Delivered</div>}
            </>}
        </>);
    }
}

const mapStateToProps = state => ({
    chatOwner: state.chatOwner,
    messages: state.messages,
    deliveredIndex: state.deliveredIndex
});

export default connect(mapStateToProps)(Message);
