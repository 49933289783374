import React, { Component } from 'react';
import ContentAPI from '../LumiAPI/APIs/content.js';
import { Token } from '../LumiAPI/APIs/auth.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import * as fileDownload from 'js-file-download';

import { withNav } from '../hooks/useNav';

import Section from '../partials/Section';
import Content from '../components/Content';
import Icon from '../components/Icon';
import Button from '../components/Button';

import { connect } from 'react-redux';

import './css/files.css';

class Files extends Component {
	state = {
		files: [],
		uploaded: 0,
		loading: {},
		changes: {
			button: {
				width: {
					from: 120,
					to: 60,
					elements: [
						{
							name: "width",
							value: width => width + "px"
						}
					]
				},
				padding: {
					from: 0,
					to: 12,
					elements: [
						{
							name: "padding",
							value: padding => padding + "px"
						},
						{
							name: "paddingLeft",
							value: padding => (padding * 0.5) + "px"
						}
					]
				},
				radius: {
					from: 6.66,
					to: 50,
					elements: [
						{
							name: "borderRadius",
							value: radius => `0px ${radius}% ${radius}% 0px`
						}
					]
				}
			},
			icons: {
				size: {
					from: 120,
					to: 40,
					elements: [
						{
							name: "width",
							value: width => width + "px"
						},
						{
							name: "height",
							value: height => height + "px"
						}
					]
				},
				radius: {
					from: 6.66,
					to: 50,
					elements: [
						{
							name: "borderRadius",
							value: radius => radius + "%"
						}
					]
				}
			},
			banner: {
				position: {
					from: 0,
					to: 100,
					elements: [
						{
							name: "position",
							value: value => value > 50 ? "absolute" : "static"
						}
					]
				},
				bottom: {
					from: 0,
					to: 8,
					elements: [
						{
							name: "bottom",
							value: value => value + "px"
						}
					]
				},
				left: {
					from: 0,
					to: 45,
					elements: [
						{
							name: "left",
							value: value => value + "%"
						}
					]
				},
				borderWidth: {
					from: 0,
					to: 2,
					elements: [
						{
							name: "borderWidth",
							value: width => width + "px"
						}
					]
				},
				borderRadius: {
					from: 8,
					to: 12,
					elements: [
						{
							name: "borderRadius",
							value: radius => radius + "px"
						}
					]
				},
				padding: {
					from: 10,
					to: 2,
					elements: [
						{
							name: "padding",
							value: padding => padding + "px"
						}
					]
				}
			},
			add: {
				size: {
					from: 32,
					to: 15,
					elements: [
						{
							name: "width",
							value: width => width + "px"
						},
						{
							name: "height",
							value: height => height + "px"
						}
					]
				},
				marginRight: {
					from: 5,
					to: 0,
					elements: [
						{
							name: "marginRight",
							value: margin => margin + "px"
						}
					]
				}
			},
			label: {
				opacity: {
					from: 0.999999,
					to: 0,
					elements: [
						{
							name: "opacity",
							value: opacity => opacity
						}
					]
				},
				fontSize: {
					from: 13,
					to: 0,
					elements: [
						{
							name: "fontSize",
							value: size => size + "px"
						}
					]
				}
			}
		}
	};

	updateFilesList = contents => {
		var files = [];

		const update = contents => {
			contents.forEach(content => {
				var newContent = new Content();
				newContent.set(content);
				files.push(newContent);
			});
			this.setState({ files: files });
		};

		if (contents === undefined) {
			this.setState({ files: [] });
			if (!Token.isValid()) return;
			ContentAPI.contents.get().then(update).catch(handleErrors);
		} else {
			if (this.state.uploaded === 0) update(contents);
		}
	};

	download = i => {
		var files = this.state.files;
		files[i].downloading = 1;
		this.setState({ files: files });
		ContentAPI.content.get(files[i].contentId, progress => {
			var completed = Math.round((progress.loaded * 100)/progress.total);
			files[i].downloading = completed === 100 ? 0 : completed;
			this.setState({ files: files });
		}).then(file => {
			fileDownload(file, files[i].fileName);
		}).catch(handleErrors);
	};

	getFrameValue = (percent, range) => {
		if (percent > 1) percent /= 100;
		return percent * (range.to - range.from) + range.from;
	};

	handleScrolling = () => {
		let container = document.querySelector("#files .files");
		let startingPoint = 60;
		let change = container.scrollLeft - 20;
		if (change > 60) change = 60;
		let percentage = (change/startingPoint).toFixed(2);

		// PARTS TO CHANGE
		let button = container.querySelector("#new");
		let icons = button.querySelector("#icons");
		let banner = button.querySelector("#banner");
		let add = banner.querySelector("#newIcon");
		let label = banner.querySelector("label");

		// APPLY ANIMATION FRAMES
		if (change >= 0) {
			let allChanges = [ { button }, { icons }, { banner }, { add }, { label } ];

			allChanges.forEach(changeObj => {
				let name = Object.keys(changeObj)[0];
				let obj = changeObj[name];

				let changes = this.state.changes[name];
				Object.keys(changes).forEach(change => {
					let newValue = this.getFrameValue(percentage, changes[change]);
					changes[change].elements.forEach(element => {
						let propName = element.name;
						let propValue = element.value(newValue);
						obj.style[propName] = propValue;
					});
				});
			});
		} else {
			button.style = undefined;
			icons.style = undefined;
			banner.style = undefined;
			add.style = undefined;
			label.style = undefined;
		}
	};

	updateTotalProgress = loading => {
		let uploaded = 0;
		let totalUploads = Object.keys(loading).length;
		for (let fileName in loading) {
			uploaded += loading[fileName];
		}

		uploaded /= totalUploads;
		if (uploaded >= 100) {
			uploaded = 0;
			loading = {};
		}

		this.setState({ loading: loading, uploaded: Math.round(uploaded) });
	};

	uploadContent = content => {
		ContentAPI.content.new(content.getFormData(), progress => {
			let completed = Math.round((progress.loaded * 100)/progress.total);
			let loading = this.state.loading;
			loading[content.fileName] = completed;
			this.updateTotalProgress(loading);
		}).then(content => this.updateFilesList()).catch(handleErrors);
	};

	handleFiles = e => {
		let files = [...e.target.files];
		files.forEach(file => {
			var content = Content.fromFile(file);
			let loading = this.state.loading;
			loading[content.fileName] = 1;
			this.setState({ loading: loading });
			this.uploadContent(content);
		});
	};

	fileListClassName = () => {
		let className = "files";
		className += this.state.uploaded > 0 ? " disabled" : "";
		return className;
	};

	componentDidMount() {
		this.updateFilesList();
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentApp !== prevProps.currentApp) {
			this.updateFilesList();
		}
	}

	render () {
		return (
			<Section id="files" title="Files" buttons={[
				<button onClick={() => this.goTo("/Portal/Library")}>View All</button>
			]}>
				{this.state.files.length === 0
					? <div className="noFiles">
						<div id="graphic"><span className="dot"></span></div>
						<div id="details">
							<p>You haven't uploaded any files yet. New files will appear here from your library.</p>
							<Button outline onClick={() => this.goTo("/Portal/Library")}>Go To Library</Button>
						</div>
						<div id="graphic"><span className="dot"></span></div>
					</div>
					: <div className={this.fileListClassName()}>{/* onScroll={this.handleScrolling}>*/}
						<input id="newFileElem" type="file" multiple onChange={this.handleFiles} />
						<button id="new" className={this.state.size} onClick={() => {
							document.querySelector("#files .files").scrollTo({ left: 0, behavior: 'smooth' });
							document.querySelector("#newFileElem").click();
						}}>
							<div id="icons">
								{this.state.uploaded === 0
									? <Icon id="fileIcon" icon={faFileAlt} />
									: <div id="uploading"><Icon icon="loading" className="spin" text={this.state.uploaded+"%"} /></div>
								}
							</div>
							<div id="banner">
								<Icon id="newIcon" icon="add" highlight />
								<label>Upload More Files</label>
							</div>
						</button>
						{this.state.files.sort((a, b) => {
							// NEED TO DOUBLE CHECK THIS WORKS WHEN ALLEN UPDATES THE API
							if (a.created === undefined || b.created === undefined) return 0;
							return a.created < b.created ? -1 : 1;
						}).map((file, i) => (
							<React.Fragment key={file.contentId}>
							{i < 6 && <div className="file" onClick={() => this.download(i)}>
								{file.downloading === 0
									? <Icon icon={file.getIcon()} color={file.getIconColor()} />
									: <div id="download"><Icon icon="loading" className="spin" text={file.downloading+"%"} /></div>
								}
								<div><span>{file.displayName}</span></div>
							</div>}
							</React.Fragment>
						))}
					</div>
				}
			</Section>
		);
	}
}

const mapStateToProps = state => ({
	currentApp: state.currentApp
});

export default connect(mapStateToProps)(withNav(Files));
