import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';

import Button from '../../../components/Button';
import Slides from '../../partials/Slides';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import slackLogo from '../../../images/integrations/slackLogo.png';
import Slack1 from '../../../images/slack/Slack-0.jpg';
import Slack2 from '../../../images/slack/Slack-1.jpg';
import Slack3 from '../../../images/slack/Slack-2.jpg';

import '../css/slackLanding.css';

const BASE_URL = window.location.hostname === "localhost"
    ? 'http://localhost:5000'
    : `https://portal-api${ENV.getApiDomain()}.cordoniq.com`;

const SlackSlides = [ Slack1, Slack2, Slack3 ];

class Slack extends Component {
    state = { success: false };

    openSlackInstall = () => window.open(`${BASE_URL}/api/slack/auth/direct`, "_self");

    openCategory = e => {
        let category = e.target.innerHTML.toLowerCase();
        this.goTo(`/Integrations?category=${category}`);
    };

    componentDidMount() {
        this.setState({ success: this.props.success ?? false });

        if (this.props.success && ENV.isProd()) {
            ReactGA.event({
                category: 'App Installation',
                action: 'Slack'
            });
        }

        document.title = "Slack Integration | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        const Code = props => <SyntaxHighlighter language={props.language ?? "javascript"} style={this.state.code ?? undefined}>{props.children}</SyntaxHighlighter>;

        return(
            <LandingPageWithBreadCrumbs id="slackLanding" breadcrumbs={[
                { title: "App Integrations", path: "/Integrations" },
                { title: "Slack" }
            ]} logo={slackLogo} title="Slack Integration" subtitle="Start your secure, HD video meeting in Slack">
                {this.state.success
                    ? <>
                    <div id="content">
                        <h2>Thank You!</h2>
                        <p>Thanks for installing Cordoniq for Slack. We hope your team will enjoy our high-quality meetings and collaboration tools!</p>
                        <p>Visit <a href="https://support.cordoniq.com">Cordoniq Support</a> for questions, comments, or troubleshooting.</p>
                    </div>
                    </>
                    : <>
                    <div id="content">
                        <Button onClick={this.openSlackInstall}>Add to Slack</Button>
                        <p>The Cordoniq platform is designed for your business, education or training collaboration needs. Simply add Cordoniq to your Slack team and engage face-to-face, with high quality video meetings and a full suite of collaboration tools that make your Slack workspace more efficient and productive.</p>
                        <p>It's easy to start. Simply type our Slack Command <code>/lumiroom</code> followed by a name for your meeting as shown below and we'll take care of the rest.</p>
                        <Code>{"Format: /lumiroom [meeting name]\nExample: /lumiroom My Awesome Meeting"}</Code>
                        <h2>Integration Features</h2>
                        <Slides slides={SlackSlides} />
                        <h3>Real-time Document and Screen Sharing</h3>
                        <p>Seamless integration for sharing over 70 document format types - no more scrambling to change files for your team. Share them immediately.</p>
                        <h3>Document Annotation</h3>
                        <p>Need to markup and change documents in real time? Cordoniq provides easy to use annotation tools that get the documents you need changed while the whole team participates.</p>
                        <h3>Whiteboarding</h3>
                        <p>Need to draw, design, or create great business or learning ideas? Utilize the Cordoniq whiteboarding features with easy-to-use tools, for quick and productive teamwork.</p>
                        <h3>Recording</h3>
                        <p>Record meetings for later reference. View your collaboration information whenever you need it, with recorded video meetings.</p>
                        <h2>Privacy & Security</h2>
                        <p>Cordoniq provides your Slack teams with secure, end-to-end encryption utilizing industry standard protocols. API driven, efficient and easy-to-use, Cordoniq is the platform that supports Slack business, education and training teams with the latest in video meeting collaboration.</p>
                    </div>
                    <aside>
                        <Button onClick={this.openSlackInstall}>Add to Slack</Button>
                        <h2>Categories</h2>
                        <div id="categories">
                            <span onClick={this.openCategory}>Business</span>
                            <span onClick={this.openCategory}>Productivity</span>
                        </div>
                        <h2>Support</h2>
                        <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                        <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                        <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                    </aside>
                    </>
                }
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(Slack);
