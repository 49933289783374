import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { EVENT_DESCRIPTION, Subscriber } from '../LumiAPI/eventsAPI';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';
import Dialog, { Container } from '../components/Dialog';
import TextField from '../components/TextField';
import Toggle from '../components/Toggle';
import List from '../partials/List';

import Modal from '../tools/Modal';
import Notification from '../tools/Notification';

import { connect } from 'react-redux';

import './css/subscribers.css';

import zapierLogo from '../images/integrations/zapierLogo.svg';

class Subscribers extends Component {
    state = {
        loaded: false,
        subscribers: {},
        selected: [],
        changes: {}
    };

    eventsDescription = subscriber => {
        let eventKinds = subscriber?.eventKinds?.split(",") ?? [],
            description = "";
        eventKinds.forEach((kind, i) => {
            description += EVENT_DESCRIPTION?.[kind] ?? "";
            if (i < eventKinds.length - 1 && EVENT_DESCRIPTION[kind] !== undefined) {
                description += ", "
            }
        });
        return description;
    };

    getSubscribers = () => {
        this.setState({ loaded: false });
        Subscriber.getAll().then(subscribers => {
            let list = {};
            subscribers.forEach(sub => {
                let userDefined = sub.subscriptionKind === 1;
                if (userDefined) {
                    sub.icon = { name: "userAccount" }
                } else {
                    sub.image = {
                        props: {
                            style: {
                                backgroundImage: `url(${zapierLogo})`
                            }
                        }
                    }
                }
                sub.title = sub.displayName;
                sub.subtitle = sub.targetUrl;
                sub.buttons = [
                    { icon: "annotate", props: {
                        onClick: e => {
                            e.stopPropagation();
                            this.openEdit(sub.subscriberId);
                        }
                    }},
                    { icon: "arrowLeft", props: {
                        className: "arrow",
                        onClick: e => {
                            e.stopPropagation();
                            this.toggleDetails(sub.subscriberId);
                        }
                    }}
                ];
                sub.details = [
                    { name: "Status", value: sub.enabled ? "Enabled" : "Disabled" },
                    { name: "Subscriber Kind", value: userDefined ? "User Defined" : "Zapier" },
                    { name: "Subscriber Description", value: sub.displayName },
                    { name: "Target Url", value: sub.targetUrl },
                    { name: "Events Interval", value: sub.targetInterval },
                    { name: "Event Subscriptions", value: this.eventsDescription(sub) }
                ];
                list[sub.subscriberId] = sub;
            });
            this.setState({ loaded: true, subscribers: list });
        }).catch(handleErrors);
    };

    toggleDetails = subId => {
        let { subscribers } = this.state;
        subscribers[subId].showDetails = !subscribers[subId]?.showDetails ?? true;
        this.setState({ subscribers });
    };

    openNew = () => this.setState({
        showNew: true,
        changes: {
            displayName: "",
            targetUrl: "",
            targetInterval: 15,
            eventKinds: { 0: true }
        }
    });

    newDisabled = () => {
        let changes = this.state.changes;
        if (changes.displayName === "") return true;
        if (changes.targetUrl === "") return true;
        if (changes.targetInterval === "") return true;
        return false;
    };

    add = () => {
        let { changes } = this.state,
            eventKinds = [];
        Object.keys(changes.eventKinds).forEach(kind => {
            if (changes.eventKinds[kind] === true) eventKinds.push(kind);
        });
        let subscriberData = {
                enabled: true,
                eventKinds: eventKinds.join(","),
                targetUrl: changes.targetUrl,
                targetInterval: parseInt(changes.targetInterval) ?? 15,
                displayName: changes.displayName,
                subscriptionKind: 1
            };
        this.setState({ showNew: false, loaded: false });
        Subscriber.add(subscriberData).then(subscriber => {
            this.getSubscribers();
        }).catch(handleErrors);
    };

    openEdit = subId => this.setState({
        showEdit: true,
        changes: { ...this.state.subscribers[subId] }
    });

    editDisabled = () => {
        let { changes } = this.state,
            subscriber = this.state.subscribers[changes.subscriberId],
            disabled = true;

        Object.keys(changes).some(prop => {
            if (prop === "eventKinds") return false;
            if (changes[prop] === subscriber?.[prop] ?? false) {
                return false;
            } else if (changes[prop] !== "") {
                disabled = false;
                return true;
            } else {
                return false;
            }
        });

        let changedKinds = changes.eventKinds.split(","),
            savedKinds = subscriber.eventKinds.split(",");

        savedKinds.some(kind => {
            if (changedKinds.includes(kind)) {
                return false;
            } else {
                disabled = false;
                return true;
            }
        });
        if (changedKinds.length > savedKinds.length) disabled = false;

        return disabled;
    };

    update = () => {
        let { changes } = this.state,
            subscriber = this.state.subscribers[changes.subscriberId],
            data = {};

        Object.keys(subscriber).forEach(prop => {
            if ((prop !== "eventKinds") &&
                (changes[prop] !== subscriber[prop])
            ) {
                if (prop === "targetInterval") changes[prop] = parseInt(changes[prop]);
                data[prop] = changes[prop];
            }
        });

        let changedKinds = changes.eventKinds.split(","),
            savedKinds = subscriber.eventKinds.split(",");

        if (changedKinds.length !== savedKinds.length) {
            data.eventKinds = changes.eventKinds;
        } else {
            savedKinds.some(kind => {
                if (changedKinds.includes(kind)) {
                    return false;
                } else {
                    data.eventKinds = changes.eventKinds;
                    return true;
                }
            });
        }

        Subscriber.update(changes.subscriberId, data).then(subscriber => {
            this.setState({ showEdit: false });
            this.getSubscribers();
        }).catch(err => {
            new Notification({ id: "failedUpdate", message: `Error Occurred Updating "${changes.displayName}"`, type: "error" });
            handleErrors(err);
        });
    };

    enableSelected = () => {
        let selected = this.state.selected;
        selected.forEach((id, i) => {
            let subscriber = this.state.subscribers[id];
            if (subscriber.subscriptionKind !== 2) {
                Subscriber.update(id, { enabled: true }).then(subscriber => {
                    if (i === selected.length - 1) this.getSubscribers();
                }).catch(err => {
                    new Notification({ id: "enableFailed", message: `Error occured enabling subscriber "${id}"`, type: "error"});
                    handleErrors(err);
                })
            } else {
                Modal(<>
                    <h1>Error Occurred</h1>
                    <p>Cannot change status of subscriber "{subscriber.displayName}" because it was created in Zapier.</p>
                    <p>Please go to your Zapier account to toggle the status of the Zap and this subscriber.</p>
                </>, [
                    { name: "Close", props: { onClick: () => this.setState({ selected: [] }) } }
                ], { alert: true });
            }
        });
    };

    disableSelected = () => {
        let selected = this.state.selected;
        selected.forEach((id, i) => {
            let subscriber = this.state.subscribers[id];
            if (subscriber.subscriptionKind !== 2) {
                Subscriber.update(id, { enabled: false }).then(subscriber => {
                    if (i === selected.length - 1) this.getSubscribers();
                }).catch(err => {
                    new Notification({ id: "enableFailed", message: `Error occured enabling subscriber "${id}"`, type: "error"});
                    handleErrors(err);
                })
            } else {
                Modal(<>
                    <h1>Error Occurred</h1>
                    <p>Cannot change status of subscriber "{subscriber.displayName}" because it was created in Zapier.</p>
                    <p>Please go to your Zapier account to toggle the status of the Zap and this subscriber.</p>
                </>, [
                    { name: "Close", props: { onClick: () => this.setState({ selected: [] }) } }
                ], { alert: true });
            }
        });
    };

    deleteSelected = () => Modal(<>
        <h1>Delete?</h1>
        <p>Are you sure you want to delete the selected subscriber/s?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let selected = this.state.selected;
            selected.forEach((id, i) => {
                let subscriber = this.state.subscribers[id];
                if (subscriber.subscriptionKind === 2) {
                    Modal(<>
                        <h1>Error Occurred</h1>
                        <p>Cannot delete subscriber "{subscriber.displayName}" because it was created in Zapier.</p>
                        <p>Please go to your Zapier account and disable this Zap to remove the subscriber.</p>
                    </>, [
                        { name: "Close", props: { onClick: () => this.setState({ selected: [] }) } }
                    ], { alert: true });
                } else {
                    Subscriber.remove(id).then(result => {
                        if (i === selected.length - 1) this.getSubscribers();
                    }).catch(err => {
                        new Notification({ id: "deleteFailed", message: `Unable to delete subscriber "${id}"`, type: "error" });
                        handleErrors(err);
                    });
                }
            });
        }}}
    ], { alert: true });

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection;
        this.setState({ changes });
    };

    componentDidMount() {
        document.title = "Subscribers | Cordoniq";
        this.getSubscribers();
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.getSubscribers();
        }
    }

    render() {
        let changes = this.state.changes;
        return (
            <ContentWithToolbar id="subscribers" tools={[
                <button onClick={this.openNew}>Create New</button>
            ]}>
                {this.state.showNew && <Dialog show={this.state.showNew} title="New Subscriber" footer={[
                    <Button outline onClick={() => this.setState({ showNew: false })}>Cancel</Button>,
                    <Button onClick={this.add} disabled={this.newDisabled()}>Create</Button>
                ]}>
                    <Container label="Subscription Configuration">
                        <TextField name="displayName" label="Subscriber Description" placeholder="Ex: Conference Listener" value={changes.displayName} onEdit={this.onEdit} />
                        <TextField type="url" name="targetUrl" label="Subscriber Target Url" placeholder="URL to send events to" value={changes.targetUrl} onEdit={this.onEdit} />
                        <TextField name="targetInterval" label="Events Check Interval (Seconds)" placeholder="Default every 15 seconds" value={changes.targetInterval} onEdit={this.onEdit} />
                    </Container>
                    <Container id="eventList" label="Subscription Events">
                        {Object.keys(EVENT_DESCRIPTION).map(kind => {
                            let value = changes.eventKinds?.[kind] ?? false;
                            return <Toggle label={EVENT_DESCRIPTION[kind]} enabled={value} click={enabled => {
                                let changes = this.state.changes;
                                if (parseInt(kind) === 0) {
                                    changes.eventKinds = { 0: enabled };
                                } else {
                                    changes.eventKinds[0] = false;
                                    changes.eventKinds[kind] = enabled;
                                }
                                this.setState({ changes });
                            }} />
                        })}
                    </Container>
                </Dialog>}
                {this.state.showEdit && <Dialog show={this.state.showEdit} title="Edit Subscriber" footer={[
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Cancel</Button>,
                    <Button onClick={this.update} disabled={this.editDisabled()}>Save</Button>
                ]}>
                    <Container label="Subscription Configuration">
                        <TextField name="displayName" label="Subscriber Description" placeholder="Ex: Conference Listener" value={changes.displayName} onEdit={this.onEdit} />
                        <TextField type="url" name="targetUrl" label="Subscriber Target Url" placeholder="URL to send events to" value={changes.targetUrl} onEdit={this.onEdit} disabled={changes.subscriptionKind === 2} />
                        <TextField name="targetInterval" label="Events Check Interval (Seconds)" placeholder="Default every 15 seconds" value={changes.targetInterval} onEdit={this.onEdit} />
                    </Container>
                    {changes.subscriptionKind === 1 && <Container id="eventList" label="Subscription Events">
                    {Object.keys(EVENT_DESCRIPTION).map(kind => {
                        let changes = this.state.changes,
                            kinds = changes.eventKinds.split(","),
                            value = kinds.includes(kind);
                        return <Toggle label={EVENT_DESCRIPTION[kind]} enabled={value} click={enabled => {
                            if (parseInt(kind) === 0) {
                                changes.eventKinds = enabled ? "0" : "";
                            } else {
                                let noneIndex = kinds.indexOf(0);
                                if (noneIndex !== -1) kinds.slice(noneIndex, 1);
                                let kindIndex = kinds.indexOf(kind);
                                kindIndex !== -1 ? kinds.splice(kindIndex, 1) : kinds.push(kind);
                                changes.eventKinds = kinds.join(",");
                            }
                            this.setState({ changes });
                        }} />
                    })}
                    </Container>}
                </Dialog>}
                <h1>Subscribers</h1>
                <List id="subscribers" listOnly loaded={this.state.loaded} items={this.state.subscribers} actions={[
                    {
                        label: "Enable",
                        props: { onClick: this.enableSelected, disabled: this.state.selected.length === 0 }
                    },
                    {
                        label: "Disable",
                        props: { onClick: this.disableSelected, disabled: this.state.selected.length === 0 }
                    },
                    {
                        label: "Delete",
                        props: { onClick: this.deleteSelected, delete: true, disabled: this.state.selected.length === 0 }
                    }
                ]} filters={[
                    { name: "Search", value: "search", match: "displayName", placeholder: "Search Subscribers..." },
                    { name: "Event Kind", value: "eventKinds", includes: "eventKinds", options: [
                        { name: "Select Event Kind...", dontSelect: true },
                        { name: "All Event Kinds", value: "" },
                        ...Object.keys(EVENT_DESCRIPTION).map(kind => (
                            { name: EVENT_DESCRIPTION[kind], value: kind }
                        ))
                    ] }
                ]} sort={[
                    { options: [
                        { name: "Description", value: "displayName" },
                        { name: "Target URL", value: "targetUrl" },
                        { name: "Interval", value: "targetInterval" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    app: state.apps[state.currentApp]
});

export default connect(mapStateToProps)(withNav(Subscribers));
