import React, { Component } from 'react';

import Button from '../components/Button';

import './css/conferenceTour.css';

const mobileMenu = window.matchMedia("(max-width: 325px)");

class ConferenceTour extends Component {
    state = {
        mobile: mobileMenu.matches,
        show: false,
        currentSelection: 0,
        overlays: [
            {
                id: "create",
                title: "Start Here",
                description: "Click here to create a new conference room.",
                action: () => this.props.openNew()
            },
            {
                id: "enterDetails",
                title: "Enter Details",
                description: "Choose a name for the room, a start method, and features you'll need. Then click \"Save.\"",
                hideButtons: true
            },
            {
                id: "conferenceList",
                title: "Conference List",
                description: "Every conference you create within this app will appear here."
            },
            {
                id: "conferenceState",
                title: "Filter",
                description: "Review your conferences by their state: waiting to start, live, and ended."
            },
            {
                id: "confSelect",
                title: "You're in Control",
                description: "Start, end, or delete a conference or multiple conferences from here."
            }
        ]
    };

    show = () => this.setState({ show: true });
    hide = () => this.setState({ show: false, currentSelection: 0 });

    nextDialog = () => {
        const current = this.state.currentSelection;
        const skipPages = this.state.mobile && current === 3;
        const lastDialog = current === this.state.overlays.length - 1;
        if (skipPages || lastDialog) {
            this.hide();
        } else {
            var newSelection = current + 1;
            this.setState({ currentSelection: newSelection });
            if (newSelection >= this.state.overlays.length) {
                this.hide();
            }
        }
        var action = this.state.overlays[current].action;
        if (action) action();
    };

    showPaginator = i => {
        var skippedPages = this.state.mobile && i > 3;
        if (skippedPages) {
            return false;
        } else {
            return true;
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.dialogControl !== prevProps.dialogControl) {
            if (this.state.currentSelection === 1) {
                this.nextDialog();
            }
        }
    }

    render () {
        return this.state.show
            ? <div id="portalTour" className={"show "+this.state.overlays[this.state.currentSelection].id}>
                {this.state.overlays.map((overlay, i) => (
                    <div key={overlay.id} id={overlay.id} className="overlay">
                        <div className="dialogBox">
                            <h1>{overlay.title}</h1>
                            <p>{overlay.altDescription ?? overlay.description}</p>
                            <div className="paginator steps">
                                {this.state.overlays.map((overlays, j) => (
                                    <>
                                    {this.showPaginator(j) && <div key={j} className={j === this.state.currentSelection ? "selected" : j < this.state.currentSelection ? "completed" : ""}>{j+1}</div>}
                                    </>
                                ))}
                            </div>
                            {!overlay.hideButtons && <footer>
                                {((!this.state.mobile && i < this.state.overlays.length - 1) || (this.state.mobile && i < 3)) && <Button outline onClick={this.hide}>Close</Button>}
                                <Button onClick={this.nextDialog}>{(i === this.state.overlays.length - 1 || i === 3) && this.state.mobile ? "Finished" : "Next"}</Button>
                            </footer>}
                        </div>
                    </div>
                ))}
            </div>
            : null;
    }
}

export default ConferenceTour;
