import React, { Component } from 'react';

import Icon from './Icon';

import './css/selectBox.css';

class SelectBox extends Component {
	state = {
		selected: this.props.selected ?? 0,
		hover: this.props.selected ?? 0,
		label: this.props.label,
		name: this.props.name ?? "",
		opened: this.props.selected && this.props.selected !== 0 ? true : false,
		showIcon: this.props.showIcon ?? false,
		showList: false,
		options: this.props.options ?? []
	};

	hide = () => { this.setState({ showList: false }) };

	showHideList = e => {
		if (e) {
			e.stopPropagation();
			this.closeOtherSelect(e.target);
		}

		this.setState({
			opened: true,
			showList: !this.state.showList,
			hover: this.state.selected
		});
	};

	moveHover = direction => {
		var hover = parseInt(this.state.hover) || parseInt(this.state.selected);
		if (hover + direction >= this.state.options.length) {
			hover = 1;
		} else if (hover + direction < 1) {
			hover = this.state.options.length - 1;
		} else {
			hover += direction;
		}
		this.setState({ hover: hover });
	};

	handleKeyPress = e => {
		if (e.keyCode !== 9) e.preventDefault();
		var open = this.state.showList;

		switch(e.keyCode) {
			case 9: // Tab
				this.hide();
				break;
			case 32: // Space
				if (this.state.selected !== this.state.hover) {
					this.changeSelection(this.state.hover);
				} else {
					this.showHideList();
				}
				break;
			case 38: // Up
				if (open) this.moveHover(-1);
				break;
			case 40: // Down
				if (open) this.moveHover(1);
				break;
			default:
				break;
		}
	};

	getName = i => {
		var option = this.state.options[i];
		return option.name;
	};

	getValue = i => {
		var option = this.state.options[i];
		return option.value ?? option.name;
	};

	getSelectedValue = () => {
		var selected = this.state.selected;
		return this.getValue(selected);
	};

	changeSelection = i => {
		var option = this.state.options[i];

		if (option.action) {
			option.action(i);
		}

		if (!option.dontSelect) {
			this.setState({ selected: i });

			if (this.props.onChange) {
				this.props.onChange(this.getValue(i));
			}

			if (this.props.onSelect) {
				this.props.onSelect({ index: i, name: this.state.name, option: option });
			}
		}

		this.hide();
	};

	closeAllSelect = e => {
		if (e.target.classList.contains("selected")) return;
		if (this.state.showList) this.hide();
	};

	closeOtherSelect = select => {
		var allSelected = document.querySelectorAll(".selectBox .selected");

		allSelected.forEach(selected => {
			var isSelected = select === selected;
			var isOpen = selected.classList.contains("arrowActive");

			if (!isSelected && isOpen) selected.click();
		});
	};

	getSelectedClass = () => {
		var className = "selectBox";
		if (this.state.opened) {
			className += " opened";
		}
		if (this.state.showList) {
			className += " showList";
		}
		if (this.props.fill) {
			className += " fill";
		}
		if (this.props.wide) {
			className += " wide";
		}
		if (this.props.up) {
			className += " up";
		}
		return className;
	};

	getItemClass = i => {
		var className = "item";
		if (parseInt(this.state.selected) === i) {
			className += " same-as-selected";
		}
		if (parseInt(this.state.hover) === i) {
			className += " hover";
		}
		return className;
	};

	componentDidMount() {
		document.querySelector("body").addEventListener("click", this.closeAllSelect);
	}

	componentWillUnmount() {
		document.querySelector("body").removeEventListener("click", this.closeAllSelect);
	}

	componentDidUpdate(prevProps) {
		if (this.props.selected !== prevProps.selected) {
			this.setState({ selected: this.props.selected, opened: true });
		}

		if (this.props.options !== prevProps.options) {
			this.setState({ options: this.props.options });
		}
	}

	render() {
		const selected = this.state.options[this.state.selected];

		return (
			<div id="select" className={(this.props.className ?? "") + (this.props.disabled ? " disabled" : "")} style={this.props.style ?? undefined}>
				{this.state.label && <label>{this.state.label}</label>}
				<div id={this.state.name} className={this.getSelectedClass()} tabIndex={this.props.tabIndex ?? -1} onKeyDown={e => this.handleKeyPress(e)}>
					<div className={this.state.showList ? "selected arrowActive" : "selected"} onClick={e => this.showHideList(e)}>
						{this.state.showIcon && (selected.icon
							? <img className="logo" alt="App Icon" src={selected.icon} />
							: <span className="logo" style={{backgroundColor:selected.color}}></span>)}
						<span>{selected.name}</span>
						<Icon className="arrow" icon="selectArrow" mode="light" />
					</div>
					<div className={this.state.showList ? "list" : "list hide"}>
						{this.state.options.map((option, i) => {
							if (option.hide) {
								return null;
							} else {
								return (
									<React.Fragment key={i}>
									{i !== 0 && <div className={this.getItemClass(i)}
										 onClick={() => this.changeSelection(i)}>
										{option.color && <span style={{background:option.color}}></span>}
										{option.name}
									</div>}
									</React.Fragment>
								);
							}
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default SelectBox;
