import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';

import Button from '../../../components/Button';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs';

import zapierAction from '../../../images/integrations/zapier/actions.png';
import zapierTrigger from '../../../images/integrations/zapier/triggers.png';
import zapier from '../../../images/integrations/zapier/zapier.jpg';
import zapierLogo from '../../../images/integrations/zapierLogo.svg';

import '../css/zapierLanding.css';

class Zapier extends Component {
    openZapier = () => {
        if (ENV.isProd()) {
            ReactGA.event({
                category: 'App Installation',
                action: 'Zapier'
            });
        }
        window.open(`https://www.zapier.com/apps/lumicademy/integrations`, "_blank");
    }

    openCategory = e => {
        let category = e.target.innerHTML.toLowerCase();
        this.goTo(`/Integrations?category=${category}`);
    };

    componentDidMount() {
        document.title = "Zapier Integration | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <LandingPageWithBreadCrumbs id="zapierLanding" breadcrumbs={[
                { title: "App Integrations", path: "/Integrations" },
                { title: "Zapier" }
            ]} logo={zapierLogo} title="Zapier Integration" subtitle="Create easy, automated workflows with secure, HD video in Zapier">
                <div id="content">
                    <Button onClick={this.openZapier}>Add to Zapier</Button>
                    <p>The Cordoniq platform is designed for your business, education or training collaboration needs. Simply install Cordoniq in your Zapier account and create powerful, custom workflows with high quality video meetings and a full suite of collaboration tools to help you be more efficient and productive.</p>
                    <img src={zapier} className="noCaption" alt="Zapier Integrations" />
                    <p>With Zapier, Cordoniq can integrate with over 3,000 apps, all without having to write a single line of code. Start with a trigger from Cordoniq to listen for changes in a conference. Or use a trigger from another app and take action with Cordoniq to create a new conference or update an existing one.</p>
                    <h2>Triggers</h2>
                    <img src={zapierTrigger} alt="Send a message in Slack when a Cordoniq conference is created" />
                    <p className="caption">Example: Send a message in Slack when a Cordoniq conference is created</p>
                    <p>Use triggers to start a workflow whenever a chosen event happens in Cordoniq. You can select any of the following events for a Cordoniq trigger:</p>
                    <ul>
                        <li><b>Conference Created</b> - Whenever a new conference is created in your Cordoniq app</li>
                        <li><b>Conference Updated</b> - Whenever an existing conference from your Cordoniq app is updated</li>
                        <li><b>Conference Deleted</b> - Whenever a conference is delete from your Cordoniq app</li>
                        <li><b>User Joined</b> - Whenever a user joins a conference</li>
                        <li><b>User Left</b> - Whenever a user leaves a conference</li>
                    </ul>
                    <h2>Actions</h2>
                    <img src={zapierAction} alt="Create a new conference when an event is added to Google Calendar" />
                    <p className="caption">Example: Create a new conference when an event is added to Google Calendar</p>
                    <p>Use actions to make changes to your conferences or add a new one. You can use any of the available actions below to manage your Cordoniq app:</p>
                    <ul>
                        <li><b>New Conference</b> - Create a new conference using information from a trigger</li>
                        <li><b>Update Conference</b> - Update a conference using information from a trigger</li>
                        <li><b>Send Announcement</b> - Send a notification with optional link to a live conference using information from a trigger</li>
                        <li><b>Lookup Conference</b> - Lookup a conference from your app matching information from a trigger</li>
                    </ul>
                    <h2>Integration Features</h2>
                    <h3>Real-time Document and Screen Sharing</h3>
                    <p>Seamless integration for sharing over 70 document format types - no more scrambling to change files for your team. Share them immediately.</p>
                    <h3>Document Annotation</h3>
                    <p>Need to markup and change documents in real time? Cordoniq provides easy to use annotation tools that get the documents you need changed while the whole team participates.</p>
                    <h3>Whiteboarding</h3>
                    <p>Need to draw, design, or create great business or learning ideas? Utilize the Cordoniq whiteboarding features with easy-to-use tools, for quick and productive teamwork.</p>
                    <h3>Recording</h3>
                    <p>Record meetings for later reference. View your collaboration information whenever you need it, with recorded video meetings.</p>
                    <h2>Privacy & Security</h2>
                    <p>Cordoniq enhances your workflows with secure, end-to-end encryption utilizing industry standard protocols. API driven, efficient and easy-to-use, Cordoniq is the platform that supports business, education and training teams with the latest in video meeting collaboration.</p>
                </div>
                <aside>
                    <Button onClick={this.openZapier}>Add to Zapier</Button>
                    <h2>Categories</h2>
                    <div id="categories">
                        <span onClick={this.openCategory}>Business</span>
                        <span onClick={this.openCategory}>Productivity</span>
                    </div>
                    <h2>Support</h2>
                    <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                    <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                </aside>
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(Zapier);
