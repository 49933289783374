import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';
import Query from '../../../tools/Query';

import Checkbox from '../../../components/Checkbox';
import Icon from '../../../components/Icon';
import TextField from '../../../components/TextField';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs';

import blackboardLogo from '../../../images/integrations/blackboardLogo.png';
import canvasLogo from '../../../images/integrations/canvasLogo.png';
import moodleLogo from '../../../images/integrations/moodleLogo.png';
import slackLogo from '../../../images/integrations/slackLogo.png';
import zapierLogo from '../../../images/integrations/zapierLogo.svg';
import acuityLogo from '../../images/integrations/acuity.png';
import calendlyLogo from '../../images/integrations/calendly.png';
import coscheduleLogo from '../../images/integrations/coschedule.png';
import eventbriteLogo from '../../images/integrations/eventbrite.png';
import excelLogo from '../../images/integrations/excel.png';
import gmailLogo from '../../images/integrations/gmail.png';
import googleCalendarLogo from '../../images/integrations/googleCalendar.png';
import googleSheetsLogo from '../../images/integrations/googleSheets.png';
import mailchimpLogo from '../../images/integrations/mailchimp.png';
import outlookLogo from '../../images/integrations/outlook.png';
import salesforceLogo from '../../images/integrations/salesforce.jpg';
import scheduleonceLogo from '../../images/integrations/scheduleonce.png';
import squarespaceLogo from '../../images/integrations/squarespace.png';
import webflowLogo from '../../images/integrations/webflow.png';
import wixLogo from '../../images/integrations/wix.png';
import zendeskLogo from '../../images/integrations/zendesk.png';
import zohoLogo from '../../images/integrations/zoho.png';

import '../css/integrations.css';

const CATEGORY = {
    BUSINESS: 1,
    EDUCATION: 2,
    MARKETING: 3,
    PRODUCTIVITY: 4,
    SALES: 5,
    SUPPORT: 6
};

const CATEGORY_TITLE = {
    1: "Business",
    2: "Education",
    3: "Marketing",
    4: "Productivity",
    5: "Sales",
    6: "Support"
};

class Integrations extends Component {
    state = {
        search: "",
        categories: [],
        apps: [
            {
                id: "lumi4canvas",
                categories: [ CATEGORY.EDUCATION ],
                title: "Canvas",
                description: "Create virtual classrooms to meet with your students right inside Canvas",
                path: "/Integrations/Canvas",
                img: { src: canvasLogo },
                // style: { background: "rgba(169, 61, 53, 1)", color: "white" }
            },
            {
                id: "lumi4moodle",
                categories: [ CATEGORY.EDUCATION ],
                title: "Moodle",
                description: "Create virtual classrooms to meet with your students right inside Moodle",
                path: "/Integrations/Moodle",
                img: { src: moodleLogo },
                // style: { background: "rgba(249, 128, 18, 1)" }
            },
            {
                id: "lumi4slack",
                categories: [ CATEGORY.BUSINESS, CATEGORY.PRODUCTIVITY ],
                title: "Slack",
                description: "Create and invite your coworkers to join a meeting right from a DM or channel",
                path: "/Integrations/Slack",
                img: { src: slackLogo }
            },
            {
                id: "lumi4blackboard",
                categories: [ CATEGORY.EDUCATION ],
                title: "Blackboard",
                description: "Create virtual classrooms to meet with your students right inside Blackboard",
                path: "/Integrations/Blackboard",
                img: { src: blackboardLogo }
            },
            {
                id: "lumi4hubspot",
                categories: [ CATEGORY.SALES, CATEGORY.MARKETING, CATEGORY.SUPPORT ],
                title: "HubSpot",
                description: "Integrate Cordoniq conferences directly with HubSpot Meetings",
                path: "/Integrations/HubSpot",
                icon: { name: "hubsprocket", props: { mode: "color" } },
                // style: { background: "rgba(255, 122, 89, 1)", color: "rgba(60, 60, 60, 1)" }
            },
            {
                id: "lumi4zapier",
                categories: [ CATEGORY.BUSINESS, CATEGORY.PRODUCTIVITY ],
                title: "Zapier",
                description: "Manage and control workflows with Cordoniq and over 3,000 other apps",
                path: "/Integrations/Zapier",
                img: { src: zapierLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4mailchimp",
                categories: [ CATEGORY.MARKETING, CATEGORY.PRODUCTIVITY ],
                title: "Mailchimp",
                description: "Add Cordoniq conferences to your email campaigns in Mailchimp using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/mailchimp",
                isZapier: true,
                img: { src: mailchimpLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4googlesheets",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.BUSINESS ],
                title: "Google Sheets",
                description: "Log usage data from Cordoniq conferences in Google Sheets using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/google-sheets",
                isZapier: true,
                img: { src: googleSheetsLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4gmail",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.MARKETING, CATEGORY.BUSINESS ],
                title: "Gmail",
                description: "Send invites to your conferences automatically in Gmail using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/gmail",
                isZapier: true,
                img: { src: gmailLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4googleCalendar",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.BUSINESS ],
                title: "Google Calendar",
                description: "Add Cordoniq conference links to your Google Calendar events using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/google-calendar",
                isZapier: true,
                img: { src: googleCalendarLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4acuity",
                categories: [ CATEGORY.MARKETING, CATEGORY.SALES ],
                title: "Acuity Scheduling",
                description: "Create new Cordoniq conferences from new bookings in Acuity Scheduling using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/acuity-scheduling",
                isZapier: true,
                img: { src: acuityLogo }
            },
            {
                id: "lumi4squarespace",
                categories: [ CATEGORY.MARKETING, CATEGORY.SALES, CATEGORY.BUSINESS, CATEGORY.PRODUCTIVITY ],
                title: "Squarespace",
                description: "Create new Cordoniq conferences from Squarespace form submissions using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/squarespace",
                isZapier: true,
                img: { src: squarespaceLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4calendly",
                categories: [ CATEGORY.MARKETING, CATEGORY.SALES, CATEGORY.BUSINESS, CATEGORY.PRODUCTIVITY ],
                title: "Calendly",
                description: "Create new Cordoniq conferences from new events in Calendly using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/calendly",
                isZapier: true,
                img: { src: calendlyLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4excel",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.BUSINESS ],
                title: "Microsoft Excel",
                description: "Log usage data from Cordoniq conferences in Microsoft Excel using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/excel",
                isZapier: true,
                img: { src: excelLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4outlook",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.MARKETING, CATEGORY.BUSINESS ],
                title: "Microsoft Outlook",
                description: "Send calendar invites with links to your Cordoniq conferences from Microsoft Outlook using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/microsoft-outlook",
                isZapier: true,
                img: { src: outlookLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4salesforce",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.MARKETING, CATEGORY.SALES, CATEGORY.SUPPORT ],
                title: "Salesforce",
                description: "Create Cordoniq conferences from new leads in Salesforce using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/salesforce",
                isZapier: true,
                img: { src: salesforceLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4zoho",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.MARKETING, CATEGORY.SALES ],
                title: "Zoho CRM",
                description: "Create Cordoniq conferences from new leads in Zoho using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/zoho-crm",
                isZapier: true,
                img: { src: zohoLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4zendesk",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.SUPPORT, CATEGORY.SALES ],
                title: "Zendesk",
                description: "Create Cordoniq conferences from new tickets in Zendesk using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/zendesk",
                isZapier: true,
                img: { src: zendeskLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4webflow",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.BUSINESS ],
                title: "Webflow",
                description: "Create Cordoniq conferences from Webflow form submissions using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/webflow",
                isZapier: true,
                img: { src: webflowLogo }
            },
            {
                id: "lumi4coschedule",
                categories: [ CATEGORY.MARKETING, CATEGORY.PRODUCTIVITY ],
                title: "CoSchedule",
                description: "Add a Cordoniq conference as a webinar to your CoSchedule campaigns using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/coschedule",
                isZapier: true,
                img: { src: coscheduleLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4scheduleonce",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.MARKETING, CATEGORY.SALES ],
                title: "ScheduleOnce",
                description: "Create new Cordoniq conferences from new bookings in ScheduleOnce using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/scheduleonce",
                isZapier: true,
                img: { src: scheduleonceLogo, style: { padding: "12px" } }
            },
            {
                id: "lumi4wix",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.BUSINESS ],
                title: "Wix",
                description: "Create Cordoniq conferences from Wix automations using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/wix-automations",
                isZapier: true,
                img: { src: wixLogo }
            },
            {
                id: "lumi4eventbrite",
                categories: [ CATEGORY.PRODUCTIVITY, CATEGORY.MARKETING, CATEGORY.BUSINESS ],
                title: "Eventbrite",
                description: "Add Cordoniq conferences to virtual events in Eventbrite using Zapier",
                path: "https://zapier.com/apps/lumicademy/integrations/eventbrite",
                isZapier: true,
                img: { src: eventbriteLogo, style: { padding: "12px" } }
            }
        ]
    };

    onEdit = change => this.setState({ [change.name]: change.value });

    openApp = app => {
        if (app.isZapier) {
            if (ENV.isProd()) {
                ReactGA.event({
                    category: 'App Installation',
                    action: app.title
                });
            }
            window.open(app.path, "_blank");
        } else {
            this.goTo(app.path);
        }
    };

    toggleCategory = category => {
        let { categories } = this.state;
        let index = categories.indexOf(category);
        index !== -1
            ? categories.splice(index, 1)
            : categories.push(category);
        this.setState({ categories });
    };

    filter = app => {
        let { search, categories } = this.state;
        let matchSearch = search !== "" ? app.title.toLowerCase().includes(search.toLowerCase()) : true;
        let matchCategories = categories.length === 0 ? true : false;
        categories.some(category => {
            matchCategories = app.categories.includes(category);
            return matchCategories;
        });
        return matchSearch && matchCategories;
    };

    sort = (a, b) => {
        if (a.title === b.title) return 0;
        return a.title < b.title ? -1 : 1;
    };

    componentDidMount() {
        document.title = "Integrations | Cordoniq";
        window.scrollTo(0, 0);

        let query = new Query(window.location.search);
        let category = query.get('category');
        category = CATEGORY?.[category?.toUpperCase()] ?? undefined;
        this.setState({ categories: category === undefined ? [] : [category] })
    }

    render() {
        let apps = this.state.apps.filter(this.filter).sort(this.sort);

        return (
            <LandingPageWithBreadCrumbs id="mainIntegrations" title="App Integrations" subtitle="Discover all the ways you can connect Cordoniq with the apps you use everyday!">
                <aside>
                    <h2>Search</h2>
                    <TextField name="search" placeholder="Search apps..." value={this.state.search} onEdit={this.onEdit} />
                    <div id="categories_compact">
                        {this.state.categories.map(category => (
                            <div onClick={() => this.toggleCategory(category)}>
                                <Icon icon="close" />
                                <span>{CATEGORY_TITLE[category]}</span>
                            </div>
                        ))}
                    </div>
                    <h2>Categories <span className={this.state.categories.length === 0 ? "disabled" : ""} onClick={() => this.setState({ categories: [] })}>Clear</span></h2>
                    <div id="categories">
                        {Object.keys(CATEGORY).map(category => (
                            <div onClick={() => this.toggleCategory(CATEGORY[category])}>
                                <Checkbox id={`category-${CATEGORY[category]}`} checked={this.state.categories.includes(CATEGORY[category])} />
                                <span>{category}</span>
                            </div>
                        ))}
                    </div>
                    <h2>Support</h2>
                    <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                    <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                </aside>
                <div id="content">
                    {apps.map(app => (<>
                        {(app.showCondition ?? true) && <div className="app" id={app.id} style={app.style ?? undefined} onClick={() => this.openApp(app)}>
                            {app.isZapier
                                ? <>
                                    {app.img && <div className="zapierApp">
                                        <img src={app.img.src} style={app.img?.style ?? undefined} alt="app icon" />
                                        <img src={zapierLogo} alt="zapier logo" />
                                    </div>}
                                    {app.icon && <Icon icon={app.icon.name} {...app.icon.props} />}
                                </>
                                : <>
                                    {app.img && <img src={app.img.src} style={app.img?.style ?? undefined} alt="app icon" />}
                                    {app.icon && <Icon icon={app.icon.name} {...app.icon.props} />}
                                </>
                            }
                            <div>
                                <h3>{app.title}</h3>
                                <div className="tags">
                                    {app.categories.sort((a, b) => {
                                        if (a === b) return 0;
                                        return a < b ? -1 : 1;
                                    }).map(category => (
                                        <span className={this.state.categories.includes(category) ? "selected" : ""} onClick={e => {
                                            e.stopPropagation();
                                            this.toggleCategory(category);
                                        }}>
                                            {CATEGORY_TITLE[category]}
                                        </span>
                                    ))}
                                </div>
                                {app.description && <p>{app.description}</p>}
                            </div>
                        </div>}
                    </>))}
                    {apps.length === 0 && <p>No Matching Apps Found</p>}
                </div>
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(Integrations);
