import React, { Component } from 'react';
import { setThemeColor, overrideColorScheme, removeColorSchemeOverride } from '../../tools/colorSchemeManager';

import NavBar from '../partials/NavBar';
import GraphicHeader from '../partials/GraphicHeader';
import CompactFooter from '../partials/CompactFooter';

import "./css/contentPageWithHeader.css";

class ContentPageWithHeader extends Component {
    componentDidMount() {
        setThemeColor(255, 255, 255);
        overrideColorScheme('light');
    }

    componentWillUnmount() {
        removeColorSchemeOverride();
    }

    render () {
        return (
            <main className="contentPageWithHeader">
                <NavBar linkTag={this.props.id ?? "nav"} />
                <GraphicHeader {...this.props} />
                <div id={this.props.id}>{this.props.children}</div>
                <CompactFooter />
            </main>
        );
    }
}

export default ContentPageWithHeader;
