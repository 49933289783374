import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNav from '../../hooks/useNav';
import useQuery from '../../hooks/useQuery';

import WebApp from '../../LumiAPI/APIs/internal/webApp';
import { EVENT_KINDS } from '../../LumiAPI/eventsAPI';
import WebRTC from '../WebRTC';
// import hark from 'hark';

import Button from '../../components/Button';
import Dialog, { Container } from '../../components/Dialog';
import SelectBox from '../../components/SelectBox';

import Toast from '../components/Toast';
import Video from '../components/Video';
import Header from '../partials/Header';
import Tools from '../partials/Tools';
import UserList from '../partials/UserList';

import "./css/interface.css";

const interfaceReducer = (state, action) => {
	switch(action.type) {
		case 'update_media':
			return {
				...state,
				stream: action.stream
			}
		case 'update_devices':
			return {
				...state,
				cameraList: action.cameraList,
				microphoneList: action.microphoneList,
				speakerList: action.speakerList,
				selectedCamera: action.cameraList.length > 0
					? { index: 1, option: { value: undefined } }
					: { index: 0, option: { value: undefined } },
				selectedMicrophone: action.microphoneList.length > 0
					? { index: 1, option: { value: undefined } }
					: { index: 0, option: { value: undefined } },
				selectedSpeaker: action.speakerList.length > 0
					? { index: 1, option: { value: undefined } }
					: { index: 0, option: { value: undefined } }
			}
		case 'set_remote_streams':
			return {
				...state,
				remoteStreams: action.remoteStreams
			}
		case 'set_media_state':
			return {
				...state,
				...action.changes
			}
		case 'set_screen_sharing':
			return {
				...state,
				shareStream: action.stream,
				sharing: action.stream !== null
			}
		case 'toggle_user_pane':
			return {
				...state,
				users: !state.users
			}
		case 'select':
			return {
				...state,
				[action.selection.name]: action.selection
			}
		case 'adjust_video_size':
			return {
				...state,
				videoDimensions: action.videoDimensions,
				resizing: true
			}
		case 'stop_resize':
			return {
				...state,
				resizing: false
			}
		default:
			return state;
	}
};

const initialState = {
	resizing: false,
	videoDimensions: { width: 398, height: 198 },
	cameraList: [],
	microphoneList: [],
	speakerList: [],
	selectedCamera: { index: 0, option: { value: undefined } },
	selectedMicrophone: { index: 0, option: { value: undefined } },
	selectedSpeaker: { index: 0, option: { value: undefined } },
	video: false,
	audio: false,
	sharing: false,
	users: false,
	speaking: false,
	stream: null,
	remoteStreams: [],
	shareStream: null
};

const Interface = props => {
	document.title = "Cordoniq for Web";
	const query = useQuery();
	const { intId } = useParams();
	const { confId } = props.conference;
	const { goTo } = useNav();
	const [ showSettings, setShowSettings ] = useState(false),
		  [ features, setFeatures ] = useState({}),
		  [ privileges, setPrivileges ] = useState({});
	const [ state, dispatch ] = useReducer(interfaceReducer, initialState),
		  { resizing, videoDimensions,
			cameraList, microphoneList, speakerList,
			selectedCamera, selectedMicrophone, selectedSpeaker,
			video, audio, sharing, users, speaking,
			stream, remoteStreams, shareStream
		  } = state;

	const getUserMedia = useCallback(async constraints => {
		console.log("getUserMedia cached");
		try {
			let newStream = stream ?? new MediaStream();

			if (constraints) {
				if (constraints.video !== undefined) {
					newStream.getVideoTracks().forEach(track => {
						track.stop();
						newStream.removeTrack(track);
					});
					if (constraints.video !== false) {
						let videoStream = await navigator.mediaDevices.getUserMedia({ video: constraints.video });
						let newVideoTrack = videoStream.getVideoTracks()[0];
						newStream.addTrack(newVideoTrack);
						videoStream.removeTrack(newVideoTrack);
					}
				}
				if (constraints.audio !== undefined) {
					newStream.getAudioTracks().forEach(track => {
						track.stop();
						newStream.removeTrack(track);
					});
					if (constraints.audio !== false) {
						let audioStream = await navigator.mediaDevices.getUserMedia({ audio: constraints.audio });
						let newAudioTrack = audioStream.getAudioTracks()[0];
						newStream.addTrack(newAudioTrack);
						audioStream.removeTrack(newAudioTrack);
					}
				}
			}

			dispatch({ type: 'update_media', stream: newStream });
			return newStream;
		} catch (e) {
			console.log(e);
		}
	}, [stream, dispatch]);

	const openRoom = useCallback(async confId => {
		console.log("openRoom cached");
		try {
			let stream = await getUserMedia();
			return await WebRTC.openRoom(confId, stream);
		} catch (err) {
			console.log(err);
		}
	}, [getUserMedia]);

	const leaveRoom = async () => {
		try {
			await WebRTC.leaveRoom();
			goTo(`/app/${intId}`)
			// this.setState({
			// 	name: "",
			// 	roomId: "",
			// 	showRoomSelection: true,
			// 	video: false,
			// 	audio: false,
			// 	sharing: false,
			// 	speaking: false,
			// 	stream: null,
			// 	remoteStreams: [],
			// 	shareStream: null
			// });
		} catch (err) {
			console.log(err);
		}
 	};

	const updateMediaState = changes => {
		let videoOn = changes?.video ?? video,
			audioOn = changes?.audio ?? audio,
			constraints = {
				video: videoOn && selectedCamera.option.value ? { deviceId: { exact: selectedCamera.option.value } } : videoOn,
				audio: audioOn && selectedMicrophone.option.value ? { deviceId: { exact: selectedMicrophone.option.value } } : audioOn
			};

		getUserMedia(constraints).then(newStream => {
			WebRTC.set.stream(newStream);
			dispatch({ type: 'set_media_state', changes });
		});
	};

	const updateDisplayMediaState = changes => {
		let sharingOn = changes?.sharing,
			constraints = {
				video: sharingOn
					? { cursor: 'always' }
					: undefined
			};

		if (sharingOn) {
			navigator.mediaDevices.getDisplayMedia(constraints).then(newStream => {
				let tracks = newStream.getTracks();
				tracks[0].onended = e => {
					if (e.type === "ended") {
						dispatch({ type: 'set_screen_sharing', stream: null });
					}
				};
				dispatch({ type: 'set_screen_sharing', stream: newStream });
			}).catch(err => {
				dispatch({ type: 'set_screen_sharing', stream: null });
				console.log(err);
			});
		} else {
			shareStream.getTracks().forEach(track => {
				console.log(track);
				track.stop();
			});
			dispatch({ type: 'set_screen_sharing', stream: null });
		}
	};

	const setVideo = value => updateMediaState({ video: value });
	const setAudio = value => updateMediaState({ audio: value });
	const setScreenSharing = value => updateDisplayMediaState({ sharing: value });

	const toggleVideo = () => setVideo(!video);
	const toggleAudio = () => setAudio(!audio);
	const toggleSharing = () => setScreenSharing(!sharing);
	const toggleUsers = () => dispatch({ type: 'toggle_user_pane' });

	const adjustVideoSize = useCallback(() => {
		let container = document.querySelector("#lumiApp #videos"),
			containerWidth = container.clientWidth,
			columns = sharing ? 1 : containerWidth >= 1500 ? 3 : 2;
		let videoDimensions = { width: (containerWidth / columns) - 2, height: (containerWidth / columns / 2) - 2 };
		dispatch({ type: 'adjust_video_size', videoDimensions });
	}, [sharing]);

	const stopResize = () => dispatch({ type: 'stop_resize' });

	const handleSelect = selection => dispatch({ type: 'select', selection });

	useEffect(() => {
		console.log("Running Open Room Effect");
		openRoom(confId)
			.then(roomId => console.log(roomId))
			.catch(console.log);
	}, [confId, openRoom]);

	useEffect(() => {
		const updateDeviceLists = devices => {
			let cameras = [], microphones = [], speakers = [];
			devices.forEach((device, i) => {
				switch (device.kind) {
					case "videoinput":
						cameras.push(device);
						break;
					case "audioinput":
						microphones.push(device);
						break;
					case "audiooutput":
						speakers.push(device);
						break;
					default:
						console.log("Unknown device found", device);
				}
			});
			dispatch({
				type: "update_devices",
				cameraList: cameras,
				microphoneList: microphones,
				speakerList: speakers
			});
		};
		WebRTC.get.devices().then(updateDeviceLists).catch(console.log);
		WebRTC.addListener("devices", updateDeviceLists);
		return () => WebRTC.removeListener("devices", updateDeviceLists);
	}, []);

	useEffect(() => {
		const addRemoteStream = newStream => {
			let streams = remoteStreams;
			if (!streams.includes(newStream)) {
				streams.push(newStream);
			}
			dispatch({ type: 'set_remote_streams', remoteStreams: streams });
		};
		WebRTC.addListener("remotestream", addRemoteStream);
		return () => WebRTC.removeListener("remotestream", addRemoteStream);
	}, [remoteStreams]);

	useEffect(() => {
		(async () => {
			let password = query.get('password') ?? undefined;
			try {
				setFeatures(await WebApp.conference.features(intId, confId));
				setPrivileges(await WebApp.user.privileges(intId, confId, password));
			} catch (e) {
				throw e;
			}
		})();
	}, [query, intId, confId]);

	useEffect(() => {
		WebApp.subscriber.start(intId)
			.then(result => console.log(result))
			.catch(console.log);
	}, [intId]);

	useEffect(() => {
		const handleUserEvent = event => {
			switch (event.eventKind) {
				case EVENT_KINDS.JoinUser:
					new Toast({ id: event.eventId, message: `${event.displayName} joined`, duration: 6 });
					break;
				case EVENT_KINDS.LeaveUser:
					new Toast({ id: event.eventId, message: `${event.displayName} left`, duration: 6 });
					break;
				default:
					console.log(event);
			}
		};
		WebApp.listeners.add("users", handleUserEvent);
		return () => WebApp.listeners.remove("users", handleUserEvent);
	}, []);

	useEffect(() => adjustVideoSize(), [adjustVideoSize, sharing, users]);
	useEffect(() => {
		adjustVideoSize();
		window.addEventListener("resize", adjustVideoSize);
		window.addEventListener("mouseover", stopResize);
		return () => {
			window.removeEventListener("resize", adjustVideoSize);
			window.removeEventListener("mouseover", stopResize);
		};
	}, [adjustVideoSize]);

	const videoClassName = () => {
		let className = "";
		className += !video ? " noVideo" : "";
		className += !audio ? " noAudio" : "";
		className += speaking ? " audioActive" : "";
		className += resizing ? " resizing" : "";
		return className.trim();
	};

	const remoteVideoClassName = remoteStream => {
		let className = "",
			videoTracks = remoteStream?.getVideoTracks() ?? [],
			audioTracks = remoteStream?.getAudioTracks() ?? [];
		className += videoTracks.length === 0 ? " noVideo" : "";
		className += audioTracks.length === 0 ? " noAudio" : "";
		className += resizing ? " resizing" : "";
		return className.trim();
	};

	let tools = {
		recording: {
			show: features?.recording ?? true,
			disabled: !privileges?.record ?? true
		},
		video: {
			show: features?.video ?? true,
			disabled: !privileges?.video ?? true,
			state: video,
			toggle: toggleVideo
		},
		audio: {
			show: features?.audio ?? true,
			disabled: !privileges?.audio ?? true,
			state: audio,
			toggle: toggleAudio
		},
		sharing: {
			show: features?.desktopShare ?? true,
			disabled: !privileges?.share ?? true,
			state: sharing,
			toggle: toggleSharing
		},
		users: {
			show: true,
			disabled: !privileges?.viewUsers ?? false,
			state: users,
			toggle: toggleUsers
		},
		breakout: {
			show: features?.breakout ?? false,
			disabled: !privileges?.breakout ?? true
		},
		chat: {
			show: false, // features?.chat ?? true,
			disabled: !privileges?.chat ?? true
		}
	};

	return (
		<main id="lumiApp">
			{showSettings && <Dialog alert show={showSettings} title="Settings" footer={[
				<Button onClick={() => setShowSettings(false)}>Close</Button>
			]}>
				<Container label="Video Settings">
					<SelectBox name="selectedCamera" label="Video Capture Device" selected={selectedCamera.index} options={[
						{ name: "Pick One...", dontSelect: true },
						...cameraList.map((camera, i) => ({ name: camera.label || `Camera ${i + 1}`, value: camera.deviceId }))
					]} onSelect={handleSelect} />
				</Container>
				<Container label="Audio Settings">
					<SelectBox name="selectedMicrophone" label="Audio Capture Device" selected={selectedMicrophone.index} options={[
						{ name: "Pick One...", dontSelect: true },
						...microphoneList.map((microphone, i) => ({ name: microphone.label || `Microphone ${i + 1}`, value: microphone.deviceId }))
					]} onSelect={handleSelect} />
					<SelectBox name="selectedSpeaker" label="Audio Playback Device" selected={selectedSpeaker.index} options={[
						{ name: "Pick One...", dontSelect: true },
						...speakerList.map((speaker, i) => ({ name: speaker.label || `Speaker ${i + 1}`, value: speaker.deviceId }))
					]} onSelect={handleSelect} disabled={!('sinkId' in HTMLMediaElement.prototype)} />
				</Container>
			</Dialog>}
			<Header conference={props.conference}
				recording={tools.recording}
				settings={() => setShowSettings(true)}
				leave={leaveRoom} />
			<div id="ui">
				<Tools {...tools} />
				{users && <UserList intId={intId} confId={props.conference?.confId} />}
				<div id="videos" className={sharing ? "compact" : ""}>
					{stream && <Video className={videoClassName()} srcObject={stream} metaData={{ name: "ME" }} style={{...videoDimensions}} />}
					{remoteStreams.map(remoteStream => (
						<Video className={remoteVideoClassName(remoteStream)} srcObject={remoteStream} style={{...videoDimensions}} />
					))}
				</div>
				{shareStream && <div id="shares">
					<Video srcObject={shareStream} />
				</div>}
			</div>
		</main>
	);
};

export default Interface;
