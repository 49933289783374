import React, { Component } from 'react';

import Placeholder from '../components/Placeholder';
import Checkbox from '../components/Checkbox';
import TextField from '../components/TextField';
import SelectBox from '../components/SelectBox';
import Button from '../components/Button';
import Icon from '../components/Icon';
import { faSort, faTh, faThList } from '@fortawesome/free-solid-svg-icons';
import Card from './Card';

import './css/list.css';

const VIEWS = { GRID: 1, LIST: 2 };

class List extends Component {
    state = {
        loaded: this.props.loaded ?? false,
        view: this.props.listOnly === true ? VIEWS.LIST : VIEWS.GRID,
        selected: [],
        hideFilters: true,
        sort: { index: 1, option: { value: "" } },
        items: this.props.items ?? {}
    };

    selectAll = () => {
        let selected = this.state.selected,
            items = Object.keys(this.state.items),
            check = false;

        if (selected.length === 0) { // Unselected
            check = true;
        } else if (selected.length < items.length) { // Partially Selected
            check = true;
        } else if (selected.length === items.length) { // Selected
            check = false;
        }

        selected = [];
        if (check) {
            items.forEach(id => selected.push(id));
        }
        if (this.props.onSelect) this.props.onSelect(selected);
        this.setState({ selected });
    };

    select = id => {
        if (this.props?.actions?.length === 0 ?? true) return;
        let selected = this.state.selected;
        let index = selected.indexOf(id);
        index !== -1 ? selected.splice(index, 1) : selected.push(id);
        if (this.props.onSelect) this.props.onSelect(selected);
        this.setState({ selected });
    };

    getSelectedTab = () => {
        let tabs = [ { label: "" }, ...this.props?.tabs ?? [] ],
            selectedIndex = 0;
        tabs.some((tab, i) => {
            if (tab?.props?.selected ?? false) {
                selectedIndex = i;
                return true;
            }
            return false;
        });

        return selectedIndex;
    };

    getTabOptions = () => {
        let tabs = this.props?.tabs ?? [],
            options = [{ name: "Pick One...", dontSelect: true }];
        tabs.forEach(tab => {
            options.push({ name: tab.label, action: tab.props?.onClick ?? undefined });
        });
        return options;
    };

    totalFilters = () => {
        if (this.props.filters === undefined) return 0;
        let total = 0;
        let filters = this.props.filters;
        filters.forEach(filter => {
            let key = filter.name.toLowerCase(),
                value = this.state[key];
            if (value !== undefined) {
                if (filter.options) {
                    if (filter.selected && filter.default) {
                        total += filter.selected === filter.default ? 0 : 1;
                    } else {
                        total += value?.index !== 0 ?? false ? 1 : 0;
                    }
                } else {
                    total += value === "" ? 0 : 1;
                }
            }
        });
        return total;
    };

    clearFilters = () => {
        let filters = this.props.filters,
            newState = {},
            actionQueue = [];
        filters.forEach(filter => {
            let key = filter.name.toLowerCase();
            if (filter.options) {
                if (filter?.selected !== filter?.default ?? false) {
                    newState[key] = { index: filter.default, option: { value: null } };
                    let selected = filter.options[filter.default];
                    if (selected.action) actionQueue.push(selected.action);
                } else {
                    newState[key] = { index: 0, option: { value: null } };
                    let selected = filter.options[0];
                    if (selected.action) actionQueue.push(selected.action);
                }
            } else {
                newState[key] = "";
            }
        });
        actionQueue.forEach(action => setTimeout(action, 1));
        this.setState(newState);
    };

    getFilterFields = () => {
        let input = this.props.filters, fields = [];
        input.forEach(field => {
            let key = field.name.toLowerCase();
            if (field.options) {
                fields.push(<SelectBox key={key} name={key} showIcon={field.options[0].color !== undefined}
                    selected={field.selected ?? this.state?.[key]?.index ?? undefined} options={field.options}
                    onSelect={selection => {
                        this.setState({ [key]: selection, selected: [] });
                        if (this.props.onSelect) this.props.onSelect([]);
                    }} />
                );
            } else {
                fields.push(<TextField key={key} name={key} value={this.state[key]} placeholder={field.placeholder}
                    onEdit={change => {
                        this.setState({ [key]: change.value, selected: [] });
                        if (this.props.onSelect) this.props.onSelect([]);
                    }} />);
            }
        });
        return fields;
    };

    filter = id => {
        let item = this.state.items[id],
            filters = this.props.filters,
            show = [];
        if (this.props.filters === undefined) return true;
        filters.forEach(filter => {
            let key = filter.name.toLowerCase(),
                value = this.state[key];

            if (filter.match) {
                let field = item[filter.match];
                if (filter.options) {
                    value = value?.option?.value ?? null;
                    show.push(field === value || value === null);
                } else {
                    field = field?.toLowerCase() ?? field;
                    value = value?.toLowerCase() ?? "";
                    show.push(field.includes(value));
                }
            } else if (filter.includes) {
                let field = item[filter.includes],
                    fields = field.split(",");
                value = value?.option?.value ?? "";
                show.push(fields.includes(value) || value === "");
            } else {
                show.push(true);
            }
        });

        return !show.includes(false);
    };

    getSortFields = () => {
        let dropdowns = this.props.sort, fields = [];
        dropdowns.forEach(dropdown => {
            let key = dropdown.name ?? "sort",
                options = [ { name: "Pick One...", dontSelect: true }, ...dropdown.options ];
            fields.push(<SelectBox key={key} name={key} selected={this.state[key]?.index ?? 0} options={options}
                onSelect={selection => this.setState({ [key]: selection })}/>);
        });
        return fields;
    };

    sort = (a, b) => {
        if (this.props.sort === undefined) return 0;
        let { items, sort } = this.state,
            key = sort.option.value,
            left = items[a][key],
            right = items[b][key],
            firstOption = this.props.sort[0].options[0].value;
        if (left === right) {
            if (key !== firstOption) {
                key = firstOption;
                left = items[a][key];
                right = items[b][key];
            } else {
                return 0;
            }
        }
        let asc = left < right ? -1 : 1;
        if (key === "active") asc *= -1;
        return this.state?.asc ?? true ? asc : -asc;
    };

    setView = view => {
        let key = this.state.viewKey;
        this.setState({ viewKey: key, view });
        localStorage.setItem(key, view);
    };

    className = () => {
        let className = "list_container";
        return className;
    };

    componentDidMount() {
        let viewKey = this.props.id ? `${this.props.id}View` : "listView";
        this.setState({ viewKey });

        let view = this.props.listOnly ? VIEWS.LIST
            : localStorage.getItem(viewKey) ?? VIEWS.GRID;
		localStorage.setItem(viewKey, view);
		this.setState({ view: parseInt(view) });

        if (this.props.sort) {
            this.props.sort.forEach(dropdown => {
                let key = dropdown.name ?? "sort";
                this.setState({ [key]: { index: 1, option: { value: dropdown.options[0].value } } });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items) {
            this.setState({ items: this.props.items, selected: [] });
            if (this.props.onSelect) this.props.onSelect([]);
        }

        if (this.props.loaded !== prevProps.loaded) {
            this.setState({ loaded: this.props.loaded });
        }
    }

    render() {
        let itemIds = Object.keys(this.state.items).filter(this.filter).sort(this.sort);
        let totalFilters = this.totalFilters();

        return(
            <div className={this.className()}>
                <div id="action_container">
                    <div id="tools">
                        <Checkbox id="selectAll" checked={this.state.selected.length > 0}
                                  partial={this.state.selected.length < itemIds.length}
                                  onClick={this.selectAll}
                                  disabled={itemIds.length === 0} />
                        <span>Select</span>
                        {this.props.actions && this.props.actions.map(action => (
                            <Button key={action.label} {...action.props}>{action.label}</Button>
                        ))}
                    </div>
                    {this.props.tabs && <div id="tabs">
                        {this.props.tabs.map(tab => (
                            <Button key={tab.label} {...tab.props} className={tab?.props?.selected ? "selected" : ""}>{tab.label}</Button>
                        ))}
                        <SelectBox name="selectedTab" selected={this.getSelectedTab()} options={this.getTabOptions()} />
                    </div>}
                </div>
                <div id="item_container">
                    <div id="list_controller">
                        {this.props.filters && (Object.keys(this.state.items).length >= 6 || this.props.showFilters || totalFilters > 0) && <div id="filtering" className={this.state.hideFilters ? "hide" : ""}>
                            <div id="header">
                                <span>Filters</span>
                                {totalFilters > 0 && <span onMouseOver={e => { e.target.innerHtml = "Clear" }}>{totalFilters === 1 ? "1 Filter" : `${totalFilters} Filters`}</span>}
                                <Icon icon="add" className={this.state.hideFilters ? "" : "close"}
                                      onClick={() => this.setState({ hideFilters: !this.state.hideFilters })} />
                            </div>
                            {this.getFilterFields().map(field => field)}
                            <Button outline onClick={this.clearFilters} disabled={totalFilters === 0}>Clear</Button>
                        </div>}
                        <div id="sorting">
                            {this.props.sort && <>
                                <span>Sort by</span>
                                {this.getSortFields().map(field => field)}
                                <Icon icon={faSort} onClick={() => {
                                    let asc = this.state.asc ?? true;
                                    this.setState({ asc: !asc });
                                }} />
                                {this.props.listOnly !== true && <div id="view">
                                    <Icon icon={faTh} className={this.state.view === VIEWS.GRID ? "selected" : ""} onClick={() => this.setView(VIEWS.GRID)} />
                                    <Icon icon={faThList} className={this.state.view === VIEWS.LIST ? "selected" : ""} onClick={() => this.setView(VIEWS.LIST)} />
                                </div>}
                            </>}
                        </div>
                    </div>
                    {this.state.loaded
                        ? itemIds.length === 0
                            ? <p>{totalFilters > 0 ? "No Matches" : this.props.emptyMessage ?? "None Found"}</p>
                            : itemIds.map(id => {
                                let item = this.state.items[id];
                                return (
                                    <Card key={id} layout={this.state.view} item={item}
                                        mode={this.state.selected.length > 0 ? "selection" : undefined}
                                        selected={this.state.selected.includes(id)}
                                        onClick={() => this.select(id)} />
                                );
                            })
                        : <>
                            <Placeholder className={this.state.view === 1 ? "square" : "rectangle"} />
                            <Placeholder className={this.state.view === 1 ? "square" : "rectangle"} />
                            <Placeholder className={this.state.view === 1 ? "square" : "rectangle"} />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default List;
