import React from 'react';
import ReactDOM from 'react-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { setUnsavedChanges } from '../actions/unsavedChanges-actions';

import Dialog from '../components/Dialog';
import Button from '../components/Button';

const UserConfirmation = (message, callback) => {
	const container = document.createElement("div");
	container.id = "leavePageConfirmation";
	document.body.appendChild(container);

	const leavePage = callbackState => {
		ReactDOM.unmountComponentAtNode(container);
		document.querySelector("#leavePageConfirmation").remove();
		callback(callbackState);
	};

	ReactDOM.render(
		<Dialog show={true} alert footer={[
			<Button outline onClick={() => leavePage(true)}>Leave</Button>,
			<Button onClick={() => leavePage(false)}>Stay</Button>
		]}>
			<h1>You have unsaved changes!</h1>
			<p>Are you sure you want to leave the page?</p>
		</Dialog>,
		container
	);
};

export default UserConfirmation;
