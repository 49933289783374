import { useEffect, useState } from 'react';

const useLocalStorage = key => {
    const [storedData, setData] = useState(() => {
        let value = localStorage.getItem(key);
        return value === "object" ? JSON.parse(value) : value;
    });

    const updateStorage = value => {
        let stringValue = typeof value === "object" ? JSON.stringify(value) : value;
        value === ""
            ? localStorage.removeItem(key)
            : localStorage.setItem(key, stringValue);
        setData(value === "" ? null : value);
    };

    useEffect(() => {
        let value = localStorage.getItem(key);
        setData(value === "object" ? JSON.parse(value) : value);
    }, [key, setData]);

    return [storedData, updateStorage];
};

export default useLocalStorage;

export class Storage {
    constructor(key) {
        this.key = key;
        let value = localStorage.getItem(key);
        this.value = value === "object" ? JSON.parse(value) : value;
    }

    getKey = () => (this.key);

    setKey = newKey => {
        this.key = newKey;
        let value = localStorage.getItem(newKey);
        this.value = value === "object" ? JSON.parse(value) : value;
    };

    getValue = () => (this.value);

    setValue = newValue => {
        let stringValue = typeof newValue === "object" ? JSON.stringify(newValue) : newValue;
        newValue === ""
            ? this.remove()
            : localStorage.setItem(this.key, stringValue);
        this.value = newValue === "" ? null : newValue;
    };

    remove = () => localStorage.removeItem(this.key);
}
