if(!String.prototype.splice) {
	var STR = String;
	STR.prototype.splice = function(idx, rem, str) {
		return this.slice(0, idx) + (str ? str : "") + this.slice(idx + Math.abs(rem));
	}
}

export const addSpaces = str => {
	if (str === undefined) return undefined;
	let matches = str.match(/[A-Z]/g);
	if (matches && matches.length > 1) {
		for (var i = 1; i < matches.length; i++) {
			 var index = str.indexOf(matches[i]);
			 str = str.splice(index, 0, " ");
		}
	}
	return str;
};

export const capitalize = str => {
	return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
};

export const parseBool = value => {
	return value.toLowerCase() === "true";
};
