import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { ENV } from './tools/envTools.js';

import UserConfirmation from './tools/UserConfirmation';

import './css/index.css';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from './reducers';

const isChrome = window.navigator.userAgent.includes('Chrome');

// STORE ENHANCERS
const storeEnhancers = isChrome
	? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	: undefined;

// STORE
export const store = createStore(allReducers, storeEnhancers);

if (!ENV.isDev()) {
	Sentry.init({
		dsn: "https://94b7912bb2804514a6dae37e1b4aa3f1@o1082775.ingest.sentry.io/4503921309777920",
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					"localhost", 
					// /lti.*.cordoniq.com/, /portal-api.*.cordoniq.com/,
					// /auth.*.cordoniq.com/, /api.*.cordoniq.com/
				],
			  }),
			  new Sentry.Replay(),
		],
		environmemt: ENV.get() === 'root' ? 'production' : ENV.get(),
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.01, 
  		replaysOnErrorSampleRate: 1.0,
	});
} else {
	console.log('Sentry logging is disabled in development environments.');
}

ReactDOM.render(
	<Provider store={store}>
		<Router getUserConfirmation={(message, callback) => UserConfirmation(message, callback)}><App /></Router>
	</Provider>
, document.getElementById('root'));

serviceWorker.register();

let LUMI_VERSION_KEY = `Lumi_Version${ENV.getStorageSuffix()}`;
let lumiVersion = localStorage.getItem(LUMI_VERSION_KEY);
if (lumiVersion === null || lumiVersion !== window.lumiVersion) {
	localStorage.setItem(LUMI_VERSION_KEY, window.lumiVersion);
	serviceWorker.purgeCache();
}
