import React, { Component } from 'react';
import UserAPI from '../LumiAPI/APIs/core/user.js';
import { privilegesList } from '../LumiAPI/APIs/core/confDefaults';
import Events from '../tools/Events';
import { ENV } from '../tools/envTools';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Section from '../partials/Section';
import Placeholder from '../components/Placeholder';
import Dialog, { Container } from '../components/Dialog';
import Checkbox from '../components/Checkbox';
import TextField from '../components/TextField';
import SelectBox from '../components/SelectBox';
import Icon from '../components/Icon';
import Button from '../components/Button';

import Modal from '../tools/Modal';
import Notification from '../tools/Notification';

import { connect } from 'react-redux';

import './css/conferenceUsers.css';

class ConferenceUsers extends Component {
    shareRef = React.createRef();

    state = {
        loading: false,
        active: { index: 1, option: { value: false } },
        users: {},
        changes: {}
    };

    getUsers = (active) => {
        if (active === undefined) {
            active = this.state.active.option.value;
        } else {
            this.setState({ active: { index: active ? 2 : 1, option: { value: active } } });
        }

        let filters = [ "all_audio", "all_video" ];
        if (active) filters.push(active);

        this.setState({ loading: true });
        UserAPI.users.get(this.props.confId, filters).then(users => {
            let usersObj = {};
            users.forEach(user => {
                usersObj[user.userId] = user;
            });
            this.setState({ users: usersObj, loading: false });
        });
    };

    openNew = () => {
        this.setState({
            showNew: true,
            changes: {
                displayName: "",
                role: 1
            }
        });
    };

    createUser = () => {
        UserAPI.user.new(this.props.confId, this.state.changes).then(user => {
            this.setState({ showNew: false });
            this.getUsers();
        }).catch(handleErrors);
    };

    openEdit = id => {
        let user = this.state.users[id];
        this.setState({ showEdit: true, changes: {...user} });
    };

    saveName = () => {
        let { changes } = this.state;
        this.setState({ saving: true });
        UserAPI.user.update(this.props.confId, changes.userId, {
            displayName: changes.displayName
        }).then(user => {
            let users = this.state.users;
            users[changes.userId].displayName = changes.displayName;
            this.setState({ users, saving: false });
        }).catch(handleErrors);
    };

    changeRole = role => {
        let { changes } = this.state,
            userId = changes.userId;
        this.setState({ changingRole: true });
        UserAPI.user.changeRole(this.props.confId, userId, role).then(user => {
            let users = this.state.users;
            users[userId].privileges = { ...user.privileges };
            changes.privileges = { ...user.privileges };
            this.setState({ users, changes, changingRole: false });
        });
    };

    changePrivilege = (e, privilege) => {
        let checkbox = e.target;
        checkbox.disabled = true;

        let userId = this.state.changes.userId,
            user = this.state.users[userId],
            privileges = user.privileges;

        let change = { privileges: { [privilege]: !privileges[privilege] } };
        UserAPI.user.update(this.props.confId, userId, change).then(user => {
            checkbox.disabled = false;
            let users = this.state.users;
            users[userId].privileges[privilege] = user.privileges[privilege];
            this.setState({ users });
        }).catch(handleErrors);
    };

    toggleVideoState = () => {
        let { changes, users } = this.state;
        let newState = changes.videoState ? 0 : 1;
        UserAPI.user.update(this.props.confId, changes.userId, { videoState: newState }).then(user => {
            users[changes.userId].videoState = newState;
            changes.videoState = newState;
            this.setState({ users, changes });
        }).catch(handleErrors);
    };

    toggleAudioState = () => {
        let { changes, users } = this.state;
        let newState = changes.audioState ? 0 : 1;
        UserAPI.user.update(this.props.confId, changes.userId, { audioState: newState }).then(user => {
            users[changes.userId].audioState = newState;
            changes.audioState = newState;
            this.setState({ users, changes });
        }).catch(handleErrors);
    };

    deleteUser = () => Modal(<>
        <h1>Delete User</h1>
        <p>Are you sure you want to delete {this.state.changes.displayName} from this conference?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let userId = this.state.changes.userId;
            UserAPI.user.delete(this.props.confId, userId).then(result => {
                this.getUsers();
                this.setState({ showEdit: false });
            }).catch(handleErrors);
        }}}
    ], { alert: true });

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection;
        this.setState({ changes });
    };

    handleEvents = events => {
        let e = events;
        new Notification({ id: e.userId+e.eventKind, message: e.eventKind, type: "success" });
        this.getUsers();
    };

    componentDidMount() {
        this.getUsers();

        if (ENV.isDev()) {
            Events.addListener("users", this.handleEvents);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curRole !== prevProps.curRole) {
            this.checkPermissions(this.props.curRole);
        }

        if (this.props.currentApp !== prevProps.currentApp &&
            this.props.currentApp !== "" && prevProps.currentApp !== ""
        ) {
            this.goTo("/Portal/Conferences");
        }
    }

    componentWillUnmount() {
        if (ENV.isDev()) {
            Events.removeListener("users", this.handleErrors);
        }
    }

    render() {
        let users = this.state.users,
            changes = this.state.changes,
            userIds = Object.keys(this.state.users).filter(id => {
                if (this.state.active.option.value) return users[id].active;
                return true;
            }).sort((a, b) => {
                if (users[a].active === users[b].active) {
                    if (users[a].displayName === users[b].displayName) return 0;
                    return users[a].displayName < users[b].displayName ? -1 : 1;
                }
                return users[a].active && !users[b].active ? -1 : 1;
            });

        return (
            <Section id="conferenceUsers" title="Users" buttons={[
                <button onClick={this.openNew}><Icon icon="add" /></button>
            ]} toolbar={<>
                <div className="totalUsers">{userIds.length + " users"}</div>
                <SelectBox name="active" selected={this.state.active.index} options={[
                    { name: "Pick One...", dontSelect: true },
                    { name: "All Users", value: false },
                    { name: "Online Users", value: true }
                ]} onSelect={selection => this.setState({ active: selection })} />
            </>}>
                {this.state.showNew && <Dialog className="newUser" show={this.state.showNew} title="Create New" footer={[
                    <Button outline onClick={() => this.setState({ showNew: false })}>Cancel</Button>,
                    <Button onClick={this.createUser}>Create</Button>
                ]}>
                    <Container label="User Settings">
                        <TextField name="displayName" label="Name" placeholder="User's Name" value={changes.displayName} onEdit={this.onEdit} />
                        <SelectBox name="role" label="Role" selected={changes.role} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Attendee", value: 1 }, { name: "Presenter", value: 2 }, { name: "Host", value: 3 }
                        ]} onSelect={selection => {
                            changes.role = selection.option.value;
                            this.setState({ changes });
                        }} />
                    </Container>
                </Dialog>}
                {this.state.showEdit && <Dialog className="editUser" show={this.state.showEdit} title="Edit User" footer={[
                    <Button delete onClick={this.deleteUser}>Delete</Button>,
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Close</Button>
                ]}>
                    <Container label="User Settings">
                        <TextField name="displayName" label="Name" placeholder="User's Name" value={changes?.displayName ?? ""} onEdit={this.onEdit} />
                        <Button onClick={this.saveName} disabled={changes?.displayName === users[changes.userId].displayName || this.state.saving}>
                            {this.state.saving
                                ? "Processing..."
                                : changes?.displayName === users[changes.userId].displayName ? "Saved" : "Save"
                            }
                        </Button>
                    </Container>
                    {changes.active && <>
                    <Container label="Media Controls">
                        <div className="privilege">
                            <Checkbox id="videoState" checked={parseInt(changes?.videoState) === 1 ?? false}
                                onChange={this.toggleVideoState} />
                            <Icon icon={parseInt(changes.videoState) === 1 ? "camera" : "cameraOff"} />
                            <span>Video {parseInt(changes.videoState) === 1 ? "On" : "Off"}</span>
                        </div>
                        <div className="privilege">
                            <Checkbox id="audioState" checked={parseInt(changes?.audioState) === 1 ?? false}
                                onChange={this.toggleAudioState} />
                            <Icon icon={parseInt(changes.audioState) === 1 ? "mic" : "micDisabled"} />
                            <span>Audio {parseInt(changes.audioState) === 1 ? "On" : "Off"}</span>
                        </div>
                    </Container>
                    </>}
                    <Container label="Conference Settings">
                        <SelectBox name="role" label="Role" selected={changes?.role ?? 1} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Attendee", value: 1 }, { name: "Presenter", value: 2 }, { name: "Host", value: 3 }
                        ]} onSelect={selection => this.changeRole(selection.option.value)} />
                        <label>Privileges</label>
                        <div className="privilegesContainer">
                        {privilegesList.map((privilege, i) => (
                            <div className="privilege">
                                <Checkbox id={privilege.key+i} checked={changes.privileges[privilege.key]}
                                    onChange={e => this.changePrivilege(e, privilege.key)}
                                    disabled={this.state.changingRole} />
                                <Icon icon={privilege.icon} />
                                <span>{privilege.name}</span>
                            </div>
                        ))}
                        </div>
                    </Container>
                </Dialog>}
                {this.state.loading
                    ? <><Placeholder /><Placeholder /><Placeholder /></>
                    : userIds.length === 0
                        ? <p>No Users Found</p>
                        : userIds.map(id => (
                            <div className="user" onClick={() => this.openEdit(id)}>
                                <div><Icon icon="userAccount" /><span className={users[id].active ? "active" : ""}></span></div>
                                {users[id].displayName}
                            </div>
                        ))
                }
            </Section>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    currentApp: state.currentApp
});

export default connect(mapStateToProps)(ConferenceUsers);
