import { roles } from '../LumiAPI/APIs/internal/account.js';

const curRole = (state = roles.OWNER, action) => {
    switch (action.type) {
        case 'VIEWAS':
            return action.payload.role;
        default:
            return state;
    }
};

export default curRole;
