import CodeDark from './sidebar/code_dark.png';
import CodeHoverDark from './sidebar/code_hover_dark.png';
import CodeHoverLight from './sidebar/code_hover_light.png';
import CodeLight from './sidebar/code_light.png';
import CodeSelectedDark from './sidebar/code_selected_dark.png';
import CodeSelectedLight from './sidebar/code_selected_light.png';

import ConferencesDark from './sidebar/conferences_dark.png';
import ConferencesHoverDark from './sidebar/conferences_hover_dark.png';
import ConferencesHoverLight from './sidebar/conferences_hover_light.png';
import ConferencesLight from './sidebar/conferences_light.png';
import ConferencesSelectedDark from './sidebar/conferences_selected_dark.png';
import ConferencesSelectedLight from './sidebar/conferences_selected_light.png';

import DashboardDark from './sidebar/dashboard_dark.png';
import DashboardHoverDark from './sidebar/dashboard_hover_dark.png';
import DashboardHoverLight from './sidebar/dashboard_hover_light.png';
import DashboardLight from './sidebar/dashboard_light.png';
import DashboardSelectedDark from './sidebar/dashboard_selected_dark.png';
import DashboardSelectedLight from './sidebar/dashboard_selected_light.png';

import DownloadsDark from './sidebar/downloads_dark.png';
import DownloadsHoverDark from './sidebar/downloads_hover_dark.png';
import DownloadsHoverLight from './sidebar/downloads_hover_light.png';
import DownloadsLight from './sidebar/downloads_light.png';
import DownloadsSelectedDark from './sidebar/downloads_selected_dark.png';
import DownloadsSelectedLight from './sidebar/downloads_selected_light.png';

import LibraryDark from './sidebar/library_dark.png';
import LibraryHoverDark from './sidebar/library_hover_dark.png';
import LibraryHoverLight from './sidebar/library_hover_light.png';
import LibraryLight from './sidebar/library_light.png';
import LibrarySelectedDark from './sidebar/library_selected_dark.png';
import LibrarySelectedLight from './sidebar/library_selected_light.png';

import RecordingsDark from './sidebar/recordings_dark.png';
import RecordingsHoverDark from './sidebar/recordings_hover_dark.png';
import RecordingsHoverLight from './sidebar/recordings_hover_light.png';
import RecordingsLight from './sidebar/recordings_light.png';
import RecordingsSelectedDark from './sidebar/recordings_selected_dark.png';
import RecordingsSelectedLight from './sidebar/recordings_selected_light.png';

import RolesDark from './sidebar/roles_dark.png';
import RolesHoverDark from './sidebar/roles_hover_dark.png';
import RolesHoverLight from './sidebar/roles_hover_light.png';
import RolesLight from './sidebar/roles_light.png';
import RolesSelectedDark from './sidebar/roles_selected_dark.png';
import RolesSelectedLight from './sidebar/roles_selected_light.png';

import SettingsDark from './sidebar/settings_dark.png';
import SettingsHoverDark from './sidebar/settings_hover_dark.png';
import SettingsHoverLight from './sidebar/settings_hover_light.png';
import SettingsLight from './sidebar/settings_light.png';
import SettingsSelectedDark from './sidebar/settings_selected_dark.png';
import SettingsSelectedLight from './sidebar/settings_selected_light.png';

const icons = {
    dashboard: {
        dark: {
            primary: DashboardDark,
            hover: DashboardHoverDark,
            selected: DashboardSelectedDark
        },
        light: {
            primary: DashboardLight,
            hover: DashboardHoverLight,
            selected: DashboardSelectedLight
        }
    },
    settings: {
        dark: {
            primary: SettingsDark,
            hover: SettingsHoverDark,
            selected: SettingsSelectedDark
        },
        light: {
            primary: SettingsLight,
            hover: SettingsHoverLight,
            selected: SettingsSelectedLight
        }
    },
    conference: {
        dark: {
            primary: ConferencesDark,
            hover: ConferencesHoverDark,
            selected: ConferencesSelectedDark
        },
        light: {
            primary: ConferencesLight,
            hover: ConferencesHoverLight,
            selected: ConferencesSelectedLight
        }
    },
    people: {
        dark: {
            primary: RolesDark,
            hover: RolesHoverDark,
            selected: RolesSelectedDark
        },
        light: {
            primary: RolesLight,
            hover: RolesHoverLight,
            selected: RolesSelectedLight
        }
    },
    record: {
        dark: {
            primary: RecordingsDark,
            hover: RecordingsHoverDark,
            selected: RecordingsSelectedDark
        },
        light: {
            primary: RecordingsLight,
            hover: RecordingsHoverLight,
            selected: RecordingsSelectedLight
        }
    },
    library: {
        dark: {
            primary: LibraryDark,
            hover: LibraryHoverDark,
            selected: LibrarySelectedDark
        },
        light: {
            primary: LibraryLight,
            hover: LibraryHoverLight,
            selected: LibrarySelectedLight
        }
    },
    integrations: {
        dark: {
            primary: CodeDark,
            hover: CodeHoverDark,
            selected: CodeSelectedDark
        },
        light: {
            primary: CodeLight,
            hover: CodeHoverLight,
            selected: CodeSelectedLight
        }
    },
    download: {
        dark: {
            primary: DownloadsDark,
            hover: DownloadsHoverDark,
            selected: DownloadsSelectedDark
        },
        light: {
            primary: DownloadsLight,
            hover: DownloadsHoverLight,
            selected: DownloadsSelectedLight
        }
    }
}

export default icons;