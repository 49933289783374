import React, { Component } from 'react';
import TextField from './TextField';
import Password from './Password';
import Button from './Button';

import './css/copyField.css';

class CopyField extends Component {
    passRef = React.createRef();

    state = {
        type: this.props.type ?? "text",
        copied: false
    };

    copyText = e => {
        e.preventDefault();
        let button = e.target;
        let key = button.name;
        let field = document.querySelector("#COPY_"+key);
        let isPassword = this.props.type === "password";
        if (isPassword) this.passRef.current.toggle();

        field.select();
        document.execCommand("copy");
        this.setState({ copied: true });
        setTimeout(() => {
            if (isPassword) this.passRef.current.toggle();
            this.setState({ copied: false });
        }, 1000);
    };

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type });
        }
    }

    render() {
        return (
            <div className="noWrap">
                {this.state.type === "text"
                    ? <TextField className={this.state.copied ? "copied" : ""} {...this.props} readonly />
                    : <Password className={this.state.copied ? "copied" : ""} ref={this.passRef} {...this.props} readonly />
                }
                <Button fill={this.props.fill ?? undefined} outline={this.props.fill !== true} name={this.props.id} onClick={this.copyText}>
                    {this.state.copied ? this.props.confirm ?? "Copied" : this.props.action ?? "Copy"}
                </Button>
                <textarea id={"COPY_"+this.props.id} className="copyField" value={this.props.value}></textarea>
            </div>
        );
    }
}

export default CopyField;
