import React, { Component } from 'react';
// import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { withNav } from '../../hooks/useNav';
import handleErrors from '../../LumiAPI/APIs/errors.js';
import ChatAPI, { removeHTML } from '../../LumiAPI/newChatAPI.js';
import { ColorUpdater, /*colors, accountIconColor,*/ getColorScheme } from '../../tools/colorSchemeManager';
import Query from '../../tools/Query';

import ContentNoToolbar from '../../templates/ContentNoToolbar';

import ConversationList from './ConversationList';
import MessageWindow from './MessageWindow';

import Icon from '../../components/Icon';
import LookupBox from '../../components/LookupBox';
import SelectBox from '../../components/SelectBox';

import { connect } from 'react-redux';
import { changeChatConf, openChat, refreshChats, refreshChatUsers, refreshMessages, refreshRecents, setChatOwner, setDelivered, setRecipients, updateChat } from '../../actions/chat-actions.js';

import './css/chat.css';

class Chat extends Component {
    state = {
        loading: true,
        confId: { index: 0, option: { value: "" } },
        conferences: [],
        colorScheme: getColorScheme()
    };

    getConferences = () => {
        ChatAPI.conferences.get().then(conferences => {
            let confId = conferences[0].confId;
            this.setState({
                conferences,
                confId: { index: 1, option: { value: confId } }
            });
            this.props.onChangeChatConf(confId);
            this.getUsers(confId);
            this.getChats(confId);
        }).catch(err => {
            this.setState({
                conferences: [{ confId: "noconferences", displayName: "No Conferences Found" }],
                confId: { index: 1, option: { value: "noconferences" } }
            });
            this.props.onChangeChatConf("noconferences");
            this.getUsers("noconferences");
            this.getChats("noconferences");
            handleErrors(err);
        });
    };

    switchConf = selection => {
        let confId = selection.option.value;
        this.setState({ confId: selection });
        this.props.onChangeChatConf(confId);
        this.props.onRefreshMessages([]);
        this.getUsers(confId);
        this.getChats(confId);
    };

    getUsers = confId => {
        confId = confId ?? this.state.confId.option.value;
        this.props.onRefreshChatUsers({});
        ChatAPI.users.get(confId).then(users => {
            this.props.onRefreshChatUsers(users);
        }).catch(handleErrors);
    };

    getChats = confId => {
        confId = confId ?? this.state.confId.option.value;
        if (confId === "noconferences") {
            this.props.onRefreshChats({
                noChats: { displayName: "No Chats Found" }
            });
            return;
        }

        this.setState({ loading: true });
        this.props.onRefreshChats({});
        this.props.onOpenChat("");

        ChatAPI.chats.get(confId).then(chats => {
            let chatObj = {};
            if (chats.length === 0) {
                this.setState({ loading: false });
            } else {
                chats.forEach((chat, i) => {
                    chatObj[chat.chatId] = chat;
                });
            }
            this.props.onRefreshChats(chatObj);
        }).catch(err => {
            this.setState({ loading: false });
            this.props.onRefreshChats({});
            handleErrors(err);
        })
    };

    getRecentMessages = chats => {
        chats = chats ?? this.props.chats;

        let confId = this.state.confId.option.value;
        if (confId === "noconferences") return;

        let recents = {};
        Object.keys(chats).forEach((chatId, i) => {
            ChatAPI.messages.get(confId, chatId).then(messages => {
                let lastMessage = messages[messages.length - 1];
                recents[chatId] = { message: removeHTML(lastMessage.messageData), created: lastMessage.created };
                this.props.onRefreshRecents(recents);
            }).catch(handleErrors);
        });
    };

    getMessages = (confId, chatId) => {
        confId = confId ?? this.state.confId.option.value;
        chatId = chatId ?? this.props.currentChat;

        ChatAPI.messages.get(confId, chatId).then(messages => {
            let deliveredIndex = -1;
            for (let i = 0; i < messages.length; i++) {
                messages[i].delivered = true;
                deliveredIndex = i;
            }
            this.props.onRefreshMessages(messages);
            this.props.onSetDelivered(deliveredIndex);
        }).catch(handleErrors);
    };

    close = e => {
        if (e.target.id === "menuButton") return;
        this.setState({ listOpen: false });
    };

    updateColorScheme = colorScheme => this.setState({ colorScheme: getColorScheme() });

    componentDidMount() {
        document.title = "Cordoniq Chat";

        let intId = this.props.match.params.integrationId;
        ChatAPI.setIntId(intId);
        let query = new Query(window.location.search),
            userId = query.get("userId"),
            userName = query.get("name");
        this.props.onSetChatOwner({
            userId: userId ?? uuid().replace(/-/g, ""),
            userName: userName ?? "Guest"
        });

        this.getConferences();

        document.body.addEventListener("click", this.close);
        ColorUpdater.add(this.updateColorScheme);
    }

    componentDidUpdate(prevProps) {
        if (this.props.chats !== prevProps.chats) {
            this.getRecentMessages(this.props.chats);
        }

        if (this.props.chatOwner !== prevProps.chatOwner) {
            this.setState(this.props.chatOwner);
        }

        if (this.props.currentChat !== prevProps.currentChat) {
            if (this.props.currentChat !== "") {
                this.getMessages(null, this.props.currentChat);
            }
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.close);
        ColorUpdater.remove(this.updateColorScheme);
    }

    render() {
        let tools = [];
        if (this.props.currentChat !== "") {
            tools.push(<button id="menuButton" onClick={() => {
                this.setState({ listOpen: !this.state.listOpen ?? true });
            }}><Icon icon="menu" /></button>);
        }
        tools.push(<SelectBox name="confId" selected={this.state.confId.index} options={[
            { name: "Pick Conference...", dontSelect: true, color: "transparent" },
            ...this.state.conferences.map(conference => ({
                name: conference.displayName,
                value: conference.confId,
                color: conference.active ? "#f1a738"
                    : this.state.colorScheme === "dark"
                        ? "#3d3740" : "#f5f4f5"
            }))
        ]} onSelect={this.switchConf} style={{marginRight: "auto"}} />);
        if (this.props.currentChat !== "" && this.props.currentChat !== "newMessage") {
            tools.push(<div id="chatName">{this.props.chats[this.props.currentChat].displayName}</div>);
        } else if (this.props.currentChat === "newMessage") {
            tools.push(<LookupBox fill name="newRecipients" selected={this.props.newRecipients.map(item => item.id)} placeholder="Search Contacts..." list={[
                ...Object.keys(this.props.chatUsers).map(userId => {
                    let user = this.props.chatUsers[userId];
                    return { name: user.displayName, value: userId }
                })
            ]} onSelect={selection => {
                this.props.onSetRecipients(selection.list);
            }} />);
        }
        tools.push(<button onClick={() => {
            this.props.onRefreshChats({
                newMessage: { chatId: "newMessage", displayName: "New Message" },
                ...this.props.chats
            });
            this.props.onOpenChat("newMessage");
        }}><Icon icon="compose" /></button>);

        return (
            <ContentNoToolbar id="chat" tools={tools}>
                <ConversationList loading={this.state.loading} open={this.state.listOpen ?? false} />
                <MessageWindow />
            </ContentNoToolbar>
        );
    }
}

const mapStateToProps = state => ({
    chatOwner: state.chatOwner,
    chats: state.chats,
    currentChat: state.currentChat,
    chatUsers: state.chatUsers,
    newRecipients: state.newRecipients
});

const mapActionsToProps = {
    onSetChatOwner: setChatOwner,
    onRefreshChats: refreshChats,
    onChangeChatConf: changeChatConf,
    onOpenChat: openChat,
    onUpdateChat: updateChat,
    onRefreshChatUsers: refreshChatUsers,
    onRefreshMessages: refreshMessages,
    onRefreshRecents: refreshRecents,
    onSetDelivered: setDelivered,
    onSetRecipients: setRecipients
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(Chat));
