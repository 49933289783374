import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { getApp, updateApp as updateBackend } from '../LumiAPI/APIs/internal/app.js';

import Slider from '../components/Slider';
import Section from '../partials/Section';

import { connect } from 'react-redux';
import { updateApp } from '../actions/apps-actions.js';

import './css/privateCloudSettings.css';

const smallDisplay = window.matchMedia("(max-width: 500px)");

class PrivateCloudSettings extends Component {
    state = {
        fullLabel: !smallDisplay.matches,
        app: this.props.app ?? undefined
    };

    updateBackend = value => {
        let app = this.state.app;
        updateBackend(app.clientId, { backend: value }).then(result => {
            getApp(app.clientId).then(app => {
                this.setState({ app });
                this.props.onUpdateApp(app);
                this.goTo("/Portal/PrivateCloud");
            }).catch(handleErrors);
        }).catch(handleErrors);
    };

    updateLabel = e => this.setState({ fullLabel: !e.matches });

    componentDidMount() {
        smallDisplay.addListener(this.updateLabel);
    }

    componentWillUnmount() {
        smallDisplay.removeListener(this.updateLabel);
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.setState({ app: this.props.app });
        }
    }

    render() {
        let fullLabel = this.state.fullLabel;
        let pCloud = this.state.app?.backend === true;

        return (
            <Section id="privateCloud" title={pCloud ? "Disable Private Cloud" : "Enable Private Cloud"}>
                {!pCloud
                    ? <div>
                        <p>By default, all conferences created in Cordoniq are hosted on Cordoniq servers.</p>
                        <p>If you'd like to host Cordoniq on your own private cloud, enable Private Cloud settings below.</p>
                        <p>Enabling Private Cloud settings will add the following pages to the App Settings menu:</p>
                        <ul>
                            <li><b>Private Cloud:</b> Set up your private cloud and domain settings.</li>
                            <li><b>My Servers:</b> View and manage a list of all your servers within your private cloud.</li>
                        </ul>
                        <p>NOTE: If you enable Private Cloud settings, <b>you will need to set up your own servers</b> in order to continue creating and using conferences.</p>
                        <Slider label={`Enable${fullLabel ? " Private Cloud" : ""}`} action={() => this.updateBackend(true)} />
                    </div>
                    : <div>
                        <p>You are currently hosting conferences on your own private cloud.</p>
                        <p>To host on Cordoniq instead, disable private cloud settings below.</p>
                        <p>NOTE: Conferences created on your private cloud will no longer be accessible</p>
                        <Slider label={`Disable${fullLabel ? " Private Cloud" : ""}`} action={() => this.updateBackend(false)} />
                    </div>
                }
            </Section>
        );
    }
}

const mapStateToProps = state => ({
    app: state.apps[state.currentApp]
});

const mapActionsToProps = {
    onUpdateApp: updateApp
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(PrivateCloudSettings));
