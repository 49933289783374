import React, { Component } from 'react';
import { PORTAL } from '../tools/envTools';

import Icon from '../components/Icon';


import './css/graphicSidebar.css';

const compact = window.matchMedia("(max-width: 900px), (max-height: 600px)");

class GraphicSidebar extends Component {
    state = {
        animation: "initial",
        iconMode: undefined
    };

    className = () => {
        let className = "";
        className += PORTAL.isDev() ? "dev" : "";
        className += this.props.show ? "" : " hide";
        return className;
    };

    setIconMode = () => {
        if (compact.matches) {
            this.setState({ iconMode: undefined });
        } else if (PORTAL.isDev()) {
            this.setState({ iconMode: "dark" });
        } else {
            this.setState({ iconMode: undefined });
        }
    };

    componentDidMount() {
        setTimeout(() => this.setState({ animation: "hover" }), 1500);

        this.setIconMode();
        compact.addListener(this.setIconMode);
    }

    componentWillUnmount() {
        compact.removeListener(this.setIconMode);
    }

  	render() {
	  	return (
	  		<aside id="graphic" className={this.className()}>
	  			<Icon alt="Cordoniq Logo" id="logo" icon="logoFull" mode={this.state.iconMode} />
                <div id="terms">
                    &copy; Cordoniq &bull; <a href="https://www.cordoniq.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms</a> &bull; <a href="https://www.cordoniq.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </div>
	  		</aside>
		);
	}
}

export default GraphicSidebar;
