import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';

import Button from '../../../components/Button';
import Slides from '../../partials/Slides';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs.js';

import HubSlide1 from '../../../images/integrations/hubspot/hubspot-1.jpg';
import HubSlide2 from '../../../images/integrations/hubspot/hubspot-2.jpg';
import HubSlide3 from '../../../images/integrations/hubspot/hubspot-3.jpg';

import "../css/hubspotLanding.css";

const BASE_URL = window.location.hostname === "localhost"
    ? 'http://localhost:5000'
    : `https://portal-api${ENV.getApiDomain()}.cordoniq.com`;

const HubSpotSlides = [ HubSlide1, HubSlide2, HubSlide3 ];

class HubSpot extends Component {
    state = { success: false };

    openHubSpotInstall = () => window.open(`${BASE_URL}/api/hs/install`, "_self");

    openCategory = e => {
        let category = e.target.innerHTML.toLowerCase();
        this.goTo(`/Integrations?category=${category}`);
    };

    componentDidMount() {
        this.setState({ success: this.props.success ?? false });

        if (this.props.success && ENV.isProd()) {
            ReactGA.event({
                category: 'App Installation',
                action: 'HubSpot'
            });
        }

        document.title = "HubSpot Integration | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <LandingPageWithBreadCrumbs id="hubspotLanding" breadcrumbs={[
                { title: "App Integrations", path: "/Integrations" },
                { title: "HubSpot" }
            ]} icon="hubsprocket" iconMode="color" title="HubSpot Integration" subtitle="Start your secure, HD video meeting in HubSpot">
                {this.state.success
                    ? <>
                    <div id="content">
                        <h2>Thank you!</h2>
                        <p>Thanks for installing Cordoniq for HubSpot. We hope your team will enjoy our high-quality meetings and collaboration tools!</p>
                        <p>Visit <a href="https://support.cordoniq.com" target="_blank" rel="noopener noreferrer">Cordoniq Support</a> for questions, comments, or troubleshooting.</p>
                    </div>
                    </>
                    : <>
                    <div id="content">
                        <Button onClick={this.openHubSpotInstall}>Add to HubSpot</Button>
                        <p>The Cordoniq platform is designed for your business, education, or training collaboration needs. Simply add Cordoniq to your HubSpot account and engage face-to-face, with high-quality video meetings and a full suite of collaboration tools that make your HubSpot workspace more efficient and productive.</p>
                        <p>It's easy to start. Simply create a new meeting in HubSpot, click the video conference button from the dialog and we'll take care of the rest.</p>
                        <h2>Integration Features</h2>
                        <Slides slides={HubSpotSlides} />
                        <h3>Real-time Documents and Screen Sharing</h3>
                        <p>Seamless integration for sharing over 70 document format types - no more scrambling to change files for your team. Share them immediately.</p>
                        <h3>Document Annotation</h3>
                        <p>Need to markup and change documents in real time? Cordoniq provides easy to use annotation tools that get the documents you need changed while the whole team participates.</p>
                        <h3>Whiteboarding</h3>
                        <p>Need to draw, design, or create great business or learning ideas? Utilize the Cordoniq whiteboarding features with easy-to-use tools, for quick and productive teamwork.</p>
                        <h3>Recording</h3>
                        <p>Record meetings for later reference. View your collaboration information whenever you need it, with recorded video meetings.</p>
                        <h2>Privacy & Security</h2>
                        <p>Cordoniq provides your HubSpot teams and leads with secure, end-to-end encryption utilizing industry standard protocols. API driven efficient and easy-to-use, Cordoniq is the platform that supports business, education and training teams with the latest in video meeting collaboration.</p>
                        <Button onClick={() => this.goTo("/Sales")}>Talk to an Expert</Button>
                    </div>
                    <aside>
                        <Button onClick={this.openHubSpotInstall}>Add to HubSpot</Button>
                        <h2>Categories</h2>
                        <div id="categories">
                            <span onClick={this.openCategory}>Marketing</span>
                            <span onClick={this.openCategory}>Sales</span>
                        </div>
                        <h2>Support</h2>
                        <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                        <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                        <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                    </aside>
                    </>
                }
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(HubSpot);
