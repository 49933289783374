import { api, upload, fileOptions, downloadOptions } from './config.js';
import { Token } from './auth.js';

export const newContent = (data, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        upload(Token.get()).post("/content", data, fileOptions(onUploadProgress))
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getContent = (contentId, onDownloadProgress) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/content/${contentId}`, downloadOptions(onDownloadProgress))
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateContent = (contentId, data, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        upload(Token.get()).put(`/content/${contentId}`, data, fileOptions(onUploadProgress))
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const deleteContent = (contentId, forceDelete) => {
    return new Promise((resolve, reject) => {
        let options = forceDelete ? { params: { force: forceDelete } } : {};
        api(Token.get()).delete(`/content/${contentId}`, options)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const getContents = () => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get("/contents")
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
}

const ContentAPI = {
    content: {
        new: newContent,
        get: getContent,
        update: updateContent,
        delete: deleteContent
    },
    contents: {
        get: getContents
    }
};

export default ContentAPI;
