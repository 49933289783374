import React, { Component } from 'react';
import { getTotalUsers } from '../LumiAPI/APIs/core/user.js';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Icon from '../components/Icon';
import Checkbox from '../components/Checkbox';

import './css/card.css';

const VIEW = { GRID: 1, LIST: 2 };
const colors = [ "candlelight", "bubble", "sky", "lime" ];

class Card extends Component {
    state = {
        layout: this.props.layout ?? VIEW.GRID,
        item: this.props.item ?? {},
        imageProps: {
            width: 0,
            opacity: 0
        }
    };

    checkForLabel = item => {
        item = item ?? this.state.item;
        if (item.confId !== undefined && (item.id === item?.confId ?? false)) {
            getTotalUsers(item.confId)
                .then(total => {
                    if (total > 0) {
                        item.label = `${total} Active User${total > 1 ? "s" : ""}`;
                        this.setState({ item });
                    }
                }).catch(handleErrors);
        }
    };

    className = () => {
        let className = "card";
        className += this.state.layout === VIEW.GRID
            ? " square" : " rectangle";
        className += this.props.selected
            ? " selected" : "";
        className += this.props.mode === "selection"
            ? " showCheckBox" : "";
        className += this.state.item?.details?.length > 0 ?? false
            ? " hasDetails" : "";
        className += this.state.item.showDetails
            ? " showDetails" : "";
        if (this.state.item?.backgroundColor !== undefined ?? false) {
            className += ` ${colors[this.state.item.backgroundColor]}`;
        }
        if (parseInt(this.state.item?.state) === 3 ?? false) {
            className += " ended";
        }
        return className;
    };

    setAspectRatio = ratio => {
        let image = document.querySelector(".card #content .image"),
            height = image?.clientHeight ?? 0,
            width = height / ratio;
        this.setState({ imageProps: height === 0
            ? { width, opacity: 0 }
            : { width: `${width}px`, opacity: 1 }
        });
    };

    componentDidMount() {
        this.checkForLabel();
        this.setAspectRatio(1);
    }

    componentDidUpdate(prevProps) {
        if (this.props.layout !== prevProps.layout) {
            this.setState({ layout: this.props.layout });
        }

        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item });
            this.checkForLabel(this.props.item);
        }

        if ((this.props.selected !== prevProps.selected) ||
            (this.props.layout !== prevProps.layout)
        ) {
            setTimeout(() => this.setAspectRatio(1), 1);
        }
    }

    render() {
        let item = this.state.item,
            loading = item.loading ?? 0;
        return (
            <>
            <div className={this.className()} onClick={this.props.onClick}>
                <div className="pattern"><div></div></div>
                <div className="pattern"><div></div></div>
                <Checkbox name={this.props.id} checked={this.props.selected} mode={item.backgroundColor ? "light" : undefined} />
                <div id="content">
                    {loading !== 0 && <div className="loading">
                        <Icon icon="loading" className="spin" text={loading+"%"} />
                    </div>}
                    {item.image && loading === 0 && <div className="image" style={{...this.state.imageProps}}>
                        <div {...item.image.props}></div>
                    </div>}
                    {item.icon && loading === 0 && <Icon icon={item.icon.name} {...item.icon.props} />}
                    <div id="description">
                        {item.title && <h1>{item.title}</h1>}
                        {item.subtitle && <h2>{item.subtitle}</h2>}
                    </div>
                    {item.label && <h3>{item.label}</h3>}
                </div>
                <div id="buttons">
                    {this.props.children && <Icon icon="arrowLeft" />}
                    {item.action && <button {...item.action.props}>{item.action.label}</button>}
                    <Checkbox name={this.props.id} checked={this.props.selected} mode={item.attendeeUrl ? "light" : undefined} />
                    <div id="options">
                        {item.buttons && item.buttons.map(button => (
                            button.icon
                                ? <Icon icon={button.icon} {...button.props} />
                                : <button {...button.props}>{button.label}</button>
                        ))}
                    </div>
                </div>
            </div>
            {item.showDetails && item.details && <div className="details">
                {item.details.map(detail => (
                    <span key={detail.name}><b>{detail.name}</b>{detail.value}</span>
                ))}
            </div>}
            </>
        );
    }
}

export default Card;
