import React, { Component } from 'react';
import './css/dialog.css';
import './css/buttons.css';

export class Container extends Component {
	render() {
		return (
			<>
			{this.props.label && <h2 className="containerLabel">{this.props.label}</h2>}
			{this.props.tabs && <div className="tabs">
				{this.props.tabs.map(tab => tab)}
			</div>}
			<div className="container" id={this.props.id}>
				{this.props.children}
			</div>
			</>
		)
	}
}

class Dialog extends Component {
	state = {
		show: this.props.show ?? false,
		progress: this.props.progress ?? 0
	};

	show = () => this.setState({ show: true });

	hide = () => {
		this.setState({ show: false });
		let prompts = document.querySelectorAll("#promptContainer #prompt .withFooter")
		prompts.forEach(prompt => prompt.scrollTo(0, 0));
	};

	updateProgress = progress => { this.setState({ progress: progress }); };

	componentDidUpdate(prevProps) {
		if (this.props.show !== prevProps.show) {
			this.setState({ show: this.props.show });
			if (this.props.onLoad) setTimeout(this.props.onLoad, 1);
		}

		if (this.props.progress !== prevProps.progress) {
			this.setState({ progress: this.props.progress });
		}
	}

	render() {
		return this.state.show
			? <div id="promptContainer" className={this.props?.className ?? undefined}>
				<div id="prompt" className={this.props.withForm ? "withForm" : this.props.alert ? "alert" : ""}>
					{this.props.title && <h1>{this.props.title}</h1>}
					<div id="promptContent" className={this.props.withFooter ? "withFooter" : ""}>
						{this.props.message && this.props.message.map((line, i) => <p key={i}>{line}</p>)}
						{this.props.children}
					</div>
					<div className={this.state.progress >= 100 ? "progress complete" : "progress"} style={{width: this.state.progress+"%"}}></div>
					{this.props.footer && <div id="footer">
						{this.props.footer.map(button => button)}
					</div>}
				</div>
			</div>
			: null;
	}
}

export default Dialog;
