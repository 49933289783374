import React, { Component } from 'react';
import { ColorUpdater, getColorScheme } from '../tools/colorSchemeManager';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './css/code.css';

class Code extends Component {
    state = {};

    updateCodeColor = () => {
        let colorScheme = getColorScheme();
        this.setState({
            code: colorScheme === "light" ? undefined : tomorrow,
            colorScheme
        });
    };

    componentDidMount() {
        this.updateCodeColor();
        ColorUpdater.add(this.updateCodeColor);
    }

    componentWillUnmount() {
        ColorUpdater.remove(this.updateCodeColor);
    }

    render() {
        return (
            <div className="codeContainer">
    			{this.props.tabs && <div className="codeTabs">
    				{this.props.tabs.map(tab =>
                        <button className={tab.className} onClick={tab.onClick}>
                            {tab.name}
                        </button>
                    )}
    			</div>}
    			<SyntaxHighlighter language={this.props.language ?? "javascript"}
                    style={this.state.code ?? undefined}
                    className={this.state.code === undefined ? "light" : "dark"}
                >
    				{this.props.children}
    			</SyntaxHighlighter>
    		</div>
        );
    }
}

export default Code;
