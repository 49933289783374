import React, { Component } from 'react';
import { getLinkedContent, unlinkContent } from '../LumiAPI/APIs/core/conference.js';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Section from '../partials/Section';
import Placeholder from '../components/Placeholder';
import Content from '../components/Content';
import DocumentLoader from '../components/DocumentLoader';
import Icon from '../components/Icon';

import Modal from '../tools/Modal';

import './css/conferenceContent.css';

class Contents extends Component {
	documentPicker = React.createRef();

	state = {
		loading: false,
		files: {}
	};

	reload = () => {
		var confId = this.props.confId;

		this.setState({ loading: true });
		getLinkedContent(confId).then(files => {
			var contents = {};
			files.forEach(file => {
				var content = new Content();
				content.set(file);
				contents[content.contentId] = content;
			});
			this.setState({ files: contents, loading: false });
		}).catch(handleErrors);
	};

	remove = contentId => Modal(<>
		<h1>Unlink Content</h1>
		<p>Are you sure you want to unlink "{this.state.files[contentId].displayName}" from this conference?</p>
		<p>Note: This will not delete the file from your account.</p>
	</>, [
		{ name: "No", props: { outline: true } },
		{ name: "Yes", props: { onClick: () => {
			unlinkContent(this.props.confId, contentId)
				.then(this.reload)
				.catch(handleErrors);
		}}}
	], { alert: true });

	componentDidMount() {
		this.reload();
	}

	render() {
		let files = this.state.files,
			contentIds = Object.keys(this.state.files);
		return (
			<>
			<DocumentLoader ref={this.documentPicker} confId={this.props.confId} reload={this.reload} />
			<Section id="conferenceContent" title="Linked Content" buttons={[
				<button onClick={() => this.documentPicker.current.show()}><Icon icon="add" /></button>
			]}>
				{this.state.loading
					? <><Placeholder /><Placeholder /><Placeholder /></>
					: contentIds.length === 0
						? <p>No Content Found</p>
						: contentIds.map(id => (
							<div key={files[id].fileName} className="file" onClick={() => this.remove(id)}>
								<Icon icon={files[id].getIcon()} color={files[id].getIconColor()} />
								<div>{files[id].displayName}</div>
								<span><Icon icon="close" mode="dark" /></span>
							</div>
						))
				}
			</Section>
			</>
		);
	}
}

export default Contents;
