import React, { Component } from 'react';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Icon from './Icon';

import './css/imageSelector.css';

class ImageSelector extends Component {
	state = {
		loaded: 0,
		imagePreview: this.props.image ?? undefined,
		borderColor: this.props.border ?? undefined
	};

	setImage = url => {
		this.setState({ imagePreview: url });
	};

	clearImage = () => {
		this.setState({ imagePreview: undefined });
	};

	getImage = () => {
		return this.state.file;
	};

	uploadImage = image => {
		var add = this.props.upload.action;
		var clientId = this.props.upload?.clientId ?? undefined;

		if (clientId === undefined) {
			add(image, progress => {
				var completed = Math.round( (progress.loaded * 100) / progress.total );
				this.setState({ loaded: completed });
			}).then(() => {
				setTimeout(() => {
					this.setState({ loaded: 0 });
				}, 1500);
			}).catch(handleErrors);
		} else {
			add(clientId, image, progress => {
				var completed = Math.round( (progress.loaded * 100) / progress.total );
				this.setState({ loaded: completed });
			}).then(() => {
				setTimeout(() => {
					this.setState({ loaded: 0 });
				}, 1500);
			}).catch(handleErrors);
		}
	};

	removeImage = () => {
		var remove = this.props.delete.action;
		var clientId = this.props.delete?.clientId ?? undefined;

		if (clientId === undefined) {
			remove().then(() => {
				return null;
			}).catch(handleErrors);
		} else {
			remove(clientId).then(() => {
				return null;
			}).catch(handleErrors);
		}
	};

	handleFiles = files => {
		files = [...files];
		this.setState({ file: files[0] });

		if (this.props.upload) this.uploadImage(files[0]);

		var reader = new window.FileReader();
		reader.onload = e => {
			let preview = e.target.result;
			this.setState({ imagePreview: preview });
			if (this.props.onChange) this.props.onChange({
				file: files[0],
				image: preview
			});
		};
		reader.readAsDataURL(files[0]);
	};

	handleDrop = e => {
		e.preventDefault();

		var dt = e.dataTransfer;
		var files = dt.files;

		this.handleFiles(files);

		const dropArea = document.querySelector("#profileDropArea");
		dropArea.classList.remove("over");
	};

	handleDragEnter = e => {
		e.preventDefault();
		const dropArea = document.querySelector("#profileDropArea");
		dropArea.classList.add("over");
	};

	handleDragLeave = e => {
		e.preventDefault();
		const dropArea = document.querySelector("#profileDropArea");
		dropArea.classList.remove("over");
	};

	componentDidMount() {
		const dropArea = document.querySelector("#profileDropArea");
		dropArea.addEventListener('drop', this.handleDrop, false);
		dropArea.addEventListener('dragenter', this.handleDragEnter, false);
		dropArea.addEventListener('dragover', this.handleDragEnter, false);
		dropArea.addEventListener('dragleave', this.handleDragLeave, false);
	}

	componentWillUnmount() {
		const dropArea = document.querySelector("#profileDropArea");
		dropArea.removeEventListener('drop', this.handleDrop, false);
		dropArea.removeEventListener('dragenter', this.handleDragEnter, false);
		dropArea.removeEventListener('dragover', this.handleDragEnter, false);
		dropArea.removeEventListener('dragleave', this.handleDragLeave, false);
	}

	componentDidUpdate(prevProps) {
		if (this.props.image !== prevProps.image) {
			this.setState({ imagePreview: this.props.image });
		}
		if (this.props.border !== prevProps.border) {
			this.setState({ borderColor: this.props.border });
		}
	}

	render() {
		const hasColor = this.state.borderColor !== undefined;
		const imageLoaded = this.state.imagePreview !== undefined;
		const inProgress = this.state.loaded !== 0 && this.state.loaded !== 100;
		const hasStarted = this.state.loaded !== 0;
		const hasFinished = this.state.loaded === 100;
		const style = { height: hasStarted ? this.state.loaded+'%' : undefined };

		return (
			<div id="profileDropArea" className={hasColor ? "profile hasColor" : "profile"} style={{ background: this.state.borderColor }}>
				<div className="image" style={{ backgroundImage: 'url('+this.state.imagePreview+')' }}></div>
				<div id="fileContainer" className={imageLoaded ? "change" : ""}>
					<input type="file" id="imgSelector" onChange={e => this.handleFiles(e.target.files)} />
					<label htmlFor="imgSelector" className={hasStarted ? (hasFinished ? "progress done" : "progress") : ""} style={style}>
						{!inProgress && (imageLoaded ?
							(hasFinished ? "Changes Saved" : "Change")
							: (this.props.label ?? "Choose Icon")
						)}
					</label>
				</div>
				<div className={imageLoaded ? "mask withImage" : "mask"}>
					<button className={imageLoaded && !hasStarted ? "show" : ""} onClick={e => {
						this.setState({ imagePreview: undefined, file: undefined });
						if (this.props.remove) {
							this.props.remove();
						} else if (this.props.delete) {
							this.removeImage();
						}
					}}><Icon icon="close" mode="dark" /></button>
				</div>
			</div>
		);
	}
}

export default ImageSelector;
