import React, { Component } from 'react';
import { faPlay, faPause,
    faVolumeUp, faVolumeDown, faVolumeOff,
    faExpand, faCompress, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import Icon from './Icon';

import './css/video.css';

const $ = selector => document.querySelector(selector);

class Video extends Component {
    videoRef = React.createRef();

    state = {
        loading: true,
        showControls: true,
        // Player states
        playing: false,
        currentTime: 0,
        progress: 0,
        duration: 0,
        volume: 0,
        volumeLevel: 0,
        expanded: false,
        // Mouse Controls
        y: 0,
        clicking: "",
        playState: ""
    };

    setMetaData = () => {
        let video = this.videoRef.current;
        this.setState({
            loading: false,
            showControls: false,
            currentTime: video.currentTime,
            duration: video.duration,
            volume: video.volume
        });
        this.updateTime();
    };

    updatePlayButton = playing => this.setState({ playing, showControls: !playing });

    play = () => {
        let video = this.videoRef.current;
        video.play();
        this.updatePlayButton(true);
    };

    pause = () => {
        let video = this.videoRef.current;
        video.pause();
        this.bufferLength();
        this.updatePlayButton(false);
    };

    playPause = () => {
        let video = this.videoRef.current,
            isPlaying = video.currentTime > 0 && !video.paused && !video.ended;

        if (isPlaying) {
            video.pause();
            this.bufferLength();
        } else {
            video.play();
        }
        this.updatePlayButton(!isPlaying);
    };

    getTimeStamp = time => {
        time = time / 60;

        let minutes = Math.trunc(time);
        if (minutes === time) {
            return `${minutes}:00`;
        } else {
            let seconds = (time - minutes) * 60;
            seconds = seconds.toFixed();
            return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
        }
    };

    updateTime = () => {
        let video = this.videoRef.current,
            curTime = video.currentTime,
            progBarWidth = $('#mediaControls #progress').offsetWidth,
            { duration } = this.state;

        let progWidth = (curTime / duration) * progBarWidth,
            progress = (progWidth / progBarWidth) * 100;

        this.setState({ currentTime: curTime, progress });

        if (video.currentTime > 0 && !video.paused && !video.ended) {
            this.updatePlayButton(true);
            this.bufferLength();
        }
    };

    updateDuration = () => {
        let video = this.videoRef.current,
            duration = video.duration;
        this.setState({ duration });
    };

    bufferLength = () => {
        let video = this.videoRef.current,
            buffered = video.buffered,
            { duration } = this.state;

        let bufferElement = document.querySelectorAll('.buffered');
        bufferElement.forEach(buffer => {
            buffer.remove();
        });

        if (buffered.length > 0) {
            var i = buffered.length;
            while (i--) {
                let maxBuffer = buffered.end(i),
                    minBuffer = buffered.start(i);

                let bufferOffset = (minBuffer / duration) * 100,
                    bufferWidth = ((maxBuffer - minBuffer) / duration) * 100;

                let buffer = document.createElement("div");
                buffer.className = "buffered";
                buffer.style.left = bufferOffset+'%';
                buffer.style.width = bufferWidth+'%';
                $('#mediaControls #progress').appendChild(buffer);
            }
        }
    };

    updateVolume = () => {
        let video = this.videoRef.current;
        this.setState({ volume: video.volume });
    };

    timelineMouseDown = e => {
        e.preventDefault();

        if (this.state.playing) {
            this.setState({ playState: "playing" });
            this.playPause();
        }

        this.setState({ clicking: "timeline" });
    };

    volumeMouseDown = e => {
        e.preventDefault();
        this.setState({ clicking: "volume", y: e.pageY });
    };

    mouseMove = e => {
        let { clicking } = this.state;
        if (clicking === "timeline") {
            let video = this.videoRef.current,
                progBar = $('#mediaControls #progress').getBoundingClientRect(),
                curTime = $('#mediaControls #progress #currentTime').getBoundingClientRect(),
                { duration } = this.state,
                deltaX = e.pageX - curTime.right,
                newTimeWidth = curTime.width + deltaX;

            if (newTimeWidth < 0) {
                video.currentTime = 0;
            } else if (newTimeWidth > progBar.width) {
                video.currentTime = duration;
            } else {
                let newTime = (newTimeWidth / progBar.width) * duration;
                video.currentTime = newTime;
            }
        } else if (clicking === "volume") {
            let video = this.videoRef.current,
                volumeBar = $('#mediaControls #volumeSlider').getBoundingClientRect(),
                volumeLevel = $('#mediaControls #volumeSlider #volume').getBoundingClientRect(),
                { y } = this.state,
                deltaY = y - e.pageY,
                newVolumeHeight = volumeLevel.height + deltaY;

            if (newVolumeHeight < 0) {
                video.volume = 0;
            } else if (newVolumeHeight > volumeBar.height) {
                video.volume = 1;
            } else {
                let newVolume = newVolumeHeight / volumeBar.height;
                video.volume = newVolume;
            }

            this.setState({ y: e.pageY });
        }
    };

    mouseUp = e => {
        if (this.state.playState === "playing" && !this.state.playing) this.playPause();
        this.setState({ clicking: "", playState: "" });
        this.bufferLength();
    };

    toggleExpanded = () => {
        let expanded = !this.state.expanded;
        this.setState({ expanded });
        if (this.props.onExpand) this.props.onExpand(expanded);
    };

    newWindow = () => {
        let source = this.props.children?.[0] ?? this.props.children;
        window.open(source.props.src, "_blank");
    };

    componentDidMount() {
        let video = this.videoRef.current;
        video.addEventListener('loadedmetadata', this.setMetaData);
        video.addEventListener('play', this.play);
        video.addEventListener('pause', this.pause);
        video.addEventListener('timeupdate', this.updateTime);
        video.addEventListener('durationchange', this.updateDuration);
        video.addEventListener('ended', () => this.updatePlayButton(false));
        video.addEventListener('volumechange', this.updateVolume);

        window.addEventListener('mousemove', this.mouseMove);
        window.addEventListener('mouseup', this.mouseUp);
    }

    componentWillUnmount() {
        let video = this.videoRef.current;
        video.removeEventListener('loadedmetadata', this.setMetaData);
        video.removeEventListener('play', this.play);
        video.removeEventListener('pause', this.pause);
        video.removeEventListener('timeupdate', this.updateTime);
        video.removeEventListener('durationchange', this.updateDuration);
        video.removeEventListener('ended', () => this.updatePlayButton(false));
        video.removeEventListener('volumechange', this.updateVolume);

        window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('mouseup', this.mouseUp);
    }

    render() {
        return (
            <div id="videoPlayer" width={this.props.width} height={this.props.height}>
                {this.state.loading && <div id="loading">
                    <div></div>
                    <span>Loading</span>
                </div>}
                <video ref={this.videoRef} autoplay={this.props.autoplay} controls={false} onClick={this.playPause}>
                    {this.props.children}
                </video>
                {this.props.title && <h1 className={this.state.showControls && "show"}>{this.props.title}</h1>}
                <div id="mediaControls" className={this.state.showControls && "show"}>
                    <Icon id="playback" icon={this.state.playing ? faPause : faPlay} onClick={this.playPause} />
                    <div id="progressBar">
                        <span>{this.getTimeStamp(this.state.currentTime)}</span>
                        <div id="progress">
                            <div id="currentTime" style={{ width: `${this.state.progress}%` }}>
                                <button onMouseDown={this.timelineMouseDown}></button>
                            </div>
                        </div>
                        <span>{this.getTimeStamp(this.state.duration)}</span>
                    </div>
                    <div id="volumeControls">
                        <Icon id="volumeButton" icon={this.state.volume === 0
                            ? faVolumeOff
                            : this.state.volume > 0.5 ? faVolumeUp : faVolumeDown} />
                        <div id="volumeSliderContainer">
                            <Icon icon={faVolumeUp} />
                            <div id="volumeSlider">
                                <div id="volume" style={{ height: `${this.state.volume * 100}%`}}>
                                    <button onMouseDown={this.volumeMouseDown}></button>
                                </div>
                            </div>
                            <Icon icon={faVolumeDown} />
                        </div>
                    </div>
                    {this.props.onExpand && <Icon id="expand" icon={this.state.expanded
                        ? faCompress
                        : faExpand} onClick={this.toggleExpanded} />}
                    <Icon id="newWindow" icon={faExternalLinkAlt} onClick={this.newWindow} />
                </div>
            </div>
        );
    }
}

export default Video;
