import React, { Component } from 'react';
import moment from 'moment';
import ChatAPI, { USER_GROUP, USER_GROUP_TEXT, MESSAGE_TYPE, MESSAGE_DATA_TYPE, removeHTML } from '../../LumiAPI/newChatAPI.js';
import handleErrors from '../../LumiAPI/APIs/errors.js';

import Message from './Message';
import TextField from '../../components/TextField';

import { connect } from 'react-redux';
import { openChat, updateChat, refreshChats, refreshMessages, refreshRecents, setDelivered } from '../../actions/chat-actions.js';

import './css/messageWindow.css';

class MessageWindow extends Component {
    state = {
        messages: this.props.messages,
        composed: "",
        singleLine: true,
        newUserGroup: USER_GROUP.Specified
    };

    scrollToLastMessage = () => {
        let messageWindow = document.querySelector("#content.chat #messageWindow #messages");
        if (messageWindow !== null) messageWindow.scrollTop = messageWindow.scrollHeight;
    };

    getTextWidth = text => {
        let root = document.querySelector("#root");
        let textBox = document.createElement("span");
        textBox.id = "calculateTextWidth";
        textBox.innerHTML = text;
        root.appendChild(textBox);
        let totalWidth = textBox.offsetWidth;
        root.removeChild(textBox);
        return Math.ceil(totalWidth);
    };

    onEdit = change => {
        let composeBox = document.querySelector("#compose #message");
        let width = this.getTextWidth(change.value);
        let singleLine = width < (composeBox.offsetWidth - 70);
        this.setState({ [change.name]: change.value, singleLine: singleLine });
    };

    disabled = () => {
        return this.state.composed === "";
    };

    getChatDisplayName = userIds => {
        let list = "", users = this.props.chatUsers;
        userIds.forEach((userId, i) => {
            if (i === 0) {
                list += users[userId]?.displayName;
            } else if (userIds.length === 2) {
                list += " and " + users[userId]?.displayName;
            } else if (i < 3) {
                list += ", " + (i === userIds.length - 1 ? "and " : "") + users[userId]?.displayName;
            }
        });
        if (userIds.length > 3) {
            list += `, and ${userIds.length - 3} more`;
        }
        return list;
    };

    getSimilarChatGroup = () => {
        let newUserGroup = this.state.newUserGroup,
            chats = this.props.chats, similarChat = undefined;

        if (newUserGroup !== USER_GROUP.Specified) {
            Object.keys(chats).some(chatId => {
                let userGroup = chats[chatId].userGroup;
                if (userGroup === newUserGroup) {
                    similarChat = chatId;
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            let newRecipients = this.props.newRecipients?.map(item => item.value.value) ?? [];
            Object.keys(chats).some(chatId => {
                let userIds = chats[chatId].userIds;
                // Compare number of recipients
                if (userIds.length === newRecipients.length) {
                    let sameLists = true;
                    // Compare actual user ids
                    userIds.forEach(userId => {
                        if (!newRecipients.includes(userId)) sameLists = false;
                    });
                    if (sameLists) {
                        similarChat = chatId;
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });
        }

        return similarChat;
    };

    send = e => {
        e && e.preventDefault();
        if (this.state.composed === "") return false;

        let requestIds = { confId: this.props.confId },
            chatId = this.props.chatId;

        if (chatId === "newMessage") {
            // Look for similar chat conversation
            let similarChatId = this.getSimilarChatGroup();
            if (similarChatId === undefined) {
                // Create new chat then add message
                let userGroup = this.state.newUserGroup;
                let recipients = this.props.newRecipients?.map(item => item.value.value) ?? [];
                let displayName = userGroup === USER_GROUP.Specified
                    ? this.getChatDisplayName(recipients)
                    : USER_GROUP_TEXT[userGroup];
                let chatData = {
                    displayName: displayName,
                    userGroup: userGroup,
                    userIds: userGroup === USER_GROUP.Specified ? recipients : [],
                    ownerUserId: this.props.chatOwner.userId
                };
                ChatAPI.chat.new(requestIds.confId, chatData).then(chat => {
                    let chats = this.props.chats;
                    requestIds.chatId = chat.chatId;
                    chats[chat.chatId] = chat;
                    this.props.onRefreshChats(chats);
                    this.props.onOpenChat(chat.chatId);
                    this.sendMessage(requestIds);
                }).catch(handleErrors);
            } else {
                requestIds.chatId = similarChatId;
                this.props.onOpenChat(similarChatId);
                this.sendMessage(requestIds);
            }
        } else {
            requestIds.chatId = this.props.chatId;
            this.sendMessage(requestIds);
        }
    };

    sendMessage = requestIds => {
        let messageData = {
            messageData: `<p>${this.state.composed}</p>`,
            messageType: MESSAGE_TYPE.Text,
            messageDataType: MESSAGE_DATA_TYPE.UTF8,
            created: moment(),
            userId: this.props.chatOwner.userId,
            displayName: this.props.chatOwner.userName
        };

        // Add message to conversation as pending
        let messages = this.props.messages;
        messages.push({ ...messageData, pending: true });
        this.props.onRefreshMessages(messages);
        this.setState({ composed: "", singleLine: true });

        // Update the convo list with newest message
        let recents = this.props.recentMessages,
            lastMessage = messages[messages.length - 1];
        recents[requestIds.chatId] = {
            message: removeHTML(lastMessage.messageData),
            created: lastMessage.created
        };
        this.props.onRefreshRecents(recents);

        // Handle failure to send
        const showFailedDelivery = err => {
            messages.some((message, i) => {
                if (message?.pending ?? false) messages[i].failed = true;
                return messages[i]?.failed ?? false;
            });
            this.props.onRefreshMessages(messages);
            handleErrors(err);
        };

        // Send message data and reload list of messages
        let data = { ...messageData };
        data.created = data.created.format();
        ChatAPI.message.new(requestIds.confId, requestIds.chatId, data).then(message => {
            ChatAPI.messages.get(requestIds.confId, requestIds.chatId).then(messages => {
                for (let i = 0; i < messages.length; i++) {
                    messages[i].delivered = true;
                }
                this.props.onRefreshMessages(messages);
                this.props.onSetDelivered(messages.length - 1);
            }).catch(showFailedDelivery);
        }).catch(showFailedDelivery);
    };

    componentDidMount() {
        this.scrollToLastMessage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.messages !== prevProps.messages) {
            this.setState({ messages: this.props.messages });
            setTimeout(() => this.scrollToLastMessage(), 1000);
        }
    }

    render() {
        return (
            <div id="messageWindow">
                <div id="messages">
                    {this.state.messages.map((message, i) => (
                        <Message key={`message_${i}`} index={i} />
                    ))}
                </div>
                <form id="compose" onSubmit={this.send}>
                    <TextField wide submit={this.send} id="message" type={this.state.singleLine ? "text" : "textarea"} name="composed" placeholder="Type your message..." value={this.state.composed} onEdit={this.onEdit} />
                    <button onMouseDown={e => e.preventDefault()} disabled={this.disabled()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="sendFill" fill="#635F64" opacity="0.3" d="M4 12L2 18.9357C2 19.6879 2.78059 20.2023 3.49818 19.9229L21.3133 12.9872C21.7711 12.809 22 12.4045 22 12H4Z" />
                            <path className="sendOutline" fill="#635F64" fill-rule="evenodd" clip-rule="evenodd" d="M2 18.9357L4 12L2 5.06432C2 4.31209 2.78059 3.79773 3.49818 4.0771L21.3133 11.0128C22.2289 11.3692 22.2289 12.6308 21.3133 12.9872L3.49818 19.9229C2.78059 20.2023 2 19.6879 2 18.9357ZM5.79313 13H11C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11H5.79313L4.53174 6.6257L18.3363 12L4.53174 17.3743L5.79313 13Z" />
                        </svg>
                    </button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    confId: state.currentChatConfId,
    chatId: state.currentChat,
    chats: state.chats,
    chatOwner: state.chatOwner,
    chatUsers: state.chatUsers,
    newRecipients: state.newRecipients,
    messages: state.messages,
    recentMessages: state.recentMessages
});

const mapActionsToProps = {
    onOpenChat: openChat,
    onUpdateChat: updateChat,
    onRefreshChats: refreshChats,
    onRefreshMessages: refreshMessages,
    onRefreshRecents: refreshRecents,
    onSetDelivered: setDelivered
};

export default connect(mapStateToProps, mapActionsToProps)(MessageWindow);
