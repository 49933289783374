import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { getColorScheme, setThemeColor } from '../tools/colorSchemeManager';
import { ENV, PORTAL } from '../tools/envTools';

import Button from '../components/Button';
import Icon from '../components/Icon';

import './css/cordoniqContentWithToolbar.css';

class CordoniqContentWithToolbar extends Component {
    state = {
        path: this.props.path ?? undefined,
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.path !== prevProps.path) {
            this.setState({ path: this.props.path });
        }
    }

    componentWillUnmount() {
    }

    getEnvColor = () => {
        if (PORTAL.isDev()) {
            if (ENV.isDev()) {
                setThemeColor(24, 92, 110);
                return "dev";
            } else if (ENV.isStag()) {
                setThemeColor(44, 95, 63);
                return "stag";
            } else {
                setThemeColor(42, 11, 53);
                return "prod";
            }
        } else {
            let mode = getColorScheme();
            if (mode === "light") {
                setThemeColor(245, 244, 245);
            } else {
                setThemeColor(25, 20, 25);
            }
            return undefined;
        }
    };
    
    
    open = (path, domain) => {
        const apiDomain = ENV.getApiDomain();
        if (path === "") return;
        domain = domain ?? "www";
        let url = `https://${domain}${apiDomain}.cordoniq.com/${path}/`;
        window.open(url, "_blank");
    };

    createButtons = (button) => {
        return (
            <Button key={button.name} onClick={() => this.goTo(button.path)}>{button.name}</Button> 
        );
    }

    goHome = () => {
        window.location.assign('https://www.cordoniq.com/');
    }

    render() {
        let envColor = this.getEnvColor();
        
        return (
            <div id="cordoniqPortal">
                <header className={envColor}>
                    <div className="header left">
                        <Icon id="cordoniqLogo" icon="logoFull" mode="dark" onClick={() => this.goHome()} />
                    </div>
                    <div className="header extra">
                        {this.props.headerButtons}
                    </div>
                    {/* <div className="header right">
                        <Icon id="settings" alt="Settings" mode="dark" icon="tvSettings" bg={colors[this.state.color]} />
                    </div> */}
                </header>
                <main>
                    <div id="content" className="cordoniqContent">
                        {this.props.children}
                    </div>
                </main>
                <footer>
                    <div className='footer left'>
                        <Button onClick={() => this.open("contact")}>Contact us</Button>
                        <Button onClick={() => this.open("privacy-policy")}>Privacy Policy</Button>                    
                        <Button onClick={() => this.open("terms-of-use")}>Terms</Button>
                    </div>
                    <div className='footer right'>
                        {this.props.footerButtons}
                    </div>
                </footer>
            </div>
        );
    }
}

export default withNav(CordoniqContentWithToolbar);
