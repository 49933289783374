import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { privilegesList } from '../LumiAPI/APIs/core/confDefaults';
import { getConferences } from '../LumiAPI/APIs/core/conference.js';
import UserAPI from '../LumiAPI/APIs/core/user.js';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import Dialog, { Container } from '../components/Dialog';
import Icon from '../components/Icon';
import SelectBox from '../components/SelectBox';
import ShareDialog from '../components/ShareDialog';
import TextField from '../components/TextField';
import List from '../partials/List';
import ContentWithToolbar from '../templates/ContentWithToolbar';

import Modal from '../tools/Modal';

import { connect } from 'react-redux';

class Users extends Component {
    shareRef = React.createRef();

    state = {
        loaded: false,
        confId: { index: 0, option: { value: "" } },
        active: false,
        conferences: [],
        users: {},
        changes: {},
        selected: []
    };

    getConferences = () => {
        this.setState({ loaded: false, selected: [], conferences: [], confId: { index: 0, option: { value: "" } }, users: {} });
        getConferences().then(conferences => {
            conferences.sort((a, b) => {
                if (a.active === b.active) {
                    if (a.displayName === b.displayName) return 0;
                    return a.displayName < b.displayName ? -1 : 1;
                }
                return a.active && !b.active ? -1 : 1;
            });
            this.setState({ conferences });
            this.selectConference(conferences[0].confId);
        }).catch(handleErrors);
    };

    getUsers = (active, confId) => {
        confId = confId ?? this.state.confId.option.value;

        if (active === undefined) {
            active = this.state.active;
        } else {
            this.setState({ active });
            let selected = this.state.selected;

            if (active) {
                if (selected.length === 1 && !selected[0].active) {
                    selected = [];
                }
            } else {
                if (selected.length > 1) selected = [];
            }
            this.setState({ selected });
        }

        let filters = [ "all_video", "all_audio" ];
        if (active) filters.push("active");

        this.setState({ loaded: false, users: {} });
        UserAPI.users.get(confId, filters).then(users => {
            let userObj = {};
            let conference = null;
            this.state.conferences.some((conf, i) => {
                if (conf.confId === confId) {
                    conference = conf;
                    return true;
                } else {
                    return false;
                }
            });
            users.forEach(user => {
                user.id = user.userId;
                user.icon = { name: "userAccount" };
                user.title = user.displayName;
                user.action = {
                    label: <Icon icon="share" highlight />,
                    props: {
                        onClick: e => {
                            e.stopPropagation();
                            this.shareRef.current.open("attendee", conference, user);
                        }
                    }
                }
                user.buttons = [
                    {
                        icon: "annotate", props: {
                            onClick: e => {
                                e.stopPropagation();
                                this.openEdit(user.userId);
                            }
                        }
                    }
                ]
                userObj[user.userId] = user;
            });
			this.setState({ users: userObj, loaded: true });
		}).catch(handleErrors);
    };

    selectConference = (confId, active) => {
        confId = confId ?? this.state.confId.option.value;
        if (this.state.confId.option.value !== confId) this.setState({ selected: [] });

        let index = this.state.conferences.findIndex(conference => conference.confId === confId);
        if (index !== -1) {
            this.setState({ confId: { index: index + 1, option: { value: confId } } });
        }

        this.getUsers(active, confId);
    };

    openNew = () => this.setState({
        showNew: true,
        changes: {
            displayName: "",
            role: 1
        }
    });

    createUser = () => {
        let confId = this.state.confId.option.value;
        this.setState({ showNew: false, loaded: false });
        UserAPI.user.new(confId, this.state.changes).then(user => {
            this.getUsers();
        }).catch(handleErrors);
    };

    openEdit = id => {
        let user = this.state.users[id];
        this.setState({ showEdit: true, changes: {...user} });
    };

    saveName = () => {
        let { users, changes } = this.state,
            confId = this.state.confId.option.value;
        this.setState({ saving: true });
        UserAPI.user.update(confId, changes.userId, {
            displayName: changes.displayName
        }).then(user => {
            users[changes.userId].displayName = changes.displayName;
            this.setState({ users, saving: false });
        }).catch(handleErrors);
    };

    changeRole = role => {
        let { users, changes } = this.state,
            confId = this.state.confId.option.value,
            userId = changes.userId;
        this.setState({ changingRole: true });
        UserAPI.user.changeRole(confId, userId, role).then(user => {
            users[userId].privileges = { ...user.privileges };
            changes.privileges = { ...user.privileges };
            this.setState({ users, changes, changingRole: false });
        });
    };

    changePrivilege = (e, privilege) => {
        let checkbox = e.target;
        checkbox.disabled = true;

        let confId = this.state.confId.option.value,
            userId = this.state.changes.userId,
            user = this.state.users[userId],
            privileges = user.privileges;

        let change = { privileges: { [privilege]: !privileges[privilege] } };
        UserAPI.user.update(confId, userId, change).then(user => {
            checkbox.disabled = false;
            let users = this.state.users;
            users[userId].privileges[privilege] = change.privileges[privilege];
            this.setState({ users });
        }).catch(handleErrors);
    };

    toggleVideoState = () => {
        let { changes, users } = this.state,
            confId = this.state.confId.option.value;
        let newState = changes.videoState ? 0 : 1;
        UserAPI.user.update(confId, changes.userId, { videoState: newState }).then(user => {
            users[changes.userId].videoState = newState;
            changes.videoState = newState;
            this.setState({ users, changes });
        }).catch(handleErrors);
    };

    toggleAudioState = () => {
        let { changes, users } = this.state,
            confId = this.state.confId.option.value;
        let newState = changes.audioState ? 0 : 1;
        UserAPI.user.update(confId, changes.userId, { audioState: newState }).then(user => {
            users[changes.userId].audioState = newState;
            changes.audioState = newState;
            this.setState({ users, changes });
        }).catch(handleErrors);
    };

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection;
        this.setState({ changes });
    };

    selectedUserList = () => {
        let { selected, users } = this.state;
        if (Object.keys(users).length === 0) return "";
        let selectedList = "";
        selected.forEach((userId, i) => {
            if (i === 0) {
                selectedList += users[userId].displayName;
            } else if (selected.length === 2) {
                selectedList += " and " + users[userId].displayName;
            } else if (i < 4) {
                selectedList += ", " + users[userId].displayName;
            }
        });
        if (selected.length > 3) {
            selectedList += `, and ${selected.length - 3} more`;
        }
        return selectedList;
    };

    openMove = () => {
        let changes = {};
        changes.mode = "new";
        changes.moveConf = { index: 0, option: { value: "" } };
        changes.moveRole = { index: 1, option: { value: "attendeePassword" } };
        changes.newDisplayName = "";
        this.setState({ showMove: true, changes });
    };

    moveUsers = () => {
        let { confId, changes } = this.state,
            curConfId = confId.option.value,
            userIds = this.state.selected,
            data = {};

        if (changes.mode === "new") {
            data.displayName = changes.newDisplayName;
            this.state.conferences.some(conference => {
                if (conference.confId === curConfId) {
                    data.backgroundColor = conference.backgroundColor;
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            let selectedConf = changes.moveConf.option.value,
                selectedRole = changes.moveRole.option.value;
            data.id = selectedConf.confId;
            data.password = selectedConf[selectedRole];
        }

        UserAPI.users.move(curConfId, { userIds }, data).then(result => {
            this.setState({ selected: [] });
            this.getConferences();
        }).catch(handleErrors);
        this.setState({ showMove: false, loaded: false });
    };

    expelUsers = () => Modal(<>
        <h1>Expel Users?</h1>
        <p>Are you sure you want to expel {this.selectedUserList()} from this conference?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let confId = this.state.confId.option.value,
                userIds = this.state.selected;
            UserAPI.users.expel(confId, { userIds }).then(result => {
                this.setState({ selected: [] });
                this.getUsers(null, false);
            }).catch(handleErrors);
        }}}
    ], { alert: true });

    deleteUsers = () => Modal(<>
        <h1>Delete User/s</h1>
        <p>Are you sure you want to delete user/s from this conference?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let { selected } = this.state,
                confId = this.state.confId.option.value;
            this.setState({ loaded: false, showEdit: false });
            selected.forEach((userId, i) => {
                UserAPI.user.delete(confId, userId).then(result => {
                    if (i === selected.length - 1) this.getUsers();
                }).catch(handleErrors);
            });
        }}}
    ], { alert: true });

    componentDidMount() {
        document.title = "Users | Cordoniq";
        this.getConferences();
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.getConferences();
        }
    }

    render() {
        let changes = this.state.changes,
            users = this.state.users,
            actions = [];
        if (this.state.active) {
            actions.push({ label: "Move", props: { onClick: this.openMove, disabled: this.state.selected.length === 0 } });
            actions.push({ label: "Expel", props: { onClick: this.expelUsers, delete: true, disabled: this.state.selected.length === 0 } });
        }
        actions.push({ label: "Delete", props: { onClick: this.deleteUsers, delete: true, disabled: this.state.selected.length === 0 } });

        return (
            <ContentWithToolbar id="users" tools={[
                <button onClick={this.openNew}>Create New</button>,
                <SelectBox name="confId" selected={this.state.confId.index} options={[
                    { name: "Select a Conference...", dontSelect: true },
                    ...this.state.conferences.map(conference => (
                        { name: conference.displayName, value: conference.confId }
                    ))
                ]} onSelect={selection => this.selectConference(selection.option.value)} />
            ]}>
                <ShareDialog ref={this.shareRef} app={this.props.app} />
                {this.state.showNew && <Dialog className="newUser" show={this.state.showNew} title="Create New" footer={[
                    <Button outline onClick={() => this.setState({ showNew: false })}>Cancel</Button>,
                    <Button onClick={this.createUser}>Create</Button>
                ]}>
                    <Container label="User Settings">
                        <TextField name="displayName" label="Name" placeholder="User's Name" value={changes.displayName} onEdit={this.onEdit} />
                        <SelectBox name="role" label="Role" selected={changes.role} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Attendee", value: 1 }, { name: "Presenter", value: 2 }, { name: "Host", value: 3 }
                        ]} onSelect={selection => {
                            changes.role = selection.option.value;
                            this.setState({ changes });
                        }} />
                    </Container>
                </Dialog>}
                {this.state.showEdit && <Dialog className="editUser" show={this.state.showEdit} title="Edit User" footer={[
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Close</Button>
                ]}>
                    <Container label="User Settings">
                        <TextField name="displayName" label="Name" placeholder="User's Name" value={changes?.displayName ?? ""} onEdit={this.onEdit} />
                        <Button onClick={this.saveName} disabled={(changes?.displayName === users?.[changes.userId]?.displayName ?? true) || this.state.saving}>
                            {this.state.saving
                                ? "Processing..."
                                : (changes?.displayName === users?.[changes.userId]?.displayName ?? true) ? "Saved" : "Save"
                            }
                        </Button>
                    </Container>
                    {changes.active && <>
                    <Container label="Media Controls">
                        <div className="privilege">
                            <Checkbox id="videoState" checked={parseInt(changes?.videoState) === 1 ?? false}
                                onChange={this.toggleVideoState} />
                            <Icon icon={parseInt(changes.videoState) === 1 ? "camera" : "cameraOff"} />
                            <span>Video {parseInt(changes.videoState) === 1 ? "On" : "Off"}</span>
                        </div>
                        <div className="privilege">
                            <Checkbox id="audioState" checked={parseInt(changes?.audioState) === 1 ?? false}
                                onChange={this.toggleAudioState} />
                            <Icon icon={parseInt(changes.audioState) === 1 ? "mic" : "micDisabled"} />
                            <span>Audio {parseInt(changes.audioState) === 1 ? "On" : "Off"}</span>
                        </div>
                    </Container>
                    </>}
                    <Container label="Conference Settings">
                        <SelectBox name="role" label="Role" selected={changes?.role ?? 1} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Attendee", value: 1 }, { name: "Presenter", value: 2 }, { name: "Host", value: 3 }
                        ]} onSelect={selection => this.changeRole(selection.option.value)} />
                        <label>Privileges</label>
                        <div className="privilegesContainer">
                        {privilegesList.map((privilege, i) => (
                            <div className="privilege">
                                <Checkbox id={privilege.key+i} checked={changes.privileges[privilege.key]}
                                    onChange={e => this.changePrivilege(e, privilege.key)}
                                    disabled={this.state.changingRole} />
                                <Icon icon={privilege.icon} />
                                <span>{privilege.name}</span>
                            </div>
                        ))}
                        </div>
                    </Container>
                </Dialog>}
                {this.state.showMove && <Dialog show={this.state.showMove} title="Move Users" footer={[
                    <Button outline onClick={() => this.setState({ showMove: false })}>Cancel</Button>,
                    <Button onClick={this.moveUsers} disabled={(changes.mode === "new" && changes.newDisplayName === "") || (changes.mode !== "new" && changes.moveConf.index === 0)}>Move</Button>
                ]}>
                    <p>You are about to move {this.selectedUserList()} to another conference.</p>
                    <Container tabs={[
                        <button className={changes.mode === "new" && "selected"} onClick={() => {
                            let { changes } = this.state;
                            changes.mode = "new";
                            this.setState({ changes });
                        }}>New</button>,
                        <button className={changes.mode !== "new" && "selected"} onClick={() => {
                            let { changes } = this.state;
                            changes.mode = "exisiting";
                            this.setState({ changes });
                        }}>Existing</button>
                    ]}>
                        {changes.mode === "new"
                            ? <>
                            <p>Move users to a new conference.</p>
                            <TextField name="newDisplayName" label="Choose Name for Conference" placeholder="Conference name..." value={changes.newDisplayName} onEdit={this.onEdit} />
                            </>
                            : <>
                            <p>Move users to an existing conference.</p>
                            <SelectBox name="moveConf" label="Select Existing Conference" selected={changes.moveConf.index} options={[
                                { name: "Pick One...", dontSelect: true },
                                ...this.state.conferences.map(conference => (
                                    { name: conference.displayName, value: conference }
                                ))
                            ]} onSelect={this.onSelect} />
                            <SelectBox name="moveRole" label="Select Role" selected={changes.moveRole.index} options={[
                                { name: "Pick One...", dontSelect: true },
                                { name: "Attendee", value: "attendeePassword" },
                                { name: "Presenter", value: "presenterPassword" },
                                { name: "Host", value: "hostPassword" }
                            ]} onSelect={this.onSelect} />
                            </>
                        }
                    </Container>
                </Dialog>}
                <h1>Users</h1>
                <List id="users" loaded={this.state.loaded} items={this.state.users} actions={actions} filters={[
                    { name: "Search", value: "search", match: "displayName", placeholder: "Search Users..." },
                    { name: "Active", value: "active", default: 1, selected: this.state.active ? 2 : 1, options: [
                        { name: "Pick One...", dontSelect: true },
                        { name: "All Users", action: () => this.selectConference(null, false) },
                        { name: "Online Users", action: () => this.selectConference(null, true) }
                    ]}
                ]} sort={[
                    { options: [
                        { name: "Name", value: "displayName" },
                        { name: "User ID", value: "userId" },
                        { name: "Role", value: "role" },
                        { name: "Active", value: "active" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    app: state.apps[state.currentApp]
});

export default connect(mapStateToProps)(withNav(Users));
