import { api, allDetails } from '../config.js';
import { getPrivileges } from './conference.js';
import { Token } from '../auth.js';

export const USER_STATE = { CREATED: 1, DELETED: 2 };

export const USER_KIND = {
    1: "Guest",
    2: "Attendee",
    3: "Owner"
};

export const newUser = (confId, settings) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).post(`/conference/${confId}/user`, settings).then(response => {
            let user = response.data;
            if (settings?.role !== undefined && settings?.privileges === undefined) {
                getPrivileges(confId, settings.role).then(privileges => {
                    updateUser(confId, user.userId, { privileges })
                        .then(resolve).catch(reject);
                }).catch(reject);
            }
        }).catch(reject);
    });
};

export const getUser = (confId, userId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}/user/${userId}`, allDetails)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateUser = (confId, userId, settings) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/conference/${confId}/user/${userId}`, settings)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const changeUserRole = (confId, userId, role) => {
    return new Promise((resolve, reject) => {
        getPrivileges(confId, role).then(privileges => {
            updateUser(confId, userId, { role, privileges })
                .then(user => {
                    user.role = role;
                    user.privileges = privileges;
                    resolve(user);
                }).catch(reject);
        }).catch(reject);
    });
};

export const deleteUser = (confId, userId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/conference/${confId}/user/${userId}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const moveUser = (confId, userId, newConfDetails) => {
    return new Promise((resolve, reject) => {
        let data = {}, options = { params: {} };

        if (newConfDetails.id) {
            options.params.id = newConfDetails.id;
            if (newConfDetails.password) options.params.password = newConfDetails.password;
        } else {
            options.params.new = true;
            data = { ...newConfDetails };
        }

        api(Token.get()).put(`/conference/${confId}/user/${userId}`, data, options)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const expelUser = (confId, userId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/conference/${confId}/user/${userId}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const getUsers = (confId, filters) => {
    return new Promise((resolve, reject) => {
        let options = { params: { details: "all" } };
        if (filters) {
            filters.forEach(filter => {
                options.params[filter] = true;
            });
        }
        api(Token.get()).get(`/conference/${confId}/users`, options)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

export const getTotalUsers = confId => {
    return new Promise((resolve, reject) => {
        let options = { params: { active: true } };
        api(Token.get()).get(`/conference/${confId}/users`, options)
            .then(response => resolve(response.data.items.length))
            .catch(reject);
    });
};

export const updateUsers = (confId, settings, userGroup) => {
    return new Promise((resolve, reject) => {
        let options = userGroup ? { params: { apply_to: userGroup } } : {};
        api(Token.get()).put(`/conference/${confId}/users`, settings, options)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const changeUsersRole = (confId, { userIds, userGroup }, role) => {
    return new Promise((resolve, reject) => {
        let settings = { userIds, role };
        getPrivileges(confId, role).then(privileges => {
            settings.privileges = privileges;
            updateUsers(confId, settings, userGroup)
                .then(resolve).catch(reject);
        }).catch(reject);
    });
};

export const moveUsers = (confId, { userIds, userGroup }, newConfDetails) => {
    return new Promise((resolve, reject) => {
        let data = userIds ? { userIds } : {},
            options = userGroup ? { params: { apply_to: userGroup } } : { params: {} };

        if (newConfDetails.id) {
            options.params.id = newConfDetails.id;
            if (newConfDetails.password) options.params.password = newConfDetails.password;
        } else {
            options.params.new = true;
            data = { ...data, ...newConfDetails };
        }

        api(Token.get()).put(`/conference/${confId}/users/move`, data, options)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const expelUsers = (confId, { userIds, userGroup }) => {
    return new Promise((resolve, reject) => {
        let data = userIds ? { userIds } : {},
            options = userGroup ? { params: { apply_to: userGroup } } : {};
        api(Token.get()).put(`/conference/${confId}/users/expel`, data, options)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

const UserAPI = {
    user: {
        new: newUser,
        get: getUser,
        update: updateUser,
        changeRole: changeUserRole,
        delete: deleteUser,
        move: moveUser,
        expel: expelUser
    },
    users: {
        get: getUsers,
        getTotal: getTotalUsers,
        update: updateUsers,
        changeRole: changeUsersRole,
        move: moveUsers,
        expel: expelUsers
    }
};

export default UserAPI;
