import * as fileDownload from 'js-file-download';
import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors from '../LumiAPI/APIs/errors.js';
import IntegrationAPI from '../LumiAPI/APIs/internal/integration.js';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import CopyField from '../components/CopyField';
import Dialog, { Container } from '../components/Dialog';
import SelectBox from '../components/SelectBox';
import TextField from '../components/TextField';
import List from '../partials/List';

import Modal from '../tools/Modal';
import Notification from '../tools/Notification';

import { connect } from 'react-redux';

import blackboardLogo from '../images/integrations/blackboardLogo.png';
import canvasLogo from '../images/integrations/canvasLogo.png';
import moodleLogo from '../images/integrations/moodleLogo.png';

import "./css/integrations.css";

// const INTEG_STATE = { ENABLED: 1, DISABLED: 2, DELETED: 3 };
const INTEG = { CANVAS: 1, MOODLE: 2, BLACKBOARD: 3 };
const INTEG_DESC = [ "", "Canvas", "Moodle", "Blackboard" ];
const INTEG_LOGO = { 1: canvasLogo, 2: moodleLogo, 3: blackboardLogo };
const INTEG_SLUG = { 1: "canvas", 2: "moodle", 3: "blackboard" };

class Integrations extends Component {
    state = {
        loaded: false,
        integrations: {},
        selected: [],
        changes: {}
    };

    getIntegrations = () => {
        this.setState({ loaded: false });
        IntegrationAPI.integrations.get(this.props.app?.clientId ?? "unknown").then(integrations => {
            let enabledInts = integrations.filter(integration => integration.state === null || integration.state === 1), list = {};
            enabledInts.forEach((integration, i) => {
                let intId = integration.integrationId;
                integration.id = intId;
                integration.image = {
                    src: INTEG_LOGO[integration.platform],
                    props: {
                        style: {
                            border: "none",
                            backgroundImage: `url(${INTEG_LOGO[integration.platform]})`
                        }
                    }
                };
                integration.title = integration.description;
                integration.subtitle = intId;
                integration.action = {
                    label: "Setup",
                    props: {
                        onClick: e => {
                            e.stopPropagation();
                            this.openSetup(intId);
                        }
                    }
                };
                integration.buttons = [
                    { icon: "annotate", props: {
                        onClick: e => {
                            e.stopPropagation();
                            this.openEdit(intId);
                        }
                    }}
                ];
                list[intId] = integration;
                IntegrationAPI.integration.getConfig(intId).then(config => {
                    list[intId].config = config;
                    let hostname = window.location.origin.replace("developers", "lti").replace("3000", "4000");
                    list[intId].configUrl = `${hostname}/lti/integration/${intId}/config`;
                    // Removed chat redirect for now until feature is ready
                    let slug = INTEG_SLUG[integration.platform];
                    list[intId].redirectUris = `${hostname}/lti/${slug}/${intId}/join\n${hostname}/lti/${slug}/${intId}/select_conference`;//\n${hostname}/lti/${intId}/chat`;
                    if (i === enabledInts.length - 1) {
                        this.setState({ integrations: list, selected: [], loaded: true });
                    }
                }).catch(handleErrors);
            });
            if (integrations.length === 0) {
                this.setState({ integrations: {}, selected: [], loaded: true });
            }
        }).catch(handleErrors);
    };

    openNew = () => this.setState({
        showNew: true,
        changes: {
            platform: INTEG.CANVAS,
            description: "",
            latest: true,
            isHosted: { index: 2, option: { value: false } },
            authUrl: "",
            lms_jwk_public: ""
        }
    });

    selectPlatform = value => {
        let { changes } = this.state;
        let isHosted = value === INTEG.CANVAS || this.state.changes.latest
            ? { index: 2, option: { value: false } }
            : { index: 1, option: { value: true } };
        changes.platform = value;
        changes.isHosted = isHosted;
        this.setState({ changes });
    };

    newDisabled = () => {
        let { changes } = this.state;
        if (changes.description === "") return true;
        if (changes.isHosted.option.value && changes.authUrl === "") return true;
        if (changes.platform === INTEG.CANVAS || !changes.latest) {
            if (changes.lms_jwk_public === "") return true;
        }
        if (changes.platform === INTEG.BLACKBOARD) return true;
        return false;
    };

    add = () => {
        let { changes } = this.state,
            isHosted = changes.isHosted.option.value,
            data = {
                platform: changes.platform,
                description: changes.description,
                client_id: this.props.app.clientId,
                client_secret: this.props.app.clientSecret,
                lms_jwk_public: this.state.lms_jwk_public
            };
        data.authorization_url = data.platform === INTEG.CANVAS && !isHosted
            ? "https://canvas.instructure.com/api/lti/authorize_redirect"
            : changes.authUrl;
        IntegrationAPI.integration.new(data).then(integration => {
            this.setState({ showNew: false });
            this.getIntegrations();
        }).catch(handleErrors);
    };

    openEdit = intId => this.setState({
        showEdit: true,
        changes: { ...this.state.integrations[intId] }
    });

    editDisabled = () => {
        let { changes } = this.state,
            integration = this.state.integrations[changes.integrationId],
            disabled = true;

        Object.keys(changes).some(prop => {
            if (changes[prop] === integration?.[prop] ?? false) {
                return false;
            } else if (changes[prop] !== "") {
                disabled = false;
                return true;
            } else {
                return false;
            }
        });

        return disabled;
    };

    update = () => {
        let { changes, integrations } = this.state,
            integration = integrations[changes.integrationId],
            data = {};
        Object.keys(changes).forEach(prop => {
            if (changes[prop] !== integration[prop]) {
                data[prop] = changes[prop];
            }
        });
        IntegrationAPI.integration.update(changes.integrationId, data).then(integration => {
            this.setState({ showEdit: false });
            this.getIntegrations();
        }).catch(handleErrors);
    };

    openSetup = intId => {
        let integration = this.state.integrations[intId];
        let slug = INTEG_SLUG[integration.platform];
        let hostname = window.location.origin.replace("developers", "lti").replace("3000", "4000");
        integration.toolUrl = `${hostname}/lti/${slug}/${intId}`;
        let dialog = "";

        if (integration.platform === INTEG.CANVAS) {
            dialog = "showCanvasSetup";
        }
        if (integration.platform === INTEG.MOODLE) {
            dialog = "showMoodleSetup";
            integration.setupFeature = { index: 1, option: { value: "conferences" } };
        }
        if (integration.platform === INTEG.BLACKBOARD) {
            dialog = "showBlackboardSetup";
        }

        this.setState({
            changes: { ...integration },
            [dialog]: true
        });
    };

    downloadConfig = () => {
        let { changes } = this.state;
        fileDownload(JSON.stringify(changes.config), `CordoniqIntegration_${changes.integrationId}.json`);
    };

    deleteSelected = () => Modal(<>
        <h1>Delete?</h1>
        <p>Are you sure you want to delete the selected integration/s?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let { selected } = this.state;
            selected.forEach((id, i) => IntegrationAPI.integration.delete(id).then(result => {
                if (i === selected.length - 1) this.getIntegrations();
            }).catch(err => {
                new Notification({ id: "deleteFailed", message: `Unable to delete ${id}`, type: "error" });
            }));
        }}}
    ], { alert: true });

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection;
        this.setState({ changes });
    };

    componentDidMount() {
        document.title = "LTI Integrations | Cordoniq";
        this.checkPermissions(this.props.curRole);

        this.getIntegrations();

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.curRole !== prevProps.curRole) {
            this.checkPermissions(this.props.curRole);
        }

        if (this.props.app !== prevProps.app) {
            this.getIntegrations();
        }
    }

    render() {
        let changes = this.state.changes,
            confDetails = changes.setupFeature?.option.value === "conferences" ?? true,
            chatDetails = changes.setupFeature?.option.value === "chat" ?? false;
        return (
            <ContentWithToolbar id="integrations" tools={[
                <button onClick={this.openNew}>Create New</button>
            ]}>
                {this.state.showNew && <Dialog show={this.state.showNew} title="New LTI Integration" footer={[
                    <Button outline onClick={() => this.setState({ showNew: false })}>Cancel</Button>,
                    <Button onClick={this.add} disabled={this.newDisabled()}>Create</Button>
                ]}>
                    <Container>
                        <p>To add a new LTI integration, please select a platform and fill out the questions below.</p>
                        <div id="integrationSelect">
                            <label>Select a Platform</label>
                            {Object.keys(INTEG).map(platform => (
                                <>
                                <input id={platform} type="radio" name="platform" checked={changes.platform === INTEG[platform]} value={INTEG[platform]} />
                                <label htmlFor={platform} onClick={() => this.selectPlatform(INTEG[platform])}>
                                    <img src={INTEG_LOGO[INTEG[platform]]} alt="integration logo" />{INTEG_DESC[INTEG[platform]]}
                                </label>
                                </>
                            ))}
                        </div>
                        {changes.platform === INTEG.MOODLE && <div className="latest">
                            <Checkbox id="latest" checked={changes.latest} onClick={() => {
                                let { changes } = this.state;
                                let isHosted = !changes.latest
                                    ? { index: 2, option: { value: false } }
                                    : { index: 1, option: { value: true } };
                                changes.latest = !changes.latest;
                                changes.isHosted = isHosted;
                                this.setState({ changes });
                            }} />
                            <label>Do you have the latest version installed? <br />(3.10 or higher)</label>
                        </div>}
                        {changes.platform !== INTEG.BLACKBOARD && <TextField name="description" label="Enter a short name for your integration"
                            placeholder="Ex: John's Courses or English Dept"
                            value={changes.description}
                            onEdit={this.onEdit} />}
                        {changes.platform === INTEG.CANVAS &&
                            <SelectBox name="isHosted" label="Do you host Canvas on your own server/s?"
                                selected={changes.isHosted.index}
                                options={[
                                    { name: "", dontSelect: true },
                                    { name: "Yes", value: true }, { name: "No", value: false }
                                ]} onSelect={this.onSelect} />
                        }
                        {changes.isHosted.option.value && <>
                            <TextField type="url" name="authUrl" label="Enter the authorization redirect url"
                                placeholder="OIDC Authorization Redirect Url"
                                value={changes.authUrl}
                                onEdit={this.onEdit} />
                        </>}
                    </Container>
                    {changes.platform === INTEG.BLACKBOARD &&
                        <Container label="How to add Blackboard Integration">
                            <p>To add an integration with Blackboard, copy the Client ID below.</p>
                            <p>Then, under System Admin tools, look for the "LTI Tool Providers" option under the Integrations section.</p>
                            <p>Click on the "Register LTI 1.3/Advantage Tool" tab, paste in the Client ID, then click Submit.</p>
                            <CopyField id="applicationId" label="Client ID" value="728a8a47-8be8-4bed-b55c-0f10c62291c7" />
                            <p>NOTE: By default, all users will join as an attendee. To allow instructors to enter as a presenter, you need to enable the option to include "Role in Course" under "User Fields to Send" during setup. This will allow them to enter as a presenter or view as a student.</p>
                        </Container>
                    }
                    {(changes.platform === INTEG.CANVAS || !changes.latest) &&
                        <Container label="Authorization Key Sets">
                            <p>Enter the public keys associated with your LMS.</p>
                            <p>Keys should be formatted as JSON Web Keys (JWK) and provided as either JSON or via URL.</p>
                            <TextField type="textarea" name="lms_jwk_public" label="Enter public JSON Web Key/s for the LMS"
                                placeholder="LMS Public JWKs or URL"
                                value={changes.lms_jwk_public}
                                onEdit={this.onEdit} />
                        </Container>
                    }
                    <Container label="Need More Help?">
                        <p>For more help on how to configure and set up Cordoniq integrations, check out the FAQs on our documentation page.</p>
                        <Button outline onClick={() => this.goTo("/Docs/docs/resources")}>Integrations FAQs</Button>
                    </Container>
                </Dialog>}
                {this.state.showEdit && <Dialog show={this.state.showEdit} title="Edit Integration" footer={[
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Cancel</Button>,
                    <Button onClick={this.update} disabled={this.editDisabled()}>Save</Button>
                ]}>
                    <Container>
                    {/*<Toggle name="state" className="integrationState" label="Integration Enabled" enabled={changes.state !== INTEG_STATE.DISABLED} click={value => {
                        let { changes } = this.state;
                        changes.state = changes.state === INTEG_STATE.ENABLED
                            ? INTEG_STATE.DISABLED
                            : INTEG_STATE.ENABLED;
                        this.setState({ changes });
                    }} />*/}
                    <CopyField id="intId" label="Integration ID" value={changes.integrationId} />
                    {changes.deploymentId && <TextField name="deploymentId" label="Deployment ID" value={changes.deploymentId} disabled />}
                    <TextField name="description" label="Integration Name" placeholder="Enter a description..."
                        value={changes.description} onEdit={this.onEdit} />
                    <TextField type="url" name="authorization_url" label="Authorization Redirect Url"
                        placeholder="Enter a redirect url..." value={changes.authorization_url} onEdit={this.onEdit}
                        disabled={changes.platform === INTEG.BLACKBOARD} />
                    <TextField type="textarea" name="lms_jwk_public" label="LMS Public JWKs"
                        placeholder="Enter the lms public JWKs or URL..."
                        value={changes.lms_jwk_public} onEdit={this.onEdit}
                        disabled={changes.platform === INTEG.BLACKBOARD} />
                    </Container>
                </Dialog>}
                {this.state.showCanvasSetup && <Dialog show={this.state.showCanvasSetup} title="Integration Setup" footer={[
                    <Button outline onClick={this.downloadConfig}>Download</Button>,
                    <Button onClick={() => this.setState({ showCanvasSetup: false })}>Close</Button>
                ]}>
                    <Container label="Configuration Instructions">
                        <p>To set up in Canvas, go to admin settings, select developer keys, and add a new LTI Key.</p>
                        <p>Add a name for your key, then paste in the Redirect URIs below:</p>
                        <CopyField id="redirectUris" label="Redirect URIs" value={changes.redirectUris} />
                        <p>Select "JSON URL" and paste in the url below.</p>
                        <CopyField id="canvas_configfile" label="Canvas Config Url" value={changes.configUrl} />
                        <p>Or select "Paste JSON" to paste in the configuration below.</p>
                        <TextField type="textarea" name="config" label="Canvas Configuration"
                            value={JSON.stringify(changes.config)} disabled />
                    </Container>
                    <Container label="Need More Help?">
                        <p>For more help on how to configure and set up Cordoniq integrations, check out the FAQs on our documentation page.</p>
                        <Button outline onClick={() => this.goTo("/Docs/docs/resources")}>Integrations FAQs</Button>
                    </Container>
                </Dialog>}
                {this.state.showMoodleSetup && <Dialog show={this.state.showMoodleSetup} title="Integration Setup" footer={[
                    <Button onClick={() => this.setState({ showMoodleSetup: false })}>Close</Button>
                ]}>
                    <Container label="Configuration Instructions - Moodle 3.10">
                        <p>Do you have Moodle 3.10 installed? Go to Manage Tools then paste in this link in the "Add Tool" dialog and click "Add LTI Advantage".</p>
                        <CopyField id="registrationUrl" label="Tool URL" value={changes.toolUrl+"/register"} />
                    </Container>
                    <Container label="Configuration Instructions - Moodle 3.9">
                        <p>Don't have Moodle 3.10 yet? Use fields below to configure Moodle</p>
                        {/*<SelectBox name="setupFeature" label="Select a feature to configure..."
                            selected={changes.setupFeature.index} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Conferences", value: "conferences" },
                            { name: "Chat", value: "chat" }
                        ]} onSelect={this.onSelect} />*/}
                        <CopyField id="tool_name" label="Tool Name" value={confDetails ? "Lumi Conferences" : "Lumi Chat"} />
                        <CopyField id="tool_url" label="Tool URL" value={changes.toolUrl + (chatDetails ? "/chat" : "")} />
                        <CopyField id="tool_description" label="Tool Description" value={confDetails ? "Search for and add a conference" : "Cordoniq Chat"} />
                        <p>Select LTI 1.3 for the "LTI Version" then make sure "Public Key Type" is set to "{changes.config?.public_pem ?? false ? "RSA Key" : "Public Keyset"}"</p>
                        <CopyField id="publicKey" label={changes.config?.public_pem ?? false ? "RSA Key" : "Public Keyset"} value={changes.config?.public_pem ?? changes.config?.public_jwk_url ?? ""} />
                        <CopyField id="loginUrl" label="Initiate Login URL" value={`${changes.toolUrl}/login`} />
                        <CopyField id="redirectUrls" label="Redirection URI(s)" value={confDetails
                            ? `${changes.toolUrl}/join\n${changes.toolUrl}/select_conference`
                            : `${changes.toolUrl}/chat`} />
                        <CopyField id="custom_parameters" label="Custom parameters" value={`user_name=$Person.name.full\nuser_email=$Person.email.primary\nuser_username=$User.username`} />
                        <p>{`Click "Show More..."${confDetails ? ' and check the box labeled "Content-Item Message"' : ""}`}</p>
                        {confDetails && <CopyField id="contentSelect" label="Content Selection URL" value={`${changes.toolUrl}/select_conference`} />}
                        <CopyField id="iconUrl" label="Both Icon URL and Secure Icon URL (Optional)" value="https://developers.cordoniq.com/logo.png" />
                    </Container>
                    <Container label="Need More Help?">
                        <p>For more help on how to configure and set up Cordoniq integrations, check out the FAQs on our documentation page.</p>
                        <Button outline onClick={() => this.goTo("/Docs/docs/resources")}>Integrations FAQs</Button>
                    </Container>
                </Dialog>}
                {this.state.showBlackboardSetup && <Dialog show={this.state.showBlackboardSetup} title="Integration Setup" footer={[
                    <Button onClick={() => this.setState({ showBlackboardSetup: false })}>Close</Button>
                ]}>
                    <Container label="Configuration Instructions">
                        <p>Your Blackboard configuration can be adjusted from your Blackboard account.</p>
                        <p>To make changes, go to "System Admin", look for the Integrations section and click on "LTI Tool Providers".</p>
                        <p>Select the Cordoniq integration and click either "Edit" or "Manage Placements" to make changes.</p>
                    </Container>
                    <Container label="Need More Help?">
                        <p>For more help on how to configure and set up Cordoniq integrations, check out the FAQs on our documentation page.</p>
                        <Button outline onClick={() => this.goTo("/Docs/docs/resources")}>Integrations FAQs</Button>
                    </Container>
                </Dialog>}
                <h1>LTI Integrations</h1>
                <List id="integrations" loaded={this.state.loaded} items={this.state.integrations} actions={[
                    {
                        label: "Delete",
                        props: { onClick: this.deleteSelected, delete: true, disabled: this.state.selected.length === 0 }
                    }
                ]} filters={[
                    { name: "Search", value: "search", match: "description", placeholder: "Search Integrations..." },
                    { name: "Plaform", value: "platform", match: "platform", options: [
                        { name: "Select Platform...", dontSelect: true },
                        { name: "Canvas", value: 1 }, { name: "Moodle", value: 2 }
                    ] }
                ]} sort={[
                    { options: [
                        { name: "Name", value: "description" },
                        { name: "Platform", value: "platform" },
                        { name: "Integration ID", value: "integrationId" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    app: state.apps[state.currentApp]
});

export default connect(mapStateToProps)(withNav(Integrations));
