import { papi } from '../config.js';

export const loginHelp = ({ email, help }) => {
    return new Promise((resolve, reject) => {
        papi.post('/api/lumi/login/help', { email, help })
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const validateResetCode = resetCode => {
    // No current internal API to pre-validate a
    // password reset hash.
    return new Promise(resolve => resolve(true));
    // return new Promise((resolve, reject) => {
    //     papi.post(`/api/lumi/password/reset/${resetCode}/valid`)
    //         .then(response => resolve(response.data))
    //         .catch(reject);
    // });
};

export const resetPassword = (resetHash, newPassword) => {
    return new Promise((resolve, reject) => {
        papi.post(`/api/lumi/password/reset/${resetHash}`, { password: newPassword})
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const forcePasswordChange = ({ username, password, newPassword }) => {
    return new Promise((resolve, reject) => {
        papi.put('/api/cordoniq/password/forcechange', { username, password, newPassword})
            .then(response => resolve(response.data))
            .catch(reject);
    });
}

const PasswordResetAPI = {
    loginHelp,
    validateResetCode,
    resetPassword,
    forcePasswordChange
};

export default PasswordResetAPI;
