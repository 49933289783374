import React, { Component } from 'react';

import ContentPageWithHeader from '../templates/ContentPageWithHeader';

import vConnect from '../images/PEC-actual.jpg';
import vConnectDesktop from '../images/PEC-vConnect_desktop-setting-control.jpg';
import pec from '../images/pec.svg';

import './css/caseStudy.css';

class PECCaseStudy extends Component {
    componentDidMount() {
        document.title = "Case Study | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <ContentPageWithHeader id="pecCaseStudy">
                <img id="header" src={vConnectDesktop} alt="vConnect Desktop Client" />
                <div id="content">
                    <h3>2021 Case Study</h3>
                    <h1>How Cordoniq Modernized The Way Government Educates It's Elite Forces With PEC Inc.</h1>
                    <p className="heading">How one company built a powerful and secure private learning platform experience to support thousands of government contract clients with specialized training needs. Across 54 countries and in 95 languages, PEC delivers unparalleled distance learning classes.</p>
                    <h2>PEC Delivers Unparalleled Distance Learning Classes</h2>
                    <section>
                        <div>
                            <p>Progressive Expert Consulting, Inc. (PEC) was founded in 1987 as a training and technology company.  PEC pioneered concepts to facilitate both synchronous & asynchronous training by providing the full capability of brick-and-mortar learning, all within a virtual setting. Built upon one of PEC’s core competencies, their network infrastructure supports the demands of fully virtual, synchronous language and culture training environments.</p>
                            <p>In 2003, the company started working with the Department of Defense and developed SOFTS- the Special Operations Forces Teletraining System for the Department of Defense, which is currently operating as the longest functioning Virtual School in the DoD. They also serve many commercial clients in the language learning space, including Joint Language University and Syracuse University.</p>
                            <p>In 2013, the company began supporting the Air Force eMentor Language Sustainment Classes, which is the instructional component of the Language Enabled Airmen Program (LEAP). Because many of PEC’s users are in sensitive government roles where security requirements are paramount, public products in consumer market video conferencing were not a viable or safe solution for their customers. Security, privacy and control in the remote learning environment for training employees in active military and government institutions are critical components in their virtual learning environment.</p>
                            <p>Over the past 20 years, the company has provided over 8750 classes to approximately 19,500 students, with instruction in 95 languages across 54 countries around the world. With more than 600,000 hours of distance learning provided, the company understands how to deliver private, secure and effective instruction to their clients; as high resolution, lip-synced video and audio classes must be achieved for specialized online training in oral and written language learning.</p>
                            <div className="quote">
                                <b>&ldquo;</b>
                                <p>We needed a platform solution that we could control and would also meet our stringent requirements for military grade security. It has to be private, so we can choose how and where to install any modules as well as pass the critical encryption requirements for sensitive operations</p>
                                <hr />
                                <p>Tim Feng, Vice President, Program Management</p>
                            </div>
                        </div>
                        <aside>
                            <img src={pec} alt="PEC Logo" />
                            <h2>The Company</h2>
                            <p>PEC was founded in 1987 as an industrial training company and became incorporated in 1990 as it was enhanced with talents in the field of system integration. PEC envisioned a service that gives people and organizations the tools needed to survive and progress in an increasingly high-tech world.</p>
                            <p>We call this service invisible technology. We strongly believe intuitive technology should be applied to practices and projects without the complications typically associated with it. It should be rendered invisible to the user. Since then, PEC has participated in a host of projects that have brought this idea to life. From virtual Language and Culture training programs to telemedicine PEC has brought their idea to fruition.</p>
                        </aside>
                    </section>
                    <section className="column">
                        <p>“Our solution is a fully integrated platform, conceived and built with synchronous online learning in mind. Rather than trying to marry two disparate software packages together, we built our platform from the ground up to address the unique needs represented by a live, online synchronous learning environment. We also built our platform specifically to handle the needs represented in a Virtual School, not simply self-directed, self-paced, asynchronous learning,” says Tim Feng, Vice President and Program Management at PEC.</p>
                        <p>PEC leadership goes on to say, “We needed a platform solution that we could control and would also meet our stringent requirements for military grade security. It has to be private, so we can choose how and where to install any modules as well as pass the critical encryption requirements for sensitive operations.”</p>
                        <p>After reviewing various video conferencing cloud solutions on the market, there were none that met PEC’s strict requirements. Dan Feng, Vice President, Operations states, “Because these products are standalone applications with a constrained API, the scope of interoperability with existing LMS platforms is narrow.”</p>
                        <p>These privacy, security and control parameters led PEC to choose the Cordoniq platform as their solution provider. For their team, the lack of control over their user’s virtual class experience offered by other video products was the critical factor that led the company to their choice.</p>
                        <p>PEC developed their own learning management system (LMS) called the Collaborative Learning Environment (CLE) and utilized the Cordoniq platform solution to provide a cohesive live learning experience for their instructors and students. This functionality and ease also required a level of stringent internal controls.</p>
                        <p>In order to accomplish their complete virtual learning program offering, PEC chose the Cordoniq platform solution because they could express this complete control. Not merely a group of APIs, but the ability to control everything for the user -from top level video and audio controls, security of the classroom, right down to the control of devices utilized on each workstation.</p>
                        <div className="quote">
                            <p>The Cordoniq platform creates resiliency and scalability for PEC's entire virtual educational program. Non-cloud on-premise scalability is critically important for the company.</p>
                        </div>
                        <img src={vConnect} alt="vConnect Logo" />
                        <p>The Cordoniq platform creates resiliency and scalability for PEC’s entire virtual educational program. Non-cloud on-premise scalability is critically important for the company. The ability to manage all aspects of the classroom; for example, to add or delete chat capability on the fly in a live classroom environment was a required function. For their government users with protected and confidential identities, PEC needed the ability to run the whole operation on their own infrastructure.</p>
                        <p>“Our ability to interact with students and instructors alike in a live conference from both inside and outside of the application experience is a critical element of learning success,” says Tim Feng.</p>
                        <p>For PEC’s training and education, not only the video and audio experience, but also sensitive content such as shared documents and files along with recordings of conferences, absolutely cannot stream over the public Internet or be stored in uncontrolled cloud data centers. Because Cordoniq offered the ability to leverage TLS 1.3 and all current military grade ciphers for all of their end-to-end communications over TCP,  as well as true end-to-end encryption, not merely key-based encryption; PEC has complete control over their sensitive data including uploading and storing video class recordings. When integrated effectively, this encryption does not have a negative impact on performance even when running on low-end devices and delivering dozens of video streams. PEC, Inc. can use their own domains, SSL certificates and set their public-key encryption levels to completely leverage controls over their own information.</p>
                        <p>“We had to have control over the location of content, how it is secured, stored and retrieved.  Most products we looked at would stream over the internet to their target servers, with very little or no controls. They also would not allow you to control where your sensitive content and recordings were stored.” says Dan Feng.</p>
                        <p></p>
                        <p></p>
                    </section>
                    <section>
                        <div>
                            <h1>Tools for The Virtual Education Environment</h1>
                            <p>It is all about engaging students in the virtual classroom in a synchronous, secure and live environment so that they can learn without the distraction of technology. The learning environment created by the Cordoniq platform provides for all the tools needed for instructors and students. Language chat support in over 60 languages, whiteboarding, markup and annotation tools for documents, screen and document sharing all contribute to the ease and flow of the classroom environment offered by PEC to serve thousands of their government learning customers.</p>
                            <p>“Inside the virtual classroom, the annotation and whiteboarding tools must be fluid and robust so that students can express their skills and interact with the instructor in an effective manner. We need tools that are focused on learning, which general purpose video conferencing does not provide.  We need breakout rooms that are flexible and under our control, multi-page document sharing with markup, the ability to share files and media; all the tools required for a fluid classroom experience,” says Tim Feng.</p>
                            <p>For PEC, the ability to educate in a virtual learning environment safely, privately and with complete control over the learning environment has been paramount to their continued success.</p>
                            <p>PEC, Inc. has positioned themselves as a leader in the specialized virtual learning space when leveraging the Cordoniq platform solution. With their robust virtual learning offering, Tim states, ”PEC can provide a complete, scalable and turn-key solution that includes both the virtual school infrastructure as well as the language instruction component in a completely controlled and secure environment.”</p>
                        </div>
                        <aside>
                            <h2>About Cordoniq</h2>
                            <p>Headquartered in San Diego, CA., Cordoniq is the award-winning platform solution for live education and training. Engaging students and educators alike, Cordoniq provides the ability to interact in a live video meeting and view presentations with screen shares, document shares, annotations and whiteboards - securely and safely. Educators and learners can live chat with peers in up to 100 languages, and enjoy the learning capabilities traditional platforms cannot offer, with an unlimited amount of users joining in the classroom experience.</p>
                        </aside>
                    </section>
                </div>
            </ContentPageWithHeader>
        );
    }
}

export default PECCaseStudy;
