import { api, allDetails } from '../config.js';
import { Token } from '../auth.js';

export const getRecording = (confId, recordingId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}/recording/${recordingId}`, allDetails)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getRecordings = (confId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}/recordings`, allDetails)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

// Optional settings: expiry_minutes, expiry, content_disposition, content_type
export const getShareUrl = (confId, recordingId, settings) => {
    let options = {
        params: {
            details: "all",
            ...settings
        }
    };
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}/recording/${recordingId}/sharedaccess`, options)
            .then(response => resolve(response.data.sharedAccessUrl))
            .catch(reject);
    });
};

export const getDownload = (confId, recordingId, filename) => {
    let options = {
        params: {
            details: "all",
            content_disposition: `attachment; filename=${filename ?? `Recording_${recordingId}`}.mp4`,
            content_type: "binary"
        }
    };
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/conference/${confId}/recording/${recordingId}/sharedaccess`, options)
            .then(response => resolve(response.data.sharedAccessUrl))
            .catch(reject);
    });
};

export const updateRecordingName = (confId, recordingId, displayName) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/conference/${confId}/recording/${recordingId}`, { displayName })
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const deleteRecording = (confId, recordingId) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/conference/${confId}/recording/${recordingId}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

const RecordingAPI = {
    recording: {
        get: getRecording,
        share: getShareUrl,
        download: getDownload,
        updateName: updateRecordingName,
        delete: deleteRecording
    },
    recordings: {
        get: getRecordings,
    }
};

export default RecordingAPI;
