import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { loginHelp } from '../LumiAPI/APIs/internal/passwordReset';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';

import Button from '../components/Button';
import Radio from '../components/Radio';
import TextField from '../components/TextField';
import GraphicSidebar from '../partials/GraphicSidebar';

import Notification from '../tools/Notification';

import './css/welcomePage.css';

class SignInHelp extends Component {
	state = {
		processing: false,
        email: "",
        help: "both",
        sent: false
	};

	handleChange = field => this.setState({ [field.name]: field.value });

    disabled = () => {
        return this.state.email === "" ||
               this.state.help === "" ||
               this.state.processing;
    };

	submit = e => {
		e.preventDefault();
		this.setState({ processing: true });

        let { email, help } = this.state;

		loginHelp({ email, help }).then(results => {
			this.setState({ processing: false, sent: true });
		}).catch(e => {
			new Notification({ id: "resetError", message: e.response.data.error_description, type: "warning" });
			this.setState({ processing: false });
		});
	};

	componentDidMount() {
		document.title = "Sign In Help | Cordoniq";

        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);
	}

	componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
	}

  	render() {
	  	return (
		  	<main id="welcome" className="signInHelp">
		  		<GraphicSidebar show={true} processing={this.state.processing} />
		  		<section>
		  			<div className="content">
                        {this.state.sent
                            ? <>
                            <h1>Help is on the way!</h1>
                            <p>We have received your request for help accessing your account. Please check your email for further instructions.</p>
                            <br />
                            <p>Need more help? Check out our support page for additional assistance</p>
                            <form action="">
                                <Button outline type="button" onClick={() => window.open('https://www.cordoniq.com/Support/', '_blank')}>Cordoniq Support</Button>
                                <Button type="button" onClick={() => this.goTo("/SignIn")}>Sign In</Button>
                            </form>
                            </>
                            : <>
                            <h1>Need Help Signing In?</h1>
                            <p>Enter your email and select the assistance you need to send an email with reset instructions.</p>
                            <form onSubmit={this.submit} action="">
                                <TextField type="email" name="email" label="Email" placeholder="Enter your email..." value={this.state.email} onEdit={this.handleChange} />
                                <Radio vertical name="help" selected={this.state.help} options={[
									{ name: "both", value: "both", label: "I forgot both my username and password" },
                                    { name: "username", value: "username", label: "I forgot my username" },
                                    { name: "password", value: "password", label: "I forgot my password" }
                                ]} onChange={value => this.setState({ help: value })} />
                                <Button outline type="button" onClick={() => this.goTo("/SignIn")}>Back to Sign In</Button>
                                <Button onClick={this.submit} type="submit" disabled={this.disabled()}>{this.state.processing ? "Sending Email..." : "Send Email"}</Button>
                            </form>
                            </>
                        }
		  			</div>
		  		</section>
		  	</main>
		);
	}
}

export default withNav(SignInHelp);
