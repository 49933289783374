const apps = (state = {}, action) => {
    switch (action.type) {
        case 'REFRESHAPPS':
            return action.payload.apps;
        case 'UPDATEAPP':
            return action.payload.apps;
        default:
            return state;
    }
};

export default apps;
