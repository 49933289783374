import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import {
    attendMethodsList, confColors as colors, featuresList,
    privilegesList, startMethodOptions
} from '../LumiAPI/APIs/core/confDefaults';
import { getConference, updateConference } from '../LumiAPI/APIs/core/conference.js';
import handleErrors from '../LumiAPI/APIs/errors.js';

import Placeholder from '../components/Placeholder';
import Section from '../partials/Section';
import ContentWithToolbar from '../templates/ContentWithToolbar';
import Contents from './ConferenceContent';
import Announcements from './ConferenceToast';
import Users from './ConferenceUsers';

import Checkbox from '../components/Checkbox';
import Icon from '../components/Icon';
import SelectBox from '../components/SelectBox';
import ShareDialog from '../components/ShareDialog';
import TextField from '../components/TextField';
import Toggle from '../components/Toggle';

import { countryCodeIndex, countryCodes } from './json/countryCodes.js';

import { connect } from 'react-redux';
import { updateUsers } from '../actions/users-actions';

import './css/conferenceDetails.css';

const PANE = { SETTINGS: 1, ANNOUNCEMENTS: 2, USERS: 3, CONTENT: 4 };

class ConferenceDetails extends Component {
    shareRef = React.createRef();
    countryCodeRef = React.createRef();

    state = {
        confId: this.props.match.params.id,
        pane: PANE.SETTINGS,
        noPassword: true,
        selectedPrivilegeRole: "privileges",
        changes: {},
        saving: false
    };

    openPane = pane => this.setState({ pane });

    reset = () => {
        let { conference, mutableConference, changes } = this.state;

        for (let change in changes) {
            let value = mutableConference[change];
            if (typeof value === "object") {
                for (let key in changes[change]) {
                    mutableConference[change][key] = conference[change][key];
                }
            } else {
                mutableConference[change] = conference[change];
            }
        }

        this.setState({ mutableConference, changes: {} });
    };

    saveChanges = () => {
        let { conference, mutableConference, changes } = this.state;

        if (changes.location !== undefined && changes.countryCode === undefined) {
            changes.countryCode = this.countryCodeRef.current.getSelectedValue();
        }

        if (this.state.noPassword && (changes?.attendeePassword !== "" ?? false)) {
            changes.attendeePassword = mutableConference.attendeePassword = "";
        }

        this.setState({ saving: true });
        updateConference(conference.confId, changes).then(conf => {
            for (let change in changes) {
                let value = mutableConference[change];
                if (typeof value === "object") {
                    for (let key in changes[change]) {
                        conference[change][key] = changes[change][key];
                    }
                } else {
                    conference[change] = changes[change];
                }
            }

            this.setState({ conference, mutableConference, changes: {}, saving: false });
        }).catch(handleErrors);
    };

    onEdit = change => {
        let { conference, mutableConference, changes } = this.state,
            key = change.name,
            value = change.value;

        mutableConference[key] = value;
        if (conference[key] === mutableConference[key]) {
            delete changes[key];
        } else {
            changes[key] = value;
        }

        if (key === "location" && changes.location === undefined && changes.countryCode !== undefined) {
            delete changes.countryCode;
        }

        this.setState({ conference, mutableConference, changes, saving: false });
    };

    onEditChild = (parent, change) => {
        let { conference, mutableConference, changes } = this.state;

        changes[parent] = {};
        if (conference[parent] === undefined) conference[parent] = {};
        if (mutableConference[parent] === undefined) mutableConference[parent] = {};

        mutableConference[parent][change] = !mutableConference[parent][change];

        let hasChanges = false;
        let settings = {
			"privileges": privilegesList,
			"presenterPrivileges": privilegesList,
			"hostPrivileges": privilegesList,
			"features": featuresList,
			"attendMethods": attendMethodsList
		};
        settings[parent].forEach(setting => {
            let key = setting.key;
            let conf = conference?.[parent]?.[key] ?? false;
            let mutable = mutableConference?.[parent]?.[key] ?? false;

            changes[parent][key] = mutable;

            if (conf !== mutable) {
                hasChanges = true;
            }
        });

        if (!hasChanges) {
            delete changes[parent];
        }

        this.setState({ conference, mutableConference, changes, saving: false });
    }

    onSelect = selection => {
        let change = { name: selection.name, value: selection.option.value };
        this.onEdit(change);
    };

    saveDisabled = () => {
        let disabled = false;
        let { conference, changes, noPassword } = this.state;

        if ((changes?.displayName === "" ?? false) ||
            (changes?.hostPassword === "" ?? false) ||
            (changes?.presenterPassword === "" ?? false)
        ) {
            disabled = true;
        }

        if (changes.attendeePassword === undefined) {
            if ((conference?.attendeePassword === "" ?? false) && !noPassword) {
                // No changes made, but box was unchecked. Needs a password or recheck box
                disabled = true;
            } else if ((conference?.attendeePassword !== "" ?? false) && noPassword) {
                // No changes, but box was checked. Update to no password in submit
                disabled = disabled ? disabled : false;
            } else if (Object.keys(changes).length === 0) {
                disabled = true;
            }
        } else if (changes.attendeePassword === "") {
            if (noPassword) {
                disabled = disabled ? disabled : false; // Box checked after erase, still needs update
            } else {
                disabled = true; // Needs a password or check box; Or automatically check the box?
            }
        } else {
            if (noPassword && (conference?.attendeePassword === "" ?? false) && Object.keys(changes).length === 1) {
                disabled = true; // Box checked after update, no change
            } else {
                disabled = disabled ? disabled : false // Update the password
            }
        }

        return disabled;
    };

    copy = conference => {
        // This method raises an exception when it encounters a key
        // with a null value, and also coerces arrays into objects.
        // While the JSON method takes ~2x as long to run, it 
        // consistently handles all JSON data types.
        return JSON.parse(JSON.stringify(conference));
        // let copy = {};
        // Object.keys(conference).forEach(prop => {
        //     if (typeof conference[prop] === "object") {
        //         if (copy[prop] === undefined) copy[prop] = {};
        //         Object.keys(conference[prop]).forEach(child => {
        //             copy[prop][child] = conference[prop][child];
        //         });
        //     } else {
        //         copy[prop] = conference[prop];
        //     }
        // });
        // return copy;
    };

    componentDidMount() {
        document.title = "Conference Details | Cordoniq";
        window.scrollTo(0, 0);

        this.checkPermissions();

        getConference(this.state.confId).then(conference => {
            this.setState({
                loaded: true,
                noPassword: conference.attendeePassword === "",
                mutableConference: this.copy(conference),
                conference
            });
        }).catch(handleErrors);
    }

    componentDidUpdate(prevProps) {
        if (this.props.curRole !== prevProps.curRole) {
            this.checkPermissions(this.props.curRole);
        }

        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ confId: this.props.match.params.id });
        }

        if (this.props.currentApp !== prevProps.currentApp &&
            this.props.currentApp !== "" && prevProps.currentApp !== ""
        ) {
            this.goTo("/Portal/Conferences");
        }
    }

    render() {
        let conference = this.state.conference ?? {},
            mutableConference = this.state.mutableConference ?? {};

        let buttons = [
            <button onClick={this.reset} disabled={Object.keys(this.state.changes).length === 0}>Reset</button>,
            <button onClick={this.saveChanges} disabled={this.saveDisabled()}>
                {this.state.saving
                    ? "Saving..."
                    : Object.keys(this.state.changes).length > 0
                        ? "Save"
                        : "Saved"
                }
            </button>
        ];

        return (
            <ContentWithToolbar id="conferenceDetails" path={`/Portal/Conferences/${conference?.state + 1 ?? 1}`} tools={this.state.pane === PANE.SETTINGS ? buttons : undefined}>
                <ShareDialog ref={this.shareRef} app={this.props.app} />
                <h1>{conference?.displayName ?? <Placeholder />}</h1>
                <div id="navButtons">
                    <button className={this.state.pane === PANE.SETTINGS ? "selected" : ""} onClick={() => this.openPane(PANE.SETTINGS)}>
                        <Icon icon="settings" />Details & Settings
                    </button>
                    {(conference?.active ?? false) && <button className={this.state.pane === PANE.ANNOUNCEMENTS ? "selected" : ""} onClick={() => this.openPane(PANE.ANNOUNCEMENTS)}>
						<Icon icon="bell" />Announcements
					</button>}
					<button className={this.state.pane === PANE.USERS ? "selected" : ""} onClick={() => this.openPane(PANE.USERS)}>
						<Icon icon="people" />Users
					</button>
					<button className={this.state.pane === PANE.CONTENT ? "selected" : ""} onClick={() => this.openPane(PANE.CONTENT)}>
						<Icon icon="library" />Content
					</button>
                </div>
                {this.state.pane === PANE.SETTINGS && <>
                    <Section id="details" title="Conference Details" buttons={[
                        <button onClick={() => this.shareRef.current.open("attendee", conference)}><Icon icon="share" /></button>
                    ]}>
                        <TextField tabIndex="1" name="displayName" label="Name" placeholder="Display Name" value={mutableConference?.displayName ?? ""} onEdit={this.onEdit} required />
                        <SelectBox tabIndex="2" name="startMethod" label="Start Method" selected={mutableConference?.startMethod ?? 2} options={startMethodOptions} onSelect={this.onSelect} />
                        <div className="tag">
                            <label>Select a color label</label>
                            {colors.map((color, i) => (
                                <React.Fragment key={color}>
                                <input type="radio" id={color} name="color" value={i} checked={mutableConference?.backgroundColor === i ?? false}
                                    onClick={() => this.onEdit({ name: "backgroundColor", value: i})} />
                                <label htmlFor={color} className={color}></label>
                                </React.Fragment>
                            ))}
                        </div>
                    </Section>
                    <Section id="attendance" title="Attendance Methods">
                        <div className="features">
                            {attendMethodsList.map((method, i) => (
                                <div key={method.key}>
                                    <Icon icon={method.icon} />
                                    <label>{method.name}</label>
                                    <Toggle name={method.key} enabled={mutableConference?.attendMethods?.[method.key] ?? false}
                                        click={() => this.onEditChild("attendMethods", method.key)} />
                                </div>
                            ))}
                        </div>
                    </Section>
                    <Section id="passwords" title="Conference Passwords">
                        <TextField tabIndex="3" name="hostPassword" label="Host Password" placeholder="Enter Host Password" value={mutableConference?.hostPassword ?? ""} onEdit={this.onEdit} required />
                        <TextField tabIndex="4" name="presenterPassword" label="Presenter Password" placeholder="Enter Presenter Password" value={mutableConference?.presenterPassword ?? ""} onEdit={this.onEdit} required />
                        <div className="checkboxMessage">
                            <Checkbox id="noPassword" checked={this.state.noPassword} onClick={() => {
                                let noPassword = this.state.noPassword;
                                this.setState({ noPassword: !noPassword, saving: false });
                            }} />
                            Allow attendees to join without a password
                        </div>
                        <TextField tabIndex="5" name="attendeePassword" label="Attendee Password" placeholder="Enter Attendee Password" value={mutableConference?.attendeePassword ?? ""} onEdit={this.onEdit} disabled={this.state.noPassword} />
                    </Section>
                    <Section id="location" title="Conference Location">
                        <SelectBox tabIndex="5" name="location" label="Determine Location" selected={mutableConference?.location ?? 1} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Automatically", value: 1 },
                            { name: "By Country", value: 2 }
                        ]} onSelect={this.onSelect} />
                        <SelectBox ref={this.countryCodeRef} tabIndex="6" name="countryCode" label="Country" selected={mutableConference?.location === 2 && mutableConference.countryCode !== undefined
                            ? countryCodeIndex(mutableConference.countryCode)
                            : 0
                        } options={countryCodes} onSelect={this.onSelect} disabled={mutableConference?.location !== 2} />
                    </Section>
                    <div className="split">
                        <Section id="features" title="Features">
                            <div className="features split">
                                {featuresList.map((feature, i) => (
                                    <div key={feature.key}>
                                        <Checkbox id={feature.key} checked={mutableConference?.features?.[feature.key] ?? false}
                                            onClick={() => this.onEditChild("features", feature.key)} />
                                        <Icon icon={feature.icon} />
                                        {feature.name}
                                    </div>
                                ))}
                            </div>
                        </Section>
                        <Section id="privileges" title="Privileges" tabs={[
                            <button className={this.state.selectedPrivilegeRole === "privileges" && "selected"} onClick={() => this.setState({ selectedPrivilegeRole: "privileges"})}>Attendee</button>,
							<button className={this.state.selectedPrivilegeRole === "presenterPrivileges" && "selected"} onClick={() => this.setState({ selectedPrivilegeRole: "presenterPrivileges"})}>Presenter</button>,
							<button className={this.state.selectedPrivilegeRole === "hostPrivileges" && "selected"} onClick={() => this.setState({ selectedPrivilegeRole: "hostPrivileges"})}>Host</button>
                        ]}>
                            <div className="features">
                                {privilegesList.map(privilege => (
                                    <div key={privilege.key}>
                                        <Icon icon={privilege.icon} />
                                        <label>{privilege.name}</label>
                                        <Toggle name={privilege.key} enabled={mutableConference?.[this.state.selectedPrivilegeRole]?.[privilege.key] ?? false}
                                            click={() => this.onEditChild(this.state.selectedPrivilegeRole, privilege.key)} />
                                    </div>
                                ))}
                            </div>
                        </Section>
                    </div>
                </>}
                {this.state.pane === PANE.ANNOUNCEMENTS && <Announcements confId={this.state.confId} conference={this.state.conference} />}
				{this.state.pane === PANE.USERS && <Users confId={this.state.confId} conference={this.state.conference} />}
				{this.state.pane === PANE.CONTENT && <Contents confId={this.state.confId} />}
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    currentApp: state.currentApp,
    app: state.apps[state.currentApp]
});

const mapActionsToProps = {
    onUpdateUsers: updateUsers
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(ConferenceDetails));
