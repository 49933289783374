import { Token } from './auth.js';

export const ERRORCODE = {
    INCORRECT_PASSWORD: -3,
	USERNAME_NOT_FOUND: -1,
	CONNECTION: 1,
	OPERATION: 2,
	INVALID_JSON: 3,
	INVALID_PATH: 4,
	INVALID_REQUEST_BODY: 5,
	INVALID_URL_ENCODED_BODY: 6,
	MISSING_URI_PARAM: 7,
	MISSING_ELEMENT: 8,
	MISSING_REQUEST_BODY: 9,
	MISSING_RESPONSE_BODY: 10,
	MISSING_AUTH_HEADER: 11,
	MISSING_AUTH_CREDENTIALS: 12,
	UNSUPPORTED_AUTH_FORMAT: 13,
	TOKEN_UNAUTHORIZED: 14,
	INVALID_USERNAME: 15,
	INVALID_PASSWORD: 16,
	INVALID_CLIENT_ID: 17,
	INVALID_CLIENT_SECRET: 18,
	INVALID_API_KEY: 19,
	SERVER_UNAUTHORIZED: 20,
	START_FAILED: 21,
	INVALID_URL: 22,
	INVALID_SETTING: 23,
	NO_SERVERS_AVAILABLE: 24,
	USERNAME_EXISTS: 100,
	INVALID_CONFERENCE_ID: 200,
	INVALID_USER_ID: 201,
	CONFERENCE_DELETED: 202,
	USER_DELETED: 203,
	DUPLICATE_PASSWORDS: 204,
	STORAGE_ERROR: 300,
	INVALID_CONTENT_ID: 301,
	CONTENT_IN_USE: 302
};

const handleErrors = err => {
    let { status, data } = err?.response ?? { status: 400, data: { error_code: 0 } },
        code = data?.error_code;

    if (status === 401 || code === ERRORCODE.TOKEN_UNAUTHORIZED) {
        Token.notifyListeners();
    }

    console.log(`ERROR MAKING REQUEST: ${err?.config?.url ?? ""}`, err?.response?.data ?? err);
};

export default handleErrors;
