import React, { Component } from 'react';
import { signout } from '../LumiAPI/APIs/auth.js';
import { roles, rolesText } from '../LumiAPI/APIs/internal/account.js';
import { withNav } from '../hooks/useNav';
import { ColorUpdater, accountIconColor, colors, getColorScheme, setThemeColor } from '../tools/colorSchemeManager';
import { ENV, PORTAL } from '../tools/envTools';

import AppController from './AppController';

import { connect } from 'react-redux';

import Icon from '../components/Icon';
import Placeholder from '../components/Placeholder';

import './css/toolbar.css';


const SUPPORT_URL = `https://www${ENV.getApiDomain()}.cordoniq.com/support/`

class Toolbar extends Component {
    state = {
        menuOpen: false,
        color: this.props.account?.color ?? "",
        pictureImgUrl: this.props.account?.pictureImgUrl ?? undefined
    };

    openPage = path => {
        this.goTo(path);
        this.closeMenu();
    };

    toggleMenu = () => {
        var menuOpen = this.state.menuOpen;
        this.setState({ menuOpen: !menuOpen });
    };

    closeMenu = e => {
        if (e && e.target.id === "account") return;
        if (this.state.menuOpen) this.setState({ menuOpen: false });
    };

    getEnvColor = () => {
        if (PORTAL.isDev()) {
            if (ENV.isDev()) {
                setThemeColor(24, 92, 110);
                return "dev";
            } else if (ENV.isStag()) {
                setThemeColor(44, 95, 63);
                return "stag";
            } else {
                setThemeColor(42, 11, 53);
                return "prod";
            }
        } else {
            let mode = getColorScheme();
            if (mode === "light") {
                setThemeColor(245, 244, 245);
            } else {
                setThemeColor(25, 20, 25);
            }
            return undefined;
        }
    };

    componentDidMount() {
        ColorUpdater.add(this.getEnvColor);
        document.body.addEventListener("click", this.closeMenu);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.account !== this.props.account) {
            this.setState({
                color: this.props.account.color,
                pictureImgUrl: this.props.account.pictureImgUrl ?? undefined
            });
        }
    }

    componentWillUnmount() {
        ColorUpdater.remove(this.getEnvColor);
        document.body.removeEventListener("click", this.closeMenu);
    }

    render() {
        let envColor = this.getEnvColor();
        return (
            <header className={envColor}>
                {this.props.compactSidebar && !this.props.noSidebar && <Icon id="sidebarCollapse" icon="menu" mode={envColor !== undefined ? "dark" : undefined} onClick={this.props.toggleSidebar} />}
                <Icon id="cordoniqLogo" icon="logoFull" mode={envColor !== undefined ? "dark" : undefined} onClick={() => this.openPage("/Portal")} />
                {/* <h1 onClick={() => this.openPage("/Portal")}>Cordoniq</h1> */}
                <h2 onClick={() => this.openPage("/Portal")}>{PORTAL.isDev() ? "Account" : "Account"}</h2>
                <AppController mode={envColor}/>
                {this.props.account && <>
                    {this.state.pictureImgUrl !== undefined
                        ? <div id="account" className={this.state.menuOpen ? "big pic" : "pic"} style={{ backgroundImage: `url(${this.state.pictureImgUrl})`, borderColor: colors[this.state.color] }} onClick={this.toggleMenu}></div>
                        : <Icon id="account" alt="Account Menu" className={this.state.menuOpen ? "big" : ""} icon="userAccount" mode={accountIconColor(this.props.account.color)} bg={colors[this.state.color]} onClick={this.toggleMenu} />
                    }
                    <div id="accountMenu" className={this.state.menuOpen ? "show" : ""}>
                        <div id="acctDetails">
                            {this.props.account?.firstName && <label className="name">{this.props.account.firstName} {this.props.account.lastName}</label>}
                            <label>Username</label>
                            <p>{this.props.account?.username ?? <Placeholder />}</p>
                            <label>Email</label>
                            <p>{this.props.account?.email ?? <Placeholder />}</p>
                            <label>Role</label>
                            <p>{this.props.account ? rolesText[this.props.curRole] : <Placeholder />}</p>
                        </div>
                        <div id="menuLinks">
                            <button onClick={() => window.open(SUPPORT_URL, '_blank')}><Icon icon="support" />Support</button>
                            {this.props.curRole >= roles.OWNER && PORTAL.isDev() && <button onClick={() => this.openPage("/Docs")}><Icon icon="file" />Docs</button>}
                        </div>
                        <div>
                            <button onClick={() => this.openPage("/Portal/UserSettings")}><Icon icon="settings" /></button>
                            <button onClick={() => signout({ type: "success", message: "Successfully Signed Out!" })}>Sign Out</button>
                        </div>
                    </div>
                </>}
            </header>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    curRole: state.curRole,
});

export default connect(mapStateToProps)(withNav(Toolbar));
