import { ENV } from '../tools/envTools.js';

const storageKeySuffix = ENV.getStorageSuffix();
const SAVED_PROFILES_KEY = `savedProfiles${storageKeySuffix}`;
// Formerly used to cache the login username
// const SAVE_PROFILE_PREFERENCE_KEY = `saveProfilePref${storageKeySuffix}`;

// const parseBool = string => {
// 	return string !== false && string !== "false";
// };

export const getProfiles = () => {
    let savedProfiles = localStorage.getItem(SAVED_PROFILES_KEY);
    let profiles = savedProfiles === null ? {} : JSON.parse(savedProfiles);
    return profiles;
};

export const hasSavedProfiles = () => {
    let profiles = getProfiles();
    return Object.keys(profiles).length > 0;
};

export const getProfileDescription = username => {
    if (username === "") return "";
    let profiles = getProfiles();
    let profile = profiles[username];
    if (profile.first === undefined) return username;
    if (profile.last === undefined) return profile.first;
    return `${profile.first} ${profile.last}`;
};

export const saveProfiles = profiles => {
    let jsonProfileString = JSON.stringify(profiles);
    localStorage.setItem(SAVED_PROFILES_KEY, jsonProfileString);
    ProfileUpdater.notify(profiles);
};

export const updateProfile = account => {
    let profile = {
        first: account.firstName ?? undefined,
        last: account.lastName ?? undefined,
        color: account.color ?? 17,
        profilePic: account.pictureImgUrl ?? undefined
    };

    let profiles = getProfiles();
    profiles[account.username] = profile;
    saveProfiles(profiles);
};

export const removeProfile = account => {
    let profiles = getProfiles();
    if (profiles[account.username] !== undefined) {
        delete profiles[account.username];
        saveProfiles(profiles);
    }
};

// PROFILE SAVE PREFERENCES

export const hasProfilePreference = account => {
	// return localStorage.getItem(SAVE_PROFILE_PREFERENCE_KEY + account.username) !== null;
	return false;
}

export const getProfilePreference = account => {
    // return parseBool(localStorage.getItem(SAVE_PROFILE_PREFERENCE_KEY + account.username));
    return false;
};

export const setProfilePreference = (preference, account) => {
    // let preferenceKey = SAVE_PROFILE_PREFERENCE_KEY + account.username;
    // localStorage.setItem(preferenceKey, preference);
    // preference ? updateProfile(account) : removeProfile(account);
    return;
};

export const removeProfilePreference = account => {
    // let preferenceKey = SAVE_PROFILE_PREFERENCE_KEY + account.username;
    // localStorage.removeItem(preferenceKey);
    return;
};

export var ProfileUpdater = {
    subscribers: [],
    add: function(subscriber) {
        this.subscribers.push(subscriber);
    },
    remove: function(subscriber) {
        var index = this.subscribers.indexOf(subscriber);
        if (index !== -1) this.subscribers.splice(index, 1);
    },
    notify: function(profiles) {
        this.subscribers.forEach(subscriber => {
            subscriber(profiles);
        });
    }
};
