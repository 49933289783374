import { api, papi } from './config.js';
// import { ENV } from '../../tools/envTools.js';

import AccountAPI from './internal/account.js';
import AppAPI from './internal/app.js';
import IntegrationAPI from './internal/integration.js';
import PasswordResetAPI from './internal/passwordReset.js';
import ServerAPI from './internal/server.js';
import WebAppAPI from './internal/webApp.js';

export const getReleaseNotes = () => {
    return new Promise((resolve, reject) => {
        papi.get('/api/resource/releaseNotes')
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getAppInfo = app => {
    app = app ?? "cordoniq";
    if (app !== "cordoniq" && app !== "vconnect") app = "cordoniq";
    return new Promise((resolve, reject) => {
        let options = {
            params: { setting: app }
        };
        api().get('/setting/info', options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
}

const Internal = {
    ...AccountAPI,
    ...PasswordResetAPI,
    ...AppAPI,
    ...ServerAPI,
    ...IntegrationAPI,
    ...WebAppAPI,
    resources: {
        getReleaseNotes,
        getAppInfo
    }
};

export default Internal;
