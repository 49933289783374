import React, { Component } from 'react';

import Icon from './Icon';
import TextField from './TextField';

import './css/lookupBox.css';

class LookupBox extends Component {
    searchRef = React.createRef();

    state = {
        search: "",
        selected: this.props.selected ?? [],
        hover: undefined,
        label: this.props.label,
        focused: false,
        name: this.props.name ?? "",
        showList: false,
        list: this.props.list ?? {}
    };

    hide = () => { this.setState({ showList: false }) };

    showHideList = e => {
        if (e) {
            e.stopPropagation();
            this.checkTarget(e);
            // this.closeOtherSelect(e.target);
        }

        this.setState({
            showList: !this.state.showList,
            hover: !this.state.showList ? 0 : undefined
        });
    };

    moveHover = direction => {
        let listTotal = Object.keys(this.state.list).filter(this.filter).length;
        var hover = parseInt(this.state.hover) ?? 0;
        if (hover + direction >= listTotal) {
            hover = 0;
        } else if (hover + direction < 0) {
            hover = listTotal - 1;
        } else {
            hover += direction;
        }
        this.setState({ hover: hover });
    };

    handleKeyPress = e => {
        var open = this.state.showList;
        this.setState({ hover: 0 });

        let filteredList = Object.keys(this.state.list).filter(this.filter);

        switch(e.keyCode) {
            case 8: // Backspace
                if (this.state.search === "" && this.state.selected.length !== 0) {
                    let lastSelectedId = this.state.selected[this.state.selected.length - 1];
                    this.changeSelection(lastSelectedId);
                }
                break;
            case 9: // Tab
                if (open && filteredList.length !== 0) {
                    let id = filteredList[this.state.hover];
                    this.changeSelection(id);
                } else {
                    this.setState({ search: "" });
                }
                this.blur();
                break;
            case 32: // Space
                e.preventDefault();
                if (!open) this.setState({ showList: true, hover: 0 });
                if (open && filteredList.length !== 0) {
                    let id = filteredList[this.state.hover];
                    this.changeSelection(id);
                    this.hide();
                }
                break;
            case 13: // Enter
                e.preventDefault();
                if (open && filteredList.length !== 0) {
                    let id = filteredList[this.state.hover];
                    this.changeSelection(id);
                    this.hide();
                }
                break;
            case 38: // Up
                if (open) this.moveHover(-1);
                break;
            case 40: // Down
                if (open) this.moveHover(1);
                break;
            default:
                break;
        }
    };

    changeSelection = (id, e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        var list = this.state.list;
        var selected = this.state.selected;

        let index = selected.indexOf(id);
        let intIndex = selected.indexOf(parseInt(id));
        if (index !== -1) {
            selected.splice(index, 1);
        } else if (intIndex !== -1) {
            selected.splice(intIndex, 1);
        } else if (list[id]?.single) {
            id = parseInt(id);
            selected = [id];
        } else {
            for (var i = selected.length - 1; i >= 0; i--) {
                let selectedId = selected[i];
                if (list[selectedId]?.single) {
                    selected.splice(i, 1);
                }
            }
            selected.push(id);
        }
        this.setState({ selected: selected, search: "" });

        if (this.props.onSelect) {
            let selection = {
                name: this.state.name,
                list: selected.map(id => ({ id: id, value: list[id] }))
            };
            this.props.onSelect(selection);
        }
    };

    filter = id => {
        let item = this.state.list[id];
        if (this.state.search === "") return true;
        return item.name.toLowerCase().includes(this.state.search.toLowerCase());
    };

    getClassName = () => {
        var className = "lookupBox";
        if (this.state.showList) {
            className += " showList";
        }

        if (this.props.fill) {
            className += " fill";
        }

        if (this.props.wide) {
            className += " wide";
        }

        return className;
    };

    getLookupBoxClass = () => {
        let className = "selected";
        if (this.state.focused) {
            className += " focus";
        }
        return className;
    };

    getItemClass = id => {
        var className = "item";
        if (this.state.selected.includes(id)) {
            className += " same-as-selected";
        }
        let index = Object.keys(this.state.list).filter(this.filter).indexOf(id);
        if (parseInt(this.state.hover) === index) {
            className += " hover";
        }
        return className;
    };

    // Focus Replication
    focusText = () => this.searchRef.current.focus();
    focus = () => this.setState({ focused: true });
    blur = () => {
        this.setState({ focused: false });
        if (this.state.showList) this.hide();
    };

    isParentContainer = element => {
        let name = this.state.name;
        return element?.id === name && element?.classList.contains("lookupBox");
    };

    isChildElement = e => {
        let element = e.target;
        let parent = element.parentElement;
        while (parent?.id !== "root" ?? false) {
            if (this.isParentContainer(element) || this.isParentContainer(parent)) {
                return true;
            } else {
                element = parent;
                parent = element?.parentElement;
            }
        }
        return false;
    };

    checkTarget = e => {
        let isChild = this.isChildElement(e);
        isChild ? this.focus() : this.blur();
    };

    componentDidMount() {
        window.addEventListener("click", this.checkTarget);
        window.addEventListener("blur", this.blur);
        document.querySelector(`#lookup #${this.state.name} .selected input`).addEventListener("focus", this.focus);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.checkTarget);
        window.removeEventListener("blur", this.blur);
        document.querySelector(`#lookup #${this.state.name} .selected input`).removeEventListener("focus", this.focus);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({ selected: this.props.selected });
        }

        if (this.props.list !== prevProps.list) {
            this.setState({ list: this.props.list });
        }
    }

    render() {
        return (
            <div id="lookup" className={(this.props.className ?? "") + (this.props.disabled ? " disabled" : "")}>
                <div id={this.state.name} className={this.getClassName()} tabIndex={this.props.tabIndex ?? -1} onKeyDown={this.handleKeyPress}>
                    <div className={this.getLookupBoxClass()} onClick={e => {
                        if (e.target.classList.contains("selected")) e.target.querySelector("input").focus();
                    }}>
                        {this.state.label && <label>{this.state.label}</label>}
                        {this.state.selected.map(id => (
                            <span className="tag" onClick={e => this.changeSelection(id, e)}>
                                {this.state.list[id].name}
                                <Icon icon="close" highlight />
                                {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="closeImg" fill-rule="evenodd" clip-rule="evenodd" d="M18.3533 19.7175C18.73 20.0943 19.3408 20.0943 19.7175 19.7175C20.0943 19.3408 20.0943 18.73 19.7175 18.3533L13.3643 12L19.7175 5.64682C20.0943 5.27009 20.0943 4.65928 19.7175 4.28255C19.3408 3.90582 18.73 3.90582 18.3533 4.28255L12 10.6358L5.64682 4.28255C5.27009 3.90582 4.65928 3.90582 4.28255 4.28255C3.90582 4.65928 3.90582 5.27009 4.28255 5.64682L10.6358 12L4.28256 18.3533C3.90582 18.73 3.90582 19.3408 4.28256 19.7175C4.65929 20.0943 5.2701 20.0943 5.64683 19.7175L12 13.3643L18.3533 19.7175Z" fill="white"/>
                                </svg>*/}
                            </span>
                        ))}
                        <TextField ref={this.searchRef} fill={this.props.fill} name="search"
                            placeholder={this.state.selected.length === 0 ? this.props.placeholder : undefined}
                            value={this.state.search}
                            onEdit={change => {
                                this.setState({ showList: change.value !== "", [change.name]: change.value });
                            }}
                        />
                        <Icon icon="arrow" className={this.state.showList ? "open" : ""} onClick={this.showHideList} />
                    </div>
                    <div className={this.state.showList ? "list" : "list hide"}>
                        {Object.keys(this.state.list).filter(this.filter).map(id => (
                            <div key={id} className={this.getItemClass(id)} onClick={() => this.changeSelection(id)}>
                                {this.state.list[id].name}
                            </div>
                        ))}
                        {Object.keys(this.state.list).filter(this.filter).length === 0 && <div className="item none">No Results Found</div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default LookupBox;
