import { Token, getTokenInfo } from '../LumiAPI/APIs/auth.js';
import { EVENT_KINDS, EVENT_DESCRIPTION, getEvents } from '../LumiAPI/eventsAPI';
import moment from 'moment';

/* Polling Subscription Controls */

let interval = null;

const checkToken = () => {
    getTokenInfo().then(tokenInfo => {
        if (interval === null) subscribe();
    }).catch(err => {
        unsubscribe();
    });
};

const start = () => {
    checkToken();
    Token.addListener(checkToken);
};

const stop = () => {
    unsubscribe();
    Token.removeListener(checkToken);
};

const subscribe = frequency => {
    let gte = moment().format(),
        kinds = [];
    Object.keys(EVENT_DESCRIPTION).forEach(kind => {
        if (parseInt(kind) !== 0) kinds.push(kind);
    });
    kinds = kinds.join(",");

    if (kinds[0] === ",") kinds = kinds.splice(0, 1);

    interval = setInterval(() => {
        getEvents({ gte, kinds }).then(events => {
            if (events.length > 0) gte = moment().format();
            notify(events);
        }).catch(err => console.log(err));
    }, (frequency ?? 1) * 60 * 1000);
};

const unsubscribe = () => {
    clearInterval(interval);
    interval = null;
};

/* EVENT LISTENERS */

let listeners = {
    users: [],
    conferences: [],
    shares: []
};

export const addListener = (kind, listener) => {
    kind = kind.toLowerCase();
    let index = listeners?.[kind]?.indexOf(listener) ?? -1;
    if (index !== -1) return;
    if (listeners[kind] === undefined) listeners[kind] = [];
    listeners[kind].push(listener);
};

export const removeListener = (kind, listener) => {
    kind = kind.toLowerCase();
    let index = listeners?.[kind]?.indexOf(listener) ?? -1;
    if (index === -1) return;
    if (listeners[kind] === undefined) listeners[kind] = [];
    listeners[kind].splice(index, 1);
};

const notify = events => {
    let kinds = Object.keys(EVENT_KINDS);

    events.forEach(event => {
        let kind = event.eventKind;
        event.eventKind = EVENT_DESCRIPTION[kind];

        if (kind > 0 && kind <= 10) { // All User Events
            listeners.users.forEach(listener => listener(event));
        } else if (kind >= 100 && kind <= 104) { // All Conference Events
            listeners.conferences.forEach(listener => listener(event));
        } else if (kind >= 200 && kind <= 202) { // All Share Events
            listeners.shares.forEach(listener => listener(event));
        }

        kinds.filter(key => {
            return EVENT_KINDS[key] === kind;
        }).forEach(label => {
            if (listeners[label.toLowerCase()] === undefined) listeners[label.toLowerCase()] = [];
            listeners[label.toLowerCase()].forEach(listener => listener(event));
        });
    });
};

const Events = {
    start,
    stop,
    addListener,
    removeListener
};

export default Events;
