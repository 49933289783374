import React, { Component } from 'react';
import { ToastIcons, ToastSounds, UserGroup, sendToast } from '../LumiAPI/APIs/core/toast.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { getUsers } from '../LumiAPI/APIs/core/user.js';

import Section from '../partials/Section';
import SelectBox from '../components/SelectBox';
import Checkbox from '../components/Checkbox';
import TextField from '../components/TextField';
import Button from '../components/Button';
import Notification from '../tools/Notification';

import './css/conferenceToast.css';

class ConferenceToast extends Component {
    state = {
        loadingUsers: true,
        sending: false,
        messageText: "",
        icon: { index: 1, option: { value: ToastIcons.None } },
        sound: { index: 1, option: { value: ToastSounds.None } },
        duration: 1,
        userGroup: { index: 1, option: { value: UserGroup.Specified } },
        users: [],
        userIds: [],
        buttonText: "",
        buttonUrl: ""
    };

    reset = e => {
        e && e.preventDefault();
        this.setState({
            messageText: "",
            icon: { index: 1, option: { value: ToastIcons.None } },
            sound: { index: 1, option: { value: ToastSounds.None } },
            duration: 1,
            userGroup: { index: 1, option: { value: UserGroup.Specified } },
            userIds: [],
            buttonText: "",
            buttonUrl: ""
        });
    };

    handleSelect = selection => this.setState({ [selection.name]: selection });
    handleEdit = change => this.setState({ [change.name]: change.value });

    updateUserIds = e => {
        let userId = e.target.id,
            userIds = this.state.userIds,
            index = userIds.indexOf(userId);
        index === -1 ? userIds.push(userId) : userIds.splice(index, 1);
        this.setState({ userIds });
    };

    send = e => {
        e.preventDefault();
        let data = this.state;
        let toast = {
            messageText: data.messageText,
            icon: data.icon.option.value,
            sound: data.sound.option.value,
            durationMs: parseInt(data.duration) * 1000,
            userGroup: data.userGroup.option.value,
            userIds: data.userGroup.option.value === UserGroup.Specified ? data.userIds : [],
            buttonText: data.buttonText !== "" ? data.buttonText : undefined,
            buttonUrl: data.buttonUrl !== "" ? data.buttonUrl : undefined
        };
        this.setState({ sending: true });
        sendToast(this.props.confId, toast).then(result => {
            new Notification({ id: "toastSent", message: "Your announcement was successfully sent!", type: "success" });
            this.reset();
            this.setState({ sending: false });
        }).catch(err => {
            new Notification({ id: "toastNotSent", message: "Error Occurred! Try sending announcement again", type: "error" });
            this.setState({ sending: false });
            handleErrors(err);
        })
    };

    disabled = () => {
        return (this.state.sending) ||
            (this.state.userGroup.option.value === UserGroup.Specified && this.state.userIds.length < 1) ||
            (this.state.messageText === "") ||
            (this.state.buttonText !== "" && this.state.buttonUrl === "") ||
            (this.state.buttonText === "" && this.state.buttonUrl !== "");
    };

    componentDidMount() {
        getUsers(this.props.confId, [ "active" ])
            .then(users => this.setState({ users, loadingUsers: false }))
            .catch(handleErrors);
    }

    render() {
        return (
            <Section id="toast" title="Send Announcement">
                <form onSubmit={this.send}>
                <SelectBox name="userGroup" label="Recipients" selected={this.state.userGroup.index} options={[
                    { name: "Pick one...", dontSelect: true },
                    { name: "Specified Recipients", value: UserGroup.Specified },
                    { name: "All Users", value: UserGroup.All },
                    { name: "All Attendees", value: UserGroup.Attendees },
                    { name: "All Presenters", value: UserGroup.Presenters },
                    { name: "All Hosts", value: UserGroup.Hosts }
                ]} onSelect={this.handleSelect} />
                {this.state.userGroup.option.value === UserGroup.Specified && <div id="userIds">
                    {this.state.users.map(user => (
                        <div>
                            <Checkbox id={user.userId} checked={this.state.userIds.includes(user.userId)} onClick={this.updateUserIds} />
                            <span>{user.displayName}</span>
                        </div>
                    ))}
                    {this.state.loadingUsers && <div><span>Loading User list...</span></div>}
                    {!this.state.loadingUsers && this.state.users.length === 0 && <div><span>No Users Found</span></div>}
                </div>}
                <div className="shareSpace">
                    <SelectBox className="half" name="icon" label="Icon" selected={this.state.icon.index} options={[
                        { name: "Pick One...", dontSelect: true },
                        { name: "None", value: ToastIcons.None },
                        { name: "Success (Green Checkmark)", value: ToastIcons.Success },
                        { name: "Warning (Yellow Exclamation)", value: ToastIcons.Warning },
                        { name: "Error (Red X)", value: ToastIcons.Error },
                        { name: "Info (Orange i)", value: ToastIcons.Info }
                    ]} onSelect={this.handleSelect} />
                    <SelectBox className="half" name="sound" label="Sound" selected={this.state.sound.index} options={[
                        { name: "Pick One...", dontSelect: true },
                        { name: "None", value: ToastSounds.None },
                        { name: "Chat Message Sound", value: ToastSounds.Chat },
                        { name: "User Joined Sound", value: ToastSounds.Join },
                        { name: "User Left Sound", value: ToastSounds.Leave },
                    ]} onSelect={this.handleSelect} />
                </div>
                <TextField className="grow" name="messageText" label="Message" placeholder="Enter your message..." value={this.state.messageText} onEdit={this.handleEdit} />
                <TextField className="static" name="duration" label="Duration (Seconds)" placeholder="Duration in seconds..." value={this.state.duration} onEdit={this.handleEdit} />
                <div className="shareSpace">
                    <TextField className="half" name="buttonText" label="Link Text (Optional)" placeholder="Link Description" value={this.state.buttonText} onEdit={this.handleEdit} />
                    <TextField className="half" type="url" name="buttonUrl" label="Link URL (Optional)" placeholder="Link URL" value={this.state.buttonUrl} onEdit={this.handleEdit} />
                </div>
                <div id="buttons">
                    <Button outline onClick={this.reset}>Clear</Button>
                    <Button role="submit" disabled={this.disabled()}>{this.state.sending ? "Sending..." : "Send"}</Button>
                </div>
                </form>
            </Section>
        );
    }
}

export default ConferenceToast;
