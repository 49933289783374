import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../hooks/useNav';
import { newDemo } from '../LumiAPI/APIs/core/conference.js';
// import { Token } from '../LumiAPI/APIs/auth';
// import AccountAPI from '../LumiAPI/APIs/internal/account';


import { getConfInfo } from '../LumiAPI/APIs/core/conference.js';
import Button from '../components/Button';
// import Password from '../components/Password';
import TextField from '../components/TextField';
import CordoniqContentWithToolbar from '../templates/CordoniqContentWithToolbar';
import Notification from '../tools/Notification';
import { ENV } from '../tools/envTools';

import CheckBoxLightGrey from '../images/check_light_grey.svg';
import CheckBoxGreen from '../images/check_box_green.svg';

import './css/inviteOthers.css';


class InviteOthers extends Component {

    state = {
        shareLink: "", 
        joinLink: "",
        copyState: 0,
        hubspotSubmissionGuid: ""
    };
    
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }
    
    disabled = () => {
    }

    copySuccess = () => {
        this.setState({copyState: 1});
        setTimeout(() => {
            this.setState({copyState: 0});
          }, "3000");
    }

    createAnonymousMeeting = () => {
        let metaData = {
            customerType: this.state.type,
            hubSpotSubmissionGuid: this.state.hubspotSubmissionGuid
        }
        newDemo("Cordoniq Demo", metaData).then(demo => {
            let apiDomain = ENV.getApiDomain();
            let links = {
                confId: demo.confId,
                shareLink: demo.presenterUrl.replace("connect", `connect${apiDomain}`),
                joinLink: demo.presenterUrl.replace("connect", `connect${apiDomain}`)
            };
            this.setState(links);
            if (ENV.isProd()) {
                ReactGA.event({
                    category: 'Demo Room Flow',
                    action: 'Anonymous Demo Requested',
                    label: ""
                });
            }
        }).catch(err => {
            new Notification({ id: "unknownError", message: err, type: "error" });
        });
    }

    copyLink = e => {
        e.preventDefault();
        let copyField = document.querySelector("#copyLinkField");
        let url = copyField.value;
        this.setState({copyBtnText: "Link copied"});
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.clipboard.writeText(url).then(
                        () => {
                            //success
                            this.copySuccess();
                        },
                        () => {
                            //failure
                        }
                    );
                }
            })
            .catch(() => {
                // Firefox does not support the clipboard-write permission, 
                // so use the old method if the above fails.
                copyField.select();
                document.execCommand("copy");
                window.getSelection().removeAllRanges();
                document.activeElement.blur();
                this.copySuccess();
            });
    }

    JoinNow = e => {
        e.preventDefault();

        if (ENV.isProd()) {
            ReactGA.event({
                category: 'JoinRoom',
                action: 'joinNow',
                label: this.state.confId
            });
        }
        getConfInfo(this.state.confId)
            .then(details => {
                let link = `https://connect${ENV.getApiDomain()}.cordoniq.com/join?id=${this.state.confId}&password=${this.state.password}&name=${this.state.userCn}`;
                window.location.assign(link);
            }).catch(err => {
                console.log(`getConfInfo error: ${err}`);
                new Notification({ id: "getConfInfo", message: "The Room ID does not exist or has already ended, please try again ", type: "error" });
            });
    };

    render() {
        
        return (
            // <CordoniqContentWithToolbar buttons={buttons} headerextra={<Button>What's up</Button>}>
            <CordoniqContentWithToolbar footerButtons={[]} >
                <section>
                    <div className="inviteOthers content">
                        <h1>Invite others to join your meeting</h1>
                        {/* marginLeft: '20px', marginRight: '20px', paddingBottom: '10vh' */}
                        <form>
                            <TextField 
                                id="copyLinkField" 
                                name="copyLinkField" 
                                className="noHover noFocus" 
                                variant="outlined"
                                value={this.state.shareLink} 
                                readOnly 
                                style={{
                                    backgroundImage: this.state.copyState === 0 
                                        ? `url(${CheckBoxLightGrey})`
                                        : `url(${CheckBoxGreen})`,
                                    borderColor: this.state.copyState === 0 
                                        ? `rgb(211, 211, 211)`
                                        : `rgb(80, 200, 120)`,
                                }}
                            />
                            
                            <Button className="secondary" id="copyLink" name="copyLink" onClick={this.copyLink}>Copy invite link</Button>
                            <Button className="primary" type="submit" id="joinNow" onClick={this.JoinNow}>Join meeting room now</Button>
                        </form>
                    </div>
                </section>
            </CordoniqContentWithToolbar>
        );
    }
}

export default withNav(InviteOthers);
