import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';

import Button from '../../../components/Button';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs';

import moodle1 from '../../../images/integrations/moodle/moodle-1.png';
import moodle2 from '../../../images/integrations/moodle/moodle-2.png';
import moodle3 from '../../../images/integrations/moodle/moodle-3.png';
import moodle4 from '../../../images/integrations/moodle/moodle-4.png';
import moodle5 from '../../../images/integrations/moodle/moodle-5.png';
import moodleLogo from '../../../images/integrations/moodleLogo.png';

import '../css/moodleLanding.css';

class Moodle extends Component {
    openLTI = () => {
        if (ENV.isProd()) {
            ReactGA.event({
                category: 'App Installation',
                action: 'Moodle'
            });
        }
        window.open(`https://developers${ENV.getApiDomain()}.cordoniq.com/Portal/Integrations/LTI`, "_self");
    };

    openCategory = e => {
        let category = e.target.innerHTML.toLowerCase();
        this.goTo(`/Integrations?category=${category}`);
    };

    componentDidMount() {
        document.title = "Moodle Integration | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <LandingPageWithBreadCrumbs id="moodleLanding" breadcrumbs={[
                { title: "App Integrations", path: "/Integrations" },
                { title: "Moodle" }
            ]} logo={moodleLogo} title="Moodle Integration" subtitle="Engage with your students through secure, HD video in Moodle">
                <div id="content">
                    <Button onClick={this.openLTI}>Add to Moodle</Button>
                    <p>The Cordoniq platform is designed for your online classroom. Simply add Cordoniq to your Moodle account and engage your classroom with high quality video meetings and a full suite of collaboration tools to help you be more efficient and productive.</p>
                    <h2>How to Add a Conference to an Activity</h2>
                    <img src={moodle1} alt="Setting up an External Tool in Moodle" />
                    <p className="caption">Add a conference to your activity with an External Tool</p>
                    <p>It's easy to add a conference to your activities. Once you've set up Cordoniq in Moodle, choose External Tool as your activity type.</p>
                    <img src={moodle2} alt="Select your external tool from the preconfigured tool option" />
                    <p className="caption">Select your app from the preconfigured tool option</p>
                    <p>Find your tool from the list of preconfigured tools or click the plus sign to add a new external tool.</p>
                    <img src={moodle3} alt="Select a conference or add a new one" />
                    <p className="caption">Select a conference or add a new one</p>
                    <p>Select a conference you have already created, or create a new one for your activity. Click "Submit", then save your activity.</p>
                    <h2>How to Join a Conference in Moodle</h2>
                    <img src={moodle4} alt="Activity with a conference" />
                    <p className="caption">Activity with a conference added</p>
                    <p>Once you save your activity, you'll see a button to open the conference. Teachers will have two buttons, one to open the conference as a teacher/presenter and one to open the conference as a student/attendee.</p>
                    <img src={moodle5} alt="Cordoniq join page" />
                    <p className="caption">Example: Cordoniq join page</p>
                    <p>Clicking either button in Moodle will open the join page. Now just click to open Cordoniq and your classroom is ready.</p>
                    <h2>Integration Features</h2>
                    <h3>Real-time Document and Screen Sharing</h3>
                    <p>Seamless integration for sharing over 70 document format types - no more scrambling to change files for your team. Share them immediately.</p>
                    <h3>Document Annotation</h3>
                    <p>Need to markup and change documents in real time? Cordoniq provides easy to use annotation tools that get the documents you need changed while the whole team participates.</p>
                    <h3>Whiteboarding</h3>
                    <p>Need to draw, design, or create great business or learning ideas? Utilize the Cordoniq whiteboarding features with easy-to-use tools, for quick and productive teamwork.</p>
                    <h3>Recording</h3>
                    <p>Record meetings for later reference. View your collaboration information whenever you need it, with recorded video meetings.</p>
                    <h2>Privacy & Security</h2>
                    <p>Cordoniq enhances your classroom with secure, end-to-end encryption utilizing industry standard protocols. API driven, efficient and easy-to-use, Cordoniq is the platform that supports business, education and training teams with the latest in video meeting collaboration.</p>
                </div>
                <aside>
                    <Button onClick={this.openLTI}>Add to Moodle</Button>
                    <h2>Categories</h2>
                    <div id="categories">
                        <span onClick={this.openCategory}>Education</span>
                    </div>
                    <h2>Support</h2>
                    <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                    <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                </aside>
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(Moodle);
