export const ReactTwit = {
	init: function (convId) {
		(function (e,t,n,s,u,a) {
			return e.twq || (
				s = e.twq = function () {
					s.exe ? s.exe.apply(s,arguments) : s.queue.push(arguments);
				},
				s.version = '1.1',
				s.queue = [],
				u = t.createElement(n),
				u.async = !0,
				u.src = '//static.ads-twitter.com/uwt.js',
				a = t.getElementsByTagName(n)[0],
				a === undefined
					? document.body.appendChild(u)
					: a.parentNode.insertBefore(u,a)
			)
		})(window,document,'script');
		window.twq('init', convId);
	},
	pageView: function () {
		window.twq && window.twq('track','PageView');
	}
};

export default ReactTwit;
