import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { resetPassword } from '../LumiAPI/APIs/internal/passwordReset';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';

import Button from '../components/Button';
import Password from '../components/Password';
import GraphicSidebar from '../partials/GraphicSidebar';

import Notification from '../tools/Notification';

import './css/welcomePage.css';

class PasswordReset extends Component {
	state = {
        resetHash: "",
        newPassword: "",
        confirmPassword: "",
        validating: true,
		validCode: true,
        processing: false
	};

	handleChange = field => this.setState({ [field.name]: field.value });

    disabled = () => {
        return this.state.newPassword === "" ||
               this.state.confirmPassword === "" ||
               this.state.processing;
    };

	submit = e => {
		e.preventDefault();
		this.setState({ processing: true });

        let { newPassword, confirmPassword } = this.state;

        if (newPassword !== confirmPassword) {
            this.setState({ processing: false });
            new Notification({ id: "notMatching", message: "Passwords do not match. Re-enter and try again", type: "warning" });
            return;
        }

        resetPassword(this.state.resetHash, newPassword).then(account => {
            this.goTo("/SignIn");
        }).catch(err => {
            console.log(err);
            new Notification({ id: "resetError", message: err.error_description, type: "error" });
            this.setState({ processing: false });
        });
	};

	componentDidMount() {
		document.title = "Reset Password | Cordoniq";

        let { resetHash } = this.props.match.params;
		// Currently no method to test if a resetCode is valid
        this.setState({ resetHash, validating: false });

        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);
	}

	componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
	}

  	render() {
	  	return (
		  	<main id="welcome" className="signInHelp">
		  		<GraphicSidebar show={true} processing={this.state.processing} />
		  		<section>
		  			<div className="content">
                        {this.state.validating
							? <>
							<h1>Validating reset link...</h1>
							<p>Checking our system to authorize password reset.</p>
							</>
							: !this.state.validCode
	                            ? <>
	                            <h1>Oops Something Went Wrong...</h1>
	                            <p>Either your reset link has expired or is invalid. Please try again</p>
								<Button type="button" onClick={() => this.goTo("/SignIn/Help")}>Request New Link</Button>
	                            </>
	                            : <>
	                            <h1>Choose a new password</h1>
	                            <p>Please enter a new password and click "Save Password" below.</p>
	                            <p>You will then be prompted to sign in with your new password.</p>
	                            <form onSubmit={this.submit} action="">
	                                <Password new name="newPassword" label="New Password" placeholder="Enter a new password..." value={this.state.newPassword} onEdit={this.handleChange} />
	                                <Password name="confirmPassword" label="Confirm Password" placeholder="Please confirm your new password..." value={this.state.confirmPassword} onEdit={this.handleChange} />
	                                <Button onClick={this.submit} type="submit" disabled={this.disabled()}>{this.state.processing ? "Saving Password..." : "Save Password"}</Button>
	                            </form>
	                            </>
                        }
		  			</div>
		  		</section>
		  	</main>
		);
	}
}

export default withNav(PasswordReset);
