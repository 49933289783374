import React, { Component } from 'react';
import { ColorUpdater, getColorScheme } from '../tools/colorSchemeManager.js';

import icons from '../images/sidebarIconLibrary.js';
import './css/iconEffects.css';

class SidebarIcon extends Component {
	objRef = React.createRef();

	state = {
		icon: null,
		colorMode: "dark",
		bg: this.props.bg || ""
	};

	updateColorMode = () => {
		var mode = getColorScheme();
		this.setState({ colorMode: mode });
	};

	getIcons = () => {
		var mode = this.props.mode ?? this.state.colorMode;
		return icons[this.props.icon][mode];
	};

	componentDidMount() {
		this.updateColorMode();
		ColorUpdater.add(this.updateColorMode);

		var icon = this.props.icon;
		this.setState({ icon: icon });
	}

	componentWillUnmount() {
		ColorUpdater.remove(this.updateColorMode);
	}

	componentDidUpdate(prevProps) {
		
	}

	render() {
		let { primary, hover, selected } = this.getIcons();
		return (
			<React.Fragment key={this.state.colorMode}>
			<div>
				<img 
					id='primary'
					alt={this.state.icon} 
					src={primary} 
					{...this.props} 
				/>
				<img 
					id='selected'
					alt={this.state.icon} 
					src={selected} 
					{...this.props} 
				/>
				<img 
					id='hover'
					alt={this.state.icon} 
					src={hover} 
					{...this.props} 
				/>
			</div>
			{this.props.text && <span className="iconLabel">{this.props.text}</span>}
			</React.Fragment>
		);
	}
}

export default SidebarIcon;
