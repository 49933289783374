import { faBuilding, faGlobeAmericas, faHome } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { Token } from '../LumiAPI/APIs/auth.js';
import { papi } from '../LumiAPI/APIs/config.js';
import { ColorUpdater, getColorScheme, setThemeColor } from '../tools/colorSchemeManager';
import firestore, { firebaseSignIn } from '../tools/firestore';
import { capitalize } from '../tools/stringTools';

import Button from '../components/Button';
import Icon from '../components/Icon';
import SelectBox from '../components/SelectBox';

import './css/logs.css';

class DebugLogs extends Component {
    state = {
        authenticating: true,
        logs: {
            localhost: [],
            staging: [],
            production: []
        },
        typeList: {
            localhost: {},
            staging: {},
            production: {}
        },
        serverList: {
            localhost: {},
            staging: {},
            production: {}
        },
        nodeList: {
            localhost: {},
            staging: {},
            production: {}
        },
        env: { index: 1, option: { value: "localhost"} },
        type: { index: 0, option: { value: "" } },
        server: { index: 0, option: { value: "" } },
        node: { index: 0, option: { value: "" } }
    };

    switchEnv = env => {
        let index = env === "localhost"
            ? 1 : env === "staging" ? 2 : 3;

        ["type","server","node"].forEach(filter => {
            let selectedIndex = this.state[filter].index,
                selectedValue = this.state[filter].option.value,
                list = this.state[filter+"List"];
            if (selectedIndex !== 0 && !list[env][selectedValue]) {
                this.setState({ [filter]: { index: 0, option: { value: "" } } });
            }
        });

        this.setState({ env: { index: index, option: { value: env } } });
    };

    envSelected = env => {
        return env === this.state.env.option.value ? "selected" : "";
    };

    clearLog = () => {
        let selectedEnv = this.state.env.option.value;
        let logs = this.state.logs[selectedEnv].filter(this.filter);

        this.setState({ type: { index: 0, option: { value: "" } }, node: { index: 0, option: { value: "" } } });

        logs.forEach(log => {
            firestore.collection(selectedEnv).doc(log.id).delete();
        });
    };

    typeOptions = () => {
        let options = [ { name: "Type...", dontSelect: true }, { name: "All Types", value: "" } ];
        Object.keys(this.state.typeList[this.state.env.option.value]).forEach(type => {
            options.push({ name: capitalize(type), value: type });
        });
        return options;
    };

    serverOptions = () => {
        let options = [ { name: "Server...", dontSelect: true }, { name: "All Servers", value: "" } ];
        Object.keys(this.state.serverList[this.state.env.option.value]).forEach(server => {
            options.push({ name: server, value: server });
        });
        return options;
    };

    nodeOptions = () => {
        let options = [ { name: "Node...", dontSelect: true }, { name: "All Nodes", value: "" } ];
        Object.keys(this.state.nodeList[this.state.env.option.value]).forEach(node => {
            options.push({ name: node, value: node });
        });
        return options;
    };

    filter = log => {
        let selectedType = this.state.type.option.value;
        let selectedServer = this.state.server.option.value;
        let selectedNode = this.state.node.option.value;

        let typeTest = selectedType === "" ? true : log.type === selectedType;
        let serverTest = selectedServer === "" ? true : log.server === selectedServer;
        let nodeTest = selectedNode === "" ? true : log.node === selectedNode;

        return typeTest && serverTest && nodeTest;
    };

    sort = (a, b) => {
        let dateA = moment(a.date.raw.toMillis());
        let dateB = moment(b.date.raw.toMillis());
        let results, tests = [ "year", "month", "date", "hour", "minute", "second", "millisecond" ];

        tests.some((test, i) => {
            results = dateA[test]() < dateB[test]() ? -1 : dateA[test]() > dateB[test]() ? 1 : 0;
            // if (i < 3) results *= -1; // Sort descending for date but not time
            results *= -1;
            if (i === tests.length - 1 && results === 0) {
                let nanoA = a.date.raw.valueOf();
                let nanoB = a.date.raw.valueOf();
                results = nanoA < nanoB ? -1 : nanoA > nanoB ? 1 : 0;
            }
            return results !== 0;
        });

        return results;
    };

    authenticate = () => {
        let token = Token.get();
        papi.get('/api/firestore/login', {
            params: { token }
        }).then(response => {
            let fbToken = response.data;
            firebaseSignIn(fbToken).then(user => {
                this.setState({ authenticating: false });
                this.loadFirestore();
            }).catch(err => console.log(err));
        }).catch(err => {
            this.goTo("/SignIn");
        });
    };

    loadFirestore = () => {
        let envs = Object.keys(this.state.logs);
        envs.forEach(env => {
            let collection = firestore.collection(env);
            collection.orderBy("Date").onSnapshot(querySnapshot => {
                let envLogs = [], types = {}, servers = {}, nodes = {};
                querySnapshot.forEach(doc => {
                    let log = {
                        id: doc.id,
                        date: {
                            raw: doc.data().Date,
                            formatted: moment(doc.data().Date.toMillis())
                        },
                        type: doc.data().Type,
                        server: doc.data().Server ?? 'LTI',
                        node: doc.data().Node,
                        message: doc.data().Message
                    };
                    envLogs.push(log);
                    types[log.type] = true;
                    servers[log.server] = true;
                    nodes[log.node] = true;
                });
                let logs = this.state.logs,
                    typeList = this.state.typeList,
                    serverList = this.state.serverList,
                    nodeList = this.state.nodeList;
                logs[env] = envLogs;
                typeList[env] = types;
                serverList[env] = servers;
                nodeList[env] = nodes;
                this.setState({ logs: logs, typeList: typeList, serverList: serverList, nodeList: nodeList });
            });
        });
    };

    setThemeColor = () => {
        let theme = getColorScheme();
        if (theme === "light") {
            setThemeColor(241, 241, 241);
        } else {
            setThemeColor(43, 39, 45);
        }
    }

    componentDidMount() {
        document.title = "Cordoniq Logs";
        this.authenticate();

        this.setThemeColor();
        ColorUpdater.add(this.setThemeColor);
    }

    componentWillUnmount() {
        ColorUpdater.remove(this.setThemeColor);
    }

    render() {
        let selectedEnv = this.state.logs[this.state.env.option.value].filter(this.filter);

        return (
            <>
            <main id="log">
                <header>
                    <Icon icon="logo" onClick={() => this.goTo("/Portal")} />
                    <h1>Lumi Logs</h1>
                    <div id="buttons">
                        <Button className={this.envSelected("localhost")} onClick={() => this.switchEnv("localhost")}><Icon icon={faHome} /><span>Localhost</span></Button>
                        <Button className={this.envSelected("staging")} onClick={() => this.switchEnv("staging")}><Icon icon={faBuilding} /><span>Staging</span></Button>
                        <Button className={this.envSelected("production")} onClick={() => this.switchEnv("production")}><Icon icon={faGlobeAmericas} /><span>Production</span></Button>
                    </div>
                </header>
                {!this.state.authenticating && <section>
                    <div id="tools">
                        {this.typeOptions().length > 3 && <SelectBox name="type" selected={this.state.type.index} options={this.typeOptions()}
                            onSelect={selection => this.setState({ type: selection })} />}
                        {this.serverOptions().length > 3 && <SelectBox name="server" selected={this.state.server.index} options={this.serverOptions()}
                            onSelect={selection => this.setState({ server: selection })} />}
                        {this.nodeOptions().length > 3 && <SelectBox name="node" selected={this.state.node.index} options={this.nodeOptions()}
                            onSelect={selection => this.setState({ node: selection })} />}
                        <Button onClick={this.clearLog} disabled={selectedEnv.length === 0}>Clear Log</Button>
                    </div>
                    {selectedEnv.sort(this.sort).map((log, i) => (
                        <>
                        {(i === 0 || (selectedEnv[i].date.formatted.date() !== selectedEnv[i - 1]?.date.formatted.date() ?? false)) && <span>{log.date.formatted.format("ll")}</span>}
                        <div className={log.type}><b>{log.server ? `${log.server} ` : ""}{log.node ? `${log.node} - ` : ""}{log.date.formatted.format("LT")}</b><pre>{log.message}</pre></div>
                        </>
                    ))}
                    {selectedEnv.length === 0 && <><span>{moment().format('ll')}</span><div id="noLogs">No Logs Found</div></>}
                </section>}
                {this.state.authenticating && <section>
                    <div id="loading">
                        <div className="spinner"></div>
                        <span>Authenticating</span>
                    </div>
                </section>}
            </main>
            </>
        );
    }
}

export default withNav(DebugLogs);
