import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';
import { ColorUpdater, setNeutralThemeColor } from '../../tools/colorSchemeManager';

import Section from '../../partials/Section';
import ContentWithToolbar from '../../templates/ContentWithToolbar';

import Button from '../../components/Button';
import Icon from '../../components/Icon';

import './css/redirect.css';

class NotFound extends Component {
	state = {
		noToolbar: this.props.noToolbar
	};

	componentDidMount() {
		document.title = "Page Not Found | Cordoniq";
		setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);
	}

	componentWillUnmount() {
		ColorUpdater.remove(setNeutralThemeColor);
	}

	render() {
		return (<>
			{this.state.noToolbar
				? <main id="redirect" className="noToolbar">
					<Icon icon="logoFull" mode="light" />
					<section>
						<h1>Oops! Page Not Found</h1>
						<p>The page "{window.location.pathname}" does not exist!</p>
						<Button onClick={() => this.goTo("/Portal")}>Go to Dashboard</Button>
					</section>
				</main>
				: <ContentWithToolbar id="redirect" noSidebar>
					<Section title="Oops! Page Not Found">
						<p>The page "{window.location.pathname}" does not exist!</p>
						<Button onClick={() => this.goTo("/Portal")}>Go to Dashboard</Button>
					</Section>
				</ContentWithToolbar>
			}
		</>);
	}
}

export default withNav(NotFound);
