import React, { Component } from 'react';
import { withTouch } from '../../tools/touchTools.js';

import Icon from '../../components/Icon';

import './css/slides.css';

const bumperSize = 30;

class Slides extends Component {
    loop = null;

    state = {
        selected: 0,
        containerWidth: 0,
        slideWidth: 800,
        slidePosition: "0px",
        resizing: false
    };

    prev = () => {
        let selected = this.state.selected;
        if (selected === 0) return;
        this.getPosition(selected - 1);
    };

    next = () => {
        let selected = this.state.selected;
        if (selected === this.props.slides.length - 1) return;
        this.getPosition(selected + 1);
    };

    getPosition = selected => {
        let { slideWidth } = this.state;
        let position = selected === 0
            ? (selected * -slideWidth)
            : selected === this.props.slides.length - 1
                ? (selected * -slideWidth) + (bumperSize / 2)
                : (selected * -slideWidth) + (bumperSize / 4);
        this.setState({
            resizing: false,
            selected: selected,
            slidePosition: `${position}px`
        });
    };

    resizing = e => {
        this.setState({ containerWidth: 0 });
        let parentWidth = document.querySelector(".mainSlides").parentElement.clientWidth;
        let slideWidth = parentWidth - (bumperSize * 1.5);
        this.setState({ containerWidth: parentWidth, slideWidth });
    };

    onSwipe = direction => {
        switch (direction) {
            case 'left':
                this.next();
                break;
            case 'right':
                this.prev();
                break;
            default:
                return;
        }
    };

    componentDidMount() {
        this.resizing();
        window.addEventListener("resize", this.resizing);
        this.addTouchEvents(document.querySelector(".mainSlides"));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizing);
        this.removeTouchEvents(document.querySelector(".mainSlides"));
    }

    render() {
        let firstSelected = this.state.selected === 0;
        let lastSelected = this.state.selected === this.props.slides?.length - 1;

        return (
            <div className="mainSlides">
                <div className={this.state.resizing ? "slideContainer resizing" : "slideContainer"} style={{ left: this.state.slidePosition, width: `${this.state.containerWidth}px` }}>
                    {this.props.slides?.map((slide, i) => (
                        <img alt={"slide_"+i} src={slide} style={{ width: `${this.state.slideWidth}px` }} />
                    ))}
                </div>
                {this.props.slides?.length > 1 && <>
                    <Icon id="left" className={firstSelected && "disabled"} icon="arrowLeft" mode={firstSelected ? "dark" : "light"} onClick={this.prev} />
                    <Icon id="right" className={lastSelected && "disabled"} icon="arrowRight" mode={lastSelected ? "dark" : "light"} onClick={this.next} />
                </>}
            </div>
        );
    }
}

export default withTouch(Slides);
