import { api, allDetails } from '../config.js';
import { Token } from '../auth.js';

export const getServer = url => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/server/${url}`, allDetails)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getServerLocation = (confId, ip) => {
    return new Promise((resolve, reject) => {
        let options = { params: { id: confId, ip: ip } };
        api(Token.get()).get("/server/locinfo", options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateServer = (url, changes) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/server/${url}`, changes)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const deleteServer = url => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/server/${url}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const getServers = () => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get("/servers", allDetails)
            .then(response => resolve(response.data.items))
            .catch(err => resolve([]));
    });
};

const ServerAPI = {
    server: {
        get: getServer,
        getLocation: getServerLocation,
        update: updateServer,
        delete: deleteServer
    },
    servers: {
        get: getServers
    }
}

export default ServerAPI;
