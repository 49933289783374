import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../../../hooks/useNav';
import { ENV } from '../../../tools/envTools';

import Button from '../../../components/Button';

import LandingPageWithBreadCrumbs from '../../templates/LandingPageWithBreadCrumbs';

import bb1 from '../../../images/integrations/blackboard/bb-1.png';
import bb2 from '../../../images/integrations/blackboard/bb-2.png';
import bb3 from '../../../images/integrations/blackboard/bb-3.png';
import bb4 from '../../../images/integrations/blackboard/bb-4.png';
import bb5 from '../../../images/integrations/blackboard/bb-5.png';
import bb6 from '../../../images/integrations/blackboard/bb-6.png';
import bb7 from '../../../images/integrations/blackboard/bb-7.png';
import blackboardLogo from '../../../images/integrations/blackboardLogo.png';

import '../css/blackboardLanding.css';

class Blackboard extends Component {
    openLTI = () => {
        if (ENV.isProd()) {
            ReactGA.event({
                category: 'App Installation',
                action: 'Blackboard'
            });
        }
        window.open(`https://developers${ENV.getApiDomain()}.cordoniq.com/Portal/Integrations/LTI`, "_self");
    };

    openCategory = e => {
        let category = e.target.innerHTML.toLowerCase();
        this.goTo(`/Integrations?category=${category}`);
    };

    componentDidMount() {
        document.title = "Blackboard Integration | Cordoniq";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <LandingPageWithBreadCrumbs id="blackboardLanding" breadcrumbs={[
                { title: "App Integrations", path: "/Integrations" },
                { title: "Blackboard" }
            ]} logo={blackboardLogo} title="Blackboard Integration" subtitle="Engage with your students through secure, HD video in Blackboard">
                <div id="content">
                    <Button onClick={this.openLTI}>Add to Blackboard</Button>
                    <p>The Cordoniq platform is designed for your online classroom. Simply add Cordoniq to your Blackboard account and engage your classroom with high quality video meetings and a full suite of collaboration tools to help you be more efficient and productive.</p>
                    <h2>How to Connect Blackboard to Cordoniq</h2>
                    <img src={bb2} alt="Sign into your Cordoniq account" />
                    <p className="caption">Sign into your Cordoniq account (first time only)</p>
                    <p>If you're using this integration for the first time and you attempt to select a conference, you will be asked to sign into your Cordoniq account.</p>
                    <img src={bb3} alt="Grant permission" />
                    <p className="caption">Give Blackboard permission to connect</p>
                    <p>After signing in, select the app you want to connect with and give Blackboard permission to create a new integration.</p>
                    <img src={bb4} alt="Describe your new integration" />
                    <p className="caption">Choose a description for your integration</p>
                    <p>The last step for the intial set up is to give your new integration a description. You will be able to find this integration in your Cordoniq account and change it at any time.</p>
                    <h2>How to Add a Conference Link to Blackboard</h2>
                    <img src={bb1} alt="Add conference link with 'Select a conference..' command" />
                    <p className="caption">Add a Cordoniq conference to your content library</p>
                    <p>It's easy to add a conference to your assignments. Once you've set up Cordoniq in Blackboard, choose "Build Content" from your content library. Then find the option "Select a conference...".</p>
                    <img src={bb5} alt="Choose a conference or create a new one" />
                    <p className="caption">Select a conference or create a new one</p>
                    <p>Select an existing conference from the list or create a new one. You also have the option to choose your own presenter password.</p>
                    <img src={bb6} alt="New conference in content library" />
                    <p className="caption">Conference linked to your content library</p>
                    <p>After submitting, you'll find your conference link in your content library with the rest of the course content.</p>
                    <img src={bb7} alt="Cordoniq join page" />
                    <p className="caption">Example: Cordoniq join page</p>
                    <p>Clicking the link in Blackboard will open the join page. Now just click to open Cordoniq and your classroom is ready.</p>
                    <h2>Integration Features</h2>
                    <h3>Real-time Document and Screen Sharing</h3>
                    <p>Seamless integration for sharing over 70 document format types - no more scrambling to change files for your team. Share them immediately.</p>
                    <h3>Document Annotation</h3>
                    <p>Need to markup and change documents in real time? Cordoniq provides easy to use annotation tools that get the documents you need changed while the whole team participates.</p>
                    <h3>Whiteboarding</h3>
                    <p>Need to draw, design, or create great business or learning ideas? Utilize the Cordoniq whiteboarding features with easy-to-use tools, for quick and productive teamwork.</p>
                    <h3>Recording</h3>
                    <p>Record meetings for later reference. View your collaboration information whenever you need it, with recorded video meetings.</p>
                    <h2>Privacy & Security</h2>
                    <p>Cordoniq enhances your classroom with secure, end-to-end encryption utilizing industry standard protocols. API driven, efficient and easy-to-use, Cordoniq is the platform that supports business, education and training teams with the latest in video meeting collaboration.</p>
                </div>
                <aside>
                    <Button onClick={this.openLTI}>Add to Canvas</Button>
                    <h2>Categories</h2>
                    <div id="categories">
                        <span onClick={this.openCategory}>Education</span>
                    </div>
                    <h2>Support</h2>
                    <p><a href="mailto:support@cordoniq.com">support@cordoniq.com</a></p>
                    <p><a href="https://www.cordoniq.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p><a href="https://www.cordoniq.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                </aside>
            </LandingPageWithBreadCrumbs>
        );
    }
}

export default withNav(Blackboard);
