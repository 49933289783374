import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../hooks/useNav';
import { getConfInfo } from '../LumiAPI/APIs/core/conference.js';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';
import { ENV } from '../tools/envTools';
import { checkProtocolSupport } from '../tools/protocol_detection';
import Query from '../tools/Query';
import { capitalize } from '../tools/stringTools';

import Button from '../components/Button';
import TextField from '../components/TextField';
import GraphicSidebar from '../partials/GraphicSidebar';

const TERMS = {
    education: {
        room: "classroom",
        attendee: "student",
        attendees: "students",
        presenter: "teacher"
    },
    corporate: {
        room: "meeting",
        attendee: "coworker",
        attendees: "coworkers",
        presenter: "presenter"
    },
    general: {
        room: "room",
        attendee: "guest",
        attendees: "guests",
        presenter: "presenter"
    }
};

class JoinRoom extends Component {
    state = {
        type: "general",
        confId: "",
        invalid: false
    };

    handleEdit = change => this.setState({ [change.name]: change.value, invalid: false });

    joinNow = e => {
        e.preventDefault();

        let confId = this.extractConferenceId() ?? this.state.confId.toLowerCase();

        if (ENV.isProd()) {
            ReactGA.event({
                category: 'JoinRoom',
                action: 'joinNow',
                label: this.state.confId
            });
        }

        getConfInfo(confId)
            .then(details => {
                let link = `https://connect${ENV.getApiDomain()}.cordoniq.com/join?id=${details.confId}`;
                let protocolUrl = link.replace("https://", "cordoniq://");
                checkProtocolSupport(protocolUrl,
                    () => window.open(link, '_blank'),
                    () => { return protocolUrl },
                    () => window.open(link, '_blank')
                );
            }).catch(err => this.setState({ invalid: true }));
    };

    extractConferenceId = () => {
        let confIdorUrl = this.state.confId.toLowerCase();
        let result = null;
        // Try to determine if this is a valid conference URL...
        if (confIdorUrl.indexOf("join?") !== -1) {
            // Ensure that it starts with a scheme..
            if (confIdorUrl.indexOf("://") <= 0) {
                confIdorUrl = `https://${confIdorUrl}`;
            }
            try {
                let url = new URL(confIdorUrl);
                result = url.searchParams.get('id');
            }
            catch(err) {
                console.error(`"${confIdorUrl}" is not a valid url`);
            }
        }
        return result;
    }

    componentDidMount() {
        document.title = "Join Room | Cordoniq";

        let query = new Query(this.props.location.state?.query ?? window.location.search);
        let id = query.get("id");
        if (id !== undefined) this.setState({ confId: id });

        let types = Object.keys(TERMS);
		let type = this.props.match?.params?.type?.toLowerCase() ?? this.props?.type?.toLowerCase() ?? "general";
        if (!types.includes(type)) this.goTo("/JoinRoom");
        else this.setState({ type: type });

        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);

        let firstField = document.querySelector("#welcome section .content form input:first-of-type");
        firstField.focus();
    }

    componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
	}

    render() {
        let terms = TERMS[this.state.type];

        return (
            <main id="welcome" className="joinRoom">
                <GraphicSidebar show="true" />
                <section>
                    <div className="content">
                        <h1>Enter {capitalize(terms.room)}</h1>
                        {/* marginLeft: '20px', marginRight: '20px', paddingBottom: '10vh' */}
                        <p style={{ margin: '40px 20px 40px 20px'}}>If you were provided a link or {capitalize(terms.room)} ID to join a video collaboration meeting, please enter it below.</p>
                        <form>
                            <TextField name="confId" placeholder={`Enter link or ${capitalize(terms.room)} ID`} value={this.state.confId} onEdit={this.handleEdit} />
                            {this.state.invalid && <p>Invalid {terms.room} ID. Try again.</p>}
                            <p className="disclaimer">By clicking Join Now, you agree to the <Button link href="https://www.cordoniq.com/terms-of-use/">Terms Of Use</Button> and <Button link href="https://www.cordoniq.com/privacy-policy/">Privacy Policy</Button>.</p>
                            <Button onClick={this.joinNow}>Join now</Button>
                        </form>
                    </div>
                </section>
            </main>
        );
    }
}

export default withNav(JoinRoom);
