import { api, papi, upload, allDetails, imgOptions } from '../config.js';
import { Token, signin } from '../auth.js';

export const roles = { "ATTENDEE": 1, "PRESENTER": 2, "HOST": 3, "OWNER": 4 };
export const rolesText = { 1: "Attendee", 2: "Presenter", 3: "Host", 4: "Owner" };

export const signup = accountInfo => {
    return new Promise((resolve, reject) => {
        api().post("/account", accountInfo).then(response => {
            signin(accountInfo).then(resolve).catch(reject);
        }).catch(reject);
    });
};

export const getAccount = () => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get("/account", allDetails)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateAccount = changes => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put("/account", changes)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getPrivateKey = responseType => {
    return new Promise((resolve, reject) => {
        let options = responseType === "json" ? undefined : { responseType: 'blob' };
        api(Token.get()).get("/account/privateKey", options)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const setProfilePic = (file, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        let options = imgOptions(onUploadProgress);
        upload(Token.get()).post("/account/picture", file, options)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const removeProfilePic = () => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete("/account/picture")
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const createSubAccount = accountInfo => {
    return new Promise((resolve, reject) => {
        api(Token.get()).post("/subaccount", accountInfo)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getSubAccount = username => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get(`/subaccount/${username}`, allDetails)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateSubAccount = (username, changes) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/subaccount/${username}`, changes)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const deleteSubAccount = username => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/subaccount/${username}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const getSubAccounts = () => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get("/subaccounts", allDetails)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

export const isFirestoreAdmin = async username => {
    try {
        let { data } = await papi.get(`/api/firestore/isadmin/${username}`);
        return data;
    } catch (e) {
        throw e;
    }
};

const AccountAPI = {
    roles,
    rolesText,
    account: {
        signup,
        get: getAccount,
        update: updateAccount,
        getPrivateKey,
        setProfilePic,
        removeProfilePic
    },
    subaccount: {
        new: createSubAccount,
        get: getSubAccount,
        update: updateSubAccount,
        delete: deleteSubAccount
    },
    subaccounts: {
        get: getSubAccounts
    },
    isFirestoreAdmin
};

export default AccountAPI;
