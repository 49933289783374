import React, { Component } from 'react';
import './css/tourDialog.css';

import Button from '../components/Button';
import tourImg from '../images/graphics/startTour.png';

class TourDialog extends Component {
	state = {
		show: false
	};

	show = () => this.setState({ show: true });

	hide = () => this.setState({ show: false });

	openTour = () => {
		this.props.setTour(true);
		this.hide();
	}

	render () {
		return this.state.show
			? <div id="tourContainer">
				<div id="tour">
					<img src={tourImg} alt="Illustration of teacher" />
					<div>
						<h1>Welcome to Cordoniq</h1>
						<p>Hi my name is Lucien, and I will be your tour guide. Let's take a walkthrough together as I show you what is possible. Click below to get started.</p>
						<div className="footer">
							<Button outline={true} onClick={this.hide}>SKIP THE TOUR</Button>
							<Button fill onClick={this.openTour}>Take a Quick Tour</Button>
						</div>
					</div>
				</div>
			</div>
			: null;
	}
}

export default TourDialog;
