import React, { Component } from 'react';

import './css/radio.css';

class Radio extends Component {
	state = {
		selected: this.props.selected ?? "auto"
	};

	getSelection = () => {
		var selected = this.state.items[this.state.selected];
		return selected.value;
	};

	handleClick = e => {
		var value = e.target.htmlFor;
		if (this.props.onChange) this.props.onChange(value);
		this.setState({ selected: value });
	};

	render () {
		return (
			<div className={this.props.vertical ? "radio vertical" : "radio"}>
				{this.props.options.map(item => {
					var selected = item.value === this.state.selected;
					return (
						<React.Fragment key={item.name}>
						<input type="radio" id={item.value} name={this.props.name} value={item.value} checked={selected} disabled={this.props.disabled || undefined} />
						<label htmlFor={item.value}  onClick={this.handleClick}>
							<div className={selected ? "radioBtn selected" : "radioBtn"}>
								<div className="ripple"></div>
								<div className="button"></div>
							</div>
							{item.label}
						</label>
						</React.Fragment>
					)
				})}
			</div>
		);
	}
}

export default Radio;
