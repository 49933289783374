import closeIconWhite from '../images/close_dark.svg';
import closeIconBlack from '../images/close_light.svg';

import alertWhite from '../images/alert_dark.svg';
import alertBlack from '../images/alert_light.svg';

class Notification {
    notification = null;

    constructor(data) {
        this.notification = document.createElement("div");
        this.notification.id = data.id;
        this.notification.classList.add("notification");
        this.notification.classList.add(data.type);
        let icon = document.createElement("img");
        icon.src = data.type === "error" ? alertWhite : alertBlack;
        let message = document.createElement("p");
        message.innerHTML = data.message;
        let close = document.createElement("img");
        close.src = data.type === "error" ? closeIconWhite : closeIconBlack;
        close.onclick = () => this.close();
        if (data.type !== "success") this.notification.appendChild(icon);
        this.notification.appendChild(message);
        this.notification.appendChild(close);
        document.querySelector("#root").appendChild(this.notification);
        setTimeout(this.close, 3000);
    }

    close = () => {
        this.notification.remove();
    };
}

export default Notification;
