import React, { Component } from 'react';
import { ENV } from '../../tools/envTools.js';

import Button from '../../components/Button';
import Icon from '../../components/Icon';

import "./css/cordoniqFooter.css";

const apiDomain = ENV.getApiDomain();

class CordoniqFooter extends Component {
    open = (path) => {
        path = path ?? "/";
        let url = `https://www${apiDomain}.cordoniq.com/${path}`;
        window.open(url, "_blank");
    };

    render() {
        return (
            <footer id="cordoniqFooter">
                <div id="linksContainer">
                    <Button link onClick={() => this.open("privacy-policy/")}>Privacy policy</Button>
                    <Button link onClick={() => this.open("terms-of-use/")}>Terms of use</Button>
                    <span>Copyright &copy; {new Date().getFullYear()} Cordoniq</span>
                </div>
                <div id="compactLinksContainer">
                    <Icon icon="logoFull" onClick={() => this.open("/")} />
                    <span>Copyright &copy; {new Date().getFullYear()} Cordoniq</span>
                    <div>
                        <Button link onClick={() => this.open("privacy-policy/")}>Privacy policy</Button>
                        <Button link onClick={() => this.open("terms-of-use/")}>Terms of use</Button>
                    </div>
                </div>
                <p>All product and company names are trademarks™ or registered® trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.</p>
            </footer>
        );
    }
}

export default CordoniqFooter;
