import React from 'react';
import ReactDOM from 'react-dom';

import Dialog from '../components/Dialog';
import Button from '../components/Button';

const Modal = (content, actions, props) => {
    let container = document.createElement("div");
    container.id = "portalModalDialog";
    document.body.appendChild(container);

    const execute = action => {
        let closeModal = action?.() ?? true;
        if (closeModal !== false) {
            ReactDOM.unmountComponentAtNode(container);
            document.querySelector("#portalModalDialog").remove();
        }
    };

    let dialog = <Dialog {...props} show={true} footer={actions.map((action, i) => (
        <Button key={`modal_${i}`} {...action.props} onClick={() => execute(action.props.onClick)}>
            {action.name}
        </Button>
    ))}>{content}</Dialog>;

    ReactDOM.render(dialog, container);

    return dialog;
};

export default Modal;
