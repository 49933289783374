import React, { Component } from 'react';
import { roles } from '../LumiAPI/APIs/internal/account.js';
import { PORTAL } from '../tools/envTools';

import Button from '../components/Button';

import { connect } from 'react-redux';

import './css/navigationTour.css';

const mobileMenu = window.matchMedia("(max-width: 950px)"); //640

class NavigationTour extends Component {
    state = {
        role: this.props.curRole ?? roles.OWNER,
        mobile: mobileMenu.matches,
        show: this.props.show ?? false,
        currentSelection: 0,
        overlays: [
            {
                id: "toolbar",
                title: "Toolbar",
                description: "The Cordoniq toolbar gives you quick access to your app list, support, docs and your profile.",
                altDescription: "The Cordoniq toolbar gives you quick access to support and your profile any time."
            },
            {
                id: "appList",
                title: "App List",
                description: "This is the main list of all apps you have created in Cordoniq. You can switch between them at any time using this drop down menu. Add a new app by selecting \"Add New App\" from the menu.",
                level: roles.OWNER
            },
            {
                id: "links",
                title: "Account & Quick Links",
                description: "Click on your profile picture to access your account settings and quick links to helpful resources.",
                level: roles.OWNER
            },
            {
                id: "sidebar",
                title: "Feature Pages",
                description: "The main pages that demonstrate the features and capabilities of Cordoniq."
            },
            {
                id: "dashboard",
                title: "Dashboard",
                description: "Your dashboard provides a summary of other features on Cordoniq like the app details, available files from your library, and upcoming conferences."
            },
            {
                id: "conferences",
                title: "Conferences",
                description: "Create, edit, and manage all aspects of the conferences created for the selected app you’re working in.",
                altDescription: "Manage your conferences that are associated with your app here."
            },
            {
                id: "roles",
                title: "Roles",
                description: "Roles allows you to manage other users you create to access your app. You control what they can access and do during a conference.",
                level: roles.OWNER
            },
            {
                id: "recordings",
                title: "Recordings",
                description: "Access recordings from your conferences to view, download, or share with others."
            },
            {
                id: "library",
                title: "Library",
                description: "A collection of the files that can be shared and presented during a conference."
            },
            {
                id: "integrations",
                title: "Integrations",
                description: "Find out how to use Cordoniq with the apps you use everyday.",
                level: roles.OWNER,
                hidden: PORTAL.isMy()
            },
            {
                id: "downloads",
                title: "Downloads",
                description: "Download the Cordoniq app and other necessary tools for development.",
                altDescription: "Download the Cordoniq app here."
            },
            {
                id: "settings",
                title: "Settings",
                description: "Manage the app details, retrieve API keys, and set up custom backend servers for further development.",
                altDescription: "Manage your profile and personal settings here."
            }
        ]
    };

    show = () => this.setState({ show: true });
    hide = () => this.setState({ show: false, currentSelection: 0 });

    checkMobile = e => {
        this.setState({ mobile: e.matches });
        if (e.matches && this.state.currentSelection > 3) {
            this.setState({ currentSelection: 3 });
        }
    };

    nextDialog = () => {
        const current = this.state.currentSelection;
        const skipPages = this.state.mobile && current === 3;
        const lastDialog = current === this.state.overlays.length - 1;
        if (skipPages || lastDialog) {
            this.props?.setTour(false) ?? this.hide();
        } else {
            var newSelection, i = 1;
            while (newSelection === undefined) {
                var level = this.state.overlays[current + i].level ?? this.state.role;
                if (level > this.state.role) {
                    i++;
                } else if (this.state.overlays[current + i]?.hidden ?? false) {
                    i++;
                } else {
                    newSelection = current + i;
                    this.setState({ currentSelection: newSelection });
                    if (newSelection >= this.state.overlays.length) {
                        this.props?.setTour(false) ?? this.hide();
                    }
                }
            }
        }
    };

    showPaginator = i => {
        var skippedPages = this.state.mobile && i > 3;
        if (skippedPages) {
            return false;
        } else {
            var level = this.state.overlays[i]?.level ?? this.state.role;
            if (level > this.state.role) {
                return false;
            } else if (this.state.overlays[i]?.hidden ?? false) {
                return false;
            } else {
                return true;
            }
        }
    };

    openDialog = i => this.setState({ currentSelection: i });

    getClass = () => {
        let className = "show ";
        className += this.state.role < roles.OWNER ? "alt " : "";
        className += this.props.hideNavBar ? "noNav " : "";
        className += PORTAL.isMy() ? "my " : "";
        className += this.state.overlays[this.state.currentSelection].id;
        return className;
    };

    componentDidMount() {
        mobileMenu.addListener(this.checkMobile);
    }

    componentWillUnmount() {
        mobileMenu.removeListener(this.checkMobile);
    }

    componentDidUpdate(prevProps) {
        if (this.props.curRole !== prevProps.curRole) {
            this.setState({ role: this.props.curRole });
        }

        if (this.props.show !== prevProps.show) {
            this.setState({ show: this.props.show });
        }
    }

    render () {
        const loadAlternatives = this.state.role < roles.OWNER;
        return this.state.show
            ? <div id="portalTour" className={this.getClass()}>
                {this.state.overlays.map((overlay, i) => (
                    <div id={overlay.id} key={overlay.id} className="overlay">
                        <div className="dialogBox">
                            <h1>{overlay.title}</h1>
                            <p>{loadAlternatives ? (overlay.altDescription ?? overlay.description) : overlay.description}</p>
                            <div className="paginator">
                                {this.state.overlays.map((overlay, j) => (
                                    <React.Fragment key={overlay.id+j}>
                                    {this.showPaginator(j) && <div className={j === this.state.currentSelection ? "selected" : ""}
                                         onClick={() => this.openDialog(j)}></div>}
                                    </React.Fragment>
                                ))}
                            </div>
                            <footer>
                                {((!this.state.mobile && i < this.state.overlays.length - 1) || (this.state.mobile && i < 3)) && <Button outline onClick={this.hide}>Close</Button>}
                                <Button onClick={this.nextDialog}>{i === this.state.overlays.length - 1 || (i === 3 && this.state.mobile) ? "Finished" : "Next"}</Button>
                            </footer>
                        </div>
                    </div>
                ))}
            </div>
            : null;
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole
});

export default connect(mapStateToProps)(NavigationTour);
