import moment from 'moment';
import React, { Component } from 'react';
import { getReleaseNotes } from '../LumiAPI/APIs/internal.js';

import Icon from '../components/Icon';
import Placeholder from '../components/Placeholder';

import '../partials/css/toolbar.css';
import '../templates/css/contentWithToolbar.css';
import './css/releaseNotes.css';

class ReleaseNotes extends Component {
    state = {
        notes: []
    };

    getNotes = () => {
        getReleaseNotes().then(notes => this.setState({ notes: notes }));
    };

    getTaskString = tasks => {
        if (tasks === undefined || tasks.length === 0) return "";
        let taskString = "Related Tasks: [COR - ";
        tasks.forEach((task, i) => {
            taskString += i === 0 ? "" : ", ";
            taskString += task;
        });
        taskString += "]";
        return taskString;
    };

    componentDidMount() {
		document.title = "Release Notes | Cordoniq";
        this.getNotes();
	}

    render () {
        return (
            <div id="newportal" className="releaseNotes">
            <header>
				<Icon id="cordoniqLogo" alt="Cordoniq Logo" icon="logo" mode="dark" />
				<h1>Cordoniq</h1>
				<h2>Release Notes</h2>
			</header>
            <main>
                {this.state.notes.map(release => (
                    <>
                    <h2>Release {release.version} - {moment(release.date).format('ll')}</h2>
                    {release.description && release.description.map(p => (
                        <p dangerouslySetInnerHTML={{ __html: p }} />
                    ))}
                    <ul>
                    {release.new && <li>
                        What's new
                        <ul>
                            {release.new.map(item => (
                                <li>{item.description}<br />{item.tasks && <span>{this.getTaskString(item.tasks)}</span>}</li>
                            ))}
                        </ul>
                    </li>}
                    {release.fixes && <li>
                        Bug fixes
                        <ul>
                            {release.fixes.map(item => (
                                <li>{item.description}<br />{item.tasks && <span>{this.getTaskString(item.tasks)}</span>}</li>
                            ))}
                        </ul>
                    </li>}
                    </ul>
                    </>
                ))}
                {this.state.notes.length === 0 && <>
                    <Placeholder heading />
                    <Placeholder />
                    <Placeholder />
                </>}
            </main>
            </div>
        );
    }
}

export default ReleaseNotes;
