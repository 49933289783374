import React, { Component } from 'react';
import WebApp from '../../LumiAPI/APIs/internal/webApp';

import User from './User';

import './css/userList.css';

class UserList extends Component {
    state = {
        loading: true,
        users: {}
    };

    toggleMenu = userId => {
        let users = this.state.users,
            menuOpen = !users[userId]?.menuOpen ?? true;
        users[userId].menuOpen = menuOpen;
        this.setState({ users });
    };

    getUsers = () => {
        let userObj = {};
        this.setState({ loading: true });
        WebApp.users.get(this.props.intId, this.props.confId).then(users => {
            users.forEach(user => {
                userObj[user.userId] = user;
            });
            this.setState({ users: userObj, loading: false });
        });
    };

    componentDidMount() {
        this.getUsers();
        WebApp.listeners.add("users", this.getUsers);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.intId !== prevProps.intId) ||
            (this.props.confId !== prevProps.confId)
        ) {
            this.getUsers();
        }
    }

    componentWillUnmount() {
        WebApp.listeners.remove("users", this.getUsers);
    }

    render () {
        let users = this.state.users,
            hostIds = Object.keys(users).filter(userId => (users[userId].role === 3)),
            presenterIds = Object.keys(users).filter(userId => (users[userId].role === 2)),
            attendeeIds = Object.keys(users).filter(userId => (users[userId].role === 1));

        return (<>
            {!this.state.loading && <ul>
                {hostIds.length > 0 && <li className="header">Hosts</li>}
                {hostIds.map(userId => <User user={users[userId]} />)}
                {presenterIds.length > 0 && <li className="header">Presenters</li>}
                {presenterIds.map(userId => <User user={users[userId]} />)}
                {attendeeIds.length > 0 && <li className="header">Attendees</li>}
                {attendeeIds.map(userId => <User user={users[userId]} />)}
                {Object.keys(users).length === 0 && <li className="header" style={{ border: "none"}}>No Users Found</li>}
            </ul>}
            {this.state.loading && <ul><li className="header" style={{ border: "none"}}>Loading...</li></ul>}
        </>);
    }
}

export default UserList;
