import React, { Component } from 'react';
import { roles } from '../LumiAPI/APIs/internal/account.js';
import { withNav } from '../hooks/useNav';

import Icon from '../components/Icon';
import Placeholder from '../components/Placeholder';

import { connect } from 'react-redux';

import './css/dashHeader.css';

class DashHeader extends Component {
    state = { app: this.props.app };

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.setState({ app: this.props.app });
        }
    }

    render() {
        return (
            <section id="dashHeader">
                {this.state.app
                    ? (this.state.app?.logoImgUrl ?? false)
                        ? <div id="appLogo"><img alt="App Icon" src={this.state.app.logoImgUrl} /></div>
                        : ""
                    : <Placeholder id="appLogo" light circle />
                }
                <div>
                    <h1>{this.state.app?.title ?? <Placeholder light heading />}</h1>
                    <p><span className="status"></span>{this.state.app?.description ?? <Placeholder light />}</p>
                </div>
                {this.props.curRole === roles.OWNER && <button onClick={() => this.goTo("/Portal/Settings")}>
                    <Icon icon="settings" mode="dark" />Settings
                </button>}
            </section>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    app: state.apps[state.currentApp]
})

export default connect(mapStateToProps)(withNav(DashHeader));
