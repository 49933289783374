const authAPI = "" +
"import axios from 'axios';\n" +
"\n" +
"const headers = {\n" +
"\t'accept': 'application/json',\n" +
"\t'Content-Type': 'application/json'\n" +
"};\n" +
"\n" +
"const auth = axios.create({\n" +
"\tbaseURL: 'https://auth.cordoniq.com/',\n" +
"\theaders: headers\n" +
"});\n" +
"\n" +
"// Required fields for the login API\n" +
"var fields = {\n" +
"\tusername: 'YOUR_USERNAME_HERE',\n" +
"\tpassword: 'YOUR_PASSWORD_HERE'\n" +
"};\n" +
"\n" +
"auth.post('/auth/login/', fields)\n" +
"\t.then(response => {\n" +
"\t\tvar tokenInfo = response.data;\n" +
"\t\t// See Core API examples for function definition\n" +
"\t\tgetConferences(tokenInfo.access_token);\n" +
"\t}).catch(console.error);";

const npmAuthAPI = "" +
"import Cordoniq from 'cordoniq';\n" +
"\n" +
"Cordoniq.auth.signin({\n" +
"\tusername: 'YOUR_USERNAME_HERE',\n" +
"\tpassword: 'YOUR_PASSWORD_HERE'\n" +
"}).then(tokenInfo => {\n" +
"\t// tokenInfo is automatically cached for future use\n" +
"\t// Perform any additional actions with tokenInfo here\n" +
"}).catch(console.error);";

const clientAuth = "" +
"import axios from 'axios';\n" +
"\n" +
"const headers = {\n" +
"\t'Accept': 'application/json',\n" +
"\t'Content-Type': 'application/x-www-form-urlencoded'\n" +
"};\n" +
"\n" +
"const auth = axios.create({\n" +
"\tbaseURL: 'https://auth.cordoniq.com/',\n" +
"\theaders: headers\n" +
"};\n" +
"\n" +
"// Required data for token API\n" +
"var clientData = 'grant_type=client_credentials';\n" +
"\tclientData += `&client_id=${client_id}`;\n" + // eslint-disable-line no-template-curly-in-string
"\tclientData += `&client_secret=${client_secret}`;\n" + // eslint-disable-line no-template-curly-in-string
"\n" +
"auth.post('/auth/oauth2/token', clientData)\n" +
"\t.then(response => {\n" +
"\t\tvar tokenInfo = response.data;\n" +
"\t\t//Save or make requests with token\n" +
"\t}).catch(console.error);";

const npmClientAuth = "" +
"import Cordoniq from 'cordoniq';\n" +
"\n" +
"Cordoniq.auth.token.fromClientCredentials({\n" +
"\tclient_id: 'YOUR_CLIENT_ID',\n" +
"\tclient_secret: 'YOUR_CLIENT_SECRET'\n" +
"}).then(tokenInfo => {\n" +
"\t//Save or make requests with token\n" +
"}).catch(console.error);";

const confJSON = '' +
'{\n' +
'\t"confId": "string",\n' +
'\t"displayName": "string",\n' +
'\t"active": true,\n' +
'\t"state": 2,\n' +
'\t"startMethod": 2,\n' +
'\t"attendMethods": {\n' +
'\t\t"password": true,\n' +
'\t\t"url": true\n' +
'\t},\n' +
'\t"features": {\n' +
'\t\t"video": true,\n' +
'\t\t"audio": true,\n' +
'\t\t"whiteboard": true\n' +
'\t\t"desktopShare": true,\n' +
'\t\t"documentShare": true,\n' +
'\t\t"fileShare": true,\n' +
'\t\t"recording": true,\n' +
'\t\t"chat": true\n' +
'\t},\n' +
'\t"privileges": {\n' +
'\t\t"annotate": true,\n' +
'\t\t"video": true,\n' +
'\t\t"audio": true,\n' +
'\t\t"share": false,\n' +
'\t\t"viewUsers": true,\n' +
'\t\t"changeUser": false,\n' +
'\t\t"record": false,\n' +
'\t\t"changePages": false,\n' +
'\t\t"changeShares": false,\n' +
'\t\t"chat": true\n' +
'\t},\n' +
'\t"presenterPrivileges": {\n' +
'\t\t"annotate": true,\n' +
'\t\t"video": true,\n' +
'\t\t"audio": true,\n' +
'\t\t"share": true,\n' +
'\t\t"viewUsers": true,\n' +
'\t\t"changeUser": true,\n' +
'\t\t"record": false,\n' +
'\t\t"changePages": true,\n' +
'\t\t"changeShares": true,\n' +
'\t\t"chat": true\n' +
'\t},\n' +
'\t"hostPrivileges": {\n' +
'\t\t"annotate": true,\n' +
'\t\t"video": true,\n' +
'\t\t"audio": true,\n' +
'\t\t"share": true,\n' +
'\t\t"viewUsers": true,\n' +
'\t\t"changeUser": true,\n' +
'\t\t"record": true,\n' +
'\t\t"changePages": true,\n' +
'\t\t"changeShares": true,\n' +
'\t\t"chat": true\n' +
'\t},\n' +
'\t"hostUrl": "string",\n' +
'\t"presenterUrl": "string",\n' +
'\t"attendeeUrl": "string"\n' +
'\t"hostPassword": "string",\n' +
'\t"presenterPassword": "string",\n' +
'\t"attendeePassword": "string",\n' +
'\t"audioTypes": {\n' +
'\t\t"computer": true\n' +
'\t},\n' +
'\t"location": 1,\n' +
'\t"countryCode": "string",\n' +
'\t"backgroundColor": 0,\n' +
'\t"videoPreferWideScreen": true,\n' +
'\t"videoAllowHD": true,\n' +
'\t"videoEnforceLimits": true,\n' +
'\t"videoLimits": {},\n' +
'\t"metaData1": "string",\n' +
'\t"metaData2": "string",\n' +
'\t"metaData3": "string",\n' +
'\t"created": "2019-11-22T16:24:58.707Z",\n' +
'\t"ended": "2019-11-23T17:04:34.493Z",\n' +
'\t"deleted": "2019-11-23T18:00:23.201Z"\n' +
'}';

const coreAPI = "" +
"const api = axios.create({\n" +
"\tbaseURL: 'https://api.cordoniq.com/',\n" +
"\theaders: headers\n" +
"});\n" +
"\n" +
"getConferences = access_token => {\n" +
"\tvar options = {\n" +
"\t\theaders: {\n" +
"\t\t\t'Authorization': `Bearer ${access_token}`\n" + // eslint-disable-line no-template-curly-in-string
"\t\t},\n" +
"\t\tparams: {\n" +
"\t\t\t'details': 'all'\n" +
"\t\t}\n" +
"\t};\n" +
"\n" +
"\tapi.get('/conferences', options)\n" +
"\t\t.then(response => {\n" +
"\t\t\tvar conferences = response.data.items;\n" +
"\t\t\t// DO SOMETHING WITH CONFERENCES\n" +
"\t\t}).catch(console.error);\n" +
"};";

const npmCoreAPI = "" +
"import Cordoniq from 'cordoniq';\n" +
"\n" +
"const getConferences = async () => {\n" +
"\ttry {\n" +
"\t\tif (!Cordoniq.auth.token.isValid()) {\n" +
"\t\t\tawait Cordoniq.auth.signin({ username: 'USERNAME', password: 'PASSWORD' });\n" +
"\t\t}\n" +
"\t\treturn await Cordoniq.core.conferences.get();\n" +
"\t} catch (e) {\n" +
"\t\tthrow e;\n" +
"\t}\n" +
"};";

const userJSON = '' +
'{\n' +
'\t"userId": "string",\n' +
'\t"displayName": "string",\n' +
'\t"kind": 1,\n' +
'\t"state": 1,\n' +
'\t"attendAsHostUrl": "string",\n' +
'\t"attendAsPresenterUrl": "string",\n' +
'\t"attendAsAttendeeUrl": "string",\n' +
'\t"attend": true,\n' +
'\t"active": true,\n' +
'\t"attended": "2019-11-22T17:13:24.125Z",\n' +
'\t"privileges": {\n' +
'\t\t"annotate": true,\n' +
'\t\t"video": true,\n' +
'\t\t"audio": true,\n' +
'\t\t"share": true,\n' +
'\t\t"viewUsers": true,\n' +
'\t\t"changeUser": true,\n' +
'\t\t"record": true,\n' +
'\t\t"changePages": true,\n' +
'\t\t"changeShares": true,\n' +
'\t\t"chat": true\n' +
'\t},\n' +
'\t"role": 1,\n' +
'\t"created": "2019-11-21T13:17:20.104Z",\n' +
'\t"deleted": "2019-11-24T08:10:49.149Z",\n' +
'\t"audioPlaybackDevices": [\n' +
'\t\t{\n' +
'\t\t\t"uniqueId": "string",\n' +
'\t\t\t"displayName": "string"\n' +
'\t\t}\n' +
'\t],\n' +
'\t"audioPlaybackDeviceId": "string",\n' +
'\t"audioRecordingDevices": [\n' +
'\t\t{\n' +
'\t\t\t"uniqueId": "string",\n' +
'\t\t\t"displayName": "string"\n' +
'\t\t}\n' +
'\t],\n' +
'\t"audioRecordingDeviceId": "string",\n' +
'\t"audioRecordingDeviceVolume": 0,\n' +
'\t"audioRecordingDeviceMuted": true,\n' +
'\t"audioPlaybackDeviceVolume": 0,\n' +
'\t"audioState": 0,\n' +
'\t"audioAGC": true,\n' +
'\t"audioAGCMode": 0,\n' +
'\t"audioAGCMicLevelMin": 0,\n' +
'\t"audioAGCMicLevelMax": 0,\n' +
'\t"audioAGCTargetLevel": 0,\n' +
'\t"audioAGCCompressionGain": 0,\n' +
'\t"audioAGCEnableLimiter": true,\n' +
'\t"audioNS": true,\n' +
'\t"audioNSAggressiveness": 0,\n' +
'\t"audioAEC": true,\n' +
'\t"audioAECAggressiveness": 0,\n' +
'\t"audioAECDelayEstimate": 0,\n' +
'\t"audioAECSkewedDelay": true,\n' +
'\t"videoCaptureDevices": [\n' +
'\t\t{\n' +
'\t\t\t"uniqueId": "string",\n' +
'\t\t\t"displayName": "string"\n' +
'\t\t}\n' +
'\t],\n' +
'\t"videoCaptureDeviceId": "string",\n' +
'\t"videoState": 0,\n' +
'\t"videoCaptureFormats": [\n' +
'\t\t{\n' +
'\t\t\t"width": 0,\n' +
'\t\t\t"height": 0,\n' +
'\t\t\t"framerate": 0,\n' +
'\t\t\t"minFrameRate": 0,\n' +
'\t\t\t"maxFrameRate": 0\n' +
'\t\t}\n' +
'\t],\n' +
'\t"videoCaptureFormat": {},\n' +
'\t"videoPreferWideScreen": true,\n' +
'\t"videoAllowHD": true,\n' +
'\t"videoEnforceLimits": true,\n' +
'\t"videoLimits": {}\n' +
'}';

const userAPI = "" +
"getUsers = (access_token, confId) => {\n" +
"\tvar options = {\n" +
"\t\theaders: {\n" +
"\t\t\t'Authorization': `Bearer ${access_token}`\n" + // eslint-disable-line no-template-curly-in-string
"\t\t},\n" +
"\t\tparams: {\n" +
"\t\t\t'details': 'all'\n" +
"\t\t}\n" +
"\t};\n" +
"\n" +
"\tapi.get(`/conference/${confId}/users`, options)\n" + // eslint-disable-line no-template-curly-in-string
"\t\t.then(response => {\n" +
"\t\t\tvar users = response.data.items;\n" +
"\t\t\t// DO SOMETHING WITH USERS\n" +
"\t\t}).catch(console.error);\n" +
"};";

const npmUserAPI = "" +
"import Cordoniq from 'cordoniq';\n" +
"\n" +
"const getUsers = async (confId) => {\n" +
"\ttry {\n" +
"\t\tif (!Cordoniq.auth.token.isValid()) {\n" +
"\t\t\tawait Cordoniq.auth.signin({ username: 'USERNAME', password: 'PASSWORD' });\n" +
"\t\t}\n" +
"\t\treturn await Cordoniq.core.users.get(confId);\n" +
"\t} catch (e) {\n" +
"\t\tthrow e;\n" +
"\t}\n" +
"};";

const userSettingsAPI = "" +
"getUser = (access_token, confId, userId) => {\n" +
"\tvar options = {\n" +
"\t\theaders: {\n" +
"\t\t\t'Authorization': `Bearer ${access_token}`\n" + // eslint-disable-line no-template-curly-in-string
"\t\t},\n" +
"\t\tparams: {\n" +
"\t\t\t'details': 'all',\n" +
"\t\t\t'all_video': true, // Returns all video settings\n" +
"\t\t\t// OR specify which settings you need in the request\n" +
"\t\t\t'video_state': true,\n" +
"\t\t\t'video_capture_device': true,\n" +
"\t\t\t'video_capture_format': true\n" +
"\t\t}\n" +
"\t};\n" +
"\n" +
"\tapi.get(`/conference/${confId}/user/${userId}`, options)\n" + // eslint-disable-line no-template-curly-in-string
"\t\t.then(response => {\n" +
"\t\t\tvar user = response.data;\n" +
"\t\t\t// DO SOMETHING WITH USER INCLUDING:\n" +
"\t\t\t// videoState, videoCaptureDevice, and videoCaptureFormat\n" +
"\t\t}).catch(console.error);\n" +
"};";

const npmUserSettingsAPI = "" +
"import Cordoniq from 'cordoniq';\n" +
"\n" +
"let filters = ['active']; // Return only active users\n" +
"\t// Return all video settings\n" +
"\tfilters.push('all_video');\n" +
"\t// OR specify which settings you need\n" +
"\tfilters.push('video_state'); // Whether video camera is on or off\n" +
"\tfilters.push('video_capture_device'); // Current camera in use\n" +
"\tfilters.push('video_capture_format'); // Video format including resolution\n" +
"\n" +
"const getUsers = async (confId, filters) => {\n" +
"\ttry {\n" +
"\t\tif (!Cordoniq.auth.token.isValid()) {\n" +
"\t\t\tawait Cordoniq.auth.signin({ username: 'USERNAME', password: 'PASSWORD' });\n" +
"\t\t}\n" +
"\t\treturn await Cordoniq.core.users.get(confId, filters);\n" +
"\t} catch (e) {\n" +
"\t\tthrow e;\n" +
"\t}\n" +
"};";

const contentJSON = '' +
'{\n' +
'\t"contentId": "string",\n' +
'\t"contentType": "string",\n' +
'\t"displayName": "string",\n' +
'\t"fileName": "string",\n' +
'\t"deleteMethod": 1,\n' +
'\t"created": "2019-11-22T17:12:20.194Z"\n' +
'}';

const upload = "" +
"// Create another Axios definition without headers for content type\n" +
"const content = axios.create({\n" +
"\tbaseURL: 'https://api.cordoniq.com/'\n" +
"});\n" +
"\n" +
"// File from HTML Form\n" +
"var file = [...file][0];\n" +
"\n" +
"// Define JSON object of content information\n" +
"var request = {\n" +
"\tcontentType: file.type,\n" +
"\tdisplayName: DISPLAY_NAME,\n" +
"\tfileName: file.name,\n" +
"\tdeleteMethod: DELETE_METHOD // Default 1\n" +
"};\n" +
"\n" +
"// Create a new Blob from JSON object\n" +
"const requestBlob = new Blob([JSON.stringify(request)], { type: 'application/json' });\n" +
"\n" +
"// Create FormData of both the JSON object and the file itself\n" +
"var data = new FormData();\n" +
"data.append('request', requestBlob);\n" +
"data.append('content', file);\n" +
"\n" +
"uploadContent = (data, access_token) => {\n" +
"\tvar options = {\n" +
"\t\theaders: 'Authorization': `Bearer ${access_token}`\n" + // eslint-disable-line no-template-curly-in-string
"\t};\n" +
"\n" +
"\tcontent.post('/content', data, options)\n" +
"\t\t.then(response => {\n" +
"\t\t\t// DO SOMETHING AFTER UPLOAD IS COMPLETE\n" +
"\t\t}).catch(console.error);\n" +
"};";

const npmUpload = "" +
"// Import content model from Core APIs\n" +
"import Cordoniq from 'cordoniq';\n" +
"import Content from 'cordoniq/apis/content/content-model';\n" +
"\n" +
"const uploadContent = async (e) => {\n" +
"\tconst file = [...e.target.files][0];\n" +
"\n" +
"\t// Create a new Content object from selected file using static function\n" +
"\tconst content = Content.fromFile(file);\n" +
"\n" +
"\t// Export FormData from Content object\n" +
"\tconst formData = content.getFormData();\n" +
"\n" +
"\ttry {\n" +
"\t\tif (!Cordoniq.auth.token.isValid()) {\n" +
"\t\t\tawait Cordoniq.auth.signin({ username: 'USERNAME', password: 'PASSWORD' });\n" +
"\t\t}\n" +
"\n" +
"\t\t// Return newly created content with optional progress callback\n" +
"\t\treturn await Cordoniq.content.new(formData, progress => {\n" +
"\t\t\tlet completed = Math.round( (progress.loaded * 100) / progress.total );\n" +
"\t\t\tconsole.log(completed);\n" +
"\t\t});\n" +
"\t} catch (e) {\n" +
"\t\tthrow e;\n" +
"\t}\n" +
"};";

const download = "" +
"import * as fileDownload from 'js-file-download';\n" +
"\n" +
"onDownloadProgress = progress => {\n" +
"\tvar completed = Math.round( (progress.loaded * 100) / progress.total );\n" +
"\t// DO SOMETHING WITH COMPLETED PROGRESS UPDATE\n" +
"};\n" +
"\n" +
"downloadContent = (contentId, filename, onProgress) => {\n" +
"\tvar options = {\n" +
"\t\tresponseType: 'blob',\n" +
"\t\tonDownloadProgress: onProgress || undefined\n" +
"\t};\n" +
"\n" +
"\tcontent.get('/content/'+contentId, options)\n" +
"\t\t.then(response => {\n" +
"\t\t\tfileDownload(response.data, filename);\n" +
"\t\t}).catch(console.error);\n" +
"};";

const npmDownload = "" +
"import Cordoniq from 'cordoniq';\n" +
"import * as fileDownload from 'js-file-download';\n" +
"\n" +
"const downloadContent = async (contentId, filename) => {\n" +
"\ttry {\n" +
"\t\tif (!Cordoniq.auth.token.isValid()) {\n" +
"\t\t\tawait Cordoniq.auth.signin({ username: 'USERNAME', password: 'PASSWORD' });\n" +
"\t\t}\n" +
"\n" +
"\t\t// Download content from server with optional progress callback\n" +
"\t\tconst fileBuffer = await Cordoniq.content.get(contentId, progress => {\n" +
"\t\t\tlet completed = Math.round( (progress.loaded * 100) / progress.total );\n" +
"\t\t\tconsole.log(completed);\n" +
"\t\t});\n" +
"\n" +
"\t\tfileDownload(fileBuffer, filename);\n" +
"\t\treturn \"OK\";\n" +
"\t} catch (e) {\n" +
"\t\tthrow e;\n" +
"\t}\n" +
"};";

var Examples = {
	authAPI: authAPI,
	npmAuthAPI,
	clientAuth: clientAuth,
	npmClientAuth,
	confJSON: confJSON,
	coreAPI: coreAPI,
	npmCoreAPI,
	userJSON: userJSON,
	userAPI: userAPI,
	npmUserAPI,
	userSettingsAPI: userSettingsAPI,
	npmUserSettingsAPI,
	contentJSON: contentJSON,
	upload: upload,
	npmUpload,
	download: download,
	npmDownload
};

export default Examples;
