import { faPlay } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { getConferences } from '../LumiAPI/APIs/core/conference';
import RecordingAPI from '../LumiAPI/APIs/core/recording';
import handleErrors from '../LumiAPI/APIs/errors';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';
import CopyField from '../components/CopyField';
import Dialog, { Container } from '../components/Dialog';
import Icon from '../components/Icon';
import SelectBox from '../components/SelectBox';
import TextField from '../components/TextField';
import Video from '../components/Video';
import List from '../partials/List';

import Modal from '../tools/Modal';
import Notification from '../tools/Notification';

import { connect } from 'react-redux';

import './css/recordings.css';

class Recordings extends Component {
    state = {
        loaded: {
            recordings: false,
            video: false,
            link: false
        },
        conferences: [],
        recordings: {},
        selected: [],
        video: "",
        expanded: false,
        changes: {}
    };

    refreshRecordings = () => {
        this.setState({ loaded: { ...this.state.loaded, recordings: false } });
        getConferences().then(conferences => {
            this.setState({ conferences });
            let allRecordings = {};
            conferences.forEach((conference, i) => {
                RecordingAPI.recordings.get(conference.confId).then(recordings => {
                    recordings.forEach(recording => {
                        recording.id = recording.recordingId;
                        recording.icon = { name: "desktop", props: { style: { boxShadow: 'none' } } };
                        recording.title = recording.displayName;
                        recording.subtitle = `${conference.displayName} (${conference.confId})`;
                        recording.confName = conference.displayName;
                        recording.action = {
                            label: <><Icon icon={faPlay} style={{ marginRight: "8px" }} />Play</>,
                            props: {
                                onClick: e => {
                                    e.stopPropagation();
                                    this.getVideo(recording.recordingId);
                                }
                            }
                        };
                        recording.buttons = [
                            { icon: "annotate", props: {
                                onClick: e => {
                                    e.stopPropagation();
                                    this.openEdit(recording.recordingId);
                                }
                            }},
                            { icon: "share", props: {
                                onClick: e => {
                                    e.stopPropagation();
                                    this.openShare(recording.recordingId);
                                }
                            }}
                        ];
                        allRecordings[recording.recordingId] = recording;
                    });
                    if (i === conferences.length - 1) {
                        this.setState({ loaded: { ...this.state.loaded, recordings: true }, recordings: allRecordings });
                    }
                }).catch(handleErrors);
            });
            if (conferences.length === 0) {
                this.setState({ loaded: { ...this.state.loaded, recordings: true }, recordings: allRecordings });
            }
        }).catch(handleErrors);
    };

    downloadSelected = () => {
        let { recordings, selected } = this.state,
            recordingId = selected[0],
            recording = recordings[recordingId],
            filename = recording.filename;
        RecordingAPI.recording.download(recording.confId, recordingId, filename).then(downloadLink => {
            let tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = downloadLink;
            tempLink.setAttribute('download', `${filename}.mp4`);

            document.body.appendChild(tempLink);
            tempLink.click();

            setTimeout(() => {
                document.body.removeChild(tempLink);
            }, 200);
        }).catch(err => {
            new Notification({ id: "failedDownload", message: "Failed to download recording. Try again", type: "error" });
            handleErrors(err);
        });
    };

    deleteSelected = () => Modal(<>
        <h1>Delete?</h1>
        <p>Are you sure you want to delete the selected recording/s?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let { recordings, selected } = this.state;
            this.setState({ selected: [], video: "", loaded: { ...this.state.loaded, recordings: false } });
            selected.forEach((recordingId, i) => {
                RecordingAPI.recording.delete(recordings[recordingId].confId, recordingId).then(result => {
                    delete recordings[recordingId];
                    this.setState({ recordings, loaded: { ...this.state.loaded, recordings: true } });
                }).catch(err => {
                    new Notification({ id: "failedDelete", message: "Failed to delete recording. Try again", type: "error" });
                    handleErrors(err);
                });
            });
        }}}
    ], { alert: true });

    openEdit = recordingId => this.setState({
        showEdit: true,
        changes: { ...this.state.recordings[recordingId] }
    });

    save = () => {
        let { changes } = this.state;
        this.setState({ showEdit: false, loaded: { ...this.state.loaded, recordings: false } });
        RecordingAPI.recording.updateName(changes.confId, changes.recordingId, changes.displayName).then(recording => {
            let recordings = this.state.recordings;
            recordings[changes.recordingId].displayName = changes.displayName;
            recordings[changes.recordingId].title = changes.displayName;
            this.setState({ recordings, loaded: { ...this.state.loaded, recordings: true } });
        }).catch(err => {
            new Notification({ id: "failedUpdate", message: "Failed to update recording. Try again", type: "error" });
            handleErrors(err);
        });
    };

    openShare = recordingId => this.setState({
        showShare: true,
        loaded: { ...this.state.loaded, link: true },
        changes: {
            confId: this.state.recordings[recordingId].confId,
            recordingId,
            expiration: { index: 1, option: { value: "" } },
            expiry_minutes: 10,
            expiry: moment().format(),
            link: ""
        }
    });

    getShareLink = () => {
        let { changes } = this.state;

        let settings;
        switch (changes.expiration.option.value) {
            case 0: // Never
                break;
            case 1: // In Minutes
                settings = {};
                settings.expiry_minutes = changes.expiry_minutes;
                break;
            case 2: // On Date
                settings = {};
                settings.expiry = moment(changes.expiry).format();
                break;
            default:
                break;
        }

        this.setState({ loaded: { ...this.state.loaded, link: false } });
        RecordingAPI.recording.share(changes.confId, changes.recordingId, settings).then(link => {
            changes.link = link;
            this.setState({ changes, loaded: { ...this.state.loaded, link: true } });
        }).catch(err => {
            new Notification({ id: "failedToShare", message: "Failed to retrieve sharing link. Try again", type: "error" });
            handleErrors(err);
        })
    };

    shareLink = () => {
        let { changes } = this.state,
            confName = this.state.recordings[changes.recordingId].confName;
        if (navigator.share) {
            navigator.share({
                title: `Cordoniq Recording from ${confName}`,
                url: changes.link
            });
        } else {
            let encoded = encodeURIComponent(changes.link);
            window.open(`mailto:YOUR_RECIPIENT?subject=Cordoniq Recording from ${confName}&body=${encoded}`);
        }
    };

    getExpirationLabel = () => {
        let { changes } = this.state;
        switch (changes.expiration.option.value) {
            case 1: // In Minutes
                return `This link expire in ${changes.expiry_minutes} minutes`;
            case 2: // On Date
                let date = moment(changes.expiry).format('LL');
                return `This link expires on ${date}`;
            default:
                return "This link does not expire";
        }
    };

    getVideo = (recordingId, settings) => {
        let { recordings } = this.state;
        this.setState({ video: {}, loaded: { ...this.state.loaded, video: false } });
        RecordingAPI.recording.share(recordings[recordingId].confId, recordingId, settings).then(url => {
            this.setState({ video: { id: recordingId, url }, loaded: { ...this.state.loaded, video: true } });
        }).catch(err => {
            new Notification({ id: "failedShare", message: "Failed to retrieve video url. Try again", type: "error" });
            handleErrors(err);
        });
    };

    toggleExpandedVideo = expanded => this.setState({ expanded });

    videoClassName = () => {
        let className = "";
        if (this.state.selected && !this.state.loadingVideo) className += "showVideo";
        if (this.state.expanded) className += " expand";
        return className.trim();
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection;
        this.setState({ changes });
    };

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    componentDidMount() {
        document.title = "Recordings | Cordoniq";
        window.scrollTo(0, 0);

        this.refreshRecordings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.refreshRecordings();
        }
    }

    render() {
        let empty = Object.keys(this.state.recordings).length === 0,
            changes = this.state.changes;
        return (
            <ContentWithToolbar id="recordings">
                {this.state.showShare && <Dialog show={this.state.showShare} title="Share Recording Link" footer={[
                    <Button outline onClick={() => this.setState({ showShare: false })}>
                        {changes.link === "" ? "Cancel" : "Close"}
                    </Button>,
                    <Button onClick={changes.link === "" ? this.getShareLink : this.shareLink} disabled={!this.state.loaded.link}>
                        {!this.state.loaded.link ? "Loading..."
                            : changes.link === "" ? "Next" : "Share"}
                    </Button>
                ]}>
                    <Container label={this.state.link === "" ? "Share Link Settings" : "Your Recording Link"}>
                        {changes.link === ""
                            ? <>
                            <SelectBox name="expiration" label="Link Expires" selected={changes.expiration.index} options={[
                                { name: "Pick one...", dontSelect: true },
                                { name: "Never", value: 0 },
                                { name: "In Minutes", value: 1 },
                                { name: "On Date", value: 2 }
                            ]} onSelect={this.onSelect} />
                            {changes.expiration.option.value === 1 && <TextField type="number" name="expiry_minutes" value={changes.expiry_minutes} label="Expiration in Minutes" placeholder="Enter Minutes..." onEdit={this.onEdit} />}
                            {changes.expiration.option.value === 2 && <TextField type="date" name="expiry" value={changes.expiry} label="Expiration Date" onEdit={this.onEdit} />}
                            </>
                            : <>
                            <CopyField id="recordingShareLink" label="Recording Link" value={changes.link} />
                            <p>{this.getExpirationLabel()}</p>
                            </>
                        }
                    </Container>
                </Dialog>}
                {this.state.showEdit && <Dialog show={this.state.showEdit} title="Edit Recording" footer={[
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Cancel</Button>,
                    <Button onClick={this.save} disabled={(changes.displayName === this.state.recordings[changes.recordingId].displayName) || (changes.displayName === "")}>Save</Button>
                ]}>
                    <TextField name="displayName" label="Recording Name" placeholder="Enter a name..." value={changes.displayName} onEdit={this.onEdit} />
                    <TextField name="filename" label="Filename" value={changes.filename + ".mp4"} disabled />
                    <TextField name="created" label="Created" value={moment(changes.created).format('ll')} disabled />
                </Dialog>}
                <div id="viewer" className={this.videoClassName()}>
                    <div id="title">
                        <h1>Recordings</h1>
                        {!empty && <p>Select a recording below to view</p>}
                    </div>
                    {Object.keys(this.state.video).length > 0 &&
                        <Video autoplay="true" onExpand={this.toggleExpandedVideo} title={this.state.recordings?.[this.state.video.id]?.displayName}>
                            <source src={this.state.video.url} type={this.state.recordings[this.state.video.id]?.contentType} />
                        </Video>
                    }
                </div>
                <List id="recordings" loaded={this.state.loaded.recordings} items={this.state.recordings} actions={[
                    {
                        label: "Download",
                        props: { onClick: this.downloadSelected, disabled: this.state.selected.length !== 1 }
                    },
                    {
                        label: "Delete",
                        props: { onClick: this.deleteSelected, delete: true, disabled: this.state.selected.length === 0 }
                    }
                ]} filters={[
                    { name: "Search", value: "search", match: "displayName", placeholder: "Search Recordings..." },
                    { name: "Conference", value: "confId", match: "confId", options: [
                        { name: "Select Conference...", dontSelect: true },
                        ...this.state.conferences.map(conference => ({ name: conference.displayName, value: conference.confId }))
                    ] }
                ]} sort={[
                    { options: [
                        { name: "Name", value: "displayName" },
                        { name: "Conference ID", value: "confId" },
                        { name: "Created", value: "created" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    app: state.apps[state.currentApp]
});

export default connect(mapStateToProps)(withNav(Recordings));
