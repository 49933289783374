import React, { Component } from 'react';

import './css/buttons.css';

class Button extends Component {

	getClassName = () => {
		var className = this.props.className ?? "";
		if (this.props.fill) className += " primary";
		if (this.props.outline) className += " secondary";
		if (this.props.delete) className += " delete";
		if (this.props.tool) className += " tool";
		if (className.trim() === "") className = "primary";
		return className;
	};

	render () {
		return (<>
			{this.props.link
				? this.props.href
					? <a href={this.props.href} target={this.props.target ?? "_blank"} rel="noopener noreferrer">
						{this.props.children}
					</a>
					: <span className="button link" {...this.props}>
						{this.props.children}
					</span>
				: <button className={this.getClassName()} {...this.props}>
					{this.props.children}
				</button>
			}
		</>);
	}
}

export default Button;
