import { auth, api, upload, allDetails, imgOptions } from '../config.js';
import { Token, getTokenInfo } from '../auth.js';

// var listeners = [],
//     AppListener = {
//         addListener: function(listener) {
//             listeners.push(listener);
//         },
//         removeListener: function(listener) {
//             var index = listeners.indexOf(listener);
//             if (index !== -1) listeners.splice(index, 1);
//         },
//         load: function(clientId) {
//             listeners.forEach(listener => {
//                 listener(clientId);
//             });
//         }
//     };

export const createApp = appDetails => {
    return new Promise((resolve, reject) => {
        api(Token.get()).post("/app", appDetails).then(response => {
            let app = response.data;
            // AppListener.load(app.clientId);
            resolve(app);
        }).catch(reject);
    });
};

export const getApp = async clientId => {
    try {
        if (clientId === undefined) {
            let tokenInfo = await getTokenInfo();
            clientId = tokenInfo.client_id;
        }
        let response = await api(Token.get()).get(`/app/${clientId}`, allDetails);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const updateApp = (clientId, changes) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/app/${clientId}`, changes).then(response => {
            // AppListener.load(clientId);
            resolve("OK");
        }).catch(reject);
    });
};

export const deleteApp = clientId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/app/${clientId}`).then(response => {
            resolve("OK");
            // getAccount().then(account => {
            //     AppListener.load(account.clientId);
            //     resolve("OK");
            // }).catch(err => {
            //     AppListener.load();
            //     resolve("OK");
            // });
        }).catch(reject);
    });
};

export const switchApp = clientId => {
    return new Promise((resolve, reject) => {
        let claims = {
            "grant_type": "refresh_token",
            "refresh_token": Token.getRefresh(),
            "client_id": clientId
        };

        auth(Token.get()).post("/auth/oauth2/token", claims)
            .then(response => {
                Token.set(response.data);
                // AppListener.load(clientId);
                resolve("OK");
            }).catch(reject);
    });
};

export const setAppIcon = (clientId, file, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        let options = imgOptions(onUploadProgress);
        upload(Token.get()).post(`/app/${clientId}/icon`, file, options)
            .then(response => {
                // AppListener.load(clientId);
                resolve("OK");
            }).catch(reject);
    });
};

export const removeAppIcon = clientId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/app/${clientId}/icon`).then(response => {
            // AppListener.load(clientId);
            resolve("OK");
        }).catch(reject);
    });
};

export const setAppLogo = (clientId, file, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        let options = imgOptions(onUploadProgress);
        upload(Token.get()).post(`/app/${clientId}/logo`, file, options)
            .then(response => {
                // AppListener.load(clientId);
                resolve("OK");
            }).catch(reject);
    });
};

export const removeAppLogo = clientId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/app/${clientId}/logo`).then(response => {
            // AppListener.load(clientId);
            resolve("OK");
        }).catch(reject);
    });
};

export const addCertificate = (clientId, formData) => {
    return new Promise((resolve, reject) => {
        let options = { headers: { 'Content-Type': 'multipart/form-data' } };
        upload(Token.get()).post(`/app/${clientId}/cert`, formData, options)
            .then(response => {
                // AppListener.load(clientId);
                resolve("OK");
            }).catch(reject);
    });
};

export const removeCertificate = clientId => {
    return new Promise((resolve, reject) => {
        api(Token.get()).delete(`/app/${clientId}/cert`)
            .then(response => {
                // AppListener.load(clientId);
                resolve("OK");
            }).catch(reject);
    });
};

export const updateDomains = (clientId, domains) => {
    return new Promise((resolve, reject) => {
        api(Token.get()).put(`/app/${clientId}`, domains)
            .then(response => {
                // AppListener.load(clientId);
                resolve("OK");
            }).catch(reject);
    });
};

export const getApps = () => {
    return new Promise((resolve, reject) => {
        api(Token.get()).get("/apps", allDetails)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

const AppAPI = {
    app: {
        new: createApp,
        get: getApp,
        update: updateApp,
        delete: deleteApp,
        switch: switchApp,
        setIcon: setAppIcon,
        removeIcon: removeAppIcon,
        setLogo: setAppLogo,
        removeLogo: removeAppLogo,
        addCertificate,
        removeCertificate,
        updateDomains,
        // ...AppListener
    },
    apps: {
        get: getApps,
    }
};

export default AppAPI;
