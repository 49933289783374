import React, { useEffect, useState } from 'react';
import useLocalStorage from './hooks/useLocalStorage';
import useQuery from './hooks/useQuery';

import UserAgent from './tools/userAgentHandler';

import ContentWithToolbar from './templates/ContentWithToolbar';
import Section from './partials/Section';

import TextField from './components/TextField';
import Button from './components/Button';

import "./css/testing.css";

const SaveValue = ({ storageKey }) => {
    const [ storedValue, setStoredValue ] = useLocalStorage(storageKey);
    const [ value, setValue ] = useState(storedValue);

    useEffect(() => setValue(storedValue ?? ""), [storedValue, setValue]);

    return (<>
        <TextField type="textarea" name="localValue" label="Stored Value" value={value} onEdit={change => setValue(change.value)} />
        <Button delete onClick={() => setStoredValue("")} disabled={storedValue === null} style={{ marginRight: "10px" }}>Delete Item</Button>
        <Button onClick={() => setStoredValue(value)} disabled={value === storedValue || value === ""}>Save Changes</Button>
    </>);
};

const Testing = props => {
    let ua = new UserAgent();

    let params = useQuery(), pairs = {};
    params.forEach((value, key) => {
        pairs[key] = value;
    });

    const [ storageKey, setStorageKey ] = useState(params.get("storage_key") ?? "testing_local_storage");

    return (
        <ContentWithToolbar id="testing">
            <h1>Testing</h1>
            <Section title="Query String">
                {Object.keys(pairs).map(key => (
                    <div><b>{key}</b> {pairs[key]}</div>
                ))}
            </Section>
            <Section title="Local Storage">
                <TextField name="localKey" label="Storage Key" value={storageKey} onEdit={change => setStorageKey(change.value)} />
                <SaveValue storageKey={storageKey} />
            </Section>
            <Section title="User Agent Testing - Platform">
                <div><b>Windows</b><span>{ua.isWindows() ? "True" : "False"}</span></div>
                <div><b>macOS</b><span>{ua.isMacOS() ? "True" : "False"}</span></div>
                <div><b>Linux</b><span>{ua.isLinux() ? "True" : "False"}</span></div>
                <div><b>Mobile</b><span>{ua.isMobile() ? "True" : "False"}</span></div>
                <div><b>iOS</b><span>{ua.isIOS() ? "True" : "False"}</span></div>
                <div><b>Android</b><span>{ua.isAndroid() ? "True" : "False"}</span></div>
            </Section>
            <Section title="User Agent Testing - Browsers">
                <div><b>Opera</b><span>{ua.isOpera() ? "True" : "False"}</span></div>
                <div><b>Firefox</b><span>{ua.isFirefox() ? "True" : "False"}</span></div>
                <div><b>Chrome</b><span>{ua.isChrome() ? "True" : "False"}</span></div>
                <div><b>Safari</b><span>{ua.isSafari() ? "True" : "False"}</span></div>
                <div><b>Internet Explorer</b><span>{ua.isIE() ? "True" : "False"}</span></div>
                <div><b>Version Number</b><span>{ua.version()}</span></div>
            </Section>
        </ContentWithToolbar>
    );
}

export default Testing;
