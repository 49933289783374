import { api, allDetails } from './APIs/config.js';
import { Token } from './APIs/auth.js';
import moment from 'moment';

export const EVENT_KINDS = {
    None: 0,
    CreateUser: 1,
    UpdateUser: 2,
    DeleteUser: 3,
    ExpelUser: 4,
    MoveUser: 5,
    JoinUser: 6,
    LeaveUser: 7,
    UpdateUsers: 8,
    MoveUsers: 9,
    ExpelUsers: 10,
    CreateConference: 100,
    StartConference: 101,
    StopConference: 102,
    UpdateConference: 103,
    DeleteConference: 104,
    CreateShare: 200,
    UpdateShare: 201,
    StopShare: 202
};

export const EVENT_DESCRIPTION = {
    0: "None",
    1: "User was created",
    2: "User was updated",
    3: "User was deleted",
    4: "User was expelled",
    5: "User was moved",
    6: "User joined",
    7: "User left",
    8: "Multiple users were updated",
    9: "Multiple users were moved",
    10: "Multiple users were expelled",
    100: "Conference was created",
    101: "Conference was started",
    102: "Conference was stopped",
    103: "Conference was updated",
    104: "Conference was deleted",
    200: "Share was started",
    201: "Share was changed",
    202: "Share has ended"
};

export const SUBSCRIBER_KIND = {
    UserDefined: 1,
    Zapier: 2
};

export const SUBSCRIBER_DESCRIPTION = {
    1: "User Defined",
    2: "Zapier"
};

export class Events {
    constructor({ kinds, interval }, action) {
        let gte = moment().format();

        this.subscriber = setInterval(() => {
            getEvents({ gte, kinds })
                .then(events => {
                    if (events.length > 0) {
                        gte = moment().format();
                    }
                    events.map(e => {
                        e.eventKind = EVENT_DESCRIPTION[e.eventKind];
                        return e;
                    });
                    action(events);
                }).catch(err => console.log(err));
        }, (interval ?? 1) * 60 * 1000);
    }

    stop = () => {
        clearInterval(this.subscriber);
    };
}

export const getEvents = ({ gt, gte, lt, lte, kinds }) => {
    let options = {
        params: {
            details: "all",
            gt: gt,
            gte: gte,
            lt: lt,
            lte: lte,
            kinds: kinds
        }
    };

    return new Promise((resolve, reject) => {
        api(Token.get()).get("/events", options)
            .then(response => {
                let events = response.data.items;
                resolve(events);
            }).catch(reject);
    });
};

export const Subscriber = {
    get: subscriberId => {
        return new Promise((resolve, reject) => {
            api(Token.get()).get(`/subscriber/${subscriberId}`, allDetails)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    },
    getAll: () => {
        return new Promise((resolve, reject) => {
            api(Token.get()).get(`/subscribers`, allDetails)
                .then(response => resolve(response.data.items))
                .catch(reject);
        });
    },
    add: ({ eventKinds, targetUrl, targetInterval, displayName, subscriptionKind }) => {
        return new Promise((resolve, reject) => {
            let subscriberData = {
                eventKinds,
                targetUrl,
                targetInterval: targetInterval ?? 15,
                displayName,
                subscriptionKind: subscriptionKind ?? 1
            };
            api(Token.get()).post(`/subscriber`, subscriberData)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    },
    update: (subscriberId, settings) => {
        return new Promise((resolve, reject) => {
            api(Token.get()).put(`/subscriber/${subscriberId}`, settings)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    },
    remove: subscriberId => {
        return new Promise((resolve, reject) => {
            api(Token.get()).delete(`/subscriber/${subscriberId}`)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }
};
