import { papi } from './APIs/config.js';

// REQUEST { first, email, topic, subject, message }
export const sendSupportForm = (data, callback) => {
    papi.post('/api/contactSupport', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    });
};

// REQUEST { first, last, email, phone, company, employees
//           role, help, details }
export const sendSalesLead = (data, callback) => {
    papi.post('/api/saleslead', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    })
};

// REQUEST { firstName, lastName, email }
export const sendNewAccount = data => {
    return new Promise((resolve, reject) => {
        papi.post('/api/newaccount', data)
            .then(resolve).catch(reject);
    });
};

// REQUEST { first, last, email, company, employees, role }
export const sendEBookForm = data => {
    return new Promise((resolve, reject) => {
        papi.post('/api/ebookrequest/8techniques', data)
            .then(resolve).catch(reject);
    });
};

// REQUEST { first, last, email, company, employees, role }
export const sendBuyersGuideForm = data => {
    return new Promise((resolve, reject) => {
        papi.post('/api/ebookrequest/buyersguide', data)
            .then(resolve).catch(reject);
    });
};

// REQUEST { first, last, email, company, employees, role }
export const sendLowCodeForm = data => {
    return new Promise((resolve, reject) => {
        papi.post('/api/ebookrequest/lowcode', data)
            .then(resolve).catch(reject);
    })
};

// REQUEST { subject, first, email, version, osversion, message, category, error }
export const sendErrorReport = (data, callback) => {
    papi.post('/api/contact/report', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    });
};

// REQUEST { email, type, room, name, attendees, confId, joinLink, shareLink }
export const sendDemoLinks = data => {
    return new Promise((resolve, reject) => {
        papi.post('/api/sendDemoLinks', data)
            .then(resolve).catch(reject);
    });
};

// REQUEST { email, first }
// export const sendSurveyComplete = (data, callback) => {
//     papi.post('/api/sendSurveyComplete', data).then(response => {
//         callback(response, null);
//     }).catch(err => {
//         callback(null, err);
//     });
// };

// REQUEST { email, first }
// export const sendSurveyIncomplete = (data, callback) => {
//     papi.post('/api/sendSurveyIncomplete', data).then(response => {
//         callback(response, null);
//     }).catch(err => {
//         callback(null, err);
//     });
// };

// REQUEST { email, first }
export const sendWelcome = (data, callback) => {
    papi.post('/api/sendWelcome', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    });
};

// REQUEST { email, first, link }
export const sendVerification = (data, callback) => {
    papi.post('/api/sendVerification', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    });
};

// REQUEST { email, first, link }
export const sendResetLink = (data, callback) => {
    papi.post('/api/sendPasswordReset', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    });
};

// REQUEST { email, first }
export const sendContactResponse = (data, callback) => {
    papi.post('/api/sendContactResponse', data).then(response => {
        callback(response, null);
    }).catch(err => {
        callback(null, err);
    });
};
