import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';

import Toolbar from '../partials/Toolbar';
import Sidebar from '../partials/Sidebar';
import Tools from '../partials/Tools';

import './css/contentWithToolbar.css';

const compactLayout = window.matchMedia("(max-width: 950px)");
const mobileLayout = window.matchMedia("(max-width: 450px)");

class ContentWithToolbar extends Component {
    state = {
        path: this.props.path ?? undefined,
        showSidebar: false,
        compact: false,
        mobile: false
    };

    toggleSidebar = () => {
        let sidebar = this.state.showSidebar;
        this.setState({ showSidebar: !sidebar });
    };

    closeSidebar = () => this.setState({ showSidebar: false });

    updateLayout = () => this.setState({ compact: compactLayout.matches, mobile: mobileLayout.matches });

    className = () => {
        let className = this.props.id ?? "";
        className += this.props.noSidebar ? " noSidebar" : "";
        return className;
    };

    componentDidMount() {
        document.querySelector("main").addEventListener("click", this.closeSidebar);
        this.updateLayout();
        compactLayout.addListener(this.updateLayout);
        mobileLayout.addListener(this.updateLayout);
    }

    componentDidUpdate(prevProps) {
        if (this.props.path !== prevProps.path) {
            this.setState({ path: this.props.path });
        }
    }

    componentWillUnmount() {
        document.querySelector("main").removeEventListener("click", this.closeSidebar);
        compactLayout.removeListener(this.updateLayout);
        mobileLayout.removeListener(this.updateLayout);
    }

    render() {
        return (
            <div id="newportal">
                <Toolbar compactSidebar={this.state.compact} toggleSidebar={() => this.toggleSidebar()} noSidebar={this.props.noSidebar ?? false} />
                <main>
                    {this.props.noSidebar !== true && <Sidebar {...this.state} />}
                    <div id="content" className={this.className()}>
                        <Tools tools={this.props.tools} />
                        {this.props.children}
                    </div>
                </main>
            </div>
        );
    }
}

export default withNav(ContentWithToolbar);
