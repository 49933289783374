import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withNav } from '../hooks/useNav';
import { newDemo } from '../LumiAPI/APIs/core/conference.js';
import { sendDemoLinks } from '../LumiAPI/emailAPI';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';
import { ENV } from '../tools/envTools.js';
import { checkProtocolSupport } from '../tools/protocol_detection';
import { capitalize } from '../tools/stringTools.js';

import Button from '../components/Button';
import Icon from '../components/Icon';
import TextField from '../components/TextField';

import ContentPageNoHeader from '../main/templates/ContentPageNoHeader';

import CheckBox from '../images/check_box.svg';
import CheckBoxGreen from '../images/check_box_green.svg';
import './css/welcomePage.css';

const PAGE = { EMAIL: 0, NAME: 1, INVITE: 2, JOIN: 3 };
const TERMS = {
    education: {
        room: "classroom",
        attendee: "student",
        attendees: "students",
        presenter: "teacher"
    },
    corporate: {
        room: "meeting",
        attendee: "coworker",
        attendees: "coworkers",
        presenter: "presenter"
    },
    general: {
        room: "room",
        attendee: "guest",
        attendees: "guests",
        presenter: "presenter"
    }
};

class DemoRoom extends Component {
    state = {
        type: "general",
        first: "",
        last: "",
        email: "",
        page: PAGE.EMAIL,
        displayName: "",
        shareLabel: "Share Link",
        shareLink: "", //"https://www.cordoniq.com/placeholder", // !!!
        joinLink: "",
        copyState: 0,
        hubspotSubmissionGuid: "",
        copyBtnText: "Copy invite link"
    };

    handleEdit = change => this.setState({ [change.name]: change.value });

    next = () => {
        this.setState({ page: this.state.page + 1 });
        setTimeout(() => {
            let firstField = document.querySelector("#welcome .content form input:first-of-type");
            if (firstField !== null) firstField.focus();
        }, 0);
        return false;
    };

    back = () => {
        this.setState({ page: this.state.page - 1 });
        return false;
    };

    createAnonymousMeeting = () => {
        let metaData = {
            customerType: this.state.type,
            hubSpotSubmissionGuid: this.state.hubspotSubmissionGuid
        }
        newDemo("Cordoniq Demo", metaData).then(demo => {
            let apiDomain = ENV.getApiDomain();
            let links = {
                confId: demo.confId,
                shareLink: demo.presenterUrl.replace("connect", `connect${apiDomain}`),
                joinLink: demo.presenterUrl.replace("connect", `connect${apiDomain}`)
            };
            this.setState(links);
            if (ENV.isProd()) {
                ReactGA.event({
                    category: 'Demo Room Flow',
                    action: 'Anonymous Demo Requested',
                    label: ""
                });
            }
        }).catch(err => {
            new Notification({ id: "unknownError", message: err, type: "error" });
        });
    }

    createNewMeeting = e => {
        e.preventDefault();

        let metaData = {
            email: this.state.email,
            customerType: this.state.type
        };

        newDemo(this.state.displayName, metaData).then(demo => {
            let apiDomain = ENV.getApiDomain();
            let links = {
                confId: demo.confId,
                shareLink: demo.presenterUrl.replace("connect", `connect${apiDomain}`),
                joinLink: demo.presenterUrl.replace("connect", `connect${apiDomain}`)
            };
            this.setState(links);

            let emailData = {
                first: this.state.first,
                last: this.state.last,
                email: this.state.email,
                type: this.state.type,
                room: TERMS[this.state.type].room,
                name: this.state.displayName,
                attendees: TERMS[this.state.type].attendees,
                ...links
            };
            sendDemoLinks(emailData).then(result => {
                if (ENV.isProd()) {
                    ReactGA.event({
                        category: 'Form Submission',
                        action: 'Demo Requested',
                        label: emailData.email
                    });
                }
                this.next();
            }).catch(err => {
                console.log(err);
                this.next();
            });
        }).catch(err => {
            new Notification({ id: "unknownError", message: "Unknown error occurred! Try again.", type: "error" });
        });
    };

    copySuccess = () => {
        this.setState({copyState: 1});
        setTimeout(() => {
            this.setState({copyState: 0});
          }, "3000");
    }

    copyLink = e => {
        e.preventDefault();
        let copyField = document.querySelector("#shareLinkField");
        let url = copyField.value;
        this.setState({copyBtnText: "Link copied"});
        // navigator.clipboard.writeText(url).then(
        //     () => {
        //         //success
        //         console.log("copyLink succeeded");
        //     },
        //     () => {
        //         //failure
        //         console.log("copyLink failed");
        //     }
        // );
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.clipboard.writeText(url).then(
                        () => {
                            //success
                            this.copySuccess();
                        },
                        () => {
                            //failure
                        }
                    );
                }
            })
            .catch(() => {
                // Firefox does not support the clipboard-write permission, 
                // so use the old method if the above fails.
                copyField.select();
                document.execCommand("copy");
                window.getSelection().removeAllRanges();
                this.copySuccess();
            });
    }

    joinNow = () => {
        let protocolUrl = this.state.joinLink.replace("https://", "cordoniq://");
        checkProtocolSupport(protocolUrl,
            () => window.open(this.state.joinLink, '_blank'),
            () => { return protocolUrl },
            () => window.open(this.state.joinLink, '_blank')
        );
    };

    connectNow = e => {
        e.preventDefault();
        window.location = this.state.joinLink;
    }

    componentDidMount() {
        document.title = "Demo Room | Cordoniq";

        let types = Object.keys(TERMS);
		let type = this.props.match?.params?.type?.toLowerCase() ?? this.props?.type?.toLowerCase() ?? "general";
        if (!types.includes(type)) this.goTo("/DemoRoom");
        else this.setState({ type: type });

        let query = new URLSearchParams(this.props.location?.search);
        let startFrom = query.get("startFrom")?.toUpperCase();
        if (startFrom && Object.keys(PAGE).includes(startFrom)) {
            let submissionGuid = query.get("submissionGuid");
            this.setState({
                page: PAGE[startFrom],
                hubspotSubmissionGuid: submissionGuid
            }, () => {
                this.createAnonymousMeeting();
            });
        }

        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);

        let firstField = document.querySelector("#welcome section .content form input:first-of-type");
        firstField.focus();
    }

    componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
	}

    render() {
        let terms = TERMS[this.state.type];
        let copyBtnText = this.state.copyBtnText;

        return (
            <main id="welcome" className="demoRoom">
                <ContentPageNoHeader id="demoRoom" >
                    <section>
                    {this.state.page === PAGE.EMAIL && <div className="content">
                        <h1>Enjoy the free demo</h1>
                        <h4>Enter Cordoniq: online collaboration made simple. We provide you with live, first-rate tools and features to boost your remote learning and training engagement.</h4>
                        <p>The Cordoniq solution offers:</p>
                        <ul>
                            <li>Crystal clear audio/video and screen sharing</li>
                            <li>Real-time whiteboarding and markup annotation</li>
                            <li>Multi-language chat messaging and so much more</li>
                        </ul>
                        <p>It's easy to use, just provide your name and email (for login purposes only).</p>
                        <form>
                            <TextField name="first" className="half" placeholder="First name" value={this.state.first} onEdit={this.handleEdit} required />
                            <TextField name="last" className="half" placeholder="Last name" value={this.state.last} onEdit={this.handleEdit} />
                            <TextField name="email" type="email" placeholder="Your email" value={this.state.email} onEdit={this.handleEdit} required />
                            <Button onClick={this.next} disabled={this.state.email === "" || this.state.first === ""}>Next</Button>
                        </form>
                        <div id="sponsors">
                            <h4>Trusted by</h4>
                            <Icon id="pec" onClick={() => window.open('https://www.pecinc.com', '_blank')} icon="pec" />
                            <Icon id="math" onClick={() => window.open('https://www.mathnasium.com', '_blank')} icon="mathnasium" />
                            <Icon id="delphi" onClick={() => window.open('https://www.embarcadero.com/products/delphi', '_blank')} icon="delphi" />
                        </div>
                    </div>}
                    {this.state.page === PAGE.NAME && <div className="content">
                        <h1>Enter a demo {terms.room} name</h1>
                        <p>Name it anything. Example: Lily's {capitalize(terms.room)} or Demo Conference {capitalize(terms.room)}</p>
                        <form>
                            <TextField name="displayName" placeholder={`Enter a ${terms.room} name`} value={this.state.displayName} onEdit={this.handleEdit} />
                            <Button outline type="button" onClick={this.back}>Back</Button>
                            <Button id="demoFormSubmission" type="submit" onClick={this.createNewMeeting} disabled={this.state.displayName === ""}>Next</Button>
                        </form>
                    </div>}
                    {this.state.page === PAGE.INVITE && <div className="content center">
                        <h1>Invite others to join your demo room</h1>
                        <p>Share your private link via email, social media, or another communication platform so {terms.attendees} can join you in your dedicated demo {terms.room}</p>
                        <form>
                            <div className="noWrap">
                                <div className="shareGroup">
                                    <TextField 
                                        id="shareLinkField" 
                                        name="shareLinkField" 
                                        className="" 
                                        variant="outlined"
                                        value={this.state.shareLink} 
                                        readOnly 
                                        style={{
                                            backgroundImage: this.state.copyState === 0 
                                                ? `url(${CheckBox})`
                                                : `url(${CheckBoxGreen})`,
                                            borderColor: this.state.copyState === 0 
                                                ? `rgba(20,8,33,.16)`
                                                : `rgba(23, 130, 131, 1)`,
                                        }}
                                    /><div className="tooltip"><span style={{opacity: this.state.copyState }}><img alt="" src=""/>Copied to clipboard</span></div>
                                </div>
                                <div id="buttonFlex">
                                    <Button id="copyLink" name="copyLink" onClick={this.copyLink}>{copyBtnText}</Button>
                                    <Button type="submit" onClick={this.connectNow}>Enter demo {terms.room}</Button>
                                </div>
                                <p id="disclaimer">By clicking Enter demo room, you agree to the <Button link href="https://www.cordoniq.com/terms-of-use/">terms of use</Button> and <Button link href="https://www.cordoniq.com/privacy-policy/">privacy policy</Button>.</p>
                            </div>
                        </form>
                    </div>}
                    {this.state.page === PAGE.JOIN && <div className="content center">
                        <h1>Your <b>{this.state.displayName}</b> {capitalize(terms.room)} is Ready</h1>
                        <Button onClick={this.joinNow}>Join Your {capitalize(terms.room)} Now</Button>
                        <p>Forgot to invite someone? <Button link onClick={this.back}>Share your link</Button></p>
                        {this.state.type === "corporate" && <div className="message">
                            <div></div>
                            <p>Looking to run your own private cloud? Learn more and sign up today.</p>
                            <Button onClick={() => window.open(`https://developers.cordoniq.com/SignUp/${this.state.type}`, "_blank")}>Sign Me Up</Button>
                        </div>}
                    </div>}
                </section>
                </ContentPageNoHeader>
            </main>
        );
    }
}

export default withNav(DemoRoom);
