import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withPageViews } from './hooks/usePageViews';
import { isFirestoreAdmin } from './LumiAPI/APIs/internal/account';
import { ENV } from './tools/envTools.js';
import Events from './tools/Events';

import { Token } from './LumiAPI/APIs/auth.js';
import { removeColorSchemeOverride } from './tools/colorSchemeManager';

import LumiApp from './app/LumiApp';

import Chat from './pages/chat/Chat';
import DebugLogs from './pages/DebugLogs';
import DemoRoom from './pages/DemoRoom';
import Docs from './pages/Docs';
import JoinRoom from './pages/JoinRoom';
import PasswordReset from './pages/PasswordReset';
import Portal from './pages/Portal';
import ReleaseNotes from './pages/ReleaseNotes';
import NotFound from './pages/routing/NotFound';
import SignIn from './pages/SignIn';
import SignInHelp from './pages/SignInHelp';
import SignUp from './pages/SignUp';
import Test from './pages/Test';

import Article from './main/pages/Article';
import ContactSupport from './main/pages/ContactSupport';

import PECCaseStudy from './main/pages/PECCaseStudy';

import Blackboard from './main/pages/integrations/Blackboard';
import Canvas from './main/pages/integrations/Canvas';
import HubSpot from './main/pages/integrations/HubSpot';
import Integrations from './main/pages/integrations/Integrations';
import Moodle from './main/pages/integrations/Moodle';
import Slack from './main/pages/integrations/Slack';
import Zapier from './main/pages/integrations/Zapier';

import InviteOthers from './pages/InviteOthers.js';
import JoinConference from './pages/JoinConference';
import Login from './pages/Login';
import StartMeeting from './pages/StartMeeting.js';
import tvPostJoin from './pages/TvPostJoin.js';
import TvPreJoin from './pages/TvPreJoin.js';

// The intent is to no longer use this saved profiles
// feature because it is quite awkward.
// const SAVED_PROFILES_KEY = `savedProfiles${ENV.getStorageSuffix()}`;
// LUMI-733: Disable direct registration. When true, we redirect 
// /SignUp to a URL on the public website with a form to fill out to
// request access via a HubSpot form. 
const SIGN_UP_REQUEST_ACCESS = false;
const SIGN_UP_REQUEST_ACCESS_URL = `https://www${ENV.getApiDomain()}.cordoniq.com/developers-portal-access-request/`

// Determines whether users who arrive at the portal via a 
// special domain (eg: lumiroom.live) are shown the 
// demoroom flow or redirected to the homepage
// When true: visitors will see the DemoRoom flow
// When false: visitors will be redirected to www.cordoniq.com
const DEMO_ROOM_FLOW_ENABLED = false;

const lightModeOnly = [
	"/support",
	"/forms",
	"/lp",
	"/integration",
	"/demoroom"
];

class App extends Component {
	state = {
		isAdmin: false
	};

	checkAdmin = username => isFirestoreAdmin(username)
		.then(isAdmin => this.setState({ isAdmin }))
		.catch(console.error);

	componentDidMount() {
		document.title = "Cordoniq";
		window.scrollTo(0, 0);

		// this.checkAdmin(this.props.account?.username ?? "unknown");

		let pathname = window.location.pathname.toLowerCase();
		let removeOverride = !lightModeOnly.some(path => {
			return pathname.includes(path) && !pathname.includes("/portal");
		});

		if (removeOverride) {
			try {
				removeColorSchemeOverride();
			} catch (err) {
				console.log(err);
			}
		}

		if (ENV.isDev()) Events.start();
	}

	componentDidUpdate(prevProps) {
		if (this.props.account !== prevProps.account) {
			this.checkAdmin(this.props.account?.username ?? "unknown");
		}
	}

	componentWillUnmount() {
		if (ENV.isDev()) Events.stop();
	}

	render () {
		let isAuthenticated = Token.isAuthenticated() && Token.isValid();
		return (
			<Switch>
				<Route exact path="/" render={props => {
					let domain = window.location.host.split(".")[0];
					if (domain === "lumiroom") {
						if(DEMO_ROOM_FLOW_ENABLED) {
							return <DemoRoom type="general" />
						} else {
							window.location.replace('https://www.cordoniq.com/');
							return null;
						}
					}
					let defaultPath = "/SignIn";
					var subdomain = window.location.hostname.split('.');
					if (subdomain && subdomain.length > 1) {
						subdomain = subdomain[0].toLowerCase();
						switch(subdomain) {
							case "docs":
								return <Docs />;
							case "support":
								return <ContactSupport />;
							default:
								return <Redirect to={isAuthenticated ? "/Portal" : defaultPath} />;
						}
					} else {
						return <Redirect to={isAuthenticated ? "/Portal" : defaultPath} />;
					}
				}} />
				<Route exact path="/SignIn/Help"><SignInHelp /></Route>
				<Route exact path="/Password/Reset/:resetHash"><PasswordReset /></Route>
				<Route path={[ "/SignIn/:type", "/SignIn" ]} render={ props => {
					return isAuthenticated
						? <Redirect to="/Portal" />
						: <SignIn {...props} />
				}} />
				<Route path={[ "/SignUp/:type", "/SignUp" ]} render={ props => {
					return SIGN_UP_REQUEST_ACCESS
						? window.location.replace(SIGN_UP_REQUEST_ACCESS_URL)
						: <SignUp {...props} />
				}} />
				<Route path={[ "/JoinRoom/:type", "/JoinRoom" ]} component={JoinRoom} />
				<Route path={[ "/DemoRoom/:type", "/DemoRoom" ]} component={DemoRoom} />
				<Route path="/Portal"><Portal /></Route>
				<Route exact path="/Docs"><Redirect to="/Docs/Docs/Overview" /></Route>
				<Route path={[ "/Docs/:mode/:selected", "/Docs/:mode" ]} component={Docs} />
				<Route exact path="/ReleaseNotes"><ReleaseNotes /></Route>

				<Route path={[ "/TV/join", "/join" ]} component={JoinConference} />
				<Route exact path="/TV/login" component={Login} />
				<Route exact path="/TV/invite" component={InviteOthers} />
				<Route exact path="/TV/pre" component={TvPreJoin} />
				<Route exact path="/TV/post" component={tvPostJoin} />
				<Route exact path="/TV/start" component={StartMeeting} />


				{/* CAN REMOVE SALES AND SALES SENT PAGES BELOW WHEN SECRET SUSHI HAS FINSIHED TESTING THE NEW ONE */}
				<Route exact path="/Sales"><NotFound noToolbar={true} /></Route>
				<Route exact path="/Sales/Sent"><NotFound noToolbar={true} /></Route>
				{/* CAN REMOVE SALES AND SALES SENT PAGES ABOVE WHEN SECRET SUSHI HAS FINISHED TESTING THE NEW ONE */}

				<Route exact path="/forms/contact"><NotFound noToolbar={true} /></Route>

				{/* SUPPORT PAGES HAVE NOT BEEN IMPLEMENTED INTO WORDPRESS YET, BUT WOULD BE THE SAME FORMAT AS THE SALES PAGE */}
				<Route exact path="/Support"><NotFound noToolbar={true} /></Route>
				<Route exact path="/Support/Sent"><NotFound noToolbar={true} /></Route>
				<Route path={[ "/Support/Article", "/Support/moreInfo", "/moreInfo" ]}><Article /></Route>

				<Route exact path="/forms/ebook/8techniques"><NotFound noToolbar={true} /></Route>
				<Route exact path="/forms/ebook/buyersguide"><NotFound noToolbar={true} /></Route>
				<Route exact path="/forms/ebook/lowcode"><NotFound noToolbar={true} /></Route>
				{/* CAN REMOVE LP PAGES BELOW AFTER SECRET SUSHI HAS FINISHED CONVERTING THEM TO THE FORMS */}
				<Route exact path="/lp/ebook/8techniques"><NotFound noToolbar={true} /></Route>
				<Route exact path="/lp/ebook/8techniques/sent"><NotFound noToolbar={true} /></Route>
				<Route exact path="/lp/ebook/buyersguide"><NotFound noToolbar={true} /></Route>
				<Route exact path="/lp/ebook/buyersguide/sent"><NotFound noToolbar={true} /></Route>
				{/* CAN REMOVE LP PAGES ABOVE AFTER SECRET SUSHI HAS FINSIHED CONVERTING THEM TO THE FORMS */}

				<Route exact path="/lp/case-study/pec"><PECCaseStudy /></Route>

				<Route exact path="/Integrations"><Integrations /></Route>
				<Route exact path={[ "/Integrations/Canvas", "/Canvas" ]}><Canvas /></Route>
				<Route exact path={[ "/Integrations/Moodle", "/Moodle" ]}><Moodle /></Route>
				<Route exact path={[ "/Integrations/Slack", "/Slack" ]}><Slack /></Route>
				<Route exact path="/Integrations/Slack/Complete"><Slack success={true} /></Route>
				<Route exact path="/Integrations/Blackboard"><Blackboard /></Route>
				<Route exact path={[ "/Integrations/HubSpot", "/Hubspot" ]}><HubSpot /></Route>
				<Route exact path="/Integrations/HubSpot/Complete"><HubSpot success={true} /></Route>
				<Route exact path={[ "/Integrations/Zapier", "/Zapier" ]}><Zapier /></Route>

				{ENV.isDev() && <Route path={[ "/lms/:integrationId/chat", "/Chat" ]} component={Chat} />}

				{/* REMOVE THIS AFTER SLACK APPROVES NEWEST CHANGES */}
				<Route exact path="/api/slack/auth">
					<Redirect to={`https://portal-api.cordoniq.com/api/slack/auth${window.location.search}`} />
				</Route>

				{ENV.isStag() && <Route exact path="/app/:intId" component={LumiApp} />}
				{ENV.isDev() && <Route exact path="/Testing" render={props => {
					const { default: Testing } = require('./Testing');
					return <Testing {...props} />;
				}} />}
				
				<Route exact path="/test" render={props => {
					if(!ENV.isProd()) {
						return <Test {...props} />
					} else {
						return <NotFound noToolbar={true} />
					}
				}} />
				{(this.state.isAdmin || ENV.isDev()) && <Route exact path="/Logs" component={DebugLogs} />}
				<Route path="/:page" render={props => {
					let domain = window.location.host.split(".")[0];
					if (domain === "lumiroom") {
						if(DEMO_ROOM_FLOW_ENABLED) {
							return <DemoRoom type={props.match.params.page} />
						} else {
							window.location.replace('https://www.cordoniq.com/');
							return null;
						}
					} else {
						return <NotFound noToolbar={true} />
					}
				}} />
			</Switch>
		);
	}
}

const mapStateToProps = state => ({
	account: state.account
});

export default connect(mapStateToProps)(withPageViews(App));
