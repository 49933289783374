import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorUpdater, getColorScheme } from '../tools/colorSchemeManager.js';

import icons from '../images/iconLibrary.js';
import './css/iconEffects.css';

class Icon extends Component {
	objRef = React.createRef();

	state = {
		isFA: typeof this.props.icon !== "string",
		icon: null,
		colorMode: "dark",
		bg: this.props.bg || ""
	};

	updateColorMode = () => {
		var mode = getColorScheme();
		this.setState({ colorMode: mode });
	};

	getIcon = highlighted => {
		var mode = this.props.mode ?? this.state.colorMode;
		if (highlighted === true) {
			mode = (mode === "light" ? "dark" : "light");
		}
		return icons[this.props.icon][mode];
	};

	setColor = color => {
		if (this.props.svg && color !== undefined) {
			let object = this.objRef.current;
			let svg = object.contentDocument.querySelector("svg");
			if (svg === null) return;
			let paths = svg.querySelectorAll("path");
			paths.forEach(path => {
				path.setAttribute("fill", color);
			});
		}
	};

	componentDidMount() {
		this.updateColorMode();
		ColorUpdater.add(this.updateColorMode);

		var icon = this.props.icon;
		var isFA = typeof icon !== "string";
		this.setState({ isFA: isFA, icon: icon });
	}

	componentWillUnmount() {
		ColorUpdater.remove(this.updateColorMode);
	}

	componentDidUpdate(prevProps) {
		if (this.props.bg !== prevProps.bg) {
			this.setState({ bg: this.props.bg });
		}

		if (this.props.svg && this.props.color !== prevProps.color) {
			this.setColor(this.props.color);
		}
	}

	render() {
		const highlight = this.props.highlight ?? false;
		return (
			<React.Fragment key={this.state.colorMode}>
			{this.state.isFA
				? <FontAwesomeIcon icon={this.state.icon} {...this.props} />
				: this.props.svg
					? <object ref={this.objRef} type="image/svg+xml" data={this.getIcon(highlight)} onLoad={() => this.setColor(this.props.color)} {...this.props}>
						<img alt={this.state.icon} src={this.getIcon(highlight)} style={{ backgroundColor: this.state.bg }} {...this.props} />
					</object>
					: <img alt={this.state.icon} src={this.getIcon(highlight)} style={{ backgroundColor: this.state.bg }} {...this.props} />
			}
			{this.props.text && <span className="iconLabel">{this.props.text}</span>}
			</React.Fragment>
		);
	}
}

export default Icon;
