import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';

import Button from '../components/Button';
import GraphicSidebar from '../partials/GraphicSidebar';


import './css/welcomePage.css';

class Test extends Component {
	state = {
	};

	componentDidMount() {
        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);
	}

    componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
	}

  	render() {
	  	return (
		  	<main id="welcome" className="signUp">
		  		<GraphicSidebar show={true} />
		  		<section>
		  			<div className="content">
                        {/* eslint-disable-next-line no-undef */}
						<p><Button onClick={() => kaboom()}>Generate Sentry event</Button></p>
					</div>
		  		</section>
		  	</main>
		);
	}
}

export default withNav(Test);
