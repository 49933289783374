import { useCallback } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { roles } from '../LumiAPI/APIs/internal/account';
import { useSelector, useDispatch, connect } from 'react-redux';
import { setUnsavedChanges } from '../actions/unsavedChanges-actions';
import { pathToTitle } from './usePageViews';

const useNav = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const setChanges = useCallback(state => {
        dispatch(setUnsavedChanges(state))
    }, [dispatch]);

    const curRole = useSelector(state => state.curRole);

    return {
        getCurrentPage: () => pathToTitle(location.pathname),
        goTo: path => {
            setChanges(false);
            history.push(path);
        },
        goBack: () => {
            setChanges(false);
            history.goBack();
        },
        goForward: () => {
            setChanges(false);
            history.goForward();
        },
        redirectTo: path => {
            history.replace(path);
        },
        checkPermissions: newRole => {
            let currentPage = pathToTitle(location.pathname);
            newRole = newRole ?? curRole;

            let level;
            switch (currentPage) {
                case "Conference":
                    level = roles.PRESENTER;
                    break;
                case "Users":
                    level = roles.HOST;
                    break;
                default:
                    level = roles.OWNER;
            }

            if (newRole < level) {
                location.state = { from: location.pathname };
                history.replace("/Portal/NoPermission");
            }
        },
        redirectWithPermission: () => {
            let referrer = location.state?.from ?? "/Dashboard";
            history.replace(referrer);
        }
    };
};

export default useNav;

export const withNav = WrappedComponent => {
    const WrappedWithHistory = class extends WrappedComponent {
        getCurrentPage = () => pathToTitle(this.props.location.pathname);

        goBack = () => {
            this.props.onSetUnsavedChanges(false);
            this.props.history.goBack();
        };

        goForward = () => {
            this.props.onSetUnsavedChanges(false);
            this.props.history.goForward();
        };

        goTo = path => {
            this.props.onSetUnsavedChanges(false);
            this.props.history.push(path);
        };

        redirectTo = path => this.props.history.replace(path);

        checkPermissions = newRole => {
            let currentPage = pathToTitle(this.props.location.pathname);
            newRole = newRole ?? this.props.curRole;

            let level;
            switch (currentPage) {
                case "Conference":
                    level = roles.PRESENTER;
                    break;
                case "Users":
                    level = roles.HOST;
                    break;
                default:
                    level = roles.OWNER;
            }

            if (newRole < level) {
                this.props.location.state = { from: this.props.location.pathname };
                this.props.history.replace("/Portal/NoPermission");
            }
        };

        redirectWithPermission = () => {
            let referrer = this.props.location.state?.from ?? "/Dashboard";
            this.props.history.replace(referrer);
        };
    }

    const mapStateToProps = state => ({
        unsavedChanges: state.unsavedChanges
    });

    const mapActionsToProps = {
        onSetUnsavedChanges: setUnsavedChanges
    };

    return connect(mapStateToProps, mapActionsToProps)(withRouter(WrappedWithHistory));
};
