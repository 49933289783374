export default class Query {
	queryString = "";
	queryObject = {};

	constructor(string) {
		this.queryString = string.split("?")[1];

		this.createQueryObject = this.createQueryObject.bind(this);
		this.get = this.get.bind(this);

		if (this.queryString) {
			this.createQueryObject();
		}
	}

	createQueryObject() {
		var queries = this.queryString.split("&");

		for (var i = 0; i < queries.length; i++) {
			var query = queries[i].split("=");
			var key = query[0];
			var value = query.length === 1 ? true : query[1].replace(/%20/g, " ");
			this.queryObject[key] = value;
		}
	}

	get(key) {
		return this.queryObject[key];
	}
}
