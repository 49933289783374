import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';
import { sendContactResponse, sendErrorReport } from '../../LumiAPI/emailAPI';
import Query from '../../tools/Query';
import { capitalize } from '../../tools/stringTools';

import ContentPageWithHeader from '../templates/ContentPageWithHeader';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import TextField from '../../components/TextField';
import Notification from '../../tools/Notification';

import articles from './supportArticles.json';

import './css/article.css';

const OS = { windows: "Windows", macos: "macOS", ios: "iOS", android: "Android", other: "Other", unknown: "Unknown" };

class Article extends Component {
    nameRef = React.createRef();
    emailRef = React.createRef();
    messageRef = React.createRef();

    state = {
        search: "",
        article: undefined,
        processing: false,
        sent: false,
        showForm: false,
        first: "",
        email: "",
        subject: "Unknown",
        message: ""
    };

    edit = change => this.setState({ [change.name]: change.value });

    displayContent = content => (
        content.map(item => {
            switch(item.type) {
                case "container":
                    return <div className={item.style ?? ""}>{this.displayContent(item.content)}</div>
                case "list":
                    return <ul>{this.displayContent(item.content)}</ul>
                case "steps":
                    return <ol>{this.displayContent(item.content)}</ol>
                case "item":
                    return <li>{typeof item.content == "object"
                        ? this.displayContent(item.content)
                        : item.content
                    }</li>
                case "image":
                    return <img src={item.content} className={item.style ?? ""} alt="unknown" />
                case "subtitle":
                    return <h2>{item.content}</h2>
                case "text":
                    return <p>{item.content}</p>
                default:
                    return item.content
            }
        })
    );

    formSent = () => this.setState({ sent: true, processing: false });
    resetForm = () => this.setState({ first: "", email: "", message: "", showForm: false, sent: false });

    validate = e => {
        e.preventDefault();
        if (this.state.first === "") {
            this.nameRef.current.focus();
            new Notification({ id: "noName", message: "Please enter your name", type: "warning" });
        } else if (this.state.email === "" || !this.emailRef.current.isValid()) {
            this.emailRef.current.focus();
            new Notification({ id: "invalidEmail", message: "Please enter a valid email", type: "warning" });
        } else if (this.state.message === "") {
            this.messageRef.current.focus();
            new Notification({ id: "noMessage", message: "Please enter your message", type: "warning" });
        } else {
            this.send();
        }
    };

    send = () => {
        this.setState({ processing: true });
        sendErrorReport(this.state, (response, err) => {
            if (err) {
                console.log(err);
                new Notification({ id: "failedSend", message: "An error occurred! Please try again", type: "error" });
                this.setState({ processing: false });
            } else {
                sendContactResponse(this.state, (response, err) => {
                    if (err) {
                        console.log(err.response);
                    }
                    this.formSent();
                })
            }
        });
    };

    parseQueryString = () => {
        let queryString = this.props.location.state?.query ?? window.location.search;
        if (queryString === "") {
            this.setState({
                category: "",
                error: "",
                article: undefined,
                subject: "",
                version: "",
                os: "Unknown",
                osversion: "Unknown"
            });
            return;
        }
        let query = new Query(queryString);
        let category = query.get("category") ?? "general";
        let error = query.get("error") ?? "1";
        let version = query.get("version") ?? "Unknown";
        let os = query.get("os") ?? "unknown";
        os = os.toLowerCase();
        let osversion = query.get("osversion") ?? "Unknown";

        if (articles.items[category] === undefined) {
            category = "general";
        }
        if (articles.items[category][error] === undefined) {
            error = "1";
        }

        this.setState({
            category: capitalize(category),
            error: error,
            article: articles.items[category][error],
            subject: articles.items[category][error].title,
            version: version,
            os: OS[os],
            osversion: osversion
        });
    };

    articleList = () => {
        let search = this.state.search.toLowerCase();
        let list = {};
        let categoryList = articles.items;
        Object.keys(categoryList).forEach(category => {
            let errorList = categoryList[category];
            Object.keys(errorList).forEach(error => {
                if (error === "1") return;
                let article = errorList[error];
                let key = `${category}-${error}`;
                if (article.title.toLowerCase().includes(search)) list[key] = article;
                article.content && article.content.forEach(content => {
                    if (content.type === "text") {
                        if (list[key] !== undefined) return;
                        if (content.content.toLowerCase().includes(search)) list[key] = article;
                    }
                });
            });
        });
        return list;
    };

    openArticle = (lookup, article) => {
        let [ category, error ] = lookup.split("-");
        this.setState({
            search: "",
            category: capitalize(category),
            error: error,
            article: articles.items[category][error],
            subject: articles.items[category][error].title
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Support Article | Cordoniq";
        this.parseQueryString();
    }

    render () {
        let article = this.state.article;
        let searchResults = this.articleList();
        let searchCount = Object.keys(searchResults).length;

        return (
            <ContentPageWithHeader id="supportArticle" title={article?.title ?? "Article Not Found"}>
                <article>
                    {this.state.search !== ""
                        ? <div id="results">
                            {searchCount > 0
                                ? <h3 className="hasResults">{searchCount} Result{searchCount === 1 ? "" : "s"} Found<button onClick={() => this.setState({ search: "" })}>Clear</button></h3>
                                : <h3>No Results Found</h3>
                            }
                            {Object.keys(searchResults).map(lookup => {
                                let found = searchResults[lookup];
                                return <div className="searchResult" onClick={() => this.openArticle(lookup, found)}>
                                    <Icon icon="file" mode="light" />
                                    <span>{found.title}</span>
                                    <Icon icon="arrowRight" mode="light" />
                                </div>
                            })}
                        </div>
                        : <>
                            {article?.content && this.displayContent(article.content)}
                            {article === undefined
                                ? <p>Oops! Looks like we weren't able to find what you were looking for. Check the link or search for it.</p>
                                : <>
                                {this.state.error === "1"
                                    ? <p>Looks like we've encountered an error. Please fill out the form below with a description of what happened leading up to this error so we can resolve it as soon as possible.</p>
                                    : <hr />
                                }
                                {this.state.showForm || this.state.error === "1"
                                    ? <div id="report">
                                        {this.state.sent
                                            ? <div className="success">
                                                <h2>Your report has been sent!</h2>
                                                <p>Thank you for your feedback. We'll work towards a solution as soon as possible</p>
                                                <Button onClick={this.resetForm}>Done</Button>
                                            </div>
                                            : <>
                                                <h2>Report Additional Details</h2>
                                                <form onSubmit={this.validate}>
                                                    <TextField ref={this.nameRef} name="first" className="half" label="First Name" placeholder="Enter your name" value={this.state.first} onEdit={this.edit} />
                                                    <TextField ref={this.emailRef} type="email" name="email" className="half" label="Email" placeholder="Enter your email" value={this.state.email} onEdit={this.edit} />
                                                    <TextField ref={this.messageRef} type="textarea" name="message" label="Message" placeholder="Please enter as many details as possible" value={this.state.message} onEdit={this.edit} />
                                                    <Button disabled={this.state.processing}>{this.state.processing ? "Sending..." : "Submit"}</Button>
                                                </form>
                                            </>
                                        }
                                    </div>
                                    : < div id="report" className="prompt">
                                        <h2>Report Additional Details</h2>
                                        <p>Would you like to report additional details regarding this article? Click below to send a report.</p>
                                        <Button onClick={() => this.setState({ showForm: true })}>Send Report</Button>
                                    </div>
                                }
                                </>
                            }

                        </>
                    }
                </article>
                <aside>
                    <h2>Need more assistance?</h2>
                    <p>Search for an article or contact one of our teams for any additional questions you might have.</p>
                    <TextField name="search" label="Search Support" placeholder="Start your search here..." value={this.state.search} onEdit={this.edit} />
                    <section id="contactForms">
                        <div onClick={() => this.goTo("/Support")}>
                            <h2>Contact Support<Icon icon="arrowRight" /></h2>
                            <p>Our support team is ready to answer your technical questions and help with troubleshooting</p>
                        </div>
                        <div onClick={() => this.goTo("/Sales")}>
                            <h2>Contact Sales<Icon icon="arrowRight" /></h2>
                            <p>Our sales team is happy to discuss any questions about our product and partnering with Cordoniq</p>
                        </div>
                    </section>
                </aside>
                <div id="contactLink">
                    <h2>Need more assistance?</h2>
                    <p>Contact our Support team <a href="/Support">here</a>.</p>
                </div>
            </ContentPageWithHeader>
        );
    }
}

export default withNav(Article);
