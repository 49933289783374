import React, { useEffect, useRef } from 'react';

import Icon from '../../components/Icon';

import "./css/video.css";

const Video = props => {
	const videoRef = useRef();

	const className = () => {
		let className = "lumiAppVideo";
		if (props.className) className += ` ${props.className}`;
		return className;
	};

	useEffect(() => {
		videoRef.current.srcObject = props?.srcObject ?? undefined;
	}, [props]);

	return <div className={className()} style={props.style}>
		<video ref={videoRef} autoPlay={props.autoPlay ?? true} playsInline={props.playsInline ?? true} controls={props.controls ?? false}>
			{props.children}
		</video>
		<Icon icon="userAccount" />
		{props.metaData?.name && <h1>{props.metaData.name}</h1>}
	</div>;
};

export default Video;
