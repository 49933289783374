const chatOwner = (state = {}, action) => {
    switch(action.type) {
        case 'SETCHATOWNER':
            return action.payload.owner;
        default:
            if (Object.keys(state).length === 0) {
                return { userId: "unknown", userName: "Guest" }
            }
            return state;
    }
};

const chats = (state = {}, action) => {
    switch(action.type) {
        case 'REFRESHCHATS':
        case 'UPDATECHAT':
            return action.payload.chats;
        default:
            return state;
    }
};

const currentChatConfId = (state = "", action) => {
    switch (action.type) {
        case 'CHANGECHATCONF':
            return action.payload.confId;
        default:
            return state;
    }
};

const currentChat = (state = "", action) => {
    switch (action.type) {
        case 'OPENCHAT':
            return action.payload.chatId;
        default:
            return state;
    }
};

const chatUsers = (state = {}, action) => {
    switch(action.type) {
        case 'REFRESHCHATUSERS':
            return action.payload.users;
        default:
            return state;
    }
};

const messages = (state = [], action) => {
    switch (action.type) {
        case 'REFRESHMESSAGES':
        case 'UPDATEMESSAGE':
            return action.payload.messages;
        default:
            return state;
    }
};

const recentMessages = (state = {}, action) => {
    switch (action.type) {
        case 'REFRESHRECENTS':
            return action.payload.recents;
        default:
            return state;
    }
};

const deliveredIndex = (state = -1, action) => {
    switch (action.type) {
        case 'SETDELIVERED':
            return action.payload.index;
        default:
            return state;
    }
};

const newRecipients = (state = [], action) => {
    switch (action.type) {
        case 'SETRECIPIENTS':
            return action.payload.recipients;
        default:
            return state;
    }
};

let ChatReducers = {
    chatOwner,
    chats,
    currentChatConfId,
    currentChat,
    chatUsers,
    messages,
    recentMessages,
    deliveredIndex,
    newRecipients
};

export default ChatReducers;
