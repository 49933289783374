import moment from 'moment';
import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import { getTokenInfo, signout, Token } from '../LumiAPI/APIs/auth.js';
import { getAccount } from '../LumiAPI/APIs/internal/account.js';
import { accountIconColor, colors, ColorUpdater, getColorScheme, setThemeColor } from '../tools/colorSchemeManager';
import { ENV } from '../tools/envTools.js';
import { faqs } from './json/faqs.js';

import { faNpm } from '@fortawesome/free-brands-svg-icons';
import Button from '../components/Button';
import Code from '../components/Code';
import Icon from '../components/Icon';

import Swagger from '../components/Swagger.js';
import Examples from './json/codeExamples.js';

import { connect } from 'react-redux';
import { updateCredentials } from '../actions/account-actions';

import './css/docs.css';

const compactLayout = window.matchMedia("(max-width: 950px)");
const mobileLayout = window.matchMedia("(max-width: 450px)");

const DOCS_MODE = { DOCS: "docs", APIS: "apis" };

const DOCS = { OVERVIEW: 1, AUTH: 2, CORE: 3, CONTENT: 4, UPDATES: 5, RESOURCES: 6 };
const APIS = { OVERVIEW: 0, AUTH: 1, CORE: 2, CONTENT: 3 };
const API_URLS = {
	1: "https://api.swaggerhub.com/apis/cordoniq/cordoniq-auth/v1",
	2: "https://api.swaggerhub.com/apis/cordoniq/cordoniq-core/v1",
	3: "https://api.swaggerhub.com/apis/cordoniq/cordoniq-content/v1"
};

class Docs extends Component {
	state = {
		selectedMode: DOCS_MODE.DOCS,
		selected: DOCS.OVERVIEW,
		signedIn: false,
		showAccount: false,
		preferNPM: true,
		compact: false,
		mobile: false,
		showSidebar: false,
		apiSidebar: [
			{
				displayName: "Authentication APIs",
				items: [
					{
						displayName: "Overview",
						selected: () => this.state.selected === APIS.AUTH ? "selected" : "",
						action: () => this.openDocs(APIS.AUTH)
					}
				]
			},
			{
				displayName: "Core APIs",
				items: [
					{
						displayName: "Overview",
						selected: () => this.state.selected === APIS.CORE ? "selected" : "",
						action: () => this.openDocs(APIS.CORE)
					},
					{
						displayName: "Conference",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "conference")
					},
					{
						displayName: "Content",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "content")
					},
					{
						displayName: "User",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "user")
					},
					{
						displayName: "Users",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "users")
					},
					{
						displayName: "Share",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "share")
					},
					{
						displayName: "Chat",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "chat")
					},
					{
						displayName: "Chat Messages",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "chat messages")
					},
					{
						displayName: "Recording",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "recording")
					},
					{
						displayName: "Events",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "events")
					},
					{
						displayName: "Subscribers",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "subscriber")
					},
					{
						displayName: "Toast",
						selected: () => false,
						action: () => this.openDocs(APIS.CORE, "toast")
					}
				]
			},
			{
				displayName: "Content APIs",
				items: [
					{
						displayName: "Overview",
						selected: () => this.state.selected === APIS.CONTENT ? "selected" : "",
						action: () => this.openDocs(APIS.CONTENT)
					}
				]
			}
		],
		sidebar: [
			{
				displayName: "Overview",
				items: [
					{
						displayName: "Home",
						selected: () => this.state.selected === DOCS.OVERVIEW ? "selected" : "",
						action: () => this.openDocs(DOCS.OVERVIEW)
					}
				]
			},
			{
				displayName: "Authentication API",
				items: [
					{
						displayName: "Overview",
						selected: () => this.state.selected === DOCS.AUTH ? "selected" : "",
						action: () => this.openDocs(DOCS.AUTH)
					}
				]
			},
			{
				displayName: "Core API",
				items: [
					{
						displayName: "Overview",
						selected: () => false,
						action: () => this.openDocs(DOCS.CORE)
					},
					{
						displayName: "Conferences",
						selected: () => false,
						action: () => this.openDocs(DOCS.CORE, "conferences")
					},
					{
						displayName: "Users",
						selected: () => false,
						action: () => this.openDocs(DOCS.CORE, "users")
					}
				]
			},
			{
				displayName: "Content API",
				items: [
					{
						displayName: "Overview",
						selected: () => this.state.selected === DOCS.CONTENT ? "selected" : "",
						action: () => this.openDocs(DOCS.CONTENT)
					}
				]
			},
			{
				displayName: "Updates",
				items: [
					{
						displayName: "Announcements",
						selected: () => false,
						action: () => this.openDocs(DOCS.UPDATES)
					},
					{
						displayName: "Changelog",
						selected: () => false,
						action: () => this.openDocs(DOCS.UPDATES, "changelog")
					}
				]
			},
			{
				displayName: "Additional Resources",
				items: [
					{
						displayName: "FAQs",
						selected: () => this.state.selected === DOCS.RESOURCES ? "selected" : "",
						action: () => this.openDocs(DOCS.RESOURCES)
					}
				]
			}
		],
		faqs: faqs
	};

	setMode = (mode, doc) => {
		doc = doc ?? 1
		this.setState({ selectedMode: mode, selected: doc });

		this.setSelected(doc - 1, 0);

		// Change path in url in case of reload
		let list = mode === DOCS_MODE.DOCS ? DOCS : APIS;
		let selected = mode === DOCS_MODE.DOCS ? doc - 1 : doc;
		let defaultPath = mode === DOCS_MODE.DOCS ? "overview" : "auth";
		let docPath = Object.keys(list)?.[selected]?.toLowerCase() ?? defaultPath;
		this.goTo(`/Docs/${mode}/${docPath}`);
	};

	scrollToSubItem = subitem => {
		setTimeout(() => {
			let container = document.querySelector("#docs");
			let tag = document.querySelector(`[data-tag="${subitem}"]`);
			if (tag === null) {
				this.scrollToSubItem(subitem);
			} else {
				let top = tag.getClientRects()[0].top - container.getClientRects()[0].top;
				window.scrollTo(0, top);
			}
		}, 0);
	};

	openDocs = (file, subitem) => {
		this.setState({ selected: file });

		this.setSelected(file - 1, this.getSubitemIndex(subitem));

		if (subitem !== undefined) this.scrollToSubItem(subitem);
		window.scrollTo(0, 0);

		// Change path in url in case of reload
		let list = this.state.selectedMode === DOCS_MODE.DOCS ? DOCS : APIS;
		let selected = this.state.selectedMode === DOCS_MODE.DOCS ? file - 1 : file;
		let defaultPath = this.state.selectedMode === DOCS_MODE.DOCS ? "overview" : "auth";
		let doc = Object.keys(list)?.[selected]?.toLowerCase() ?? defaultPath;
		this.goTo(`/Docs/${this.state.selectedMode}/${doc}`);
	};

	setNPMPref = preferNPM => this.setState({ preferNPM: preferNPM });

	getSubitemIndex = subitem => {
		let section = this.state.selected - 1;
		let link = 0;
		let sidebarKey = this.state.selectedMode === DOCS_MODE.DOCS ? "sidebar" : "apiSidebar";
		this.state[sidebarKey][section].items.forEach((item, i) => {
			if (item.displayName.toLowerCase() === subitem) {
				link = i;
			}
		});
		return link;
	};

	setSelected = (sectionIndex, linkIndex) => {
		let sidebarKey = this.state.selectedMode === DOCS_MODE.DOCS ? "sidebar" : "apiSidebar";
		let sidebar = this.state[sidebarKey];
		sidebar.forEach((section, i) => {
			if (sidebar[i].items.length > 1) {
				sidebar[i].items.forEach((item, j) => {
					sidebar[i].items[j].selected = () => {
						return i === sectionIndex && j === linkIndex ? "selected" : "";
					};
				});
			}
		});
		this.setState({ [sidebarKey]: sidebar });
	};

	selectOnScroll = () => {
		let tags = document.querySelectorAll('[data-tag]');
		if (tags.length !== 0) {
			tags.forEach((tag, i) => {
				let headerMargin = 80;
				let tagTop = tag.getClientRects()[0].top - headerMargin;

				let sectionIndex = this.state.selected - 1,
					linkIndex = i + 1;
				if (i === tags.length - 1) {
					if (tagTop <= 0) {
						this.setSelected(sectionIndex, linkIndex);
					}
				} else {
					let nextTagTop = tags[i+1].getClientRects()[0].top - headerMargin;
					if (tagTop > 0 && nextTagTop > 0) {
						if (i === 0) {
							this.setSelected(sectionIndex, 0)
						}
					} else if (tagTop <= 0 && nextTagTop > 0) {
						this.setSelected(sectionIndex, linkIndex);
					} else {
						// console.log(tags[i].innerHTML + " is no longer selected");
					}
				}
			});
		}
	};

	toggleAccount = () => {
		let account = this.state.showAccount;
		this.setState({ showAccount: !account });
	};

	closeAccount = e => this.setState({ showAccount: false });

	toggleSidebar = () => {
		let sidebar = this.state.showSidebar;
		this.setState({ showSidebar: !sidebar });
	};

	closeSidebar = e => this.setState({ showSidebar: false });

	sidebarClass = () => {
		let className = "docsList";
		if (this.state.compact && !this.state.showSidebar) {
			className += " hide";
		}
		return className;
	};

	updateLayout = () => this.setState({ compact: compactLayout.matches, mobile: mobileLayout.matches });
	updateThemeColor = () => {
		let colorScheme = getColorScheme();
		this.setState({ colorScheme });
		if (colorScheme === "light") {
			if (this.state.signedIn) {
				setThemeColor(42, 11, 53);
			} else {
				setThemeColor(250, 250, 250);
			}
		} else {
			setThemeColor(0, 0, 0);
		}
	};

	highlightIcon = () => (this.state.colorScheme === "light" && this.state.signedIn);

	componentDidMount() {
		document.title = "Docs | Cordoniq";
		window.scrollTo(0, 0);
		window.addEventListener("scroll", this.selectOnScroll);
		document.querySelector("main").addEventListener("click", this.closeAccount);
		document.querySelector("main").addEventListener("click", this.closeSidebar);

		let { mode, selected } = this.props.match.params;
		mode = mode?.toUpperCase() ?? "DOCS";
		if (selected !== undefined) {
			selected = selected.toUpperCase();
			selected = mode === "DOCS" ? DOCS[selected] : APIS[selected];
		}
		this.setMode(DOCS_MODE[mode], selected ?? 1);

		this.updateLayout();
		compactLayout.addListener(this.updateLayout);
		mobileLayout.addListener(this.updateLayout);
		this.updateThemeColor();
		ColorUpdater.add(this.updateThemeColor);

		getTokenInfo(Token.get()).then(tokenInfo => {
			this.setState({ signedIn: true });
			if (this.props.account.username === undefined) {
				getAccount().then(account => this.props.onUpdateCredentials(account));
			}
		}).catch(err => {
			Token.remove();
			this.setState({ signedIn: false });
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.account !== prevProps.account) {
			this.setState({ signedIn: this.props.account.username !== undefined });
			this.updateThemeColor();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.selectOnScroll);
		document.querySelector("main").removeEventListener("click", this.closeAccount);
		document.querySelector("main").removeEventListener("click", this.closeSidebar);
		compactLayout.removeListener(this.updateLayout);
		mobileLayout.removeListener(this.updateLayout);
		ColorUpdater.remove(this.updateThemeColor);
	}

	render() {
		const { preferNPM } = this.state;
		const tabs = [
			{ name: "NPM", className: preferNPM ? "selected" : "", onClick: () => this.setNPMPref(true) },
			{ name: "JavaScript", className: !preferNPM ? "selected" : "", onClick: () => this.setNPMPref(false) }
		];
		return (
			<div id="documentation">
				<header className={this.state.signedIn && "signedIn"}>
					<Icon icon="menu" highlight={this.highlightIcon()} onClick={() => this.toggleSidebar()} />
					<Icon icon="logo" highlight={this.highlightIcon()} />
					<h1>Cordoniq</h1>
					<h2>Developers</h2>
					<nav>
						<button className={this.state.selectedMode === DOCS_MODE.DOCS && "selected"} onClick={() => this.setMode(DOCS_MODE.DOCS)}>
							{this.state.compact
								? this.state.mobile
									? <Icon icon="file" highlight={this.highlightIcon()} />
									: "Docs"
								: "Documentation"
							}
						</button>
						<button className={this.state.selectedMode === DOCS_MODE.APIS && "selected"} onClick={() => this.setMode(DOCS_MODE.APIS, APIS.AUTH)}>
							{this.state.compact
								? this.state.mobile
									? <Icon icon="integrations" highlight={this.highlightIcon()} />
									: "APIs"
								: "API Reference"
							}
						</button>
						<button onClick={() => window.location.replace(`https://www${ENV.getApiDomain()}.cordoniq.com/support/`)}>
							{this.state.compact
								? this.state.mobile
									? <Icon icon="support" highlight={this.highlightIcon()} />
									: "Support"
								: "Contact Support"
							}
						</button>
						{this.state.signedIn
							? (this.props.account.pictureImgUrl !== undefined
								? <div id="account" className="pic" style={{ backgroundImage: `url(${this.props.account.pictureImgUrl})`, borderColor: colors[this.props.account.color] }} onClick={this.toggleAccount}></div>
								: <Icon id="account" alt="Account" icon="userAccount" mode={accountIconColor(this.props.account.color)} bg={colors[this.props.account.color]} onClick={this.toggleAccount} />)
							: <button onClick={() => this.goTo("/SignIn")}>{this.state.mobile ? <Icon icon="userAccount" highlight={this.highlightIcon()} /> : "Sign In"}</button>
						}
						<div id="accountOptions" className={this.state.showAccount ? "show" : ""}>
							<button onClick={() => this.goTo("/Portal")}>Dashboard</button>
							<button onClick={() => this.goTo("/Portal/UserSettings")}>Account Settings</button>
							<button onClick={() => signout().then(result => {
								this.props.onUpdateCredentials({});
								this.closeAccount();
							})}>Sign Out</button>
						</div>
					</nav>
				</header>
				<main id="docs">
					<aside className={this.sidebarClass()}>
						<ul>
							{(this.state.selectedMode === DOCS_MODE.DOCS ? this.state.sidebar : this.state.apiSidebar).map(section => (
								<li>
									<span>{section.displayName}</span>
									<ul>
										{section.items.map(link => (
											<li className={link.selected()} onClick={link.action}>{link.displayName}</li>
										))}
									</ul>
								</li>
							))}
						</ul>
					</aside>
					{this.state.selectedMode === DOCS_MODE.APIS && <Swagger url={API_URLS[this.state.selected]} />}
					{this.state.selectedMode === DOCS_MODE.DOCS && <div id="intro">
						{this.state.selected === DOCS.OVERVIEW && <>
							<h3>Overview</h3>
							<h1>Cordoniq Documentation</h1>
							<p>Cordoniq was built by and for developers, with all of the various resources available to the developer through HTTP API calls. The entire API collection is documented for easy consumption using OpenAPI 3. Most developer environments can handle OpenAPI documents directly in their environment and language of choice or you can also choose to directly access the easy-to-use APIs.</p>
							<p>When you intially created your sign-in account credentials a public/private key pair was created that is unique and related to your email address. Along with the private key, a "client id" is created that relates to your application.</p>
							<h2>Private Cloud</h2>
							<p>You can operate your own cloud modules in the Cordoniq system. To set up, go to your <a href="/Portal/Settings" target="_blank">App Settings</a> and enable Private Cloud Settings. You will then need to download the Cordoniq Server Setup and install it onto server resources at AWS, Azure, Google Cloud, or even your own servers in your datacenter.</p>
							<p>Once you do this, all your conferences will be created and take place on your own modules. Besides the benefit of securing your conference communications so that it takes place over your secure network, video quality can be greatly enhanced for your participants depending upon the available bandwidth of the cloud provider or your own network.</p>
							<h2>Domain Names</h2>
							<p>By default, Cordoniq uses the cordoniq.com domain for all meetings that take place. You can choose to use your own domain for meetings once you have deployed your own cloud modules.</p>
							<p>To do this, you need to obtain TLS web certificates that match your domain and upload them using the Private Cloud page settings under <a href="/Portal/Settings" target="_blank">App Settings</a>. If you have not enabled Private Cloud settings, you will need to do this first prior to accessing domain settings.</p>
						</>}
						{this.state.selected === DOCS.AUTH && <>
							<h3>Authentication API</h3>
							<h1>Overview</h1>
							<p>Cordoniq supports OAuth/2 client credentials based using a "client id" and "client secret." The client secret is in the form of a private key that is used for signing your requests.</p>
							<h2>Making Authorized API Calls</h2>
							<p>After you obtain your client id  from the Cordoniq Portal in <a href="/Portal/Settings" target="_blank">App Settings</a>, you need to:</p>
							<ol>
								<li>Create a JSON Web Token (JWT) that includes a header, claim set and a signature.</li>
								<li>Request an access token from the Cordoniq OAuth/2 authorization endpoint (auth.cordoniq.com).</li>
								<li>Process the JSON response, which will be an authorized token and refresh token, from the authorization server.</li>
							</ol>
							<p>Once you obtain an access token, you use the given token to access the various API resources on Cordoniq. When the access token expires, your application needs to request another token using the POST /auth/oauth2/token endpoint with grant_type defined as "refresh_token".</p>
							<h2>Token Request: Login</h2>
							<p>The example demonstrates how to make a token request and use that token to access other APIs.</p>
							{!preferNPM && <p>NOTE: This example and those that follow are using the Axios library for API calls, but these calls can be made using other libraries such as fetch or XMLHttpRequest.</p>}
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmAuthAPI : Examples.authAPI}
							</Code>
							<h2>Token Request: Client Credentials</h2>
							<p>This example uses an existing set of client credentials, "client_id" and "client_secret", to request a token.</p>
							{!preferNPM && <p>NOTE: The grant_type property must be set to client_credentials and the request must with completed with form data rather than JSON.</p>}
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmClientAuth : Examples.clientAuth}
							</Code>
							<h2>Need more help?</h2>
							<p>For a more in depth look, check out the <Button link onClick={e => {
								e.preventDefault();
								this.setMode(DOCS_MODE.APIS, APIS.AUTH);
							}}>Authentication API reference</Button>.</p>
						</>}
						{this.state.selected === DOCS.CORE && <>
							<h3>Core API</h3>
							<h1>Overview</h1>
							<p>The core Cordoniq system is comprised of Conferences and Users. You create Conferences using the HTTP APIs which act as a meeting room, classroom, etc. For each Conference you can optionally choose to create Users with the HTTP APIs. Users are able to join the Conference by using either a custom created URL or by knowing a given attendee password.</p>
							<h1 data-tag="conferences">Conferences</h1>
							<p>Using the Core API, you can create, update, and delete conferences. Each conference can be configured and customized using these APIs.</p>
							<h2>Conference Response</h2>
							<p>This is an example of a Conference JSON response:</p>
							<Code language="json">{Examples.confJSON}</Code>
							<h2>Conference Request</h2>
							<p>This example uses the access token from the Auth API example to make a request to the Core API and get a list of conferences.</p>
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmCoreAPI : Examples.coreAPI}
							</Code>
							<h1 data-tag="users">Users</h1>
							<p>A user defines a single user that is either currently active in the conference or generated users that can access the conference. Users can be customized to allow access to features within the Conference based on user roles and individual privileges.</p>
							<p>In addition to customizations, active users' settings can be directly manipulated by the APIs. These settings include audio and video state as well as the specific recording and listening devices the users are using during the conference.</p>
							<h2>User Response</h2>
							<p>This is an example of a basic User JSON response:</p>
							<Code language="json">{Examples.userJSON}</Code>
							<h2>Example: Get Users</h2>
							<p>This is an example of how to use the access token and Conference ID to retreive a list of all associated users.</p>
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmUserAPI : Examples.userAPI}
							</Code>
							<h2>Example: Get User with Video Settings</h2>
							<p>This is an example of how to request details about the user's video state and settings. There are more details that can be requested. For more details, go to the <Button link onClick={() => this.setMode(DOCS_MODE.APIS, APIS.CORE)}>Core API Reference</Button>.</p>
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmUserSettingsAPI : Examples.userSettingsAPI}
							</Code>
							<h2>Need more help?</h2>
							<p>For a more in depth look, check out the complete <Button link onClick={e => {
								e.preventDefault();
								this.setMode(DOCS_MODE.APIS, APIS.CORE);
							}}>Core API reference</Button>.</p>
						</>}
						{this.state.selected === DOCS.CONTENT && <>
							<h3>Content API</h3>
							<h1>Overview</h1>
							<p>Content is any documents, materials and media that you may want to use during the course of a meeting. Content can be either directly related with a Conference, so that it is automatically presented when the meeing begins, or indirectly presented during the meeting.</p>
							<h2>Content Response</h2>
							<p>Cordoniq content is a custom JSON object that includes an id, display name, filename and content type as shown in this example:</p>
						  	<Code language="json">{Examples.contentJSON}</Code>
						   	<h2>Example: Upload</h2>
							<p>To upload content to the Cordoniq server, you will need to submit two items from a form or formData. One is a JSON object containing the file information and the other is the actual content submitted as a binary string.</p>
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmUpload : Examples.upload}
							</Code>
							<h2>Example: Download</h2>
							<p>The download will be sent as a binary string (blob). In this example, we use the js-file-download package to download the file from the binary string.</p>
							<Code tabs={tabs}>
								{preferNPM ? Examples.npmDownload : Examples.download}
							</Code>
							<h2>Need more help?</h2>
							<p>For a more in depth look, check out the <Button link onClick={e => {
								e.preventDefault();
								this.setMode(DOCS_MODE.APIS, APIS.CONTENT);
							}}>Content API reference</Button>.</p>
						</>}
						{this.state.selected === DOCS.UPDATES && <>
							<h3>Updates</h3>
							<h1 data-tag="announcements">Announcements</h1>
							<div className="announcement" onClick={() => window.open('https://www.npmjs.com/package/cordoniq', '_blank')}>
								<div className="date">{moment("2021-12-21").format("LL")}</div>
								<div className="content">
									<Icon icon={faNpm} color="rgb(179, 51, 50)" />
									<div>
										<h1>New Cordoniq NPM Release</h1>
										<p>Check out the latest version of our npm package!</p>
									</div>
								</div>
							</div>
							<h1 data-tag="changelog">Changelog</h1>
							<ul>
								<li>{moment("2022-09-24").format("l")} - Initial commit.</li>
							</ul>
						</>}
						{this.state.selected === DOCS.RESOURCES && <>
							<h3>Additional Resources</h3>
							<h1>FAQs</h1>
							{Object.keys(this.state.faqs).map(topic => (
								<>
								<h2>{faqs[topic].heading}</h2>
								<ul className="collapsing">
									{faqs[topic].items.map((faq, i) => (
										<li className={faq?.open ?? false ? "open" : ""} onClick={() => {
											let faqs = this.state.faqs;
											let open = faqs[topic].items[i].open;
											faqs[topic].items[i].open = !open;
											this.setState({ faqs: faqs });
										}}>
											<span>{faq.question}<Icon icon="add" /></span>
											<div>
												{faq.answer.split('\n').map(line => <p>{line}</p>)}
												<div className="links">
												{faq.quickLink !== undefined
													? <Button link onMouseDown={e => e.preventDefault()}
														 onClick={() => {
															 let url = faq.quickLink.url;
															 url[0] === "/" ? this.goTo(url) : window.open(url);
														 }}>{faq.quickLink.text}</Button>
													: ""
												}
												</div>
											</div>
										</li>
									))}
								</ul>
								</>
							))}
						</>}
						</div>}
				</main>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	account: state.account
});

const mapActionsToProps = {
	onUpdateCredentials: updateCredentials
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(Docs));
