import { api, papi, allDetails } from './APIs/config.js';
import { Token } from './APIs/auth.js';
import { LUMI_LTI } from './APIs/internal/integration.js';
import moment from 'moment';

export const USER_GROUP = { Specified: 1, All: 2, Attendees: 3, Presenters: 4, Hosts: 5 };
export const USER_GROUP_TEXT = { 1: "Specified", 2: "All Users", 3: "All Attendees", 4: "All Presenters", 5: "All Hosts" };
export const MESSAGE_TYPE = { None: 0, Text: 1, Image: 2 };
export const MESSAGE_DATA_TYPE = { None: 0, UTF8: 1, Binary: 2 };

const chatDefaults = {
    chatType: 1,
    displayName: "",
    userGroup: USER_GROUP.Specified,
    userIds: [],
    ownerUserId: ""
};

const messageDefaults = {
    messageType: MESSAGE_TYPE.Text,
    messageDataType: MESSAGE_DATA_TYPE.UTF8,
    messageData: "",
    created: moment().format(),
    userId: "",
    displayName: ""
};

export const getUrlPreview = async ({ uri, args, agent }) => {
    let params = { uri: uri };
    if (args !== undefined) params.args = args;
    if (agent !== undefined) params.agent = agent;
    try {
        let response = await papi.get('/api/preview', { params });
        return response.data;
    } catch (e) {
        console.log("An error occurred retreiving preview data");
        throw e;
    }
};

export const removeHTML = text => (text?.replace(/<[^>]*>/gi, " ")?.replace(/\s+/g, " ")?.trim() ?? text);

let intId = null;

const ChatAPI = {
    setIntId: integrationId => {
        intId = integrationId ?? null;
    },
    conferences: {
        get: async () => {
            try {
                let path = `/conferences`;
                let response = intId !== null
                    ? await LUMI_LTI.get(intId, path, { details: "all", meta_data3: intId })
                    : await api(Token.get()).get(path, allDetails);
                let conferences = response.data.items;
                conferences.sort((a, b) => {
                    if (a.active === b.active) {
                        return a.displayName < b.displayName ? -1 : 1;
                    }
                    return a.active ? -1 : 1;
                });
                if (conferences.length === 0) {
                    conferences.push({ confId: "noconferences", displayName: "No Conferences Found" });
                }
                return conferences;
            } catch (e) {
                throw e;
            }
        }
    },
    users: {
        get: async confId => {
            if (confId === "noconferences" || confId === undefined) return {};
            try {
                let path = `/conference/${confId}/users`;
                let response = intId !== null
                    ? await LUMI_LTI.get(intId, path)
                    : await api(Token.get()).get(path, allDetails);
                let users = response.data.items, userObj = {};
                users.forEach(user => {
                    userObj[user.userId] = user;
                });
                return userObj;
            } catch (e) {
                return {};
            }
        }
    },
    chat: {
        get: async (confId, chatId) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}`;
                let response = intId !== null
                    ? await LUMI_LTI.get(intId, path)
                    : await api(Token.get()).get(path, allDetails);
                return response.data;
            } catch (e) {
                throw e;
            }
        },
        new: async (confId, data) => {
            try {
                let path = `/conference/${confId}/chat`;
                data = { ...chatDefaults, ...data };
                let response = intId !== null
                    ? await LUMI_LTI.post(intId, path, data)
                    : await api(Token.get()).post(path, data);
                return response.data;
            } catch (e) {
                throw e;
            }
        },
        edit: async (confId, chatId, data) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}`;
                let response = intId !== null
                    ? await LUMI_LTI.put(intId, path, data)
                    : await api(Token.get()).put(path, data);
                return response.data;
            } catch (e) {
                throw e;
            }
        },
        delete: async (confId, chatId) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}`;
                let response = intId !== null
                    ? await LUMI_LTI.delete(intId, path)
                    : await api(Token.get()).delete(path);
                return response.data.result;
            } catch (e) {
                throw e;
            }
        }
    },
    chats: {
        get: async confId => {
            try {
                let path = `/conference/${confId}/chats`;
                let response = intId !== null
                    ? await LUMI_LTI.get(intId, path)
                    : await api(Token.get()).get(path, allDetails);
                return response.data.items;
            } catch (e) {
                throw e;
            }
        }
    },
    message: {
        new: async (confId, chatId, data) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}/message`;
                data = { ...messageDefaults, ...data};
                let response = intId !== null
                    ? await LUMI_LTI.post(intId, path, data)
                    : await api(Token.get()).post(path, data);
                return response.data;
            } catch (e) {
                throw e;
            }
        },
        edit: async (confId, chatId, messageId, data) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}/message/${messageId}`;
                let response = intId !== null
                    ? await LUMI_LTI.put(intId, path, data)
                    : await api(Token.get()).put(path, data);
                return response.data;
            } catch (e) {
                throw e;
            }
        },
        delete: async (confId, chatId, messageId) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}/message/${messageId}`;
                let response = intId !== null
                    ? await LUMI_LTI.delete(intId, path)
                    : await api(Token.get()).delete(path);
                return response.data.result;
            } catch (e) {
                throw e;
            }
        }
    },
    messages: {
        get:  async (confId, chatId) => {
            try {
                let path = `/conference/${confId}/chat/${chatId}/messages`;
                let response = intId !== null
                    ? await LUMI_LTI.get(intId, path)
                    : await api(Token.get()).get(path, allDetails);
                let messages = response.data.items;
                return messages.map(message => {
                    message.created = moment(message.created);
                    return message;
                });
            } catch (e) {
                throw e;
            }
        }
    }
}

export default ChatAPI;
