import React, { Component } from 'react';
import { ENV } from '../tools/envTools.js';

import Button from '../components/Button';
import Icon from '../components/Icon';
import Section from '../partials/Section';

import { connect } from 'react-redux';

import './css/tour.css';

const SHOW_TOUR_KEY = `showTour${ENV.getStorageSuffix()}`;

// const parseBool = string => {
// 	return string !== false && string !== "false";
// };

class Tour extends Component {
	state = { show: true };

	removeTour = () => {
		this.setState({ show: false });
		localStorage.setItem(SHOW_TOUR_KEY+this.props.account.username, false);
		if (this.props.remove) this.props.remove();
	};

	render () {
		return (
			<>
			{this.state.show ? <Section id="takeTour" title="New Here? Take the Tour" buttons ={[
				<button onClick={this.removeTour}><Icon icon="close" /></button>
			]}>
				<div id="details">
					<p>Explore what you can achieve with Cordoniq.</p>
					<Button outline onClick={this.props.start}>Start The Tour</Button>
				</div>
			</Section> : ""}
			</>
		);
	}
}

const mapStateToProps = state => ({
	account: state.account
});

export default connect(mapStateToProps)(Tour);
