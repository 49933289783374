import React, { Component } from 'react';

import './css/progressBar.css';

class ProgressBar extends Component {
	state = { progress: this.props.value ?? 0 };

	update = value => { this.setState({ progress: value }) };

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({ progress: this.props.value });
		}
	}

	render() {
		return (
			<div className="progressBar">
				<div className="progress" style={{width: this.state.progress+"%"}}>
					{this.props.showLabel && <span>{this.state.progress}%</span>}
				</div>
			</div>
		);
	}
}

export default ProgressBar;
