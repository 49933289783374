let env = window.env.ENVIRONMENT;

export var ENV = {
    get: () => env,
    isProd: () => env === "root",
    isStag: () => env === "staging" || env === "development",
    isDev: () => env === "development",
    getApiDomain: () => {
        const DOMAINS = { "staging": "-staging", "development": "-staging", "root": "" };
        return DOMAINS[env] ?? "";
    },
    getStorageSuffix: () => {
        const storageKeys = { "staging": "_staging", "development": "_dev", "root": "" };
        return storageKeys[env] ?? "";
    }
};

let testing = "developers";
const getSubdomain = () => {
    let hostname = window.location.hostname;
    if (hostname === "192.168.1.125") return testing;
    let hostnameParts = hostname.split(".");
    if (hostnameParts.length > 1) {
        let subdomain = hostnameParts[0].replace("-staging", "");
        return subdomain;
    } else {
        return testing;
    }
};

export var PORTAL = {
    get: getSubdomain,
    isMy: () => getSubdomain() === "my",
    isDev: () => getSubdomain() === "developers"
};