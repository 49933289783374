import React, { Component } from 'react';
import { ERRORCODE } from '../LumiAPI/APIs/errors.js';
import { signup } from '../LumiAPI/APIs/internal/account.js';
// import { addSignUpToHubSpot, syncAccountWithHubSpot } from '../LumiAPI/hubspotAPI';
import { withNav } from '../hooks/useNav';
import { ColorUpdater, setNeutralThemeColor } from '../tools/colorSchemeManager';

import Button from '../components/Button';
import Password from '../components/Password';
import Slider from '../components/Slider';
import TextField from '../components/TextField';
import GraphicSidebar from '../partials/GraphicSidebar';

import Notification from '../tools/Notification';

import './css/welcomePage.css';

const PAGE = { PERSONAL: 0, ACCOUNT: 1, APP: 2, FINISH: 3 };
const TERMS = {
    education: {
        room: "classroom",
        attendee: "student",
        attendees: "students",
        presenter: "teacher"
    },
    corporate: {
        room: "meeting",
        attendee: "coworker",
        attendees: "coworkers",
        presenter: "presenter"
    },
    general: {
        room: "room",
        attendee: "guest",
        attendees: "guests",
        presenter: "presenter"
    }
};

class SignUp extends Component {
	state = {
		type: "general",
		firstName: "",
		lastName: "",
		email: "",
		username: "",
		password: "",
		title: "",
		description: "",
		page: PAGE.PERSONAL,
		showSidebar: true,
		menuOpen: false
	};

	open = page => {
		this.setState({ page: page });
		setTimeout(() => {
			let firstField = document.querySelector("#welcome .content form #field:first-child input");
			if (firstField !== null) firstField.focus();
		}, 0);
	};

	handleEdit = change => this.setState({ [change.name]: change.value });

	disabled = () => {
		switch (this.state.page) {
			case PAGE.PERSONAL:
				return this.state.firstName === ""
					|| this.state.lastName === ""
					|| this.state.email === "";
			case PAGE.ACCOUNT:
				return this.state.username === ""
					|| this.state.password === ""
					|| !Password.valid(this.state.password);
			case PAGE.APP:
				return this.state.title === ""
					|| this.state.description === ""
            default:
                return false;
		}
	};

	submit = () => {
		let data = {...this.state};
		delete data.page;
		delete data.showSidebar;
		delete data.menuOpen;

        signup(data).then(tokenInfo => {
            this.open(PAGE.FINISH);
            // sendNewAccount(data)
            //     .then(task => { return null })
            //     .catch(err => console.log(err))
            // sendWelcome({ email: data.email, first: data.firstName }, (response, err) => {
            //     syncAccountWithHubSpot(data).then(contact => {
            //         addSignUpToHubSpot({ contactId: contact.id }).then(note => {
            //             return null;
            //         }).catch(err => console.log(err));
            //     }).catch(err => console.log(err));
            // });
        }).catch(err => {
            let code = err?.response?.data?.error_code ?? null;
            let message = err?.response?.data.error_description ?? "An Error Occurred!";
            this.handleError("error", code, message);
        });
	};

	handleError = (type, code, message) => {
		if (code === 102) { // EMAIL TAKEN CODE
			this.open(PAGE.PERSONAL);
			new Notification({ id: "signupError", message: message, type: type });
		} else if (code === ERRORCODE.USERNAME_EXISTS) {
			this.open(PAGE.ACCOUNT);
			new Notification({ id: "signupError", message: message, type: type });
		} else {
            this.open(PAGE.PERSONAL);
            new Notification({ id: "unknownError", message: "An Unknown Error Has Occurred. Please try again", type: "error" });
        }
	};

	componentDidMount() {
		document.title = "Sign Up | Cordoniq";

        let types = Object.keys(TERMS);
		let type = this.props.match?.params?.type?.toLowerCase() ?? "general";
        if (!types.includes(type)) this.goTo("/SignUp");
        else this.setState({ type: type });

        setNeutralThemeColor();
		ColorUpdater.add(setNeutralThemeColor);

		let firstField = document.querySelector("#welcome .content form #field:first-child input");
		if (firstField !== null) firstField.focus();
	}

    componentWillUnmount() {
        ColorUpdater.remove(setNeutralThemeColor);
	}

  	render() {
		// let terms = TERMS[this.state.type];

	  	return (
		  	<main id="welcome" className="signUp">
		  		<GraphicSidebar show={this.state.showSidebar} />
		  		<section>
		  			{/* <div id="options" className={this.state.menuOpen ? "open" : ""}>
						<Icon icon={this.state.menuOpen ? "close" : "menu"} onClick={() => {
							this.setState({ menuOpen: !this.state.menuOpen });
						}} />
		  			</div>
					<aside className={this.state.menuOpen ? "open" : ""}>
						<button onClick={() => this.goTo(`/SignIn/${this.state.type}`)}>Sign In</button>
						<button onClick={() => this.setState({ menuOpen: false })}>Sign Up</button>
						<button onClick={() => this.goTo(`/JoinRoom/${this.state.type}`)}>Join {capitalize(terms.room)}</button>
						<button onClick={() => this.goTo(`/DemoRoom/${this.state.type}`)}>Demo {capitalize(terms.room)}</button>
						<button onClick={() => this.goTo("/Docs")}>Docs</button>
						<button onClick={() => { window.location = "https://www.cordoniq.com"}}>Back to Main</button>
						<button onClick={() => { window.location = "https://www.cordoniq.com/contact" }}>Contact Us</button>
						<div className="social">
							<Icon icon={faLinkedin} onClick={() => window.open("http://www.linkedin.com/company/cordoniq")} />
							<Icon icon={faFacebook} onClick={() => window.open("https://www.facebook.com/Cordoniq")} />
							<Icon icon={faTwitter} onClick={() => window.open("https://twitter.com/cordoniq")} />
						</div>
					</aside> */}
					{this.state.page === PAGE.PERSONAL && <div className="content">
						<h1>We're glad you're here.</h1>
						<p>Sign up for immediate access to the Cordoniq Developers' Portal. </p>
						<p>Simply fill out your name and email below to create your account. </p>
						<form>
							<TextField className="half" name="firstName" label="First Name" placeholder="Enter First Name" value={this.state.firstName} onEdit={this.handleEdit} />
							<TextField className="half" name="lastName" label="Last Name" placeholder="Enter Last Name" value={this.state.lastName} onEdit={this.handleEdit} />
							<TextField type="email" name="email" label="Email" placeholder="Enter Your Email" value={this.state.email} onEdit={this.handleEdit} />
							<Button onClick={() => this.open(PAGE.ACCOUNT)} disabled={this.disabled()}>Next</Button>
						</form>
						<p>Already have an account? <Button link onClick={() => this.goTo(`/SignIn/${this.state.type}`)}>Sign In</Button></p>
					</div>}
					{this.state.page === PAGE.ACCOUNT && <div className="content">
						<h1>Welcome!</h1>
						<p>Create a username and password for your new account.</p>
						<form>
							<TextField name="username" label="Username" placeholder="Enter Username" value={this.state.username} onEdit={this.handleEdit} />
							<Password new name="password" label="Create New Password" placeholder="Enter Password" value={this.state.password} onEdit={this.handleEdit} autoComplete="new-password" />
							<Button outline type="button" onClick={() => this.open(PAGE.PERSONAL)}>Back</Button>
							<Button type="submit" onClick={() => this.open(PAGE.APP)} disabled={this.disabled()}>Next</Button>
						</form>
					</div>}
					{this.state.page === PAGE.APP && <div className="content">
						<h1>You’re almost there.</h1>
						<p>Enter the name and description below. You can change this information at any time and even create multiple apps.</p>
						<form>
							<TextField name="title" label="App Name" placeholder="Enter App Name" value={this.state.title} onEdit={this.handleEdit} required />
							<TextField name="description" label="App Description" placeholder="Enter App Description" value={this.state.description} onEdit={this.handleEdit} />
							<Slider label={"Finish"} action={this.submit} disabled={this.disabled()} />
						</form>
						<p><Button link onClick={() => this.open(PAGE.ACCOUNT)}>Go Back</Button></p>
					</div>}
					{this.state.page === PAGE.FINISH && <div className="content">
						<h1>Congratulations!</h1>
						<p>You now have access to Cordoniq's Developers' Portal. Click the button below to enter your account.</p>
						<Button onClick={() => this.goTo("/Portal")}>Enter Account</Button>
					</div>}
		  		</section>
		  	</main>
		);
	}
}

export default withNav(SignUp);
