import React, { Component } from 'react';

import './css/section.css';

class Section extends Component {
    className = () => {
        let className = "withTitleBar";
        className += ` ${this.props?.className ?? ""}`;
        return className.trim();
    };

    render() {
        return (
            <section id={this.props.id ?? undefined} className={this.className()}>
                <div id="title">
                    <h1>{this.props.title}</h1>
                    {this.props.buttons && this.props.buttons.map((button, i) => <React.Fragment key={i}>{button}</React.Fragment>)}
                </div>
                {this.props.tabs && <div id="tabs">{this.props.tabs.map(tab => tab)}</div>}
                {this.props.toolbar && <div id="toolbar">{this.props.toolbar}</div>}
                <div id="content">
                    {this.props.children}
                </div>
            </section>
        );
    }
}

export default Section;
