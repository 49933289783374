import React, { Component } from 'react';

import './css/textField.css';

const fieldRef = React.createRef();
const Input = props => {
	if (props.type === "textarea") {
		return <textarea ref={fieldRef} type={undefined} {...props} />;
	} else {
		return <input ref={fieldRef} {...props} />
	}
};

class TextField extends Component {
	state = {
		value: this.props.value ?? "",
		valid: true,
		focused: false,
		required: false,
		description: this.props.description ?? undefined
	};

	getValue = () => {
		return this.state.value;
	};

	isValid = () => {
		return this.state.valid;
	};

	focus = () => {
		if (fieldRef.current) fieldRef.current.focus();
	};

	getClassName = () => {
		var className = this.props.className ?? "";
		if (this.props.disabled) className += " disabled";
		if (this.props.readonly) className += " readonly";
		if (this.props.wide) className += " wide";
		if (this.props.fill) className += " fill";
		return className;
	};

	updateField = e => {
		if (this.props.readonly) return;
		this.validate(e);
		let value = e.target.value;
		if (this.props.type === "tel") {
			value = value.replace(/[()-\s]/g, "");
			this.setState({ value: value })
		}
		this.setState({ value: value, focused: true });
		if (this.props.onChange) this.props.onChange(value);
		if (this.props.onEdit) this.props.onEdit({ name: e.target.name, value: value });
	};

	validate = e => {
		var isRequired = this.props.required ?? false;
		var isValid = e.target.checkValidity() && e.target.value !== "";

		if (isRequired || this.props.type === "email" || this.props.type === "url") {
			if (isValid) {
				this.setState({ required: isRequired, valid: true, focused: false });
			} else {
				this.setState({ required: isRequired, valid: false, focused: false });
			}
		} else {
			this.setState({ required: false, valid: true, focused: false });
		}
	};

	checkSubmit = e => {
		if (e.keyCode === 13) { // Enter
			if (this.props.submit) {
				this.props.submit(e);
			}
		}

		if (this.props.type === "tel") {
			switch(e.key) {
				case "1":
				case "2":
				case "3":
				case "4":
				case "5":
				case "6":
				case "7":
				case "8":
				case "9":
				case "0":
					if (this.state.value.length === 10) {
						e.preventDefault();
					}
					break;
				case "Backspace":
				case "Enter":
				case "Tab":
				case "ArrowLeft":
				case "ArrowRight":
					break;
				default:
					e.preventDefault();
			}
		}
	};

	formatTel = () => {
		let original = this.state.value;
		let number = original.slice(0);

		if (original.length > 6) {
			number = "("+number.slice(0, 3)+") "+number.slice(3, 6)+"-"+number.slice(6);
		} else if (original.length > 3) {
			number = "("+number.slice(0, 3)+") "+number.slice(3);
		} else if (original.length > 0) {
			number = "("+number;
		}

		return number;
	};

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({ value: this.props.value });
		}

		if (this.props.type !== prevProps.type) {
			setTimeout(() => {
				let field = document.querySelector(`[name=${this.props.name}]`);
				field.focus();

				if (field.setSelectionRange) {
					let length = this.state.value.length;
					field.setSelectionRange(length, length);
				} else {
					field.value = this.state.value;
				}

				field.scrollTop = 999999;
			}, 0);
		}
	}

	render() {
		return (
			<div id="field" className={this.getClassName()} onClick={e => e.stopPropagation()}>
				{this.props.label && <label className={this.state.valid ? (this.state.focused ? "focus" : "") : "invalid"}>{this.props.label}</label>}
				<Input style={this.props.style || undefined} type={this.props.type || "text"} id={this.props.id || undefined} name={this.props.name || undefined} value={this.props.type === "tel" ? this.formatTel() : this.state.value}
					   placeholder={this.props.placeholder || undefined}
					   disabled={this.props.disabled || undefined} readonly={this.props.readonly || undefined} required={this.state.required || undefined}
					   onChange={this.updateField} onKeyDown={this.checkSubmit}
					   onFocus={() => this.setState({ focused: true })} onBlur={this.validate} tabIndex={this.props.tabIndex} autoComplete={this.props.autocomplete || undefined} />
				{this.state.description && <p>{this.state.description}</p>}
			</div>
		);
	}
}

export default TextField;
