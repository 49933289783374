import { lti } from '../config.js';
import { defaultConfValues } from '../core/confDefaults.js';

export const newIntegration = settings => {
    return new Promise((resolve, reject) => {
        lti.post("/lti/integration", settings)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getIntegration = integrationId => {
    return new Promise((resolve, reject) => {
        lti.get(`/lti/integration/${integrationId}`)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getIntegrationConfig = integrationId => {
    return new Promise((resolve, reject) => {
        lti.get(`/lti/integration/${integrationId}/config`)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const updateIntegration = (integrationId, changes) => {
    return new Promise((resolve, reject) => {
        lti.put(`/lti/integration/${integrationId}`, changes)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const deleteIntegration = integrationId => {
    return new Promise((resolve, reject) => {
        lti.delete(`/lti/integration/${integrationId}`)
            .then(response => resolve("OK"))
            .catch(reject);
    });
};

export const getIntegrations = clientId => {
    return new Promise((resolve, reject) => {
        lti.get(`/lti/integrations/${clientId}`)
            .then(response => resolve(response.data.items))
            .catch(reject);
    });
};

export const LUMI_LTI = {
    post: (intId, path, data) => {
        return new Promise((resolve, reject) => {
            let requestData = {
                path: path,
                data: JSON.stringify(data)
            };
            lti.post(`/lti/${intId}/lumirequest`, requestData)
                .then(resolve).catch(reject);
        });
    },
    put: (intId, path, data) => {
        return new Promise((resolve, reject) => {
            let requestData = {
                path: path,
                data: JSON.stringify(data)
            };
            lti.put(`/lti/${intId}/lumirequest`, requestData)
                .then(resolve).catch(reject);
        });
    },
    get: (intId, path, params) => {
        return new Promise((resolve, reject) => {
            let options = {
                params: {
                    path: path,
                    params: params ? JSON.stringify(params) : undefined
                }
            };
            lti.get(`/lti/${intId}/lumirequest`, options)
                .then(resolve).catch(reject);
        });
    },
    delete: (intId, path) => {
        return new Promise((resolve, reject) => {
            let options = {
                params: {
                    path: path
                }
            };
            lti.delete(`/lti/${intId}/lumirequest`, options)
                .then(resolve).catch(reject);
        });
    }
};

export const newConference = (intId, displayName) => {
    return new Promise((resolve, reject) => {
        let settings = { ...defaultConfValues, displayName, metaData3: intId };
        LUMI_LTI.post(intId, "/conference", settings)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getConferences = intId => {
    return new Promise((resolve, reject) => {
        LUMI_LTI.get(intId, "/conferences", { meta_data3: intId })
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export const getUsers = (intId, confId) => {
    return new Promise((resolve, reject) => {
        LUMI_LTI.get(intId, `/conference/${confId}/users`)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

const IntegrationAPI = {
    integration: {
        new: newIntegration,
        get: getIntegration,
        getConfig: getIntegrationConfig,
        update: updateIntegration,
        delete: deleteIntegration
    },
    integrations: {
        get: getIntegrations
    }
};

export default IntegrationAPI;
