import React, { Component } from 'react';

import Icon from '../../components/Icon';

import './css/tools.css';

class Tools extends Component {
	state = {
		video: this.props?.video?.state ?? false,
		audio: this.props?.audio?.state ?? false,
		sharing: this.props?.sharing?.state ?? false,
		users: this.props?.users?.state ?? false
	};

	componentDidUpdate(prevProps) {
		if (this.props.video !== prevProps.video) {
			this.setState({ video: this.props.video.state });
		}

		if (this.props.audio !== prevProps.audio) {
			this.setState({ audio: this.props.audio.state });
		}

		if (this.props.users !== prevProps.users) {
			this.setState({ users: this.props.users.state });
		}
	}

	render() {
		return (
			<aside>
				{this.props.video.show &&
					<div className="icon" onClick={this.props.video?.toggle ?? undefined} disabled={this.props.video.disabled}>
						<div className="icn">
							<Icon icon={this.state.video ? "camera" : "cameraOff"} mode="dark" />
							<span className={this.state.video ? "on": "off"}></span>
						</div>
						<div className="label">Video</div>
					</div>
				}
				{this.props.audio.show &&
					<div className="icon" onClick={this.props.audio?.toggle ?? undefined} disabled={this.props.audio.disabled}>
						<div className="icn">
							<Icon icon={this.state.audio ? "mic" : "micDisabled"} mode="dark" />
							<span className={this.state.audio ? "on" : "off"}></span>
						</div>
						<div className="label">Audio</div>
					</div>
				}
				{this.props.sharing.show &&
					<div className="icon" onClick={this.props.sharing?.toggle ?? undefined}  disabled={this.props.sharing.disabled}>
						<div className="icn">
							<Icon icon="share" mode="dark" />
						</div>
						<div className="label">Share</div>
					</div>
				}
				{this.props.users.show &&
					<div className={this.state.users ? "icon selected" : "icon"} onClick={this.props.users?.toggle ?? undefined} disabled={this.props.users.disabled}>
						<div className="icn">
							<Icon icon="people" mode="dark" />
						</div>
						<div className="label">Users</div>
					</div>
				}
				{this.props.breakout.show &&
					<div className="icon" onClick={this.props.breakout?.toggle ?? undefined} disabled={this.props.breakout.disabled}>
						<div className="icn">
							<Icon icon="breakout" mode="dark" />
						</div>
						<div className="label">Breakout</div>
					</div>
				}
				{this.props.chat.show &&
					<div className="icon" onClick={this.props.chat?.toggle ?? undefined} disabled={this.props.chat.disabled}>
						<div className="icn">
							<Icon icon="chat" mode="dark" />
						</div>
						<div className="label">Chat</div>
					</div>
				}
			</aside>
		);
	}
}

export default Tools;
