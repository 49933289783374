import React, { Component } from 'react';

import Icon from './Icon';

import './css/checkbox.css';

class Checkbox extends Component {
	render () {
		return (
			<React.Fragment>
				<input type="checkbox" className="checkbox" {...this.props} />
				<label htmlFor={this.props.id} role="button" onClick={e => e.stopPropagation()}>
					<Icon icon={this.props.checked ? (this.props.partial ? "partSelect" : "select") : "unselect"}
						  mode={this.props.mode || undefined}
						  onClick={e => e.stopPropagation()} />
				</label>
			</React.Fragment>
		);
	}
}

export default Checkbox;
