import React from 'react';
import TextField from './TextField';
import Icon from './Icon';

import './css/password.css';

class Password extends TextField {
	state = {
		type: "password",
		createNew: this.props.new ?? false,
		showValidation: false,
		length: false,
		case: false,
		characters: true,
		value: this.props.value ?? "",
		valid: true,
		focused: false,
		required: false,
		description: this.props.description ?? undefined
	};

	updateField = e => {
		if (this.props.readonly) return;
		this.validate(e, () => {
			if (this.props.onChange) this.props.onChange(e.target.value.trim());
			if (this.props.onEdit) this.props.onEdit({ name: e.target.name, value: e.target.value.trim() });
		});
		this.setState({ value: e.target.value.trim(), focused: true });
	};

	validate = (e, callback) => {
		if (e.target.value === "" && this.props.required) {
			this.setState({ required: true, valid: false, focused: false });
		} else {
			this.setState({ valid: true, focused: false });
		}

		if (this.state.createNew) {
			var pass = e.target.value.trim();
			var digits = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
			var blacklistChars = /(?=.*[^a-zA-Z\d!@#$&*])/;

			var length = pass.length >= 8;
			var digit = digits.test(pass);
			var characters = !blacklistChars.test(pass);
			var valid = length && digit && characters;

			this.setState({
				length: length,
				case: digit,
				characters: characters,
				valid: valid,
				showValidation: true
			});
		}

		if (callback) callback();
	};

	static valid(pass) {
		var digits = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
		var blacklistChars = /(?=.*[^a-zA-Z\d!@#$&*])/;

		var length = pass.length >= 8;
		var digit = digits.test(pass);
		var characters = !blacklistChars.test(pass);
		return length && digit && characters;
	}

	toggle = e => {
		var type = this.state.type === "password" ? "text" : "password";
		this.setState({ type: type });
		if (e) e.target.parentNode.querySelector("input").focus();
	};

	quickSwitchMode = e => {
		if (e.keyCode === 32) { // Space
			e.preventDefault();
			this.toggle(e);
		}
	};

	render() {
		return (
			<div id="field" className={"pass "+this.getClassName()}>
				{this.props.label && <label className={this.state.valid ? (this.state.focused ? "focus" : "") : "invalid"}>{this.props.label}</label>}
				<div>
					<input type={this.state.type} id={this.props.id || undefined}  name={this.props.name || undefined} value={this.state.value}
						   placeholder={this.props.placeholder || undefined}
						   disabled={this.props.disabled || undefined} readonly={this.props.readonly || undefined} required={this.state.required || undefined}
						   onChange={this.updateField} onKeyDown={this.quickSwitchMode} onKeyUp={this.checkSubmit}
						   onFocus={() => this.setState({ focused: true })} onBlur={e => {this.validate(e); this.setState({ showValidation: false })}}
						   tabIndex={this.props.tabIndex} autoComplete={this.props.autoComplete || undefined} />
					<Icon icon={this.state.type === "text" ? "eyeClose" : "eye"} mode={this.props.disabled && this.props.fill && this.props.name !== "secret" ? "light" : undefined} onClick={this.toggle} />
				</div>
				{this.state.createNew && this.state.showValidation && (<div id="validation">
					<p><Icon icon={this.state.length ? "valid" : "invalid"} mode="dark" /> Must be at least 8 characters.</p>
					<p><Icon icon={this.state.case ? "valid" : "invalid"} mode="dark" /> Must have an uppercase letter, lowercase letter, and number.</p>
					<p><Icon icon={this.state.characters ? "valid" : "invalid"} mode="dark" /> Only ! @ # $ & * special characters are allowed.</p>
				</div>)}
				{this.state.description && !this.state.createNew && <p>{this.state.description}</p>}
			</div>
		);
	}
}

export default Password;
