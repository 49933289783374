import React, { Component } from 'react';
import { Token } from '../LumiAPI/APIs/auth.js';
import AppAPI from '../LumiAPI/APIs/internal/app.js';
import { roles } from '../LumiAPI/APIs/internal/account.js';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { getColorScheme } from '../tools/colorSchemeManager';
import { ENV, PORTAL } from '../tools/envTools';

import NewApp from '../pages/NewApp';

import SelectBox from '../components/SelectBox';
import Placeholder from '../components/Placeholder';

import { connect } from 'react-redux';
import { changeApp } from '../actions/currentApp-actions';
import { refreshApps } from '../actions/apps-actions';

const lastAppKey = username => (`${username}_last_app${ENV.getStorageSuffix()}`);

class AppController extends Component {
    state = {
        account: this.props.account,
        apps: this.props.apps,
        clientId: this.props.currentApp ?? "",
        default: undefined
    };

    getApps = () => {
        if (!Token.isValid()) return;
        AppAPI.apps.get().then(apps => {
            let appObj = {}, defaultApp = undefined;
            apps.forEach(app => {
                appObj[app.clientId] = app;
                if (app.default) {
                    defaultApp = app.clientId;
                    this.setState({ default: defaultApp });
                }
            });
            this.props.onRefreshApps(appObj);
            this.switchApp(defaultApp);
        }).catch(err => {
            let app = {
                title: this.props.account.title,
                description: this.props.account.description,
                clientId: this.props.account.clientId
            };
            this.setState({ default: app.clientId });
            this.props.onRefreshApps({ [app.clientId]: app });
            this.props.onChangeApp(app?.clientId ?? "");
        });
    };

    switchApp = clientId => {
        let LAST_APP = lastAppKey(this.props.account?.username ?? "Unknown"),
            lastClientId = localStorage.getItem(LAST_APP);
        if (lastClientId !== null && lastClientId !== clientId) {
            localStorage.setItem(LAST_APP, clientId);
            clientId = lastClientId;
        }
        AppAPI.app.switch(clientId).then(result => {
            this.props.onChangeApp(clientId);
        }).catch(handleErrors);
    };

    selectedApp = () => {
        let { clientId, apps } = this.state,
            index = 0;
        Object.keys(apps).some((id, i) => {
            if (id === clientId) {
                index = i + 1;
                return true;
            } else {
                return false;
            }
        });
        return index;
    };

    openNewAppDialog = () => {
        let newAppRef = NewApp.getRef();
        newAppRef.current.show();
    };

    componentDidMount() {
        if (Object.keys(this.props.apps).length === 0) this.getApps();
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.getApps();
        }

        if (this.props.apps !== prevProps.apps) {
            this.setState({ apps: this.props.apps });
        }

        if (this.props.currentApp !== prevProps.currentApp) {
            this.setState({ clientId: this.props.currentApp });
        }
    }

    render() {
        return (<>
            {Object.keys(this.state.apps).length > 0
                ? this.props.curRole === roles.OWNER
                    ? <SelectBox name="selectedApp" showIcon selected={this.selectedApp()} options={[
                        { name: "Pick One...", dontSelect: true, color: "transparent" },
                        ...Object.keys(this.state.apps).map(clientId => {
                            let app = this.state.apps[clientId];
                            return {
                                icon: app.logoImgUrl,
                                name: app.title,
                                value: clientId
                            };
                        }),
                        { name: "Add New App...", action: this.openNewAppDialog, dontSelect: true }
                    ]} onSelect={selection => this.switchApp(selection.option.value)} />
                    : <h3>{this.state.apps[this.state.clientId]?.title ?? ""}</h3>
                : <Placeholder light={!(PORTAL.isMy() && getColorScheme() !== "light")} />
            }
        </>);
    }
}

const mapStateToProps = state => ({
    account: state.account,
    curRole: state.curRole,
    apps: state.apps,
    currentApp: state.currentApp
});

const mapActionsToProps = {
    onChangeApp: changeApp,
    onRefreshApps: refreshApps
};

export default connect(mapStateToProps, mapActionsToProps)(AppController);
