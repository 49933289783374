import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors from '../LumiAPI/APIs/errors.js';
import ServerAPI from '../LumiAPI/APIs/internal/server.js';

import Button from '../components/Button';
import List from '../partials/List';
import ContentWithToolbar from '../templates/ContentWithToolbar';

import Modal from '../tools/Modal';

import { connect } from 'react-redux';
import { updateApp } from '../actions/apps-actions.js';

class Servers extends Component {
    state = {
        loaded: false,
        servers: {
            "wss://52.158.248.106": {
                url: "wss://52.158.248.106",
                enabled: true,
                setting: "conf",
                clientId: "xcji5vMdfq54P8ySkulR",
                appCertificate: false,
                ip: "52.158.248.106",
                machineId: "1ca95ff870a6eba205b5c2fb6f9f5cb62d7df8b3985a84f4eedd02bba7ba877a",
                version: "1.0.0.130",
                windowsUpdate: -1,
                rebootRequired: false,
                cpuUsage: 0,
                memUsage: 28299264,
                connections: 0,
                clients: 0,
                sendThroughput: 0,
                receiveThroughput: 0,
                pingSuccess: 0,
                pingFailed: 0
            },
            "wss://52.158.248.112": {
                url: "wss://52.158.248.112",
                enabled: true,
                setting: "conf",
                clientId: "xcji5vMdfq54P8ySkulR",
                appCertificate: false,
                ip: "52.158.248.112",
                machineId: "1ca95ff870a6eba205b5c2fb6f9f5cb6249t8hasldfkdf8b3985a84f4eedd02bba7ba877a",
                version: "1.0.0.130",
                windowsUpdate: -1,
                rebootRequired: false,
                cpuUsage: 0,
                memUsage: 28299831,
                connections: 3,
                clients: 0,
                sendThroughput: 0,
                receiveThroughput: 0,
                pingSuccess: 0,
                pingFailed: 0
            }
        },
        selected: []
    };

    getServers = () => {
        this.setState({ loaded: false });
        ServerAPI.servers.get().then(servers => {
            let list = {};
            servers.forEach(server => {
                server.title = server.url;
                server.subtitle = server.ip;
                server.buttons = [
                    { icon: "arrowLeft", props: {
                        className: "arrow",
                        onClick: e => {
                            e.stopPropagation();
                            this.toggleDetails(server.url);
                        }
                    }}
                ];
                server.details = [
                    { name: "Connections", value: server.connections },
                    { name: "Client Sessions", value: server.clients },
                    { name: "CPU Usage", value: `${server.cpuUsage}%` },
                    { name: "Memory Usage", value: `${this.convertToMB(server.memUsage)} MB` }
                ];
                list[server.url] = server;
            });
            this.setState({ loaded: true, servers });
        }).catch(handleErrors);
        // let servers = this.state.servers;
        // Object.keys(servers).forEach(url => {
        //     let server = servers[url];
        //     server.title = server.url;
        //     server.subtitle = server.ip;
        //     server.buttons = [
        //         { icon: "arrowLeft", props: {
        //             className: "arrow",
        //             onClick: e => {
        //                 e.stopPropagation();
        //                 this.toggleDetails(server.url);
        //             }
        //         }}
        //     ];
        //     server.details = [
        //         { name: "Connections", value: server.connections },
        //         { name: "Client Sessions", value: server.clients },
        //         { name: "CPU Usage", value: `${server.cpuUsage}%` },
        //         { name: "Memory Usage", value: `${this.convertToMB(server.memUsage)} MB` }
        //     ];
        //     servers[url] = server;
        // });
        // setTimeout(() => {
        //     this.setState({ loaded: true, servers });
        // }, 3000);
    };

    toggleDetails = url => {
        let { servers } = this.state;
        servers[url].showDetails = !servers[url]?.showDetails ?? true;
        this.setState({ servers });
    };

    convertToMB = bytes => {
        let kB = bytes / 1024,
            MB = kB / 1024;
        return MB.toFixed(2);
    };

    enableSelected = () => {
        let { selected } = this.state;
        selected.forEach((url, i) => {
            ServerAPI.server.update(url, { enabled: true }).then(server => {
                if (i === selected.length - 1) this.getServers();
            }).catch(handleErrors);
        });
    };

    disableSelected = () => {
        let { selected } = this.state;
        selected.forEach((url, i) => {
            ServerAPI.server.update(url, { enabled: false }).then(server => {
                if (i === selected.length - 1) this.getServers();
            }).catch(handleErrors);
        });
    };

    deleteSelected = () => Modal(<>
        <h1>Delete?</h1>
        <p>Are you sure you want to delete the selected server/s?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let selected = this.state.selected,
                total = selected.length;
            selected.forEach((url, i) => {
                ServerAPI.server.delete(url).then(result => {
                    if (i === total - 1) this.getServers();
                }).catch(handleErrors);
            });
        }}}
    ], { alert: true });

    checkBackend = backend => {
        if (!backend) {
            this.goTo("/Portal/Settings");
        }
    };

    componentDidMount() {
        document.title = "Servers | Cordoniq";
        this.checkPermissions();
        this.getServers();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.getServers();
            this.checkBackend(this.props.app.backend);
        }
    }

    render() {
        return (
            <ContentWithToolbar id="servers" tools={[
                <button onClick={this.getServers}>Refresh</button>
            ]}>
                <h1>Servers</h1>
                <List id="servers" listOnly loaded={this.state.loaded} items={this.state.servers} actions={[
                    {
                        label: "Enable",
                        props: { onClick: this.enableSelected, disabled: this.state.selected.length === 0 }
                    },
                    {
                        label: "Disable",
                        props: { onClick: this.disableSelected, disabled: this.state.selected.length === 0 }
                    },
                    {
                        label: "Delete",
                        props: { onClick: this.deleteSelected, delete: true, disabled: this.state.selected.length === 0 }
                    }
                ]} filters={[
                    { name: "Enabled", value: "enabled", match: "enabled", options: [
                        { name: "Select Enabled State...", dontSelect: true },
                        { name: "Enabled", value: true }, { name: "Disabled", value: false }
                    ] }
                ]} sort={[
                    { options: [
                        { name: "URL", value: "url" },
                        { name: "Connections", value: "connections" },
                        { name: "Sessions", value: "clients" },
                        { name: "CPU Usage", value: "cpuUsage" },
                        { name: "Memory Usage", value: "memUsage" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })}
                emptyMessage={<>No Servers Found! Setup servers from <Button link onClick={e => {
                    e.preventDefault();
                    this.goTo("/Portal/PrivateCloud");
                }}>Private Cloud</Button> settings.</>} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    app: state.apps[state.currentApp]
});

const mapActionsToProps = {
    onUpdateApp: updateApp
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(Servers));
