import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors, { ERRORCODE } from '../LumiAPI/APIs/errors.js';
import AccountAPI from '../LumiAPI/APIs/internal/account.js';
import { accountIconColor, colors } from '../tools/colorSchemeManager';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';
import Dialog, { Container } from '../components/Dialog';
import Password from '../components/Password';
import SelectBox from '../components/SelectBox';
import TextField from '../components/TextField';
import List from '../partials/List';

import Modal from '../tools/Modal';
import Notification from '../tools/Notification';

import { connect } from 'react-redux';

const ROLE = { ALL: 0, ATTENDEE: 1, PRESENTER: 2, HOST: 3 };

class Roles extends Component {
    usernameRef = React.createRef();
    emailRef = React.createRef();

    state = {
        role: 0,
        loaded: false,
        selected: [],
        accounts: {},
        changes: {}
    };

    switchRole = role => this.setState({ role, selected: [] });

    getAccounts = role => {
        if (role !== undefined) {
            role = parseInt(role);
            this.switchRole(role);
        } else {
            role = this.state.role;
        }

        this.setState({ accounts: {}, loaded: false });
        AccountAPI.subaccounts.get().then(subaccounts => {
            let accounts = {};
            subaccounts.filter(subaccount => {
                if (role === 0) return true;
                return subaccount.role === role
            }).forEach(subaccount => {
                subaccount.id = subaccount.username;
                if (subaccount.pictureImgUrl) {
                    subaccount.image = {
                        src: subaccount.pictureImgUrl,
                        props: {
                            style: {
                                backgroundImage: `url(${subaccount.pictureImgUrl})`,
                                borderColor: colors[subaccount.color]
                            }
                        }
                    }
                } else {
                    subaccount.icon = {
                        name: "userAccount",
                        props: {
                            mode: subaccount.color !== undefined
                                ? accountIconColor(subaccount.color) : undefined,
                            bg: colors[subaccount.color]
                        }
                    };
                }
                subaccount.title = subaccount.username;
                subaccount.title += subaccount.firstName !== undefined
                    ? subaccount.lastName !== undefined
                        ? ` (${subaccount.firstName} ${subaccount.lastName})`
                        : ` (${subaccount.firstName})`
                    : "";
                subaccount.subtitle = subaccount.email;
                subaccount.buttons = [
                    { icon: "annotate", props: {
                        onClick: e => {
                            e.stopPropagation();
                            this.openEdit(subaccount.username);
                        }
                    }}
                ];
                accounts[subaccount.username] = subaccount;
            });
            this.setState({ accounts, loaded: true });
        })
    };

    openNew = () => this.setState({
        showNew: true,
        changes: {
            role: ROLE.ATTENDEE,
            username: "",
            email: "",
            password: ""
        }
    });

    createAccount = () => {
        let { changes } = this.state;
        AccountAPI.subaccount.new(changes).then(subaccount => {
            this.getAccounts(subaccount.role);
            this.setState({ showNew: false });
        }).catch(err => {
            let code = err?.response?.data?.error_code ?? null;
            if (code === 102) {
                new Notification({ id: "newAccountError", message: "Email is already taken! Please try another one", type: "error" });
                this.emailRef.current.focus();
            }
            if (code === ERRORCODE.USERNAME_EXISTS) {
                new Notification({ id: "newAccountError", message: "Username is already taken! Please try another one", type: "error" });
                this.usernameRef.current.focus();
            }
            handleErrors(err);
        });
    };

    createDisabled = () => {
        let { changes } = this.state;
        return changes.username === "" ||
               changes.email === "" ||
               !Password.valid(changes.password);
    };

    openEdit = username => {
        let account = this.state.accounts[username];
        this.setState({ showEdit: true, changes: { id: username, ...account, password: "" } });
    };

    editAccount = () => {
        let { changes, accounts } = this.state,
            account = accounts[changes.id],
            settings = {};
        Object.keys(changes).forEach(key => {
            if (changes[key] !== account[key]) {
                settings[key] = changes[key];
            }
        });
        if (changes.password !== "" && Password.valid(changes.password)) {
            settings.password = changes.password;
        } else {
            delete settings.password;
        }

        AccountAPI.subaccount.update(changes.id, settings).then(subaccount => {
            this.getAccounts(subaccount?.role ?? undefined);
            this.setState({ showEdit: false });
        }).catch(err => {
            let code = err?.response?.data?.error_code ?? null;
            if (code === ERRORCODE.USERNAME_EXISTS) {
                new Notification({ id: "newAccountError", message: "Username is already taken! Please try another one", type: "error" });
                this.usernameRef.current.focus();
            }
            handleErrors(err);
        });
    };

    promote = () => {
        let { selected, role } = this.state;
        role += 1;
        this.setState({ loaded: false });
        selected.forEach((username, i) => {
            AccountAPI.subaccount.update(username, { role: role }).then(subaccount => {
                if (i === selected.length - 1) {
                    this.getAccounts(role);
                    this.setState({ loaded: true, selected: []});
                }
            }).catch(handleErrors);
        });
    };

    delete = () => Modal(<>
        <h1>Delete?</h1>
        <p>Are you sure you want to delete the selected account/s?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let { selected } = this.state;
            this.setState({ loaded: false });
            selected.forEach((username, i) => {
                AccountAPI.subaccount.delete(username).then(result => {
                    if (i === selected.length - 1) {
                        this.getAccounts();
                        this.setState({ loaded: true, selected: [] });
                    }
                }).catch(handleErrors);
            });
        }}}
    ], { alert: true });

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection.name === "role"
            ? selection.option.value
            : selection;
        this.setState({ changes });
    };

    componentDidMount() {
        document.title = "Roles | Cordoniq";
        this.checkPermissions(this.props.curRole);
        let role = this.props.match.params.role;
        this.getAccounts(role);
    }

    componentDidUpdate(prevProps) {
        if (this.props.curRole !== prevProps.curRole) {
            this.checkPermissions(this.props.curRole);
        }

        if (this.props.match.params.role !== prevProps.match.params.role) {
            let role = this.props.match.params.role;
            this.getAccounts(role);
        }

        if (this.props.currentApp !== prevProps.currentApp) {
            this.getAccounts();
        }
    }

    render() {
        let changes = this.state.changes;
        let actions = [];
        if (this.state.role !== ROLE.ALL && this.state.role < ROLE.HOST) {
            actions.push({
                label: "Promote",
                props: { onClick: this.promote, disabled: this.state.selected.length === 0 }
            });
        }
        actions.push({
            label: "Delete",
            props: { delete: true, onClick: this.delete, disabled: this.state.selected.length === 0 }
        });
        return (
            <ContentWithToolbar id="roles" path={`/Portal/Roles/${this.state.role}`} tools={[
                <button onClick={this.openNew}>Create New</button>
            ]}>
                {this.state.showNew && <Dialog alert show={this.state.showNew} title="Create New" footer={[
                    <Button outline onClick={() => this.setState({ showNew: false })}>Cancel</Button>,
                    <Button onClick={this.createAccount} disabled={this.createDisabled()}>Create</Button>
                ]}>
                    <Container label="Account Details">
                        <SelectBox name="role" label="Role" selected={changes.role} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Attendee", value: 1 }, { name: "Presenter", value: 2 }, { name: "Host", value: 3 }
                        ]} onSelect={this.onSelect} />
                        <TextField ref={this.usernameRef} name="username" label="Username" placeholder="Enter a username..." value={changes.username} onEdit={this.onEdit} />
                        <TextField ref={this.emailRef} type="email" name="email" label="Email" placeholder="Enter an email..." value={changes.email} onEdit={this.onEdit} />
                        <Password new name="password" label="Password" placeholder="Enter a password..." value={changes.password} onEdit={this.onEdit} />
                    </Container>
                </Dialog>}
                {this.state.showEdit && <Dialog alert show={this.state.showEdit} title="Edit Account" footer={[
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Cancel</Button>,
                    <Button onClick={this.editAccount} disabled={!Password.valid(changes.password) && changes.password !== ""}>Save</Button>
                ]}>
                    <Container label="Account Details">
                        <SelectBox name="role" label="Role" selected={changes.role} options={[
                            { name: "Pick One...", dontSelect: true },
                            { name: "Attendee", value: 1 }, { name: "Presenter", value: 2 }, { name: "Host", value: 3 }
                        ]} onSelect={this.onSelect} />
                        <TextField ref={this.usernameRef} name="username" label="Username" placeholder="Enter a username..." value={changes.username} onEdit={this.onEdit} />
                        <TextField ref={this.emailRef} type="email" name="email" label="Email" placeholder="Enter an email..." value={changes.email} onEdit={this.onEdit} />
                        <Password new name="password" label="Password" placeholder="Enter a password..." value={changes.password} onEdit={this.onEdit} />
                    </Container>
                </Dialog>}
                <h1>Roles</h1>
                <List id="accounts" loaded={this.state.loaded} items={this.state.accounts} actions={actions} tabs={[
                    { label: "All", props: {
                        selected: this.state.role === ROLE.ALL,
                        onClick: () => this.getAccounts(ROLE.ALL)
                    }},
                    { label: "Attendee", props: {
                        selected: this.state.role === ROLE.ATTENDEE,
                        onClick: () => this.getAccounts(ROLE.ATTENDEE)
                    }},
                    { label: "Presenter", props: {
                        selected: this.state.role === ROLE.PRESENTER,
                        onClick: () => this.getAccounts(ROLE.PRESENTER)
                    }},
                    { label: "Host", props: {
                        selected: this.state.role === ROLE.HOST,
                        onClick: () => this.getAccounts(ROLE.HOST)
                    }}
                ]} filters={[
                    { name: "Search", value: "search", match: "username", placeholder: "Search Accounts..." }
                ]} sort={[
                    { options: [
                        { name: "Username", value: "username" },
                        { name: "Email", value: "email" },
                        { name: "Role", value: "role" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
	curRole: state.curRole,
	currentApp: state.currentApp
});

export default connect(mapStateToProps)(withNav(Roles));
