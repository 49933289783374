import { combineReducers } from 'redux';

import account from './account-reducer';
import curRole from './curRole-reducer';
import apps from './apps-reducer';
import currentApp from './currentApp-reducer';
import users from './users-reducer';
import unsavedChanges from './unsavedChanges-reducer';
import ChatReducers from './chat-reducers';

const allReducers = combineReducers({
    account: account,
    curRole: curRole,
    apps: apps,
    currentApp: currentApp,
    users: users,
    unsavedChanges: unsavedChanges,
    ...ChatReducers
});

export default allReducers;
