import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import * as fileDownload from 'js-file-download';
import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import ContentAPI from '../LumiAPI/APIs/content.js';
import { deleteMethod } from '../LumiAPI/APIs/content/Content.js';
import { getConferences, getLinkedContent, unlinkContent } from '../LumiAPI/APIs/core/conference.js';
import handleErrors from '../LumiAPI/APIs/errors.js';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Button from '../components/Button';
import Content from '../components/Content';
import Dialog, { Container } from '../components/Dialog';
import Icon from '../components/Icon';
import Placeholder from '../components/Placeholder';
import ProgressBar from '../components/ProgressBar';
import SelectBox from '../components/SelectBox';
import TextField from '../components/TextField';
import List from '../partials/List';

import Modal from '../tools/Modal';

import { connect } from 'react-redux';

import './css/library.css';

const MODE = { ADD: 1, UPLOADING: 2 };

class DropArea extends Component {
    state = {
        showPrompt: false,
        mode: MODE.ADD,
        dragging: false,
        dragOver: false,
        dragDropped: false,
        newFiles: {},
        loading: false
    };

    setMode = mode => this.setState({ mode, showPrompt: true });

    selectFiles = e => {
        e && e.preventDefault();
        e && e.stopPropagation();
        document.getElementById("fileElem").click();
    };

    handleDrop = e => {
        e.preventDefault();

        let dt = e.dataTransfer,
            files = dt.files;

        this.handleFiles(files);
        this.setState({ dragging: false, dragOver: false, dragDropped: true });
        setTimeout(() => {
            this.setState({ dragDropped: false });
        }, 300);
    };

    handleDragEnter = e => {
        e.preventDefault();
        this.setMode(MODE.ADD);
        this.setState({ dragging: true });
    };

    handleDragOver = e => {
        e.preventDefault();
        this.setState({ dragOver: true });
    };

    handleDragLeave = e => {
        e.preventDefault();
        this.setState({ dragging: false, dragOver: false });
    };

    handleFiles = files => {
        files = [...files];

        files.forEach(file => {
            let content = Content.fromFile(file);
            let newFiles = this.state.newFiles;
            newFiles[content.fileName] = {
                icon: content.getIcon(),
                loading: 1
            };
            this.setState({ newFiles });
            this.uploadContent(content);
        });
    };

    uploadContent = content => {
        this.setMode(MODE.UPLOADING);
        this.props.onUpload();

        let newFiles = this.state.newFiles;
        this.setState({ loading: true });
        ContentAPI.content.new(content.getFormData(), progress => {
            let completed = Math.round( (progress.loaded * 100) / progress.total );
            newFiles[content.fileName].loading = completed;
            this.setState({ newFiles });
        }).then(content => {
            let reload = true;
            Object.keys(newFiles).some(filename => {
                if (newFiles[filename].loading < 100) {
                    reload = false;
                    return true;
                } else {
                    return false;
                }
            });
            if (reload) {
                this.setState({ loading: false });
                this.props.onFinish();
            }
        }).catch(handleErrors);
    };

    onClick = () => {
        let { newFiles } = this.state;
        Object.keys(newFiles).forEach(filename => {
            if (newFiles[filename].loading >= 100) {
                delete newFiles[filename];
            }
        });
        let mode = Object.keys(newFiles).length > 1 ? MODE.UPLOADING : MODE.ADD;
        this.setState({ newFiles, mode });
        if (window.matchMedia("(max-width: 414px)").matches && mode === MODE.ADD) {
            this.selectFiles();
        } else {
            this.setState({ showPrompt: true });
        }
    };

    close = e => {
        if ((e && e.target.id === "addFiles") || (e && e.target.id === "prompt")) return;
        this.setState({ showPrompt: false });
    };

    className = () => {
        let className = "";
        className += this.state.showPrompt ? " show" : "";
        className += this.state.mode === MODE.UPLOADING ? " uploading" : "";
        className += this.state.dragging ? " dragging" : "";
        className += this.state.dragOver ? " dragOver" : "";
        className += this.state.dragDropped ? " dragDropped" : "";
        return className;
    };

    setEvents = mode => {
        const main = document.querySelector("#newportal main");
        const dropArea = document.querySelector("#newportal #content.library #addFiles #droparea");
        if (dropArea !== null) {
            if (mode === MODE.ADD) {
                dropArea.addEventListener('drop', this.handleDrop, false);
                main.addEventListener('dragenter', this.handleDragEnter, false);
                dropArea.addEventListener('dragover', this.handleDragOver, false);
                dropArea.addEventListener('dragleave', this.handleDragLeave, false);
            } else {
                dropArea.removeEventListener('drop', this.handleDrop, false);
                main.removeEventListener('dragenter', this.handleDragEnter, false);
                dropArea.removeEventListener('dragover', this.handleDragOver, false);
                dropArea.removeEventListener('dragleave', this.handleDragLeave, false);
            }
        }
    };

    componentDidMount() {
        this.setEvents(MODE.ADD);
        document.body.addEventListener("click", this.close);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mode !== prevState.mode) {
            this.setEvents(this.state.mode);
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.close);
    }

    render() {
        let newFiles = this.state.newFiles,
            filenames = Object.keys(this.state.newFiles);
        return (
            <>
            <button id="addFiles" onClick={this.onClick}>
                <span>{this.state.mode === MODE.ADD || (!this.state.loading && !this.state.showPrompt)
                    ? "Add Files..." : "Uploading..."
                }</span>
                <div id="prompt" className={this.className()}>
                    {this.state.mode === MODE.UPLOADING
                        ? <>
                        <div className="progress">
                        {filenames.map(filename => (
                            <div className="uploadingFile">
                                <Icon icon={newFiles[filename].loading >= 100
                                    ? "valid" : newFiles[filename].icon
                                } />
                                <div>
                                    <b>{filename}</b>
                                    <ProgressBar showLabel value={newFiles[filename].loading} />
                                </div>
                            </div>
                        ))}
                        </div>
                        <div id="buttons">
                            <Button onClick={e => {
                                e.stopPropagation();
                                this.setState({ showPrompt: false });
                            }}>Close</Button>
                            <Button onClick={e => {
                                e.stopPropagation();
                                this.setMode(MODE.ADD);
                                if (window.matchMedia("(max-width: 414px)").matches) {
                                    this.selectFiles();
                                }
                            }}>Add More Files</Button>
                        </div>
                        </>
                        : <>
                        <div id="droparea">
                            <Icon icon={faFileAlt} />
                            <span>Drag and Drop Files</span>
                        </div>
                        <span>or</span>
                        <Button onClick={this.selectFiles}>Select Files</Button>
                        </>
                    }
                    <input id="fileElem" type="file" multiple onChange={e => this.handleFiles(e.target.files)} />
                </div>
            </button>
            </>
        );
    }
}

class Library extends Component {
    state = {
        loaded: false,
        files: {},
        selected: [],
        changes: {}
    };

    getFiles = (contents) => {
        this.setState({ files: {}, loaded: false });
        let files = {};

        const getList = contents => {
            if (contents.length === undefined) contents = [contents];
            contents.forEach(content => {
                let newContent = new Content();
                newContent.set(content);
                newContent.setProp("id", content.contentId);
                newContent.setProp("icon", {
                    name: newContent.getIcon(),
                    props: { className: `file`, color: newContent.getIconColor() }
                });
                newContent.setProp("subtitle", content.displayName);
                newContent.setProp("buttons", [
                    { icon: "annotate", props: {
                        onClick: e => {
                            e.stopPropagation();
                            this.openEdit(content);
                        }
                    }}
                ]);
                files[content.contentId] = newContent;
            });
            this.setState({ files, loaded: true });
        };

        if (contents === undefined) {
            ContentAPI.contents.get().then(getList).catch(handleErrors);
        } else {
            getList(contents);
        }
    };

    getLinkedConferences = contentId => {
        let linkedConferences = [];
        getConferences().then(conferences => {
            let { changes } = this.state;
            conferences.forEach((conference, i) => {
                getLinkedContent(conference.confId).then(contents => {
                    if (contents.length === 0 && i === conferences.length - 1) {
                        changes.loading = false;
                    }
                    contents.forEach((content, j) => {
                        if (content.contentId === contentId) {
                            linkedConferences.push(conference);
                            changes.loading = i === conferences.length - 1 && j === contents.length - 1;
                            changes.conferences = linkedConferences;
                        }
                    });
                    this.setState({ changes });
                }).catch(handleErrors);
            });
        }).catch(handleErrors);
    };

    unlinkConference = confId => {
        let { changes } = this.state;
        changes.loading = true;
        this.setState({ changes });
        unlinkContent(confId, changes.contentId).then(result => {
            let conferences = [...changes.conferences];
            let index = conferences.findIndex(conference => conference.confId === confId);
            if (index > -1) {
                conferences.splice(index, 1);
            }
            changes.loading = false;
            changes.conferences = conferences;
            this.setState({ changes });
        }).catch(handleErrors);
    };

    openEdit = content => {
        this.getLinkedConferences(content.contentId);
        this.setState({
            showEdit: true,
            changes: {
                contentId: content.contentId,
                displayName: content.displayName,
                deleteMethod: { index: content.deleteMethod, option: { value: content.deleteMethod } },
                loading: true,
                conferences: []
            }
        });
    };

    save = () => {
        this.setState({ loaded: false, showEdit: false });

        let { changes } = this.state,
            request = { displayName: changes.displayName, deleteMethod: changes.deleteMethod.option.value },
            json = JSON.stringify(request),
            requestBlob = new Blob([json], { type: 'application/json' }),
            formData = new FormData();

        formData.append('request', requestBlob);
        ContentAPI.content.update(changes.contentId, formData)
            .then(content => this.getFiles())
            .catch(handleErrors);
    };

    downloadSelected = () => {
        let { files, selected } = this.state;
        selected.forEach(contentId => {
            files[contentId].loading = 1;
            this.setState({ files });
            ContentAPI.content.get(contentId, progress => {
                let completed = Math.round( (progress.loaded * 100) / progress.total );
                files[contentId].loading = completed >= 100 ? 0 : completed;
                this.setState({ files });
            }).then(file => {
                fileDownload(file, files[contentId].fileName);
            }).catch(handleErrors);
        });
    };

    deleteSelected = () => Modal(<>
        <h1>Delete?</h1>
        <p>Are you sure you want to delete the selected file/s?</p>
    </>, [
        { name: "No", props: { outline: true } },
        { name: "Yes", props: { onClick: () => {
            let { files, selected } = this.state;
            this.setState({ loaded: false });
            selected.forEach((contentId, i) => {
                ContentAPI.content.delete(contentId, false).then(result => {
                    if (i === selected.length - 1) this.getFiles();
                }).catch(err => {
                    if (err?.response?.data?.error_code === 302) {
                        Modal(<>
                            <h1>Error Occurred!</h1>
                            <p>{files[contentId].displayName} cannot be removed because it is being used in a conference.</p>
                            <p>Would you like to remove anyway?</p>
                        </>, [
                            { name: "No", props: { outline: true, onClick: () => {
                                if (i === selected.length - 1) this.getFiles();
                            }}},
                            { name: "Yes", props: { onClick: () => ContentAPI.content.delete(contentId, true).then(result => {
                                if (i === selected.length - 1) this.getFiles();
                            }).catch(handleErrors)}}
                        ], { alert: true });
                    }
                })
            });
        }}}
    ], { alert: true });

    onEdit = change => {
        let { changes } = this.state;
        changes[change.name] = change.value;
        this.setState({ changes });
    };

    onSelect = selection => {
        let { changes } = this.state;
        changes[selection.name] = selection;
        this.setState({ changes });
    };

    componentDidMount() {
        document.title = "Library | Cordoniq";
        this.getFiles();
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
            this.getFiles();
        }
    }

    render() {
        let { changes } = this.state;
        return (
            <ContentWithToolbar id="library" tools={[
                <DropArea onUpload={() => this.setState({ loaded: false })} onFinish={this.getFiles} />
            ]}>
                {this.state.showEdit && <Dialog show={this.state.showEdit} title="Edit File" footer={[
                    <Button outline onClick={() => this.setState({ showEdit: false })}>Cancel</Button>,
                    <Button onClick={this.save}>Save</Button>
                ]}>
                    <Container label="File Details">
                        <TextField name="displayName" placeholder="Enter a name..." label="Name" value={changes.displayName} onEdit={this.onEdit} />
                        <SelectBox name="deleteMethod" label="Change Delete Method" selected={changes.deleteMethod.index} options={[
                            { name: "Select Method...", dontSelect: true },
                            { name: "Never automatically delete", value: deleteMethod.NEVER },
                            { name: "Automatically delete when removed from conference", value: deleteMethod.AUTO }
                        ]} onSelect={this.onSelect} />
                    </Container>
                    <Container id="linkedConferences" label="File Usage">
                        {changes.loading
                            ? <Placeholder />
                            : changes.conferences.length === 0
                                ? <p>File Not Used in Any Conferences</p>
                                : changes.conferences.map(conference => (
                                    <div>
                                        <Icon icon="conference" />
                                        {conference.displayName}
                                        <Icon icon="close" mode="dark" onClick={() => this.unlinkConference(conference.confId)} />
                                    </div>
                                ))
                        }
                    </Container>
                </Dialog>}
                <h1>Library</h1>
                <List id="library" loaded={this.state.loaded} items={this.state.files} actions={[
                    {
                        label: "Download",
                        props: { onClick: this.downloadSelected, disabled: this.state.selected.length === 0 }
                    },
                    {
                        label: "Delete",
                        props: { onClick: this.deleteSelected, delete: true, disabled: this.state.selected.length === 0 }
                    }
                ]} filters={[
                    { name: "Search", value: "search", match: "displayName", placeholder: "Search Files..." }
                ]} sort={[
                    { options: [
                        { name: "Name", value: "displayName" },
                        { name: "Type", value: "contentType" }
                    ]}
                ]} onSelect={selected => this.setState({ selected })} />
            </ContentWithToolbar>
        );
    }
}

const mapStateToProps = state => ({
    app: state.apps[state.currentApp]
});

export default connect(mapStateToProps)(withNav(Library));
