import React, { Component } from 'react';
import { withNav } from '../hooks/useNav.js';
import CordoniqContentWithToolbar from '../templates/CordoniqContentWithToolbar.js';
import { ENV } from '../tools/envTools';

import './css/tvPostJoin.css';


class TvPostJoin extends Component {

    state = {
        roomId: '',
        password: ''
    }
   
    componentDidMount() {
        let url = new URL(window.location.href);
        let data = {
            roomId: url.searchParams.get('id') || '',
            password: url.searchParams.get('password') || ''
        }
        this.setState(data);
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        let subdomain = ENV.getApiDomain();
        let joinUrl = `https://my${subdomain}.cordoniq.com/join`
        let connectUrl = `https://connect${subdomain}.cordoniq.com/join?id=${this.state.roomId}&password=${this.state.password}`
        return (
            // <CordoniqContentWithToolbar buttons={buttons} headerextra={<Button>What's up</Button>}>
            <CordoniqContentWithToolbar footerButtons={[]} >
                <section>
                    <div className="TvPostJoin content"> 
                        <h1>To invite other people into your meeting...</h1>
                        <section>
                            <div className='row'>
                                <div className='row-item'>
                                    <div className='label'><span>Room ID:</span></div>
                                    <div className='textarea'><span>{this.state.roomId}</span></div>
                                </div>
                                <div className='row-item'>
                                    <div className='label'><span>Password:</span></div>
                                    <div className='textarea'><span>{this.state.password}</span></div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <h2>For Bravia TV:</h2>
                            <h3>Launch the Cordoniq app and enter the Room ID and Password</h3>                            
                        </section>
                        <section>
                            <h2>For Windows, Mac, iOS, or Android:</h2>
                            <h3>Go to the following url: <span className='urlref'>{joinUrl}</span> and enter the 
                            Room ID and Password</h3>
                        </section>
                        <section>
                            <h2>To join directly from a web browser:</h2>
                            <h3>Go to the following url:</h3>
                            <h3><span className='urlref'>{connectUrl}</span></h3>
                        </section>
                    </div>
                </section>
            </CordoniqContentWithToolbar>
        );
    }
}

export default withNav(TvPostJoin);
