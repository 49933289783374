class UserAgent {
    constructor() {
        if (navigator.userAgentData) {
            let { brands, mobile } = navigator.userAgentData;

            this.ua = { platform: "", brands, mobile };
            navigator.userAgentData.getHighEntropyValues([ "platform" ]).then(ua => {
                this.ua = { ...this.ua, ...ua };
            });
        } else {
            this.ua = this.parseUserAgent();
        }
    }

    parseUserAgent = () => {
        let ua = navigator.userAgent.toLowerCase(),
            uaObj = {
                platform: "Unknown",
                brands: [],
                mobile: false
            };

        if (ua.includes("mobile") || ua.includes("tablet") || ua.includes("android")) {
            uaObj.platform = "Android";
            uaObj.mobile = true;
        } else if (ua.includes("windows")) {
            uaObj.platform = "Windows";
        } else if (ua.includes("mac")) {
            uaObj.platform = "macOS";
        } else if (ua.includes("linux")) {
            uaObj.platform = "Linux";
        }

        let brand = "unknown", version = "unknown", re;
        let isOpera = !!window.opera || ua.includes(' opr/');
        if (isOpera) { // Opera
            brand = "Opera";
            if (ua.includes(' opr/')) {
                re = new RegExp("opr/([0-9]{1,}[.0-9]{0,})");
            } else if (ua.includes('version/')) {
                re = new RegExp("version/([0-9]{1,}[.0-9]{0,})");
            } else {
                re = new RegExp("opera/([0-9]{1,}[.0-9]{0,})");
            }
        } else if (ua.includes("firefox")) { // Firefox
            brand = "Firefox";
            re = new RegExp("firefox/([0-9]{1,}[.0-9]{0,})");
        } else if (/ipad|iphone|ipod/.test(ua) && !window.MSStream) { // iOS
            uaObj.platform = "iOS";
            brand = ua.includes("crios") ? "Chrome" : "Safari";
            re = ua.includes("crios")
                ? new RegExp("crios/([0-9]{1,}[.0-9]{0,})")
                : new RegExp("version/([0-9]{1,}[.0-9]{0,})");
        } else if (
            (ua.includes("safari") && !ua.includes("chrome")) ||
            (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0)
        ) { // Safari
            brand = "Safari";
            re = new RegExp("version/([0-9]{1,}[.0-9]{0,})");
        } else if (!!window.chrome && !isOpera) { // Chrome
            brand = "Chrome";
            re = new RegExp("chrome/([0-9]{1,}[.0-9]{0,})");
        } else if (/*@cc_on!@*/false || !!document.documentMode) { // IE6 and Up
            brand = "Internet Explorer";
            if (navigator.appName === "Microsoft Internet Explorer") {
                re = new RegExp("msie ([0-9]{1,}[.0-9]{0,})");
            } else if (navigator.appName === "Netscape") {
                re = new RegExp("trident/.*rv:([0-9]{1,}[.0-9]{0,})");
            }
        }

        // Weird user-agents, dev tools, etc, may 
        // leave re unassigned a value, so we should
        // catch this before it blows up page views.
        if (re && re.exec(ua) !== null) {
            version = parseFloat(RegExp.$1);
        } 
        uaObj.brands = [{ brand, version }];

        return uaObj;
    };

    // BROWSERS

    isOpera = () => (this.ua.brands.findIndex(browser => browser.brand.includes("Opera")) !== -1);

    isFirefox = () => (this.ua.brands.findIndex(browser => browser.brand.includes("Firefox")) !== -1);

    isSafari = () => (this.ua.brands.findIndex(browser => browser.brand.includes("Safari")) !== -1);

    isChrome = () => (this.ua.brands.findIndex(browser => browser.brand.includes("Chrome")) !== -1);

    isIE = () => (this.ua.brands.findIndex(browser => browser.brand.includes("Internet Explorer")) !== -1);

    version = () => (this.ua.brands[0].version);

    // PLATFORMS

    isWindows = () => (this.ua.platform === "Windows");

    isMacOS = () => (this.ua.platform === "macOS");

    isLinux = () => (this.ua.platform === "Linux");

    isIOS = () => (this.ua.platform === "iOS");

    isAndroid = () => (this.ua.platform === "Android");

    isMobile = () => (this.ua.mobile);
}

export default UserAgent;
