import React, { Component } from 'react';
import { isFirestoreAdmin, roles } from '../LumiAPI/APIs/internal/account.js';
import { withNav } from '../hooks/useNav';
import { PORTAL } from '../tools/envTools';

import SidebarIcon from '../components/SidebarIcon';

import { connect } from 'react-redux';

import "./css/sidebar.css";

class Sidebar extends Component {
    state = {
        isAdmin: false,
        curPath: window.location.pathname.toLowerCase(),
        sidebar: [],
        showSidebar: this.props.showSidebar ?? false,
        compact: this.props.compact ?? false,
        mobile: this.props.mobile ?? false
    };

    checkAdmin = username => isFirestoreAdmin(username)
        .then(isAdmin => this.setState({ isAdmin }))
        .catch(console.error);

    setItems = props => {
        props = props ?? this.props;
        let showPrivateCloud = props.app?.backend ?? false;
        let curRole = props.role;

        let showLogs = this.state.isAdmin;
        let showAppSubitems = PORTAL.isDev() && (showPrivateCloud || showLogs);

        let sidebar = [
            {
                displayName: "Dashboard",
                icon: "dashboard",
                path: "/Portal/Dashboard"
            },
            { spacer: true },
            {
                displayName: "Conferences",
                icon: "conference",
                action: () => this.openPage("/Portal/Conferences/1"),
                items: [
                    { displayName: "All", path: "/Portal/Conferences/1" },
                    { displayName: "Waiting to Start", path: "/Portal/Conferences/2" },
                    { displayName: "Live", path: "/Portal/Conferences/3" },
                    { displayName: "Ended", path: "/Portal/Conferences/4" },
                    { displayName: "Users", path: "/Portal/Users", show: curRole > roles.PRESENTER }
                ]
            },
            {
                displayName: "Roles",
                icon: "people",
                action: () => this.openPage("/Portal/Roles/0"),
                items: [
                    { displayName: "All", path: "/Portal/Roles/0" },
                    { displayName: "Attendees", path: "/Portal/Roles/1" },
                    { displayName: "Presenters", path: "/Portal/Roles/2" },
                    { displayName: "Hosts", path: "/Portal/Roles/3" }
                ],
                show: curRole === roles.OWNER
            },
            {
                displayName: "Recordings",
                icon: "record",
                path: "/Portal/Recordings"
            },
            {
                displayName: "Library",
                icon: "library",
                path: "/Portal/Library"
            },
            { spacer: true },
            {
                displayName: "Integrations",
                icon: "integrations",
                action: () => this.openPage("/Portal/Integrations/LTI"),
                items: [
                    { displayName: "App Integrations", action: () => window.open("/Integrations", "_blank") },
                    { displayName: "LTI Integrations", path: "/Portal/Integrations/LTI" },
                    { displayName: "Subscribers", path: "/Portal/Subscribers" }
                ],
                show: PORTAL.isDev() && curRole === roles.OWNER
            },
            {
                displayName: "Downloads",
                icon: "download",
                path: "/Portal/Downloads"
            },
            {
                displayName: "Account Settings",
                icon: "settings",
                path: "/Portal/UserSettings",
                show: curRole < roles.OWNER
            },
            {
                displayName: "App Settings",
                icon: "settings",
                action: showAppSubitems ? () => this.openPage("/Portal/Settings") : undefined,
                path: showAppSubitems ? undefined : "/Portal/Settings",
                items: showAppSubitems ? [
                    { displayName: "Basic Settings", path: "/Portal/Settings" },
                    { displayName: "Private Cloud", path: "/Portal/PrivateCloud", show: showPrivateCloud },
                    { displayName: "My Servers", path: "/Portal/Servers", show: showPrivateCloud },
                    { displayName: "Logs", path: "/Logs", show: showLogs }
                ] : undefined,
                show: curRole === roles.OWNER
            }
        ];

        this.setState({ sidebar });
    };

    openPage = path => {
        this.setState({ path: path.toLowerCase() });
        this.goTo(path);
    };

    submenuIsOpen = section => {
        if (section.items === undefined) return false;
        let curPath = this.state.curPath,
            isOpen = false;
        section.items.some(link => {
            if (link.path?.toLowerCase() === curPath ?? false) isOpen = true;
            return isOpen;
        });
        return isOpen;
    };

    isSelected = path => {
        if (path === undefined) return undefined;
        let { curPath } = this.state;
        return curPath === path.toLowerCase() ? "selected" : "";
    };

    getMenuClass = section => {
        let { curPath } = this.state;
        if(section?.path?.toLowerCase() === curPath) {
            return  "selected";
        }
        for(let item of section?.items || []) {
            if (item?.path?.toLowerCase() === curPath) {
                return "selected-child";
            }
        }
    }

    getAction = item => {
        if (item.action) return item.action;
        if (item.path) return e => {
            e.stopPropagation();
            this.openPage(item.path);
        }
        return undefined;
    };

    className = () => {
        let className = "portalSidebar";
        className += this.state.compact ? " compact" : "";
        className += this.state.mobile ? " mobile" : "";
        if (this.state.compact && !this.state.showSidebar) {
            className += " hide";
        }
        return className;
    };

    componentDidMount() {
        this.setState({ curPath: this.props?.path?.toLowerCase() ?? window.location.pathname.toLowerCase() });
        this.checkAdmin(this.props.account?.username ?? "unknown");
        this.setItems();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.account !== this.props.account) {
            this.checkAdmin(this.props.account?.username ?? "unknown");
        }

        if ((prevProps.app !== this.props.app) ||
            (prevProps.role !== this.props.role) || 
            (prevState.isAdmin !== this.state.isAdmin)
        ) {
            this.setItems(this.props);
        }

        if (this.props.path !== prevProps.path) {
            let curPath = this.props.path === undefined
                ? window.location.pathname.toLowerCase()
                : this.props.path.toLowerCase();
            this.setState({ curPath });
        }

        if (prevProps.showSidebar !== this.props.showSidebar) {
            this.setState({ showSidebar: this.props.showSidebar });
        }
        if (prevProps.compact !== this.props.compact) {
            this.setState({ compact: this.props.compact });
        }
        if (prevProps.mobile !== this.props.mobile) {
            this.setState({ mobile: this.props.mobile });
        }
    }

    render() {
        return (
            <aside className={this.className()}>
                <ul>
                    {this.state.sidebar.map((section, i) => (
                        section.show === false ? <React.Fragment key={`nullList_${i}`}></React.Fragment> : section.spacer
                            ? <li key={`spacer_${i}`} className="spacer"></li>
                            : <li key={`${section.path}_${i}`} className={this.getMenuClass(section)} onClick={this.getAction(section)} >
                                <span>
                                    {section.icon && <SidebarIcon icon={section.icon} />}
                                    {section.displayName}
                                </span>
                                {this.submenuIsOpen(section) && section.items && <ul>
                                    {section.items.map((link, j) => {
                                        return link.show !== false
                                            ? <li key={`${link.path}_${j}`} className={this.isSelected(link.path)} onClick={this.getAction(link)}>{link.displayName}</li>
                                            : <React.Fragment key={`null_${j}`}></React.Fragment>;
                                    })}
                                </ul>}
                            </li>
                    ))}
                </ul>
            </aside>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    app: state.apps[state.currentApp],
    role: state.curRole
});

export default connect(mapStateToProps)(withNav(Sidebar));
