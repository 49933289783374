import * as fileDownload from 'js-file-download';
import React, { Component } from 'react';
import { withNav } from '../hooks/useNav';
import handleErrors from '../LumiAPI/APIs/errors.js';
import { getPrivateKey } from '../LumiAPI/APIs/internal/account.js';
import AppAPI from '../LumiAPI/APIs/internal/app.js';
import { ENV, PORTAL } from '../tools/envTools';

import PrivateCloudSettings from './PrivateCloudSettings';

import Button from '../components/Button';
import CopyField from '../components/CopyField';
import ImageSelector from '../components/ImageSelector';
import TextField from '../components/TextField';
import Toggle from '../components/Toggle';
import NewApp from '../pages/NewApp';
import Section from '../partials/Section';
import ContentWithToolbar from '../templates/ContentWithToolbar';

import Modal from '../tools/Modal';

import { connect } from 'react-redux';
import { refreshApps, updateApp } from '../actions/apps-actions';
import { changeApp } from '../actions/currentApp-actions';

import './css/settings.css';

class Settings extends Component {
    state = {
        processing: false,
        saved: false,
        logoImgUrl: this.props.app?.logoImgUrl ?? undefined,
        title: this.props.app?.title ?? "",
        description: this.props.app?.description ?? "",
        default: this.props.app?.default ?? false,
        privacyPolicyUrl: this.props.app?.privacyPolicyUrl ?? "",
        termsOfServiceUrl: this.props.app?.termsOfServiceUrl ?? "",
        clientId: this.props.app?.clientId ?? "",
        clientSecret: this.props.app?.clientSecret ?? "",
        apiKey: this.props.app?.apiKey ?? ""
    };

    refreshFields = app => {
		this.setState({
			logoImgUrl: app?.logoImgUrl ?? undefined,
			title: app?.title ?? "",
			description: app?.description ?? "",
			default: app?.default ?? false,
			privacyPolicyUrl: app?.privacyPolicyUrl ?? "",
			termsOfServiceUrl: app?.termsOfServiceUrl ?? "",
			clientId: app?.clientId ?? "",
			clientSecret: app?.clientSecret ?? "",
			apiKey: app?.apiKey ?? ""
		});
	};

    onEdit = change => this.setState({ [change.name]: change.value });

    updateApp = () => {
        this.setState({ processing: true, saved: false });

        let fields = {};

        Object.keys(this.state).forEach(key => {
            if (key === "processing" || key === "saved" || key === "privateKey") return null;
            if (this.state[key] !== this.props.app[key] && this.state[key] !== "") {
                fields[key] = this.state[key];
            }
        });

        if (Object.keys(fields).length > 0) {
            AppAPI.app.update(this.state.clientId, fields).then(result => {
                AppAPI.app.get(this.state.clientId).then(app => {
                    this.props.onUpdateApp(app);
                    this.setState({ processing: false, saved: true });
                    setTimeout(() => this.setState({ saved: false }), 3000);
                });
            }).catch(handleErrors);
        }
    };

    reloadApps = () => {
        localStorage.removeItem(`last_app${ENV.getStorageSuffix()}`);
        AppAPI.apps.get().then(apps => {
            let appObj = {}, defaultApp = undefined;
            apps.forEach(app => {
                appObj[app.clientId] = app;
                if (app.default) {
                    defaultApp = app.clientId;
                }
            });
            this.props.onRefreshApps(appObj);
            if (defaultApp !== undefined) {
                localStorage.setItem(`last_app${ENV.getStorageSuffix()}`, defaultApp);
                AppAPI.app.switch(defaultApp).then(result => {
                    this.props.onChangeApp(defaultApp);
                }).catch(handleErrors);
            }
        }).catch(err => {
            this.goTo("/Portal");
        });
    };

    delete = () => {
        const isDefault = this.state?.default ?? false;
        Modal(<>
            <h1>{isDefault ? "Unable to Delete!" : `Delete ${this.state.title}?`}</h1>
            <p>{isDefault
                ? "You must set another default app before deleting this app."
                : "Are you sure you want to delete this app?"
            }</p>
        </>, [
            { name: "Close", props: { outline: true } },
            { name: isDefault ? "Add New App" : "Delete", props: { onClick: () => {
                if (isDefault) {
                    this.setState({ showNewApp: true });
                } else {
                    AppAPI.app.delete(this.state.clientId).then(result => {
                        this.reloadApps();
                    }).catch(handleErrors);
                }
            }}}
        ], { alert: true });
    };

    downloadPrivateKey = () => getPrivateKey().then(privateKey => {
        fileDownload(privateKey, 'lumi_privateKey.txt');
    }).catch(handleErrors);

    componentDidMount() {
        this.checkPermissions();
        document.title = "App Settings | Cordoniq";

        if (this.props.app) {
            this.setState(this.props.app);
        }

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.app !== prevProps.app) {
			this.refreshFields(this.props.app);
		}

		if (this.props.curRole !== prevProps.curRole) {
			this.checkPermissions(this.props.curRole);
		}
    }

    render() {
        const isDefault = this.props.app?.default ?? false;

        var clientId = this.state.clientId;
        var uploadInfo = { clientId, action: AppAPI.app.setLogo };
        var deleteInfo = { clientId, action: AppAPI.app.removeLogo };

        return (
            <>
            {this.state.showNewApp && <NewApp show={this.state.showNewApp} set={value => this.setState({ showNewApp: value })} />}
            <ContentWithToolbar id="settings">
                <h1>App Settings</h1>
                <Section id="appDetails" title="App Details">
                    <div id="details">
                        <ImageSelector image={this.state.logoImgUrl} upload={uploadInfo} delete={deleteInfo} />
                        <div id="details-fields">
                            <TextField name="title" className="grow" placeholder="App Name Here" label="App Name" value={this.state?.title ?? ""} onEdit={this.onEdit} />
                            <div className="static">
                                <label>Make Default</label>
                                <Toggle name="default" enabled={this.state.default} disabled={isDefault} click={value => this.setState({ default: value })} />
                            </div>
                            <TextField type="textarea" name="description" placeholder="App Description Here" label="App Description" value={this.state?.description ?? ""} onEdit={this.onEdit} />
                        </div>
                    </div>
                    <div id="other-details">
                        <TextField type="url" name="privacyPolicyUrl" label="Privacy Policy URL" placeholder="Enter URL (Optional)" value={this.state?.privacyPolicyUrl ?? ""} onEdit={this.onEdit} />
                        <TextField type="url" name="termsOfServiceUrl" label="Terms of Service URL" placeholder="Enter URL (Optional)" value={this.state?.termsOfServiceUrl ?? ""} onEdit={this.onEdit} />
                    </div>
                    <div id="buttons">
                        <Button delete disabled={this.state.processing} onClick={this.delete}>Delete</Button>
                        <Button disabled={this.state.processing} onClick={this.updateApp}>
                            {this.state.processing
                                ? "Processing..."
                                : this.state.saved
                                    ? "Changes Saved!"
                                    : "Update"
                            }
                        </Button>
                    </div>
                </Section>
                {PORTAL.isDev() && <>
                <Section id="appKeys" title="App Keys">
                    <CopyField id="clientId" label="Client ID" placeholder="Client ID" value={clientId} />
                    <CopyField type="password" id="clientSecret" label="Client Secret" placeholder="Client Secret" value={this.state.clientSecret} />
                    <CopyField id="apiKey" label="API Key" placeholder="API Key" value={this.state.apiKey} />
                    <Button outline onClick={this.downloadPrivateKey}>Download Private Key</Button>
                </Section>
                <Section id="appApis" title="Download APIs">
                    <p>Want to download the APIs? Click on the links below to download the JSON or YAML versions. You can also review the docs at <Button link href="https://docs.cordoniq.com">Cordoniq Docs</Button>.</p>
                    <div>
                        <h2>Auth API</h2>
                        <Button outline onClick={() => window.open("https://app.swaggerhub.com/apiproxy/schema/file/apis/lumicademy/lumi-auth/v1?format=json")}>JSON</Button>
                        <Button outline onClick={() => window.open("https://app.swaggerhub.com/apiproxy/schema/file/apis/lumicademy/lumi-auth/v1?format=yaml")}>YAML</Button>
                    </div>
                    <div>
                        <h2>Core API </h2>
                        <Button outline onClick={() => window.open("https://app.swaggerhub.com/apiproxy/schema/file/apis/lumicademy/lumi-core/v1?format=json")}>JSON</Button>
                        <Button outline onClick={() => window.open("https://app.swaggerhub.com/apiproxy/schema/file/apis/lumicademy/lumi-core/v1?format=yaml")}>YAML</Button>
                    </div>
                    <div>
                        <h2>Content API </h2>
                        <Button outline onClick={() => window.open("https://app.swaggerhub.com/apiproxy/schema/file/apis/lumicademy/lumi-content/v1?format=json")}>JSON</Button>
                        <Button outline onClick={() => window.open("https://app.swaggerhub.com/apiproxy/schema/file/apis/lumicademy/lumi-content/v1?format=yaml")}>YAML</Button>
                    </div>
                </Section>
                <PrivateCloudSettings />
                </>}
            </ContentWithToolbar>
            </>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    app: state.apps[state.currentApp]
});

const mapActionsToProps = {
    onChangeApp: changeApp,
    onRefreshApps: refreshApps,
	onUpdateApp: updateApp
};

export default connect(mapStateToProps, mapActionsToProps)(withNav(Settings));
