const users = (state = [], action) => {
    switch (action.type) {
        case 'UPDATEUSERS':
            return action.payload.users;
        default:
            return state;
    }
};

export default users;
