import React, { Component } from 'react';
import { withNav } from '../../hooks/useNav';

import ContentWithToolbar from '../../templates/ContentWithToolbar';
import Section from '../../partials/Section';
import Button from '../../components/Button';

import { connect } from 'react-redux';

import './css/redirect.css';

class NoPermission extends Component {
	componentDidMount() {
		this.checkPermissions();
	}

	componentDidUpdate(prevProps) {
		if (this.props.curRole !== prevProps.curRole) {
			this.checkPermissions(this.props.curRole);
		}
	}

	render() {
		return (
			<ContentWithToolbar id="redirect" noSidebar>
				<Section title="Insufficient Access">
					<p>Your account does not have permission to access this feature!</p>
					<p>Talk to your account administrator if you need access to this feature</p>
					<Button onClick={() => this.goTo("/Portal")}>Go to Dashboard</Button>
				</Section>
			</ContentWithToolbar>
		);
	}
}

const mapStateToProps = state => ({
	curRole: state.curRole
});

export default connect(mapStateToProps)(withNav(NoPermission));
