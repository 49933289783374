import React, { Component } from 'react';
import { setThemeColor, overrideColorScheme, removeColorSchemeOverride } from '../../tools/colorSchemeManager.js';

import NavBar from '../partials/NavBar';
import LandingHeader from '../partials/LandingHeader';
import CompactFooter from '../partials/CompactFooter';

import "./css/landingPageWithBreadCrumbs.css";

class LandingPageWithBreadCrumbs extends Component {
    componentDidMount() {
        setThemeColor(255, 255, 255);
        overrideColorScheme('light');
    }

    componentWillUnmount() {
        removeColorSchemeOverride();
    }

    render() {
        return (
            <main className="landingPageWithBreadCrumbs">
                <NavBar linkTag={this.props.id ?? "nav"} />
                <LandingHeader {...this.props} />
                <div id={this.props.id}>{this.props.children}</div>
                <CompactFooter />
            </main>
        )
    }
}

export default LandingPageWithBreadCrumbs;
