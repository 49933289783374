import React, { Component } from 'react';
import { colors, accountIconColor } from '../tools/colorSchemeManager';
import { getProfiles, getProfileDescription, setProfilePreference, ProfileUpdater } from '../tools/profileManager';

import Icon from '../components/Icon';
import Password from '../components/Password';

import Modal from '../tools/Modal';

import './css/savedProfiles.css';

var x = 0,
    deltaX = 0,
    posCurrent = 0;

class SignInSaved extends Component {
    state = {
        profiles: {},
        selected: {}
    };

    selectProfile = (username, e) => {
        if (this.state.selected.username === username) return;
        let selected = { username, password: "" };
        this.setState({ selected });
        this.props.onChange(selected);

        let passwordField = e.target.querySelector("input[type=password]");
        setTimeout(() => passwordField.focus(), 1);
    };

    openRemoveDialog = (e, username) => {
        e.preventDefault();
        e.stopPropagation();
        Modal(<>
            <h1>Remove Saved Profile?</h1>
            <p>Are you sure you want to remove the saved profile for {getProfileDescription(username)} from this device?</p>
        </>, [
            { name: "No", props: { outline: true } },
            { name: "Yes", props: { onClick: () => setProfilePreference(false, { username: username }) } }
        ], { alert: true });
    };

    updatePassword = change => {
        let selected = this.state.selected;
        selected.password = change.value;
        this.setState({ selected });
        this.props.onChange(selected);
    };

    // TOUCH EVENTS FOR REMOVE BUTTON REVEAL

    getProfileContainerFromEvent = e => {
        let target = e.target;
        while (!target.parentElement.classList.contains("savedProfile")) {
            target = target.parentElement;
        }
        return target;
    };

    getFrameValue = (percent, range) => {
		if (percent > 1) percent /= 100;
		return percent * (range.to - range.from) + range.from;
	};

    getBorderRadius = () => {
        let percent = posCurrent / -115;
        let range = { from: 8, to: 0 };
        let radius = this.getFrameValue(percent, range);
        return `8px ${radius}px ${radius}px 8px`;
    };

    dragStart = e => {
        e = e || window.event;
        e.preventDefault();

        if (e.type === 'touchstart') {
            x = e.touches[0].pageX;
        } else {
            x = e.pageX;
            document.onmouseup = this.dragEnd;
            document.onmousemove = this.dragAction;
        }
    };

    dragAction = e => {
        e = e || window.event;
        let min = -115, max = 0;

        if (e.type === 'touchmove') {
            deltaX = e.touches[0].pageX - x;
            x = e.touches[0].pageX;
        } else {
            deltaX = e.pageX - x;
            x = e.pageX;
        }

        posCurrent += deltaX;
        posCurrent = posCurrent < min ? min : (posCurrent > max ? max : posCurrent);

        let target = this.getProfileContainerFromEvent(e);
        target.style.left = `${posCurrent}px`;
        target.style.borderRadius = this.getBorderRadius();
    };

    dragEnd = e => {
        let target = this.getProfileContainerFromEvent(e);
        let showButton = posCurrent < -57;
        let snapPosition = showButton ? -115 : 0;
        target.classList.add("transition");
        target.style.left = `${snapPosition}px`;
        target.style.borderRadius = this.getBorderRadius();
        target.classList.remove("transition");
        x = 0;
        posCurrent = snapPosition;
        deltaX = 0;
        document.onmouseup = null;
        document.onmousemove = null;
    };

    updateProfilesList = profiles => this.setState({ profiles: profiles });

    componentDidMount() {
        this.setState({ profiles: getProfiles() });
        ProfileUpdater.add(this.updateProfilesList);
    }

    componentWillUnmount() {
        ProfileUpdater.remove(this.updateProfilesList);
    }

    render() {
        return (
            <div id="savedProfiles">
                {Object.keys(this.state.profiles).map(username => {
                    let profile = this.state.profiles[username];
                    return <div className={this.state.selected.username === username ? "savedProfile selected" : "savedProfile"} onClick={e => this.selectProfile(username, e)}>
                        <div onTouchStart={this.dragStart} onTouchMove={this.dragAction} onTouchEnd={this.dragEnd}>
                            {profile.profilePic !== undefined
                                ? <div id="account" className="pic" style={{ backgroundImage: `url(${profile.profilePic})`, borderColor: colors[profile.color] }} />
                                : <Icon id="account" icon="userAccount" mode={accountIconColor(profile.color)} bg={colors[profile.color]} />
                            }
                            <div>
                                <b>{getProfileDescription(username)}</b>
                                <Password fill name="password" placeholder="Password" value={this.state.selected.password} onEdit={this.updatePassword} />
                            </div>
                            <Icon icon="close" onClick={e => this.openRemoveDialog(e, username)} />
                        </div>
                        <button type="button" onClick={e => {
                            this.openRemoveDialog(e, username);
                            posCurrent = 0;
                            e.target.previousElementSibling.style = "";
                        }}>Remove</button>
                    </div>
                })}
                <div className="savedProfile other" onClick={() => this.props.onManualEntry()}>
                    <div>
                        <Icon id="account" icon="userAccount" mode={accountIconColor(17)} bg={colors[17]} />
                        <div>
                            <b>Other Account</b>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignInSaved;
