import React, { Component } from 'react';
import { roles } from '../LumiAPI/APIs/internal/account.js';
import { ENV, PORTAL } from '../tools/envTools';

import NavigationTour from '../partials/NavigationTour';
import TourDialog from '../partials/TourDialog';

import DashHeader from '../partials/DashHeader';
import Files from '../partials/Files';
import NewUsers from '../partials/NewUsers';
import Tour from '../partials/Tour';
import Upcoming from '../partials/Upcoming';
import ContentWithToolbar from '../templates/ContentWithToolbar';

import { connect } from 'react-redux';

import "./css/dashboard.css";

const SHOW_TOUR_KEY = `showTour${ENV.getStorageSuffix()}`;

const parseBool = string => {
	return string !== false && string !== "false";
};

class Dashboard extends Component {
	tourRef = React.createRef();
    state = { components: [] };

    updateComponents = props => {
        let username = props?.account?.username ?? this.props.account?.username ?? "";
        let curRole = props?.curRole ?? this.props.curRole;
        let showTour = parseBool(localStorage.getItem(SHOW_TOUR_KEY+username));

        var components = [];
        if (showTour) {
            components.push(<Tour start={() => this.tourRef.current.show()} remove={this.updateComponents} />);
        }
        if (curRole <= roles.OWNER) {
            components.push(<Upcoming />);
            components.push(<Files />);
        }
        if (curRole > roles.HOST && PORTAL.isDev()) components.push(<NewUsers />);
        this.setState({ components });
    };

	setTour = show => this.setState({ showTour: show });

    componentDidMount() {
        document.title = "Dashboard | Cordoniq";
        this.updateComponents();

        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.updateComponents(this.props);
    }

    render() {
        return (
			<>
			<TourDialog ref={this.tourRef} setTour={this.setTour} />
			<NavigationTour show={this.state.showTour} setTour={this.setTour} />
            <ContentWithToolbar id="dashboard">
				<DashHeader />
                <div className="grid">
                    {this.state.components.map((component, i) => <React.Fragment key={i}>{component}</React.Fragment>)}
                </div>
            </ContentWithToolbar>
			</>
        );
    }
}

const mapStateToProps = state => ({
    curRole: state.curRole,
    app: state.apps[state.currentApp],
    account: state.account
});

export default connect(mapStateToProps)(Dashboard);
