export const STORAGE_KEY = 'user-color-scheme';
export const OVERRIDE_STORAGE_KEY = 'user-color-scheme-override';
export const COLOR_MODE_KEY = '--color-mode';

// For profile and annotations
export const colors = [ "#F8DF57", "#F1A738", "#C98438", "#D3EE2C", "#67D890", "#8FFF00", "#BB299B", "#E0528A", "#F68BF8", "#F14338", "#AD58CD", "#6651F6", "#99FBE6", "#56CCF2", "#116DF9", "#333233", "#827F83", "#D3CDD3" ];

export const accountIconColor = colorIndex => {
	switch (colorIndex) {
		case 6:
		case 7:
		case 9:
		case 10:
		case 11:
		case 15:
		case undefined:
			return "dark";
		default:
			return "light";
	}
};

export const setThemeColor = (red, green, blue) => {
	let tag = document.querySelector("meta[name=theme-color]");
	tag.setAttribute("content", `rgb(${red}, ${green}, ${blue})`);
};

export const setNeutralThemeColor = colorScheme => {
	if (colorScheme === undefined) colorScheme = getColorScheme();
	if (colorScheme === "light") {
		setThemeColor(245, 244, 245);
	} else {
		setThemeColor(63, 57, 65);
	}
};

// Set up a listener for system preferred color scheme changes
const DarkModeOn = window.matchMedia("(prefers-color-scheme: dark)");
DarkModeOn.addListener(() => ColorUpdater.notify());

export const getColorMode = (propKey) => {
	let response = getComputedStyle(document.documentElement).getPropertyValue(propKey);

	if (response.length) {
		response = response.replace(/"/g, '').trim();
	}

	return response;
};

export const getColorScheme = () => {
	var color;
	var portalPref = document.documentElement.getAttribute("data-user-color-scheme");

	switch(portalPref) {
		case 'auto':
			color = (DarkModeOn.matches ? "dark" : "light");
			break;
		case 'light':
			color = "light";
			break;
		case 'dark':
			color = "dark";
			break;
		default:
			color = (DarkModeOn.matches ? "dark" : "light");
	}

	return color;
};

export const setColorScheme = (passedSetting) => {
	var currentSetting = passedSetting
		|| localStorage.getItem(OVERRIDE_STORAGE_KEY)
		|| localStorage.getItem(STORAGE_KEY)
		|| 'auto';

	document.documentElement.setAttribute('data-user-color-scheme', currentSetting);
	localStorage.setItem(STORAGE_KEY, currentSetting);

	ColorUpdater.notify(currentSetting);

	return currentSetting;
};

export const overrideColorScheme = setting => {
	document.documentElement.setAttribute('data-user-color-scheme', setting);
	localStorage.setItem(OVERRIDE_STORAGE_KEY, setting);

	ColorUpdater.notify(setting);

	return setting;
};

export const removeColorSchemeOverride = () => {
	localStorage.removeItem(OVERRIDE_STORAGE_KEY);
	setColorScheme();
};

export const changeColorScheme = (e) => {
	let currentSetting;

	switch (e.target.getAttribute("for")) {
		case 'auto':
			currentSetting = 'auto';
			break;
		case 'light':
			currentSetting = 'light';
			break;
		case 'dark':
			currentSetting = 'dark';
			break;
		default:
			currentSetting = 'auto';
	}

	document.documentElement.setAttribute('data-user-color-scheme', currentSetting);
	localStorage.setItem(STORAGE_KEY, currentSetting);

	ColorUpdater.notify(currentSetting);

	return currentSetting;
};

export var ColorUpdater = {
	subscribers: [],
	add: function(subscriber) {
		this.subscribers.push(subscriber);
	},
	remove: function(subscriber) {
		var index = this.subscribers.indexOf(subscriber);
		if (index !== -1) this.subscribers.splice(index, 1);
	},
	notify: function(colorScheme) {
		this.subscribers.forEach(subscriber => {
			subscriber(colorScheme);
		});
	}
};
